import { baseURL } from "../../env"
import { get } from "../signin/axios"


export const getTemplatesCountApi = async(payload)=>{
    if(payload!=undefined){
        let res = get(baseURL+"templates/count/"+payload);
        return res;
    }
    
}