import { useEffect, useState } from "react"
import "./CreateMotors.css"
import { Button, Select, TextField, Tooltip } from "@mui/material";
import MotorBodyTileLast from "./MotorBodyTileLast";
import MotorBodyTileLeft from "./MotorBodyTileLeft";
import MotorBodyTileRight from "./MotorBodyTileRight";
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import {selectActivepipelineid, updatePipeline, selectPipeline, deployPipeline} from "../../Services/pipelines/pipeline.slice";
import TestStage from "../apps/TestStage/TestStage";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deactivatecollaborator, getActiveCollaborators, selectActiveCollaborators } from "../../Services/collaborators/collaborators.slice";

const EmptyStage = ()=>{
    return (
        <div className="empty-stage">
            <div className="empty-stage-body">
            </div>
        </div>
    )
}


// add description during saving of the pipeline -> helpful in generating apis documentation
// option to test full pipeline during testing.
// resize-ui bocks 
const CreateMotors = ({
                        close, 
                        schemaslug,
                        pipelinetype,
                        width,
                        fullscreenmode,
                        setfullscreenmode,
                        activePipelineName,
                        setActivePipelineName,
                        savePipelinename,
                        updatePipeline,
                        testStageAction,
                        position,
                        isLog,
                        source,
                        templateid,
                        setonloadAction,
                        onload,
                        expose,
                        setExposeAction,
                        reactive,
                        setReactiveAction,
                        pipelineid
                    })=>{
    

    const dispatch = useDispatch();

    const [openfullscreen, setopenfullscreen] = useState(false);

    const [pipelinewidth, setpipelinewidth] = useState(1000);

    const [fullscreenStage, setFullScreenStage] = useState(null);

    const [internalpiplelinetype, setInternalPipelineType] = useState("main");

    const [numcolumns, setnumcolumns] = useState(1);

    const pipeline = useSelector((state)=>selectPipeline(state,position));

    const getSomePipeline = ()=>{
        let numj = numcolumns;
        let mainarray = [];
        let subarray = [];
        let even= true;
        let i =0;
        const deploypipeline = [];
        for(let i=0; i < pipeline.length; i++){

            deploypipeline.push(i);
        }
        let somepipeline = deploypipeline;
        // for(let i=0; i < pipeline.length; i++){
        //     somepipeline.push(i);
        // }

        while (i < deploypipeline.length){
            if(even){
                if(subarray.length==numcolumns){
                    mainarray.push(subarray);
                    subarray = []
                    even = false;
                    subarray.push(somepipeline[i])
                }else{
                    subarray.push(somepipeline[i])
                }    
            }else{
                if(subarray.length==numcolumns){
                    mainarray.push(subarray);
                    subarray= [];
                    even = true;
                    subarray.push(somepipeline[i]);
                }else{
                    subarray = [somepipeline[i], ...subarray]
                }
            }
            i =i+1;
        }
        if(subarray.length==numcolumns){
            mainarray.push(subarray)
        }else{
            let j=0;
            let space = numcolumns-subarray.length;
            while(j<space){
                if(even){
                    subarray.push(null);
                }else{
                    subarray = [null, ...subarray];
                }
                j=j+1
            }
            mainarray.push(subarray);
        }
        return mainarray;
    }

    const getLastTrue = (i)=>{
        if(i+1==pipeline.length){
            return true;
        }else{
            return false;
        }
    }

    useEffect(()=>{
        dispatch(getActiveCollaborators({
            "resource": "pipeline",
            "resourceid": pipelineid
        }))
        return ()=>{
            dispatch(
                deactivatecollaborator({
                    "resource": "pipeline",
                    "resourceid": pipelineid
                })
            )
        }
    },[pipelineid])

    const activeusers = useSelector((state)=>{return selectActiveCollaborators(state, "pipeline", pipelineid)});

    useEffect(()=>{
        if(pipelinetype!=undefined){
            setInternalPipelineType(pipelinetype)
        }
    },[])

    const activepipleineid = useSelector(selectActivepipelineid);

    useEffect(()=>{
        setpipelinewidth(width);
    },[width])

    useEffect(()=>{
        if(fullscreenmode){
            setpipelinewidth(1000);
        }else{
            setpipelinewidth(width)
        }
    },[fullscreenmode])

    const deployPipelineAction = ()=>{
        if(activepipleineid!=undefined){
            dispatch(deployPipeline(activepipleineid));
        }
    }
    
    return(
        <>

        {(pipelinetype=="loop"&&fullscreenmode==false)?
            <>
                <div className="create-motors-container-loop">
                    <div className="create-motors-headers">
                        {internalpiplelinetype=="main"?
                            <div className="pipeline-name">
                                <TextField variant="standard" value={activePipelineName} onChange={(e)=>{setActivePipelineName(e.target.value)}} onBlur={()=>{savePipelinename()}}></TextField>
                            </div>:
                            <div className="pipeline-name">
                            </div>
                        }
                        <div style={{display: "flex", marginRight:10, alignItems: "center"}}>
                            {internalpiplelinetype=="main"?
                                <>
                                    <>
                                        {activeusers!=undefined&&
                                            <>
                                                {
                                                    activeusers.map((activeuser)=>{
                                                        <Tooltip title={activeuser.emailid}>
                                                            <IconButton>
                                                                <span className="material-symbols-outlined">
                                                                    account_circle
                                                                </span>
                                                            </IconButton>
                                                        </Tooltip>
                                                    })
                                                }
                                            </>
                                        }
                                    </>
                                    <div className="pipeline-columns">
                                        {isLog==undefined&&
                                            <Button style={{color: "#3A07CD"}}>Deploy</Button>
                                        }
                                    </div>
                                    <div>
                                        {isLog==undefined&&
                                            <Button style={{color: "#3A07CD"}} onClick={()=>{
                                                                                testStageAction()
                                                                            }}
                                            >Test</Button>
                                        }
                                    </div>
                                </>:
                                <>
                                    {fullscreenmode!=undefined&&
                                        <>
                                            {fullscreenmode?
                                                <>
                                                    <IconButton onClick={()=>{setfullscreenmode(false)}}>
                                                        <span className="material-symbols-outlined">
                                                            fullscreen
                                                        </span>
                                                    </IconButton>
                                                    <div>
                                                        <IconButton onClick={()=>{  if(pipelinetype=="main"){
                                                                                            close()
                                                                                        }else{
                                                                                            setfullscreenmode(false);
                                                                                        }
                                                                                }
                                                                            }
                                                        >
                                                            <span className="material-symbols-outlined">
                                                                close
                                                            </span>
                                                        </IconButton>
                                                    </div>
                                                </>:
                                                <>
                                                    <IconButton onClick={()=>{setfullscreenmode(true)}}>
                                                        <span className="material-symbols-outlined">
                                                            fullscreen
                                                        </span>
                                                    </IconButton>
                                                
                                                </>
                                            }
                                        
                                        </>
                                        
                                    }
                                </>
                            }
                            {internalpiplelinetype=="main"&&
                                <div>
                                    <IconButton onClick={()=>{  if(pipelinetype=="main"){
                                                                        close()
                                                                    }else{
                                                                        setfullscreenmode(false);
                                                                    }
                                                            }
                                                        }
                                    >
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                            }
                </div>
                    </div>
                    <div className="motors-body">
                        {getSomePipeline().map((line, index)=>{
                                return (
                                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                                        {(index%2)==0?
                                            <>
                                            {line.map((stage, lindex)=>{
                                                return(
                                                    <>
                                                        {lindex==(line.length-1)?
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>
                                                                :<MotorBodyTileLast 
                                                                    last={getLastTrue(stage)} 
                                                                    pipelinetype={internalpiplelinetype}
                                                                    pipelinestage={pipeline[stage]}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLast>}
                                                            </>
                                                            :
                                                            <>
                                                            {stage==null?
                                                                <><EmptyStage></EmptyStage></>:
                                                                    <MotorBodyTileRight 
                                                                        last={getLastTrue(stage)}
                                                                        pipelinestage={pipeline[stage]} 
                                                                        pipelinetype={internalpiplelinetype}
                                                                        width={pipelinewidth}
                                                                        index={stage}
                                                                        isLog={isLog}
                                                                        templateid={templateid}
                                                                        source={source}
                                                                        pipelineid={activepipleineid}
                                                                    ></MotorBodyTileRight>}
                                                                </>
                                                            }
                                                    </>
                                                )
                                            })}
                                            </>:
                                            <>
                                            {line.map((stage, lindex)=>{
                                                return(
                                                    <>
                                                        {lindex==0?
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>
                                                                :<MotorBodyTileLast 
                                                                    last={getLastTrue(stage)} 
                                                                    pipelinestage={pipeline[stage]}
                                                                    pipelinetype={internalpiplelinetype}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLast>}
                                                            </>:
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>:
                                                                <MotorBodyTileLeft 
                                                                    last={getLastTrue(stage)}
                                                                    pipelinestage={pipeline[stage]}
                                                                    pipelinetype={internalpiplelinetype}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLeft>}
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        {(openfullscreen==true)&&
                            <div className="motor-full-screen">
                                <div className="motor-full-screen-container">
                                    <div className="motor-full-screen-headers">
                                        <div>
                                            Name
                                        </div>
                                        <div className="full-screen-close" onClick={()=>{setopenfullscreen(false)}}>
                                            <span className="material-symbols-outlined" style={{fontSize: 40}}>
                                                close_fullscreen
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>:
            <>
                <div className="create-motors-container">
                    <div className="create-motors-headers">
                        {internalpiplelinetype=="main"?
                            <div className="pipeline-name">
                                <div>
                                    <TextField variant="standard" value={activePipelineName} onChange={(e)=>{setActivePipelineName(e.target.value)}} onBlur={()=>{savePipelinename()}}></TextField>
                                </div>
                                {source=="apptemplates"&&
                                    <>
                                        <div>
                                            execute on template load <input type="checkbox" checked={onload} value={onload} onChange={(e)=>{setonloadAction(e.target.checked)}}></input>
                                        </div>
                                        <div>
                                            expose as interaction <input type="checkbox" checked={expose} value={expose} onChange={(e)=>{setExposeAction(e.target.checked)}}></input>
                                        </div>
                                        <div>
                                            execute on input change <input type="checkbox" checked={reactive} value={reactive} onChange={(e)=>{setReactiveAction(e.target.checked)}}></input>
                                        </div>
                                    </>                                    
                                }
                                
                            </div>:
                            <div className="pipeline-name">
                            </div>
                        }
                        <div style={{display: "flex", marginRight:10}}>
                            
                            {internalpiplelinetype=="main"&&source=="apps"&&
                                <>
                                    <div className="pipeline-columns">
                                        <Button style={{color: "#3A07CD"}} onClick={()=>{deployPipelineAction()}}>Deploy</Button>
                                    </div>
                                    <div>
                                        <Button style={{color: "#3A07CD"}} onClick={()=>{
                                                                                    testStageAction()
                                                                                }}
                                        >Test</Button>
                                    </div>
                                </>
                            }
                            <div>
                                <IconButton onClick={()=>{
                                                            if(pipelinetype=="main"){
                                                                close()
                                                            }else{
                                                                setfullscreenmode(false);
                                                            }
                                                            
                                                        }}
                                >
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </IconButton>
                            </div>
                </div>
                    </div>
                    <div className="motors-body">
                        {getSomePipeline().map((line, index)=>{
                                return (
                                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                                        {(index%2)==0?
                                            <>
                                            {line.map((stage, lindex)=>{
                                                return(
                                                    <>
                                                        {lindex==(line.length-1)?
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>
                                                                :<MotorBodyTileLast 
                                                                    last={getLastTrue(stage)} 
                                                                    pipelinestage={pipeline[stage]}
                                                                    pipelinetype={internalpiplelinetype}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLast>}
                                                            </>
                                                            :
                                                            <>
                                                            {stage==null?
                                                                <><EmptyStage></EmptyStage></>:
                                                                    <MotorBodyTileRight 
                                                                        last={getLastTrue(stage)} 
                                                                        pipelinestage={pipeline[stage]}
                                                                        pipelinetype={internalpiplelinetype}
                                                                        width={pipelinewidth}
                                                                        index={stage}
                                                                        isLog={isLog}
                                                                        templateid={templateid}
                                                                        source={source}
                                                                        pipelineid={activepipleineid}
                                                                    ></MotorBodyTileRight>}
                                                                </>
                                                            }
                                                    </>
                                                )
                                            })}
                                            </>:
                                            <>
                                            {line.map((stage, lindex)=>{
                                                return(
                                                    <>
                                                        {lindex==0?
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>
                                                                :<MotorBodyTileLast 
                                                                    last={getLastTrue(stage)} 
                                                                    pipelinestage={pipeline[stage]}
                                                                    pipelinetype={internalpiplelinetype}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLast>}
                                                            </>:
                                                            <>{stage==null?
                                                                <><EmptyStage></EmptyStage></>:
                                                                <MotorBodyTileLeft 
                                                                    last={getLastTrue(stage)} 
                                                                    pipelinestage={pipeline[stage]}
                                                                    pipelinetype={internalpiplelinetype}
                                                                    width={pipelinewidth}
                                                                    index={stage}
                                                                    isLog={isLog}
                                                                    templateid={templateid}
                                                                    source={source}
                                                                    pipelineid={activepipleineid}
                                                                ></MotorBodyTileLeft>}
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        {(openfullscreen==true)&&
                            <div className="motor-full-screen">
                                <div className="motor-full-screen-container">
                                    <div className="motor-full-screen-headers">
                                        <div>
                                            Name
                                        </div>
                                        <div className="full-screen-close" onClick={()=>{setopenfullscreen(false)}}>
                                            <span className="material-symbols-outlined" style={{fontSize: 40}}>
                                                close_fullscreen
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        }
        </>
        
    )
}

export default CreateMotors;