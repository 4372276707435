import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTemplatesCountApi } from "./interface.service";
import { showError } from "../error.slice";


export const getTemplatesCount = createAsyncThunk(
    "interfaces/gettemplatescount",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setinterfaceloader());
            let res = await getTemplatesCountApi(payload);
            dispatch(resetinterfaceloader());
            return res;
        }catch(error){
            dispatch(resetinterfaceloader());
            dispatch(showError("Error fetching templates count"))
            throw error;
        }   
    }
)


const interfaceslice = createSlice({
    name: "interfaces",
    "initialState":{
        templates: 0,
        websites: 0,
        androidapps: 0,
        iosapps: 0,
        interfaceloader: 0
    },
    "reducers":{
        setinterfaceloader: (state)=>{
            state.interfaceloader = state.interfaceloader+1;
        },
        resetinterfaceloader: (state)=>{
            state.interfaceloader = state.interfaceloader-1;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getTemplatesCount.fulfilled, (state, action)=>{
            state.templates = action.payload
        })
    }
})

export const selectTemplatesCount = (state)=> state.interfaces.templates;
export const selectWebsitesCount = (state) => state.interfaces.websites;
export const selectAndroidapps = (state) => state.interfaces.androidapps;
export const selectIosapps = (state) => state.interfaces.iosapps;
export const selectInterfaceloader = (state) => state.interfaces.interfaceloader;

export const {
                setinterfaceloader,
                resetinterfaceloader
             } = interfaceslice.actions;

export default interfaceslice.reducer;