import { createSlice } from '@reduxjs/toolkit';

// Initial state with document and error fields
const initialState = {
  document: null, // This will store the document data
  error: null,    // This will store any error messages
};

// Create the document slice
const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    // Action to set the document data
    setDocument: (state, action) => {
      if(action.payload.document!=""){
            state.document = action.payload.document;
            state.error = null; // Reset the error when document is successfully set
      }else{
        state.document = "### No documentation found for this resource";
        state.error = null;
      }
    },
    // Action to set the error message
    setError: (state, action) => {
      state.error = action.payload;
      state.document = null; // Clear the document when an error occurs
    },
  },
});

// Export the actions to be used in components
export const { setDocument, setError } = documentSlice.actions;

// Export the reducer to be included in the store
export default documentSlice.reducer;
