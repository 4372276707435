import {get, postform, deleteM, postjson} from "../signin/axios";
import { baseURL } from "../../env";

export const getFoldersApi = async(payload)=>{
    let res = await get(baseURL+"filesystem/folders/"+payload);
    return res;
}

export const getWriteFoldersApi = async(payload)=>{
    let res = await get(baseURL+"filesystem/write/folders/"+payload.workspace+"/"+payload.source+"/"+payload.sourceid);
    return res;
}


export const createFolderApi = async(payload)=>{
    let res = await postjson(baseURL+"filesystem/folders", payload);
    return res;
}


export const deleteFolderApi = async(payload)=>{
    let res = await deleteM(baseURL+"filesystem/folders/"+payload);
    return res;
}

export const filesInFolderApi = async(payload)=>{
    let res = await get(baseURL+"filesystem/folders/files/"+payload.workspace+"/"+payload.path);
    return res;
}

export const createFileApi = async(payload)=>{
    let formData = new FormData();
    formData.append("file", payload.file);
    formData.append("path", payload.path);
    formData.append("workspace", payload.workspace);
    let res = await postform(baseURL+"actions/upload/", formData);
    return res;
}

export const deleteFileApi = async(payload)=>{
    let res = get(baseURL+"filesystem/file/delete/"+payload);
    return res;
}

export const changePrivacyApi = async(payload)=>{
    let res = get(baseURL+"filesystem/privacy/folders/"+payload.id+"/"+payload.private);
    return res;

}