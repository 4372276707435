import * as _ from "lodash";

export const Delineatedpipeline= {
    1: {
            "sync":0
        }
};

export const setDelineatedPipelinekey = (key, obj)=>{
    Delineatedpipeline[key] = obj;
}

export const modifyStageIndex = (oldindex, newindex)=>{
    let oldValues = {...Delineatedpipeline[oldindex]};
    Delineatedpipeline[newindex] = oldValues;
}

export const deleteStageIndex = (index)=>{
    delete Delineatedpipeline[index];
}

export const resetPipeline = ()=>{
    Object.keys(Delineatedpipeline).forEach(key => delete Delineatedpipeline[key]);
    Delineatedpipeline[1] = {"sync":0};
}

export const parseSchemaKey = (val)=>{
    if(val!=""){
        let steps = val.split(".");
        let appstep = steps[0];
        let appstepsplit = appstep.split("[");
        appstepsplit = appstepsplit[1];
        appstepsplit = appstepsplit.split("]");
        let appindex = appstepsplit[0];
        steps[0] = appindex;
        return steps;
    }else{
        return [null, null, null];
    }
    
}

export const replaceSchemaKeyIndex = (key, index)=>{
    let steps = key.split(".");
    let appstep = steps[0];
    let appstepsplit = appstep.split("[");
    let app = appstepsplit[0]+"["+index+"]";
    steps[0] = app;
    let newkey = "";
    for(let i=0; i< steps.length; i++){
        if(i>0){
            newkey = newkey+"."+steps[i];
        }else{
            newkey = newkey+steps[i];
        }
    }
    return newkey;
}

export const getParentRef = (stageindex)=>{
    if(Delineatedpipeline[stageindex].type=="reference"){
        stageindex = Delineatedpipeline[stageindex].refStage;
        let res = getParentRef(stageindex);
        return res;
    }else{
        return {
            stageindex: stageindex,
        }
    }
}

const generateSchema = (output, schema)=>{
    if(typeof(output)=="object"){
        if(output?._type=="file"){
            // unreachable code
            schema.push({
                "key": output._key,
                "label": output._key,
                "required": false,
                "type": "file",
                "value": {
                            "url": output.url, 
                            "size": output.size, 
                            "name": output.name, 
                            "lastModified": output.lastModified
                        },
                "subschema":[
                    {
                        "key": "size",
                        "label": "Size",
                        "required": false,
                        "type": "integer",
                        "value": output.size,
                        "subschema":[]
                    },
                    {
                        "key": "name",
                        "label": "Name",
                        "required": false,
                        "type": "string",
                        "value": output.name,
                        "subschema":[]
                    },
                    {
                        "key": "url",
                        "label": "URL",
                        "required": false,
                        "type": "string",
                        "value": output.url,
                        "subschema":[]
                    },
                    {
                        "key": "lastModified",
                        "label": "LastModified",
                        "required": false,
                        "type": "datetime",
                        "value": output.updatedAt,
                        "subschema":[]
                    }

                ] 
            })
            delete output._type;
            delete output._key;
        }else{
            _.mapKeys(output,(val,key)=>{
                let keyval = output[key];
                if(keyval==null||keyval==undefined){
                    schema.push({
                        "key": key,
                        "label": key,
                        "required": false,
                        "value": keyval,
                        "type": "any",
                        "subschema": []
                    })
                }else{
                    if(typeof(keyval)=="object"){
                        if(Array.prototype.isPrototypeOf(keyval)){
                            let subschema = [];
                            let firstval = keyval[0];
                            // check the viability of object , defaulting to string
                            if(firstval==undefined){
                                subschema = [{"type": "any", "subschema":[]}]                        
                            }else{
                                if(typeof(firstval)=="object"){
                                    let subsubschema = [];
                                    generateSchema(firstval,subsubschema)
                                    if(Array.prototype.isPrototypeOf(firstval)){
                                        subschema = [{"type": "array", "subschema":subsubschema}];
                                    }else{
                                        subschema= [{"type": "object","subschema": subsubschema}];
                                    }
                                }else{
                                    subschema = [{"type": typeof(firstval), "subschema":[]}]
                                }
                            }
                            schema.push({
                                "key": key,
                                "label": key,
                                "required": false,
                                "type": "array",
                                "value": keyval,
                                "subschema":subschema
                            })
        
                        }else{
                            if(val?._type=="file"){
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "type": "file",
                                    "value": {"url": val.url, "size": val.size, "name": val.name, "lastModified": val.lastModified},
                                    "subschema":[
                                        {
                                            "key": "size",
                                            "label": "Size",
                                            "required": false,
                                            "type": "integer",
                                            "value": val.size,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "name",
                                            "label": "Name",
                                            "required": false,
                                            "type": "string",
                                            "value": val.name,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "url",
                                            "label": "URL",
                                            "required": false,
                                            "type": "string",
                                            "value": val.url,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "lastModified",
                                            "label": "LastModified",
                                            "required": false,
                                            "type": "datetime",
                                            "value": val.updatedAt,
                                            "subschema":[]
                                        }
        
                                    ] 
                                })
                                delete val._type;
                            }else if(Date.prototype.isPrototypeOf(val)){
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "value": keyval,
                                    "type": "datetime",
                                    "subschema": []
                                })   
                            }
                            else{
                                let subschema = [];
                                generateSchema(keyval, subschema)
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "type": "object",
                                    "value": keyval,
                                    "subschema": subschema
                                })
                            }
                            
                        }
                    }else if(typeof(keyval)=="file"){
        
                    }
                    else{
                        schema.push({
                            "key": key,
                            "label": key,
                            "required": false,
                            "value": keyval,
                            "type": typeof(keyval),
                            "subschema": []
                        })
                    }
                } 
            })
        }
    }else{
        schema.push({"type": typeof(output)})
    };
}

const getObjectFromIndexArr = (schema, indexarr)=>{
    let schemaCopy = [...schema];
    let val;
    if(indexarr[indexarr.length-1]==-1){
        val=undefined
    }else{
        for(let i=0; i< indexarr.length; i++){
            if(i==(indexarr.length-1)){
                if(schemaCopy[indexarr[i]].deleted){
                    val = undefined;
                }else{
                    val = schemaCopy[indexarr[i]].value
                }
            }else{
                if(schemaCopy[indexarr[i]].type=="array"){
                    if((i+1)==(indexarr.length-1)){
                        val = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        break;
                    }else{
                        let valt = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        let newsch = generateSchema(valt);
                        schemaCopy = newsch;
                        i = i+1
                    }
                }else{
                    if(schemaCopy[indexarr[i]].deleted){
                        val = undefined;
                        break;
                    }else{
                        schemaCopy = schemaCopy[indexarr[i]].subschema;
                    }
                }
            }
        }
    }
    return val;
}

const getReferenceSchema = (stageIndex)=>{
    let refStage = Delineatedpipeline[stageIndex].refStage;
    if(refStage!=null){
        let objectIndexArr = Delineatedpipeline[stageIndex].valIndexArr;
        let objectKey = Delineatedpipeline[stageIndex].key;
        let objectVal = {};
        let refschema = [];
        if(Delineatedpipeline[refStage]?.type=="reference"){
            let res = getReferenceSchema(refStage, Delineatedpipeline);
            refschema = res.value;
        }else{
            refschema = Delineatedpipeline[refStage].outputschema
        }
        objectVal[objectKey] = getObjectFromIndexArr(refschema, objectIndexArr);
        let objectSchema = [];
        generateSchema(objectVal, objectSchema);
        return {
                object: objectVal,
                value:objectSchema
            };
    }else{
        return {
            object: undefined,
            value: []
        }
    }
}

//check for transfroms on values after the index
// check the speed and if required change to output instead of outputschema
export const getValfromPipe = (val)=>{
    let parsedVal = parseSchemaKey(val);
    let value;
    try{
        for(let i=0; i < parsedVal.length; i++){
            if(i==0){
                if(parsedVal[i+1]=="current_index"){
                    let val = Delineatedpipeline[parsedVal[i]].output.array_to_loop[Delineatedpipeline[parsedVal[i]].current_index];
                    let valueschema = [];
                    generateSchema(val, valueschema);
                    if(valueschema[0].type!="object"){
                        value = val;
                        i=i+1;
                    }else{
                        value = valueschema;
                        i=i+1;
                    }
                }else{
                    value = getOutputSchemaVal(parsedVal[i]);
                }   
            }else if(i==(parsedVal.length-1)){
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                }else{
                    value = value[concernedkeyindex].value;
                }
            }
            else{
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                    break;
                }else{
                    value = value[concernedkeyindex].subschema;
                }
            }
        }
    }catch(error){
        value = undefined;
    }
    return value;
}

const addUIMetadatatoSchema = (schema)=>{
    if(schema.type=="object"){
        let subschema = [...schema.subschema];
        for(let i=0; i< subschema.length; i++){
            subschema[i] = addUIMetadatatoSchema(subschema[i]);
        }
        schema = {...schema,
                  guided: false,
                  guidefininshed: true,
                  fillallowed: true,
                  subschema: subschema

                 }
    }else if(schema.type=="array"){
        let subschema = schema.subschema;
        if(subschema[0].type=="array"||subschema[0].type=="object"){
            let newsubschema = addUIMetadatatoSchema(subschema[0]);
            subschema[0] = newsubschema
        }
        schema = {...schema,
                  guided: false,
                  guidefininshed: true,
                  fillallowed: true
                }
    }else{
        schema = {...schema,
                  guided: false,
                  guidefininshed: true,
                  fillallowed: true
                 }
    }
    return schema;
}

export const getSchemFromPipe = (val)=>{
    let parsedVal = parseSchemaKey(val);
    let value;
    try{
        for(let i=0; i < parsedVal.length; i++){
            if(i==0){
                if(parsedVal[i+1]=="current_index"){
                    let val = Delineatedpipeline[parsedVal[i]].output.array_to_loop[Delineatedpipeline[parsedVal[i]].current_index];
                    let valueschema = [];
                    generateSchema(val, valueschema);
                    if(valueschema[0].type!="object"){
                        value = val;
                        i=i+1;
                    }else{
                        value = valueschema;
                        i=i+1;
                    }
                }else{
                    value = getOutputSchemaVal(parsedVal[i]);
                }   
            }else if(i==(parsedVal.length-1)){
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                }else{
                    value = value[concernedkeyindex];
                }
            }
            else{
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                    break;
                }else{
                    value = value[concernedkeyindex].subschema;
                }
            }
        }
    }catch(error){
        value = undefined;
    }
    if(value!=undefined){
        value = addUIMetadatatoSchema(value);
    }
    return value;
}


export const generateDataKeys = (variable, pretext, datakeys)=>{
    Object.keys(variable).map((key)=>{
        if(typeof(variable[key])=="object"){
            if(pretext==""){
                generateDataKeys(variable[key], key, datakeys);
                datakeys.push(key) 
            }else{
                generateDataKeys(variable[key], pretext+"."+key, datakeys);
                datakeys.push(pretext+"."+key)
            }
        }else{
            if(pretext==""){
                datakeys.push(key);
            }else{
                datakeys.push(pretext+"."+key);
            }
        }
    })
}

export const renderTemplate =(templatestring, datakeys,data)=>{
    for(let i=0; i< datakeys.length; i++){
        if(datakeys[i]!=""){
            templatestring = templatestring.replace("<%="+datakeys[i]+"%>",data[datakeys[i]]);
        }
    }
    return templatestring;
}

const getdval = (data, key)=>{
    let steps = key.split(".");
    let val = data;
    for(let i=0; i< steps.length; i++){
        val= val[steps[i]];
    }
    return val;
}

export const renderTextTemplate = (templatestring, datakeys, data)=>{
    for(let i=0; i< datakeys.length; i++){
        if(datakeys[i]!==""){
            templatestring = templatestring.replace("<%="+datakeys[i]+"%>", getdval(data, datakeys[i]));
        }
    }
    return templatestring;
}

export const getMappingValue = (mapping)=>{
    if(mapping.action=="const"){
        return mapping.val;
    }else if(mapping.action=="get"){
        let val="";
        if(mapping.val!=""){
            try{
                val = getValfromPipe(mapping.val);
            }catch(err){
                console.log(err);
                val = "";
            } 
        }
        return val;  
    }else if(mapping.action=="template"){
        if(Array.prototype.isPrototypeOf(mapping.val)){
            let datakeys = mapping.datakeys;
            let data = {};
            let val = [];
            for(let i=0; i< datakeys.length; i++){
                if(datakeys[i]!=""){
                    data[datakeys[i]] = getValfromPipe(datakeys[i]);
                }
            };
            for(let i=0; i<mapping.val.length; i++){
                let indexval = renderTemplate(mapping.val[i],datakeys, data);
                val.push(indexval);
            }
            return val;
        }else{
            let datakeys = mapping.datakeys;
            let data = {};
            for(let i=0; i< datakeys.length; i++){
                if(datakeys[i]!=""){
                    data[datakeys[i]] = getValfromPipe(datakeys[i]);
                }
            };
            let val = renderTemplate(mapping.val,datakeys, data);
            return val;
        }
    }
}

// get stageindex status -> to display status 
export const getSyncStatus = (stageindex)=>{
    let sync = Delineatedpipeline[stageindex]?.sync;
    return sync;
}

export const getSchemaTable = (appName, schemaslug)=>{
    let appSlug = "";
    appName.split(" ").map((part)=>{
        appSlug = appSlug+part.toLowerCase();
    });
    appSlug = appSlug.replace("__","");
    let tableName = appSlug+"__"+schemaslug;
    return tableName;
}


export const getObjectVal = (sc, sm, objectval)=>{
    if((sm!=undefined&&sm!=""&&sm!=null)){
        if(Array.prototype.isPrototypeOf(sm)){
            let schemamapping = [...sm];
            let schemaCopy = [...sc];
            // console.log(schemamapping);
            for(let i=0; i<schemamapping.length;i++){
                    if(Array.prototype.isPrototypeOf(schemamapping[i]?.mapping)){ 
                        let subobjectval = getObjectVal(sc[i].subschema,schemamapping[i]?.mapping, {});
                        objectval[schemamapping[i].key] = subobjectval;
                    }else{
                        let sci = schemaCopy[i];
                        if(sci.type=="array"){
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                if(sci.subschema[0].type=="object"){
                                    let arrval = [];
                                    for(let j=0; j< schemamapping[i].mapping.mapping.length; j++){
                                        let objval = getObjectVal(sci.subschema[0].subschema,schemamapping[i].mapping.mapping[j],{});
                                        arrval.push(objval);
                                    }
                                    objectval[schemamapping[i].key] = arrval;
                                }else if(sci.subschema[0].type=="array"){
                                    let submapping = schemamapping[i].mapping?.mapping;
                                    let arrayval = [];
                                    for(let j=0; j<submapping.length; j++){
                                        let objval = getObjectVal(sci.subschema[0].subschema, submapping[j], {});
                                        arrayval.push(objval);
                                    }
                                    objectval[schemamapping[i].key] = arrayval;
                                }
                                else{
                                    let submapping = schemamapping[i].mapping?.mapping;
                                    let arrayval = [];
                                    for(let i=0; i< submapping.length; i++){
                                        if(submapping[i].action=="const"){
                                            arrayval.push(submapping[i].val)
                                        }else if(submapping[i].action=="get"){
                                            arrayval.push(getValfromPipe(submapping[i].val));
                                        }
                                    }
                                    objectval[schemamapping[i].key] = arrayval;
                                }
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                                let val = getValfromPipe(schemamapping[i].mapping.val);
                                objectval[schemamapping[i].key] = val;
                            }
                        }else{
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                objectval[schemamapping[i].key] = schemamapping[i].mapping?.val;
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                        
                                objectval[schemamapping[i].key] = getValfromPipe(schemamapping[i].mapping?.val);
                            }
                    }
                }
            }
            return objectval;
        }else{
          let val;
          if(sc.type=="array"){
            if(sm.mapping.action==undefined){
                 val = [];
            }else if(sm.mapping?.action=="const"){
                if(sc.subschema[0].type=="object"){
                    let arrval = [];
                    let submapping = sm.mapping?.mapping;
                    for(let j=0; j< submapping.length; j++){
                        let val = getObjectVal(sc.subschema[0].subschema, submapping[j],{});
                        arrval.push(val);
                    }
                    val = arrval;
                    
                    
                    
                }else if(sc.subschema[0].type=="array"){
                    let submapping = sm.mapping?.mapping;
                    let arrayval = [];
                    for(let j=0; j<submapping.length; j++){
                        let objval = getObjectVal(sc.subschema[0].subschema, submapping[j], {});
                        arrayval.push(objval);
                    }
                    val = arrayval;
                }
                else{
                    let submapping = sm.mapping?.mapping;
                    let arrayval = [];
                    for(let i=0; i< submapping.length; i++){
                        if(submapping[i].action=="const"){
                            arrayval.push(submapping[i].val)
                        }else if(submapping[i].action=="get"){
                            arrayval.push(getValfromPipe(submapping[i].val));
                        }
                    }
                    val = arrayval;
                }
            }else if(sm.mapping?.action=="get"){
                // console.log
                val = getValfromPipe(sm.mapping.val);
                // let schemacopy = JSON.parse(JSON.stringify(sc));
                // if(val!=undefined){
                //     schemacopy = {...schemacopy,
                //         value: val
                //         }
                //     let objectval = {};
                //     let displayval = getSchemaVal(schemacopy, objectval);
                //     val = displayval;
                // }else{
                //     val=[];
                // }
                
            }
          }else if(sc.type=="object"){
            if(sm.mapping.action==undefined){
                objectval[sc.key] = getObjectVal(sc.subschema, sm.mapping,{}); 
            }else if(sm.mapping?.action=="get"){
                objectval[sc.key] = getValfromPipe(sm.mapping.val);
            }
            return objectval;
          }else{
            if(sm.mapping?.action=="const"){ 
                val = sm.mapping.val
            }else if(sm.mapping?.action=="get"){
                val = getValfromPipe(sm.mapping.val);
            }
          }
          return val;  
        }
    }else{
        return {};
    }
}

export const getRandomId = ()=>{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 20) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const getStageStatus = (stageindex)=>{
    return Delineatedpipeline[stageindex]?.sync;
}

export const setStageStatus = (stageindex)=>{
    Delineatedpipeline[stageindex] = {...Delineatedpipeline[stageindex],
                                      sync: 0
                                     }
}

const getSchemaVal = (schema, schemaval)=>{
    if(Array.prototype.isPrototypeOf(schema)){
        for(let i=0; i< schema.length; i++){
            if(schema[i].type=="object"){
                let val = {};
                val = getSchemaVal(schema[i].subschema, val);
                schemaval[schema[i].key] = val;
            }else if(schema[i].type=="array"){
                let val = [];
                for(let j=0; j<schema[i].value.length; j++){
                    if(schema[i].value[j].type=="object"){
                        let subval = {};
                        subval = getSchemaVal(schema[i].value[j].subschema, subval);
                        val.push(subval)
                    }else if(schema[i].value[j].type=="array"){
                        let subval = [];
                        subval = getSchemaVal(schema[i].value[j], subval)
                    }else{
                        val.push(schema[i].value[j].value)
                    }
                }
                if(schema[i].key!=undefined){
                    schemaval[schema[i].key] = val;
                }else{
                    schemaval = val;
                }
            }else{
                schemaval[schema[i].key] = schema[i].value;
            }
        }
        return schemaval;
    }else{
        if(schema.type=="object"){
            let val = {};
            val = getSchemaVal(schema.subschema, val)
            schemaval = val;
        }else if(schema.type=="array"){
            let val = [];
            for(let i=0; i<schema.value.length; i++){   
                if(schema.value[i].type=="object"){
                    let subval = {};
                    subval = getSchemaVal(schema.value[i].subschema, subval);
                    val.push(subval);
                }else if(schema.value[i].type=="array"){
                    let subval = [];
                    subval = getSchemaVal(schema.value[i], subval);
                    val.push(subval);
                }else{
                    val.push(schema.value[i].value);
                }
            }
            schemaval = val;
        }else{
            schemaval = schema.value;
        }
        return schemaval;
    }
    
}

export const getOutput = (stageindex)=>{
    if(Delineatedpipeline[stageindex]?.error==false){
        if(Delineatedpipeline[stageindex]!=undefined&&Delineatedpipeline[stageindex].outputschema!=undefined){
            let schemaval = {};
            schemaval = getSchemaVal(Delineatedpipeline[stageindex].outputschema, schemaval);
            return schemaval;
        }
    }else{
        return Delineatedpipeline[stageindex]?.error;
    }
}

export const getLoopStageOutput = (stageindex)=>{
    if(Delineatedpipeline[stageindex]?.error==false){

    }
}

export const getConditionalStageOutput = (stageindex)=>{

}

export const getStageError = (stageindex)=>{
    if(Delineatedpipeline[stageindex]?.error==false){
        return undefined
    }else{
        return Delineatedpipeline[stageindex]?.error
    }
}

export const getOutputSchema = (stageindex)=>{
    if(Delineatedpipeline[stageindex]?.error==false){
        if(Delineatedpipeline[stageindex]?.outputschema!=undefined){
            return Delineatedpipeline[stageindex].outputschema
        }
    }else{
        return [];
    }
}

const populateschemval = (schema)=>{
    for(let i=0; i<schema.length; i++){
        if(schema[i].type=="object"){
            let val = {};
            val = getSchemaVal(schema[i].subschema, val);
            populateschemval(schema[i].subschema)
            schema[i] = {...schema[i],
                         value: val
                        }
        }else if(schema[i].type=="array"){
            let val = [];
            val = getSchemaVal(schema[i], val);
            schema[i] = {...schema[i],
                         value: val
                        }
        }
    }

}

export const getOutputSchemaVal = (stageindex)=>{
    if(Delineatedpipeline[stageindex].error==false){
        let outputschema = JSON.parse(JSON.stringify(Delineatedpipeline[stageindex].outputschema));
        populateschemval(outputschema);
        return outputschema;
    }else{
        return [];
    }   
}

export const getLoopSchema = (stageindex)=>{
    if(Delineatedpipeline[stageindex].type=="reference"){
        
    }else{
         if(Delineatedpipeline[stageindex]?.error==false){
            return Delineatedpipeline[stageindex].loopschema;
         }else{
            return [];
         }
    }
}

export const repopulatePipeline = (newpipeline)=>{
    _.mapKeys(newpipeline, (value, key)=>{
        Delineatedpipeline[key] = value;
    })
}

const tab = "&nbsp;&nbsp;";

const printArraySchema = (schema, spacer, output)=>{
    
}

export const printOutput = (stageschema, spacer, output, location)=>{
    output = "".join(spacer)+"{<br/>";
    spacer.push(tab)
    for(let i=0; i< stageschema.length; i++){
        let key = stageschema[i].key;
        if(stageschema[i].type=="object"){
            let printkey = "".join(spacer)+key+":"+"\n";
            output = output+printkey;
            spacer.push(tab);
            output = printOutput(stageschema, spacer, output);
        }else if(stageschema[i].type=="array"){
            let printkey = "".join(spacer)+key+":\s["+"\n";
            spacer.push(tab);
        }else if(stageschema[i].type=="string"||stageschema[i].type=="text"){

        }else{

        }
        output = spacer+output;        
    }
    spacer.splice((spacer.length-1),1);
    return output;
    
}

export const resetschemapping = (schemamapping)=>{
    for(let i=0; i< schemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(schemamapping[i].mapping)){
            schemamapping[i].mapping = resetschemapping(schemamapping[i].mapping)
        }else{
            schemamapping[i].mapping = ""
        }
    }
    return schemamapping;
}

export const resetschema = (schema)=>{
    for(let i =0; i< schema.length; i++){
        schema[i].fillallowed = true;
        schema[i].guided = false;
        schema[i].guidefininshed = true;
        if(schema[i].subschema.length>0){
            schema[i].subschema = resetschema(schema[i].subschema);
        }
    }
    return schema;
}