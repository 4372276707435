import { get } from "../signin/axios";
import { baseURL } from "../../env";

export const getPipelineusageApi = async(payload)=>{
    let res = await get(baseURL+"usage/pipelineusage/"+payload.month+"/"+payload.year);
    return res;
}

export const getPipelinecountApi = async(payload)=>{
    let res = await get(baseURL+"usage/pipelinecount/"+payload.month+"/"+payload.year);
    return res;
}

export const getpipelinehttpusageApi = async(payload)=>{
    let res = await get(baseURL+"usage/getpipelinehttpusage/"+payload.month+"/"+payload.year);
    return res;
}


export const getSchemarecordsApi = async(payload)=>{
    let res = await get(baseURL+"usage/schemarecords/"+payload.month+"/"+payload.year);
    return res;
}

export const getRouteloadsApi = async(payload)=>{
    let res = await get(baseURL+"usage/routeloads/"+payload.month+"/"+payload.year);
    return res;
}

export const getStorageApi = async(payload)=>{
    let res = await get(baseURL+"usage/storage/"+payload.month+"/"+payload.year);
    return res;
}

export const getDatatransferApi = async(payload)=>{
    let res = await get(baseURL+"usage/datatransfer/"+payload.month+"/"+payload.year);
    return res;
}