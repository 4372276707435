
import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
        registerEventApi,
        getEventsApi,
        deleteEventApi,
        getLoopsApi,
        getSchemaEventsApi,
        getEventsByIdApi,
        updateEventApi,
        getWorkspaceLoopsApi,
        getWorkspaceLoopAppsApi
        } from "./event.service";

import { showError } from "../error.slice";
import * as _ from "lodash";
import { loading, loadingdone } from "../loader/loader.slice";

export const createEvent = createAsyncThunk(
    "event/createevent",
    async(payload, {dispatch})=>{
        try{
            dispatch(loading());
            let res = await registerEventApi(payload);
            dispatch(getEvents(payload.schemaid));
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(showError("Error creating event!!!"));
            throw error;
        }
    }
)

export const getEvents = createAsyncThunk(
    "event/getevents",
    async(payload, {dispatch})=>{
        try{
            dispatch(setGeteventsloader());
            let res = await getEventsApi(payload);
            dispatch(resetGeteventsloader());
            return res;
        }catch(error){
            dispatch(resetGeteventsloader());
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const deleteEvent = createAsyncThunk(
    "event/deleteevents",
    async(payload, {dispatch})=>{
        try{
            let res = await deleteEventApi(payload.eventid);
            dispatch(getEvents(payload.schemaid));
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error
        }
    }
)


export const getLoops = createAsyncThunk(
    "event/getloop",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await getLoopsApi();
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const getWorkspaceLoops = createAsyncThunk(
    "event/getworkspaceloop",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWorkspaceLoopsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("403 Unauthorized"));
            throw error;
        }
    }
)

export const getWorkspaceLoopApps = createAsyncThunk(
    "event/getworkspacealoopapps",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWorkspaceLoopAppsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("403 Unauthorized"));
            throw error;
        }
    }
)


export const getSchemaEvents = createAsyncThunk(
    "event/getschemaevent",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await getSchemaEventsApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const getEventsById = createAsyncThunk(
    "event/geteventsbyid",
    async(payload,{dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await getEventsByIdApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }   
)


export const updateEvent = createAsyncThunk(
    "event/puteventsbyid",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await updateEventApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)


const eventSlice = createSlice({
    "name": "event",
    "initialState":{
        "events":[],
        eventcreated: false,
        eventList:[],
        availableLoops: [],
        workspaceLoops: [],
        workspaceLoopApps: [],
        "schemaevents":[],
        eventObj: null,
        eventUpdated: false,
        eventdeleted: false,
        geteventsloader: 0
    },
    "reducers":{
        setGeteventsloader: (state)=>{
            state.geteventsloader = state.geteventsloader+1;
        },
        resetGeteventsloader: (state)=>{
            state.geteventsloader = state.geteventsloader-1;
        },
        reseteventdeleted: (state, action)=>{
            state.eventdeleted = false;
        },
        resetcreatedevent: (state, action)=>{
            state.eventcreated = false;
        },
        reseteventObj: (state, action)=>{
            state.eventObj = null;
        },
        reseteventupdated: (state, action)=>{
            state.eventUpdated = false;
        },
        resetworkspaceloopapps: (state, action)=>{
            state.workspaceLoopApps = [];
        }
    },
    "extraReducers":(builder)=>{
        builder.addCase(createEvent.fulfilled, (state, action)=>{
            state.eventcreated = true;
        })
        .addCase(getEvents.fulfilled, (state, action)=>{
            state.events = action.payload;
        })
        .addCase(getLoops.fulfilled,(state, action)=>{
            state.availableLoops = action.payload;
        })
        .addCase(getSchemaEvents.fulfilled, (state, action)=>{
            let schemaevent = action.payload;
            let schemaeventsCopy = [...state.schemaevents];
            let schemaIndex = _.findIndex(schemaeventsCopy, sec=>sec.schemaid==schemaevent.schemaid);
            if(schemaIndex>-1){
                schemaeventsCopy[schemaIndex] = {...schemaeventsCopy[schemaIndex],
                    eventlist: schemaevent.eventlist
                   }
            }else{
                schemaeventsCopy.push({
                    schemaid: schemaevent.schemaid,
                    eventlist: schemaevent.eventlist
                })
            }
            state.schemaevents = schemaeventsCopy;
        })
        .addCase(getEventsById.fulfilled , (state, action)=>{
            state.eventObj = action.payload;   
        })
        .addCase(updateEvent.fulfilled, (state, action)=>{
            state.eventUpdated = true;
        })
        .addCase(getWorkspaceLoops.fulfilled, (state, action)=>{
            state.workspaceLoops = action.payload;
        })
        .addCase(getWorkspaceLoopApps.fulfilled, (state, action)=>{
            state.workspaceLoopApps = action.payload;
        })
        .addCase(deleteEvent.fulfilled, (state, action)=>{
            state.eventdeleted = true;
        })
    }
})

export const selectEvents = (state) => state.event.events;

export const selectEventcreated = (state)=> state.event.eventcreated;

export const selectEventList = (state) => state.event.eventList;

export const selectAvailableLoops = (state) => state.event.availableLoops;

export const selectEventObj = (state) => state.event.eventObj;

export const selectEventUpdated = (state) => state.event.eventUpdated;

export const selectEventdeleted = (state) => state.event.eventdeleted;

export const selectSchemaevents = createSelector(
                        [state=>state.event.schemaevents, (state, schema)=>schema],
                        (schemaevents, schema)=>{
                            if(schema==undefined&&schema==null){
                                return [];
                            }else{
                                let schemaevent = schemaevents.filter(se=> se.schemaid==schema._id);
                                if(schemaevent.length==0){
                                    return []
                                }else{
                                    return schemaevent[0].eventlist;
                                }
                            }
                        }
)

export const selectWorkspaceloops = (state) => state.event.workspaceLoops;

export const selectWorspaceloopapps = (state) => state.event.workspaceLoopApps;

export const selectGetEventsLoader = (state) => state.event.geteventsloader;

export const {
                resetcreatedevent, 
                reseteventObj, 
                reseteventupdated,
                resetworkspaceloopapps,
                reseteventdeleted,
                setGeteventsloader,
                resetGeteventsloader
            } = eventSlice.actions;

export default eventSlice.reducer;
