
import { useEffect, useState } from "react";
import DeclareVariable from "../Fields/DeclareVariable";
import Modal from '@mui/material/Modal';
import { Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HttpTestStage from "./HttpTestStage";
import {testPipeline, selectPipelineresult, resetpipelineresult, runPipeline} from "../../../Services/pipelines/pipeline.slice";
import {getObjectVal} from "../../utils";
import "./TestStage.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
  };



const TestStage = ({
                    open, 
                    handleClose, 
                    loop, 
                    pipelineid,
                    header
                })=>{

    const [internalloop, setinternalloop] = useState({});

    const dispatch = useDispatch();

    const pipelineResult = useSelector(selectPipelineresult);

    const setSchemaMapping = (sm)=>{
        let internalloopCopy = {...internalloop,
                                inputschemamapping: sm
                                };
        setinternalloop(internalloopCopy);
    }

    const test = ()=>{
        let params = {};
        getObjectVal(internalloop.inputschema, internalloop.inputschemamapping,params);
        let payload = {
                "id": pipelineid,
                "params": params,
        }
        if(header=="test"){
            dispatch(testPipeline(payload));    
        }else{
            dispatch(runPipeline(payload));
        }
        
    }

    const finishGuideAndAllowFill = (schema)=>{
        for(let i=0; i< schema.length; i++){
             if(schema[i].type=="array"){
                if(schema[i].subschema[0].type=="object"){
                    let internalsubschema = finishGuideAndAllowFill(schema[i].subschema[0].subschema);
                    schema[i].subschema[0] = {...schema[i].subschema[0],
                                              subschema: internalsubschema
                                             }
                    schema[i] = {...schema[i],
                                 fillallowed: true,
                                 guided: false,
                                 guidefinished: true
                                }
                }else{
                    schema[i] = {...schema[i],
                        fillallowed: true,
                        guided: false,
                        guidefinished: true
                       }
                }  
             }else{
                if(schema[i].subschema.length>0){
                    let internalsubschema = finishGuideAndAllowFill(schema[i].subschema);
                    schema[i] = {...schema[i],
                                 fillallowed: true,
                                 guided: false,
                                 guidefinished: true,
                                 subschema: internalsubschema
                                }
                }else{
                    schema[i] = {...schema[i],
                        fillallowed: true,
                        guided: false,
                        guidefinished: true
                       }
                }
             }
        }
        return schema;
    }

    const resetSchemaMapping = (sm)=>{
        for(let i=0; i< sm.length; i++){
            if(Array.prototype.isPrototypeOf(sm[i].mapping)){
                let submapping = resetSchemaMapping(sm[i].mapping);
                sm[i] = {...sm[i],
                         mapping: submapping
                        }
            }else{
                sm[i] = {...sm[i],
                         mapping: ""
                        }
            }
        }
        return sm;
    }

    useEffect(()=>{
        if(open&&loop!=null){
            let loopCopy = JSON.parse(JSON.stringify(loop));
            let inputschemaCopy = finishGuideAndAllowFill([...loopCopy.inputschema]);
            let inputschemamappingCopy = resetSchemaMapping([...loopCopy.inputschemamapping]);
            loopCopy = {...loopCopy,
                    inputschema: inputschemaCopy,
                    inputschemamappingCopy: inputschemamappingCopy
                    }
            setinternalloop(loopCopy);
            dispatch(resetpipelineresult());
        }
        
    },[loop, open])


    const modifySchema = (sc, sm)=>{
        let loopCopy = {...internalloop,
                        inputschema:sc,
                        inputschemamapping: sm
                        }
        setinternalloop(loopCopy)
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={style}>
                <div className="test-stage-header">
                    <div>
                        <h3>
                            {header=="test"&&
                                <>
                                    Test Pipeline
                                </>
                            }
                            {header=="run"&&
                                <>
                                    Run Pipeline
                                </>
                            }
                        </h3>
                    </div>
                    <div>
                        <IconButton onClick={()=>{handleClose()}}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </IconButton>
                    </div>
                </div>
                {internalloop.appid=="http_server"&&
                        <div className="test-stage-container">
                            <HttpTestStage loop={loop}></HttpTestStage>
                        </div>
                }
                {(internalloop.appid=="manual_trigger")&&
                    <div className="test-stage-container">
                        <div className="test-stage-container-input">
                            <DeclareVariable
                                schema={internalloop.inputschema}
                                schemamapping={internalloop.inputschemamapping}
                                getpipeline={()=>{return [];}}
                                modifySchema = {modifySchema}
                                setSchema={()=>{}}
                                declaration={"sequential"}
                                pipelinetype={"motor"}
                                stageindex={0}
                                stagetype={"system"}
                                guidefinished={undefined}
                                layout="vertical"
                                teststage={true}
                                source="apps"
                            >
                            </DeclareVariable>
                        </div>
                        <div className="test-stage-container-test">
                            {header=="test"&&
                                <Button onClick={()=>{test()}}>Test</Button>
                            }
                            {header=="run"&&
                                <Button onClick={()=>{test()}}>Run</Button>
                            }
                            
                        </div>
                        <div className="test-stage-container-output">
                            <div>
                                <div>Output</div>
                                    {pipelineResult!=null&&
                                        <>
                                            <pre style={{whiteSpace: "normal", wordBreak: "break-all"}}>
                                                {JSON.stringify(pipelineResult)}
                                            </pre>
                                        </>
                                    }
                            </div>
                        </div>
                    </div>
                }
                {(internalloop.app=="scheduler")&&
                    <>
                        Scheduled pipelines cannot be tested pipeline wide as they are asynchronous. Please use stage tests to verify stages.
                    </>
                }
            </div>
        </Modal>
    )
}

export default TestStage;