import { useState, useEffect } from "react";
import CustomInput from "./apps/CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { 
            selectEventUpdated, 
            selectEventObj, 
            getEventsById, 
            updateEvent, 
            reseteventObj,
            reseteventupdated
        } from "../Services/events/event.slice";
import {showError} from "../Services/error.slice";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    backgroundColor: "#fff"
  };

const Editevent = ({
                    eventid,
                    open,
                    handleClose
                   })=>{

    let dispatch = useDispatch();

    let eventObj = useSelector(selectEventObj);

    let eventUpdated = useSelector(selectEventUpdated);

    const [saveClicked, setSaveClicked] = useState(false);

    const updateEventInt = (schema)=>{
        let payload = {
                        id: eventObj._id,
                        schema: schema
                      };
        setSaveClicked(true);
        dispatch(reseteventupdated());
        dispatch(updateEvent(payload));
    }

    useEffect(()=>{
        if(eventid!=undefined&&eventid!=null){
            dispatch(getEventsById(eventid));
        }
    },[eventid])


    useEffect(()=>{
        if(saveClicked&&eventUpdated){
            dispatch(showError("Event has been saved !!!"));
            handleClose();
        }
    },[eventUpdated])

    const closeEventModal = ()=>{
        handleClose();
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        {eventObj!=null&&eventObj!=undefined&&
                            <CustomInput  
                                close={closeEventModal} 
                                initialSchema={eventObj.schema} 
                                addtoschema={updateEventInt} 
                                closeonadd={true}>    
                            </CustomInput>
                        }
                    </div>
                </Box>
                
            </Modal>
        </>
    )
}

export default Editevent;