import { Button, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {showError} from "../../Services/error.slice";
import {
            getDomains, 
            registerDomain, 
            updateDomain, 
            deleteDomain, 
            resetdomaincreated, 
            resetdomainupdated, 
            selectDomainCreated, 
            selectDomainUpdated, 
            selectDomains,

        } from "../../Services/clients/client.slice";

import "./Domains.css";

const CreateDomain = ({close, domain, closeEdit})=>{
    const [name, setName] = useState("");
    const [key, setKey] = useState("");
    const [cert, setCert] = useState("");
    const [id, setId] = useState("");

    const [saveClicked, setSaveClicked]= useState(false);

    const dispatch = useDispatch();

    

    const save = ()=>{
        if(name==""){
            dispatch(showError("domain name cannot be empty"));
            return;
        }

        if(key==""){
            dispatch(showError("Key cannot be empty"));
            return;
        }

        if(cert==""){
            dispatch(showError("Certificate cannot be empty"));
            return;
        }
        let payload = {
            name: name,
            key: key,
            cert: cert
        }
        setSaveClicked(true);
        dispatch(resetdomaincreated());
        dispatch(registerDomain(payload));

    }

    const update = ()=>{
        if(name==""){
            dispatch(showError("domain name cannot be empty"));
            return;
        }

        if(key==""){
            dispatch(showError("Key cannot be empty"));
            return;
        }

        if(cert==""){
            dispatch(showError("Certificate cannot be empty"));
            return;
        }

        let payload = {
            name: name,
            key: key,
            cert: cert,
        }
        setSaveClicked(true);
        dispatch(resetdomainupdated());
        dispatch(updateDomain({id:id, body: payload})); 
    }

    const domainSaved = useSelector(selectDomainCreated);

    useEffect(()=>{
        if(saveClicked&&domainSaved){
            setSaveClicked(false);
            dispatch(getDomains());
            close();
        }
    },[domainSaved])

    const domainUpdated = useSelector(selectDomainUpdated);

    useEffect(()=>{
        if(saveClicked&&domainUpdated){
            setSaveClicked(false);
            dispatch(getDomains());
            closeEdit();
        }
    },[domainUpdated])

    // useEffect(()=>{
    //     setSaveClicked(false);
    // },[])

    useEffect(()=>{
        if(domain!=undefined){
            setName(domain.name);
            setCert(domain.cert);
            setKey(domain.key);
            setId(domain._id);
        }else{
            setId("");
        }
    },[domain])
    
    return (
        <div style={{padding:20}}>
          <div style={{padding:10}} >
            <TextField fullWidth value={name} label="Domain name" onChange={(e)=>{setName(e.target.value)}}></TextField>
          </div>
          <div style={{padding:10}}>
            <TextField
                value={key}
                label="Key"
                onChange={(e)=>{setKey(e.target.value)}}
                multiline
                fullWidth
                rows={4}

            >
            </TextField>
          </div>
          <div style={{padding:10}}>
            <TextField
                value={cert}
                label="Certificate"
                multiline
                onChange={(e)=>{setCert(e.target.value)}}
                fullWidth
                rows={4}
            
            >
            </TextField>
          </div>
          <div style={{display: "flex", padding:10}}>
                <Button style={{color: "#3A07CD"}} onClick={()=>{close()}}>
                    Cancel
                </Button>
                {id==""?
                    <Button style={{color: "#3A07CD"}} onClick={()=>{save()}}>
                        Save
                    </Button>:
                    <Button style={{color: "#3A07CD"}} onClick={()=>{update()}}>
                        Update
                    </Button>
                }
                
          </div>  
        </div>
    )
}




const Domains = ()=>{

    const [domaincreate, setDomainCreate] = useState(false);

    const [activeDomain, setActiveDomain] = useState(undefined);

    const dispatch = useDispatch();

    const domains = useSelector(selectDomains);


    const delDomain = (id)=>{
        dispatch(deleteDomain(id));
    }

    useEffect(()=>{
        dispatch(getDomains());
    },[])

   const openEdit = (domain)=>{
    setActiveDomain(domain);
    setDomainCreate(true);
   }

   const closeEdit = ()=>{
    setActiveDomain(undefined);
    setDomainCreate(false);
   }
    return (
        <div className="settings-list">
            <div className="setting-row">
                <div className="setting-row-header" style={{display: "flex", padding: 10, justifyContent: "space-between"}}>
                    <div>
                        Registered Domains
                    </div>
                    <div className="domain-settings-control">
                        <Button onClick={()=>{setDomainCreate(true)}} style={{color: "#3A07CD"}}>Add Domain</Button>
                        <Link to="/settings">
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </Link>
                    </div>
                </div>
                {domaincreate&&
                    <CreateDomain close={()=>{setDomainCreate(false)}} domain={activeDomain} closeEdit={closeEdit}></CreateDomain>
                }
                <div className="setting-row-body">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Key
                                </TableCell>
                                <TableCell>
                                    Certificate
                                </TableCell>
                                <TableCell>
                                    Active
                                </TableCell>
                                <TableCell>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {domains.map((option)=>{
                            return (
                                <TableRow>
                                    <TableCell>
                                        {option.name}
                                    </TableCell>
                                    <TableCell>
                                        {option.key}
                                    </TableCell>
                                    <TableCell>
                                        {option.cert}
                                    </TableCell>
                                    <TableCell>
                                        {option.active}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>{delDomain(option._id)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                        <IconButton onClick={()=>{openEdit(option)}}>
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </Table>    
                </div>
            </div>
            <div>
                After creating the domain , please create an A record pointing to the following IP: 15.207.217.4
            </div>
            {/* {settings.map((setting)=>{
                return (
                    
                )
            })} */}
        </div>
    )
}

export default Domains;