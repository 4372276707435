import { useEffect, useState } from "react";
import "./BreadCrumbs.css";
import { Link } from "react-router-dom";

const BreadCrumbs = ({
                      breadcrumbs
                    })=>{


    // useEffect(()=>{

    // },[])


    return (
        <>
            <div className="breadcrumbs-container">
                <div className="breadcrumbs-list">
                    {breadcrumbs!=undefined&&
                        <>
                            {breadcrumbs.map((breadcrumb)=>{
                                return (
                                    <span className="breadcrumb-box">
                                        <Link to={breadcrumb.url}>{breadcrumb.name} /</Link>
                                    </span>
                                )
                            })}
                        </>
                    }
                </div>
            </div>
        </>
    )
}


export default BreadCrumbs;