import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAppClasses, selectAppTemplates } from "../../Services/apptemplates/apptemplates.slice";
import { generateStyleValue } from "./utils";

const StyleSheet = ({
                        canvasWidth,
                        appdesginer
                    })=>{

    const [appStyles, setAppStyles] = useState({});

    let widthmediaselectors = ["min-width", "max-width"];

    const ref = useRef(null);

    const [stylesinit, setStylesinit] = useState(false);

    const remotestyles = useSelector(selectAppClasses);

    const getSelector = (name, state)=>{
        if(state=="default"){
            return "."+name
        }else{
            return "."+name+":"+state
        }
    }

    const getCssRule = (style)=>{

    }

    const executeMediaQuery = (condition)=>{
        let mql = window.matchMedia(condition);
        return mql.matches;
    }

    function convertToPx(value) {
        if (typeof value !== 'string') {
          throw new Error('Value must be a string with a valid CSS length unit.');
        }
      
        // Create a temporary element for unit conversion
        const tempElement = document.createElement('div');
        document.body.appendChild(tempElement);
      
        // Set initial styles for accurate measurements
        tempElement.style.position = 'absolute';
        tempElement.style.visibility = 'hidden';
        tempElement.style.height = value;
        tempElement.style.width = '0';
      
        // Retrieve pixel value
        const pixels = parseFloat(window.getComputedStyle(tempElement).height);
      
        // Clean up temporary element
        document.body.removeChild(tempElement);
      
        return pixels;
      }

    const executeWidthMediaQueries = (condition, value)=>{
        if(condition=="max-width"){
            let pixelvalue = convertToPx(value);
            return canvasWidth<pixelvalue;
        }else if(condition=="min-width"){
            let pixelvalue = convertToPx(value);
            return canvasWidth>pixelvalue;
        }
    }

    const executeMediaConditions = (mediafeatures)=>{
        let res = false;
        for(let i=0; i < mediafeatures.length; i++){
            let blockcondition = true;
            for(let j=0; j<mediafeatures[i].length; j++){
                let conditioname = mediafeatures[i][j].name;
                let conditionvalue = generateStyleValue(mediafeatures[i][j]);
                if(conditioname=="max-width"||conditioname=="min-width"){
                    let resp = executeWidthMediaQueries(conditioname, conditionvalue);
                    blockcondition = blockcondition&&resp;
                }else{
                    if(conditioname!=""&&conditioname!=undefined&&conditionvalue!=""&&conditionvalue!=undefined){
                        let conditionrule = "("+conditioname+":"+conditionvalue+")";
                        let resp = executeMediaQuery(conditionrule);
                        blockcondition = blockcondition&&resp;
                    }
                }
            }
            res = res||blockcondition;
        }
        return res;
    }

    const getMediaConditions = (mediafeatures)=>{
        let condition = "";
        for(let i=0; i<mediafeatures.length; i++){
            let blockcondition = "(";
            for(let j=0; j<mediafeatures[i].length; j++){
                let conditioname = mediafeatures[i][j].name;
                let conditionvalue = generateStyleValue(mediafeatures[i][j]);
                let conditionrule = ""
                if(conditioname!=""&&conditioname!=undefined&&conditionvalue!=""&&conditionvalue!=undefined){
                    conditionrule = ""+conditioname+":"+conditionvalue;
                }
                if(conditionvalue!=""){
                    if(blockcondition=="("){
                        blockcondition = blockcondition+"("+conditionrule+")";
                    }else{
                        blockcondition = blockcondition+" and "+"("+conditionrule+")";
                    }
                }
            }
            if(blockcondition!="("){
                blockcondition = blockcondition+")";
                if(condition!=""){
                    condition = condition+ " or "+blockcondition;
                }else{
                    condition = condition+blockcondition;
                }
            }
        }
        return condition;
    }

    const getMediaClass = (style)=>{
        let rule = "";
        let res = executeMediaConditions(style.mediafeatures);
        let selector = "";
        let cssrule = "";
        if(res){
            cssrule = "";
            selector = getSelector(style.name, "default");
            cssrule = cssrule+selector+"{";
            let valid = false;
            for(let i=0; i< style.styles.length; i++){
                cssrule = cssrule+"";
                let stylename = style.styles[i].name;
                let value = generateStyleValue(style.styles[i]);
                if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                    if(style.styles[i].important){
                        let stylerule = ""+stylename+": "+value+" !important;";
                        cssrule = cssrule+stylerule;
                        valid = true;
                    }else{
                        let stylerule = ""+stylename+": "+value+";";
                        cssrule = cssrule+stylerule;
                        valid = true;
                    }
                }
            }

            if(valid){
                cssrule = cssrule+"}";
            }
        }
        return {
            selector: selector,
            rule: cssrule
        }
    }

    const getMediaRule = (style)=>{
        let  rule = "";
        let mediacondition = getMediaConditions(style.mediafeatures);
        if(mediacondition!=""){
            rule = "@media "+mediacondition+"{ "
            let cssrule = "";
            let selector = getSelector(style.name, "default");
            cssrule = cssrule+selector+"{";
            let valid = false;
            for(let i=0; i < style.styles.length; i++){
                cssrule = cssrule+"";
                let stylename = style.styles[i].name;
                let value = generateStyleValue(style.styles[i]);
                if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                    let stylerule = ""+stylename+": "+value+";"
                    cssrule = cssrule+stylerule;
                    valid = true;
                }
            }
            if(valid){
                cssrule = cssrule+"}";
            }
            if(cssrule!=""){
                rule = rule+cssrule+"}";
            }
        }
        
        return {
            selector: mediacondition,
            rule: rule
        };
    }

    const getRule = (style)=>{
        let rule = "";
        let selector = getSelector(style.name, style.state);
        rule = rule+selector+"{";
        let valid = false;
        let cssrule = "";
        for(let i=0; i< style.styles.length; i++){
            rule = rule+"";
            cssrule = cssrule+"";
            let stylename = style.styles[i].name;
            let value = generateStyleValue(style.styles[i]);
            if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                let stylerule = ""+stylename+": "+value+";"
                rule = rule+stylerule;
                cssrule = cssrule+stylerule;
                valid = true;
            }
        }
        if(valid){
            rule = rule+"}";
        }else{
            rule = ""
        }
        return {
                 selector: selector,
                 rule:rule,
                 cssrule: cssrule
                };
    }

    const findRuleIndex = (selector, rules)=>{
        let ruleindex = -1;
        for(let i=0; i< rules.length; i++){
            if(rules[i].selectorText!=undefined){
                let selectorText = rules[i].selectorText.replace(" ", "");
                if(selectorText==selector){
                    ruleindex = i;
                    break
                }
            }
        }
        return ruleindex;
    
    }

    const findMediaRuleIndex = (selector, rules)=>{
        let ruleindex = -1;
        for(let i=0; i< rules.length; i++){
            if(rules[i].conditionText!=undefined){
                let conditionText = rules[i].conditionText.replace(" ","");
                if(conditionText==selector){
                    ruleindex = i;
                    break
                }
            }
        }
        return ruleindex;
    }

    const findMediaStyleIndex = (selector, styles)=>{
        let ruleindex = -1;
        for(let i=0; i < styles.length; i++){
            if(styles[i].ruletype=="media"){
                let condition = getMediaConditions(styles[i].mediafeatures);
                selector = selector.replace(" ","")
                if(condition==selector){
                    ruleindex = i;
                    break
                }
            }
        }
        return ruleindex;
    }

    const findStyleIndex = (selector, styles)=>{
        let styleindex = -1;
        for(let i=0; i<styles.length; i++){
            if(styles[i].ruletype==undefined||styles[i].ruletype=="css"){
                let sselector = getSelector(styles[i].name, styles[i].state); 
                selector = selector.replace(" ","");
                if(sselector==selector){
                    styleindex = i;
                    break;
                }
            }
        }
        return styleindex;
    }

    const findDesignerStyleIndex = (selector, activeselectors)=>{
        let styleindex = -1;
        for(let i=0; i < activeselectors.length; i++){
            selector = selector.replace(" ", "");
            let aselector = activeselectors[i].replace(" ", "");
            if(selector==aselector){
                styleindex=i;
                break;
            }
        }
        return styleindex;
    }

    const reconcileClassesV2 = (remotestyles)=>{
        let remotestylesCopy = {...remotestyles};
        let sheet = ref.current.sheet;
        let rulelist = sheet.cssRules;
        let templates = Object.keys(remotestylesCopy);
        
        //flatten the stylelist
        //the order of the styles is maintained by loading of templates and the fact that the order of the keys in a object is maintained by insertion order
        let stylerules = [];
        for(let i=0; i<templates.length; i++){
            let tstyles = remotestylesCopy[templates[i]];
            for(let j=0; j<tstyles.length; j++){
                stylerules.push(tstyles[j])
            }
        }

        const activeselectors = [];

        let j = 0;

        for(let i=0; i<stylerules.length; i++){
            if(stylerules[i].ruletype=="css"||stylerules[i].ruletype==undefined){
                let {rule, selector, cssrule} = getRule(stylerules[i]);
                if(selector!==""&&rule!=""){
                    activeselectors.push(selector);
                    let clsindex = findRuleIndex(selector, rulelist);
                    if(clsindex==-1){
                        if(rule!=""){
                            sheet.insertRule(rule,j);
                        }
                    }else{
                        let csstext = rulelist[clsindex].cssText;
                        if(csstext!=rule){
                            sheet.deleteRule(clsindex);
                            if(rule!=""){
                                sheet.insertRule(rule,j);
                            }
                        }
                    }
                    j = j+1;
                }
            }
            if(stylerules[i].ruletype=="media"){
                if(appdesginer){
                    let {rule, selector} = getMediaClass(stylerules[i]);
                    if(selector!==""&&rule!=""){
                        activeselectors.push(selector);
                        let clsindex = findRuleIndex(selector, rulelist);
                        if(clsindex==-1){
                            if(rule!=""){
                                sheet.insertRule(rule, j);
                            }
                        }else{
                            let csstext = rulelist[clsindex].cssText;
                            if(csstext!=rule){
                                sheet.deleteRule(clsindex);
                                if(rule!=""){
                                    sheet.insertRule(rule, j)
                                }
                            }
                        }
                        j = j+1;
                    }
                }else{
                    let {selector, rule} = getMediaRule(stylerules[i]);
                    if(selector!=""&&rule!=""){
                        let clsindex = findMediaRuleIndex(selector, rulelist);
                        if(clsindex==-1){
                            if(rule!=""){
                                sheet.insertRule(rule, j);
                            }
                        }else{
                            let csstext = rulelist[clsindex].cssText;
                            if(csstext!=rule){
                                sheet.deleteRule(clsindex);
                                if(rule!=""){
                                    sheet.insertRule(rule, j);
                                }
                            }
                        }
                        j = j+1;
                    }
                }
                
            }
        }
        

        // execute special classes for static platform media queries
        if(appdesginer){
            let staticClasses = [
                {
                    "name": "only-desktop",
                    "mediafeatures":[[
                        {
                            "name": "max-width",
                            "value": "800",
                            "type": "number",
                            "unit": "px"
                        }
                    ]],
                    "styles":[
                        {
                            "name": "display",
                            "type": "value",
                            "value": "none",
                            "important": true
                        }
                    ]
                    
                },
                {

                    "name": "only-mobile",
                    "mediafeatures":[[
                        {
                            "name": "min-width",
                            "value": "800",
                            "type": "number",
                            "unit": "px"
                        }
                    ]],
                    "styles":[
                        {
                            "name": "display",
                            "type": "value",
                            "value": "none",
                            "important": true
                        }
                    ]
                }
            ];
            for(let i=0; i < staticClasses.length; i++){
                let {rule, selector} = getMediaClass(staticClasses[i]);
                    if(selector!==""){
                        activeselectors.push(selector);
                        let clsindex = findRuleIndex(selector, rulelist);
                        if(clsindex==-1){
                            if(rule!=""){
                                sheet.insertRule(rule);
                            }
                        }else{
                            let csstext = rulelist[clsindex].cssText;
                            if(csstext!=rule){
                                sheet.deleteRule(clsindex);
                                if(rule!=""){
                                    sheet.insertRule(rule)
                                }
                            }
                        }
                    }   
            }
        }

        sheet = ref.current.sheet;
        rulelist = sheet.cssRules;
        let rulelistclone = [];
        for(let i=0; i<rulelist.length; i++){
            rulelistclone.push(rulelist[i]);
        }
        let offset = 0;
        if(appdesginer){
            for(let i=0; i < rulelistclone.length; i++){
                let selector = rulelistclone[i].selectorText;
                let styleindex = findDesignerStyleIndex(selector, activeselectors);
                if(styleindex==-1){
                    sheet.deleteRule(i-offset);
                    offset = offset+1;
                }
            }
        }else{
            for(let i=0; i<rulelistclone.length; i++){
                if(CSSStyleRule.isPrototypeOf(rulelistclone[i])){
                    let selector = rulelistclone[i].selectorText;
                    let styleindex = findStyleIndex(selector, stylerules);
                    if(styleindex==-1){
                        sheet.deleteRule(i-offset);
                        offset = offset+1
                    }
                }
    
                if(CSSMediaRule.isPrototypeOf(rulelistclone[i])){
                    let conditiontext = rulelistclone[i].conditionText;
                    let styleindex = findMediaStyleIndex(conditiontext, stylerules);
                    if(styleindex==-1){
                        sheet.deleteRule(i-offset);
                        offset = offset+1;
                    }
                }   
            }
        }
    }

    useEffect(()=>{
        if(remotestyles!=undefined){
            let remotestylesCopy = {...remotestyles};
            reconcileClassesV2(remotestylesCopy);
        }
        
    },[remotestyles, canvasWidth])

    return (
        <style 
            ref = {ref}
            id="toiler-sheet"
        >
        </style>
    )
}

export default StyleSheet;
