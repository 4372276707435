
import { Link } from "react-router-dom";
import "./ClientSettings.css";

const ClientSettings = ()=>{
    
    return (
        <div className="client-settings-box">
            <div className="client-settings-item">
                <Link to="/settings/domains">Domains</Link>
            </div>
            <div className="client-settings-item">
                <Link to="/settings/collaborators">Collaborators</Link>
            </div>
        </div>
    )

}


export default ClientSettings;