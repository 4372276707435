import { 
            useEffect, 
            useRef, 
            useState,
            useLocation 
        } from "react";
import "./ElementNavContainer.css";
import { CircularProgress, fabClasses, IconButton, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { HtmlComponents, NativeElements, ToilerComponents } from "./Settings";
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from "react-redux";
import { addElement, cloneElement, deleteElement, getAppTemplates, handleElementDrop, resetapptemplates, selectActiveKey, selectActivePosition, selectActiveRoute, selectActivetemplateid, selectAppTemplates, selectFocusKey, selectFocusPath, selectFocusPosition, selectFocusSource, setActivePosition, setElementDragged, setFocusPosition } from "../../Services/apptemplates/apptemplates.slice";
import { useParams, Link } from "react-router-dom";
import { getIcons, selecticons } from "../../Services/toilericons/toilericon.slice";
import TemplateGrid from "./TemplateGrid";

const style = {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 620,
                maxHeight:700,
                "overflowY": "auto",
                backgroundColor: '#fff',
                border: '2px solid #000',
                p: 4,
            };

const ElementAddModal = ({
                            open, 
                            handleClose,
                            position,
                            setElementExpanded
                        })=>{
    
    const {type} = useParams();

    const tempgridref = useRef();

    const dispatch = useDispatch();

    const {__source, __id} = useParams();

    const [componentsactive, setComponentsActive] = useState("native");

    const [iconquery, seticonquery] = useState("");

    const [iconsloading, setIconsLoading] = useState(false);
    
    const setIconQueryAction = (val)=>{
        setIconsLoading(true);
        dispatch(getIcons(val));
        seticonquery(val);
    }

    const icons = useSelector(selecticons);


    useEffect(()=>{
        if(iconsloading==true){
            setIconsLoading(false);
        }
    },[icons])

    const addElementAction = (element, type)=>{
        // addElement(position, element);
        if(setElementExpanded!=undefined){
            setElementExpanded(true);
        }
        dispatch(addElement({
            "position": position,
            "element": element,
            "type": type,
            "source": __source
        }))
        handleClose();
    }

    useEffect(()=>{
        if(__source==undefined){
            return
        }
        if(componentsactive=="icons"){
            setIconsLoading(true);
            dispatch(getIcons(iconquery))
        }
    },[componentsactive, __source])

    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <div style={style}>
                <div className="elements-header">
                    <div className="element-sources">
                        <div onClick={()=>{setComponentsActive("native")}} className={componentsactive=="native"?"element-header-tiles active":"element-header-tiles"}>
                            Browser Components
                        </div>
                        <div onClick={()=>{setComponentsActive("templates")}} className={componentsactive=="templates"?"element-header-tiles active":"element-header-tiles"}>
                            <div>
                                Templates
                            </div>
                            <div style={{fontSize: 8}}>
                                **Only deployed templates are avaibable here
                            </div>
                            
                        </div>
                        <div onClick={()=>{setComponentsActive("toiler")}} className={componentsactive=="toiler"?"element-header-tiles active":"element-header-tiles"}>
                            Toiler Components
                        </div>
                        <div onClick={()=>{setComponentsActive("icons")}} className={componentsactive=="icons"?"element-header-tiles active":"element-header-tiles"}>
                            <div>
                                Icons
                            </div>
                            <div style={{fontSize: 8}}>
                                **Material Icons(google font)
                            </div>
                        </div>
                    </div>
                    <div>
                        <Tooltip title="close">
                            <IconButton onClick={()=>{handleClose()}}>
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    {componentsactive=="native"&&
                        <>
                            {type=="html"&&
                                <div className="elements-container-wrapper">
                                    {HtmlComponents.map((element)=>{
                                        return (
                                            <div className="element-gallery-tiles" onClick={()=>{addElementAction(element, "native")}}>
                                                {element.tag}
                                            </div>
                                        )
                                    })}
                                </div>
                            }   
                            {type=="android"&&
                                <div className="elements-container-wrapper">
                                    {NativeElements.map((element)=>{
                                        return (
                                            <div className="element-gallery-tiles" onClick={()=>{addElementAction(element, "native")}}>
                                                {element.tag}
                                            </div>
                                        )
                                    })}
                                </div>

                            }
                        </>
                    }
                    {componentsactive=="toiler"&&
                        <div className="elements-container-wrapper">
                            {ToilerComponents.map((element)=>{
                                return (
                                    <div className="element-gallery-tiles" onClick={()=>{addElementAction(element, "native")}}>
                                        {element.tag}
                                    </div>
                                )
                            })}   
                        </div>
                    }
                    {componentsactive=="templates"&&
                        <div 
                            ref={tempgridref}
                        >
                            <TemplateGrid
                                scrollref={tempgridref}
                                source={__source}
                                addElementAction={addElementAction}
                                handleClose={handleClose}
                            >
                            </TemplateGrid>
                        </div>
                    }
                    {componentsactive=="icons"&&
                        <div>
                            <div style={{display: "flex", padding: "4px", justifyContent: "center"}}>
                                <TextField value={iconquery} onChange={(e)=>{setIconQueryAction(e.target.value)}} label="typetosearch"></TextField>
                            </div>
                            {iconsloading?
                                <>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <CircularProgress></CircularProgress>
                                    </div>
                                </>:
                                <>
                                    <div className="elements-container-wrapper">
                                        {icons?.map((icon)=>{
                                            return (
                                                <div className="element-gallery-tiles" onClick={()=>{addElementAction({"tag": "icon", elemtype: "native",name: icon.name}, "native")}}>
                                                    <span className="material-symbols-outlined">
                                                        {icon.name}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

const ElementNav = ({
                        element, 
                        position, 
                        setElementInFocus,
                        setElementActive,
                        elementInFocus,
                        elementActive
                    })=>{

    const {__source, workspace} = useParams();

    const [elementGrabed, setElementGrabed] = useState(false);

    const [dragboudaries, setDragboundries] = useState(false);

    const [elementexpanded, setElementExpanded] = useState(false);

    const dispatch = useDispatch();

    const setElementDraggedAction = (position)=>{
        dispatch(setElementDragged(position))
    }

    const handleElementDropAction = (position)=>{
        dispatch(handleElementDrop(position))
    }

    const deleteElementAction = (position)=>{
        dispatch(deleteElement(position));
    }

    const elmActive = useSelector(selectActivePosition);

    const elmFocus = useSelector(selectFocusPosition);

    const [expandedSetBy, setExpandedSetBy] = useState(false);

    const isFocusPositionContains = (
                                        position, 
                                        elmFocus
                                    )=>{
        let contained = true;
        for(let i=0; i<position.length; i++){
            if(position[i]!=elmFocus[i]){
                contained = false;
                break;
            }
        }
        return contained;
    }

    const isActivePositionContains = (
                                        position,
                                        elmActive
                                     )=>{
        let contained = true;
        for(let i=0; i < position.length; i++){
            if(position[i]!=elmActive[i]){
                contained = false;
                break;
            }
        }
        return contained;
    }

    const focussource = useSelector(selectFocusSource);

    const focuspath = useSelector(selectFocusPath);

    const focusKey = useSelector(selectFocusKey);

    const activekey = useSelector(selectActiveKey);

    const routeActive = useSelector(selectActiveRoute);

    useEffect(()=>{
        if(focussource=="canvas"&&focuspath==""&&focusKey==elmkey){
            let focuscontained = isFocusPositionContains(position, elmFocus);
            let activecontained = isActivePositionContains(position, elmActive);
            if(activecontained){
                setElementExpanded(true)
            }else{
                if(focuscontained&&elementexpanded==false){
                    setElementExpanded(true)
                }else{
                    if(elementexpanded==true){
                        setElementExpanded(false);
                    }
                }
            }
        }
    },[elmFocus])


    const getElementActive = (position)=>{
        if(JSON.stringify(position)==JSON.stringify(elmActive)&&focuspath==""&&activekey==elmkey){
            return true;
        }else{
            return false;
        }
    }

    const getRouteActive = (position, index)=>{
        if(JSON.stringify(position)==JSON.stringify(elmFocus)&&activekey==elmkey&&routeActive==index){
            return true;
        }else{
            return false;
        }
    }


    const getElementInFocus = (position)=>{
        if(JSON.stringify(position)==JSON.stringify(elmFocus)&&focuspath==""&&focusKey==elmkey){
            return true;
        }else{
            return false;
        }
    }

    const getElementClass = (position)=>{
        if(getElementActive(position)){
            return "element-nav-container active"
        }else if(getElementInFocus(position)){
            return "element-nav-container focus"
        }else{
            return "element-nav-container"
        }
    }

    const elmkey = useSelector(selectActivetemplateid)

    const setElementInFocusAction = (position, key)=>{
        dispatch(setFocusPosition({
                                    position: position,
                                    key: key,
                                    focussource: "bar",
                                    path: ""
                                  }))
    }

    const setElementActiveAction = (position, key)=>{
        dispatch(setActivePosition({
                                    position: position,
                                    key: key,
                                    path: "",
                                    focussource: "bar"
                                  }));
    }

    const [elementGalleryOpen, setElementGalleryOpen] = useState(false);

    const closeElementGallery = ()=>{
        setElementGalleryOpen(false);
    }

    const cloneElementAction = (position)=>{
        dispatch(cloneElement({
            position: position
        }))
    }

    return (
        <>
            {dragboudaries&&<div style={{width: "100%", backgroundColor:"blue", height: "2px"}}></div>}
            <div style={{display: "flex", alignItems: "center", height:"30px", marginTop:"12px"}} 
                draggable={!elementexpanded} 
                onDragEnter={()=>{setDragboundries(true)}} 
                onDragLeave={()=>{setDragboundries(false)}}
                onDragOver={(e)=>{
                    e.preventDefault();
                    if(dragboudaries==false){
                        setDragboundries(true)
                    }
                }}
                onDragStart={()=>{setElementDraggedAction(position)}}
                onDrop={
                            (e)=>{
                                e.preventDefault();
                                setDragboundries(false)
                                handleElementDropAction(position)
                            }
                        }
            >
                
                <div
                    onMouseEnter={()=>{setElementInFocusAction(position, elmkey)}}
                    onMouseLeave={()=>{setElementInFocusAction([], "")}}
                    onClick={()=>{setElementActiveAction(position, elmkey)}}
                    className={getElementClass(position)}
                >
                    <div className="element-details">
                        {element.childs!=undefined?
                                <>
                                    {elementexpanded?
                                        <Tooltip title="Expand Block">
                                            <IconButton onClick={()=>{setElementExpanded(false)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_less
                                                </span>
                                            </IconButton>
                                        </Tooltip>:
                                        <Tooltip>
                                            <IconButton onClick={()=>{setElementExpanded(true)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_more
                                                </span>
                                            </IconButton>
                                        </Tooltip>
                                    }    
                                </>:
                                <>
                                   {element.tag!="router"&&
                                        <Tooltip title="Expand Block">
                                            <IconButton disabled>
                                                <span className='material-symbols-outlined'>
                                                    expand_more                                            
                                                </span>
                                            </IconButton>
                                        </Tooltip>
                                   } 
                                </>
                            }
                            {element.tag=="router"&&
                                <>
                                    {elementexpanded?
                                        <Tooltip title="Expand Block">
                                            <IconButton onClick={()=>{setElementExpanded(false)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_less
                                                </span>
                                            </IconButton>
                                        </Tooltip>:
                                        <Tooltip>
                                            <IconButton onClick={()=>{setElementExpanded(true)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_more
                                                </span>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>
                            }
                        <span 
                            class="material-symbols-outlined" style={elementGrabed?{cursor: "grabbing"}:{cursor: "grab"}} onMouseDown={()=>{setElementGrabed(true)}} onMouseUp={()=>{setElementGrabed(false)}}>
                            drag_indicator
                        </span>
                        <span>
                            {element.elemtype=="template"?
                                <>
                                    <Link 
                                        to={"/"+workspace+"/appdesigner/html/"+__source+"/"+element._id+"/"} 
                                        state={{fromdesigner: true}}
                                    >{element.name}|{element.tag}</Link>
                                </>:
                                <>
                                    {element.name}|{element.tag}
                                </>
                            } 
                        </span>
                    </div>
                    <div className="element-settings">
                        {element.type=="single"&&
                            <Tooltip title="block single">
                                <IconButton>
                                    <span className="material-symbols-outlined">
                                        stack
                                    </span>
                                </IconButton>
                            </Tooltip>
                            
                        }
                        {element.type=="conditional"&&
                            <Tooltip title="block conditional">
                                <IconButton>
                                    <span className="material-symbols-outlined">
                                        device_hub
                                    </span>
                                </IconButton>
                            </Tooltip>
                            
                        }
                        {element.type=="loop"&&
                            <Tooltip title="block loop">
                                <IconButton>
                                    <span className="material-symbols-outlined">
                                        all_inclusive
                                    </span>
                                </IconButton>
                            </Tooltip>
                            
                        }
                        <Tooltip title="clone element">
                            <IconButton onClick={()=>{cloneElementAction(position)}}>
                                <span className="material-symbols-outlined">
                                    move_group
                                </span>
                            </IconButton>
                        </Tooltip>
                        {element.childs!=undefined&&
                            <Tooltip title="add child element">
                                <IconButton onClick={()=>{setElementGalleryOpen(true)}}>
                                    <span className="material-symbols-outlined">
                                        add
                                    </span>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="delete element">
                            <IconButton onClick={()=>{deleteElementAction(position)}}>
                                <span className="material-symbols-outlined">
                                    delete
                                </span> 
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                
            </div>
            {elementexpanded&&element.tag!="router"&&
                <>
                    <div style={{marginLeft:"10px"}}>
                        <ElementNavContainer
                            elements={element.childs}
                            position={position}
                            setElementInFocus={setElementInFocus}
                            setElementActive={setElementActive}
                            elementInFocus={elementInFocus}
                            elementActive={elementActive}
                        ></ElementNavContainer>
                    </div>
                </>
            }
            {elementexpanded&&element.tag=="router"&&
                <>
                    <div style={{marginLeft:"10px"}}>
                        {element.routes.map((route, index)=>{
                            return (
                                <div style={getRouteActive(position, index)?{    
                                                marginTop: "10px",
                                                marginLeft: "10px"
                                            }:{
                                                marginTop: "10px",
                                                marginLeft: "10px",
                                                boxShadow: "0 4px 8px 0 #3a07cd33"
                                            }}>
                                    {route.path}|<Link 
                                                        to={"/"+workspace+"/appdesigner/html/"+__source+"/"+route.template+"/"}
                                                        state={{fromdesigner: true}}
                                                >{route.templatename}</Link>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            <ElementAddModal
                open={elementGalleryOpen}
                handleClose={closeElementGallery}
                addElement={addElement}
                position={position}
                setElementExpanded={setElementExpanded}
            >

            </ElementAddModal>
        </>
    )
}

const ElementNavContainer = ({
                                elements, 
                                position,
                                setElementDragged,
                                handleElementDrop,
                                addElement,
                                deleteElement,
                                setElementInFocus,
                                setElementActive,
                                elementInFocus,
                                elementActive,
                                isBody
                            })=>{
    

    const [elementGalleryOpen, setElementGalleryOpen] = useState(false);

    const closeElementGallery = ()=>{
        setElementGalleryOpen(false);
    }

    return (
        <>
            {elements!=undefined&&
                <>
                    {elements.map((element,i)=>{
                        return(
                            <div
                            >
                                <ElementNav
                                    element={element}
                                    position={[...position, i]}
                                    setElementDragged={setElementDragged}
                                    handleElementDrop={handleElementDrop}
                                    addElement={addElement}
                                    deleteElement={deleteElement}
                                    setElementInFocus={setElementInFocus}
                                    setElementActive={setElementActive}
                                    elementInFocus={elementInFocus}
                                    elementActive={elementActive}
                                ></ElementNav>
                            </div>
                        )
                    })}
                    {isBody&&
                        <div className="add-element-container">
                            <IconButton onClick={()=>{setElementGalleryOpen(true)}}>
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </IconButton>
                        </div>
                    }
                    <ElementAddModal
                        open={elementGalleryOpen}
                        handleClose={closeElementGallery}
                        addElement={addElement}
                        position={position}
                    >
                    </ElementAddModal> 
                </>
            }  
        </>
    )

}

const MainElementContainer = ({
    elements, 
    position,
    setElementInFocus,
    setElementActive,
    elementInFocus,
    elementActive,
    
})=>{

    const {type} = useParams();

    const getBodyElements = ()=>{
        if(type=="android"){
            return elements;

        }else if(type=="html"){
            if(elements.length>0){
                return elements[1].childs
            }
        }else{
            return [];
        }
    }

    return (
        <>
            <div>
                <h6>
                    Body
                </h6>
                {type=="android"&&
                    <ElementNavContainer
                        elements={getBodyElements()}
                        position={[...position]}
                        setElementInFocus={setElementInFocus}
                        setElementActive={setElementActive}
                        elementInFocus={elementInFocus}
                        elementActive={elementActive}
                        path=""
                    >
                    </ElementNavContainer>
                }
                {type=="html"&&
                    <ElementNavContainer
                        elements={getBodyElements()}
                        position={[...position,1]}
                        setElementInFocus={setElementInFocus}
                        setElementActive={setElementActive}
                        elementInFocus={elementInFocus}
                        elementActive={elementActive}
                        isBody={true}
                    >
                    </ElementNavContainer>
                }
            </div>
        </>
    )

}

export default MainElementContainer;