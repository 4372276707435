import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { createDataObjectApi, deleteDataObjectApi, getDataDataApi, getDataHeadersApi, updateDataObjectApi } from "./data.service";


export const getDataHeaders = createAsyncThunk(
    "data/getdataheaders",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getDataHeadersApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error fetching table headers"));
            throw error;
        }
    }
)


export const getDataData = createAsyncThunk(
    "data/getdatadata",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getDataDataApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error fetching table data"));
            throw error;
        }

    }
)


export const createDataObject = createAsyncThunk(
    "data/createdataobject",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createDataObjectApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error creating schema object"));
            throw error;
        }
    }
)


export const updateDataObject = createAsyncThunk(
    "data/updatedataobject",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateDataObjectApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating schema object"));
            throw error;
        }
    }
)


export const deleteDataObject = createAsyncThunk(
    "data/deletedataobject",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteDataObjectApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error deleting schema object"));
            throw error;
        }

    }
)

const dataSlice = createSlice({
    "name": "data",
    "initialState":{
        "headers": [],
        data: [],
        "total": 0,
        "recordcreated": false,
        "recordupdated": false,
        "recorddeleted": false,
        "schemapermission": ""
    },
    "reducers":{
        resetrecordcreated: (state, action)=>{
            state.recordcreated = false
        },
        resetrecordupdated: (state, action)=>{
            state.recordupdated = false
        },
        resetrecorddeleted: (state, action)=>{
            state.recorddeleted = false;
        }
    },
    "extraReducers": (builder)=>{
        builder.addCase(getDataHeaders.fulfilled, (state, action)=>{
            state.headers = action.payload.headers;
            state.schemapermission = action.payload.access;
        })
        builder.addCase(getDataData.fulfilled, (state, action)=>{
            state.data = action.payload.objects;
            state.total = action.payload.count;
        })
        builder.addCase(createDataObject.fulfilled, (state, action)=>{
            state.recordcreated = true
        })
        builder.addCase(updateDataObject.fulfilled, (state, action)=>{
            state.recordupdated = true;
        })
        builder.addCase(deleteDataObject.fulfilled, (state, action)=>{
            state.recorddeleted = true;
        })
    }
})

export const selectDataHeaders = (state) => state.data.headers;
export const selectDataData = (state) => state.data.data;
export const selectDataRecordCreated = (state) => state.data.recordcreated;
export const selectDataRecordUpdated = (state) => state.data.recordupdated;
export const selectDataRecordDeleted = (state) => state.data.recorddeleted; 
export const selectDataSchemaPermission = (state) => state.data.schemapermission; 
export const selectDataTotal = (state) => state.data.total;

export const {resetrecordcreated, resetrecordupdated, resetrecorddeleted}  = dataSlice.actions;

export default dataSlice.reducer;