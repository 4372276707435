import { Icon, IconButton } from '@mui/material';
import React, { useState } from 'react';
import {Button} from '@mui/material';
import MarketManifestModal from './MarketManifestModal';
// CSS styles for the component
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    maxWidth: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(201 201 201)',
    fontSize: '20px',
    textTransform: 'uppercase',
  },
  appName: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '8px 0',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    // backgroundColor: '#1976d2',
    color: '#fff',
    fontSize: '20px',
    border: '1px solid #1976d2'
  },
  documentButton: {
    backgroundColor: '#fff',
    color: '#1976d2',
    border: '1px solid #1976d2',
  },
  installbuttonContainer: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
  },
  installbutton: {
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: '#1976d2',
    color: '#fff'
  },
};

const PlatformManifestdetails = ({ 
                                    key,
                                    manifestid,
                                    manifestscope,
                                    appName, 
                                    iconSrc, 
                                    onInstall, 
                                    onPreview,
                                    isInstalled 
                                }) => {
  // Function to get the first two letters of the app name
  const getInitials = (name) => {
    return name.substring(0, 2).toUpperCase();
  };


  return (
    <div style={{padding: "10px"}}>
        <div style={styles.container}>
            {/* Icon or Initials */}
            {iconSrc ? (
                <img src={iconSrc} alt={appName} style={styles.icon} />
            ) : (
                <div style={styles.icon}>{getInitials(appName)}</div>
            )}
            {/* Application Name */}
            <div style={styles.appName}>{appName}</div>
            {/* Buttons */}
            <div style={styles.buttonContainer}>
                <Button disabled={isInstalled==1} onClick={()=>{onInstall(
                                                    manifestid, 
                                                    manifestscope
                                                )}}>
                    Install
                </Button>
                <IconButton onClick={()=>{onPreview(manifestid, manifestscope)}}>
                    <span className='material-symbols-outlined'>
                        visibility
                    </span>
                </IconButton>
            </div>
        </div>
    </div>
    
  );
};

export default PlatformManifestdetails;
