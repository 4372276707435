import React from 'react';
import { Modal, Box, Button, Typography, IconButton } from '@mui/material';
import MarketManifestDetails from './MarketManifestDetails'; // Adjust the path based on your project structure

const MarketManifestModal = ({ open, handleClose, manifestid, preview, manifestscope }) => {
  return (
    <div>
      {/* MUI Modal Component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <IconButton
                onClick={()=>{handleClose()}}
            >
                <span className='material-symbols-outlined'>
                    close
                </span>
            </IconButton>
          </div>
          {/* Pass the manifestid prop to MarketManifestDetails component */}
          <MarketManifestDetails 
            manifestid={manifestid} 
            preview={preview}
            manifestscope={manifestscope}    
        />
        </Box>
      </Modal>
    </div>
  );
};

export default MarketManifestModal;