import { get, putjson } from "../signin/axios";
import { baseURL } from "../../env";


export const updateDocumentationApi = async(payload)=>{
    let res = await putjson(baseURL+"documentation/"+payload.source+"/"+payload.sourceid, {"document":payload.document});
    return res;
}

export const getDocumentationApi = async(payload)=>{
    let res = await get(baseURL+"documentation/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const getDocumentationPermissionApi = async(payload)=>{
    let res = await get(baseURL+"documentations/permission/"+payload.source+"/"+payload.sourceid);
    return res;
}