import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { createAndroidAppApi, getAndroidApplistApi, getAppCountApi, getAppDetailsApi, getAppScreensApi, getAppCreateOptionApi, createAppScreenApi, deleteAppApi, createKeystoreApi, getAndroidBuildsApi, getBuildstatusApi, updateAndroidAppApi, startAndroidBuildApi } from "./android.service";
import { downloadFavicon } from "../websites/website.service";


export const getAndroidApplist = createAsyncThunk(
    "androidapps/getandroidapplist",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAndroidApplistApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting android apps"))
            throw error;
        }
    }
)

export const createAndroidApp = createAsyncThunk(
    "andriodapps/createandroidapp",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createAndroidAppApi(payload);
            dispatch(getAndroidApplist());
            return res;
        }catch(error){
            dispatch(showError("Error creating android app"));
            throw error;
        }
    }
)

export const updateAndroidApp = createAsyncThunk(
    "androidapps/updateandroidapp",
    async(payload, {dispatch, rejectWithValue})=>{
        try{    
            let res = await updateAndroidAppApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating android app"));
        }
    }
)

export const getAppDetails = createAsyncThunk(
    "androidapps/getappdetail",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAppDetailsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting android details"));
            throw error;
        }
    }
)

export const getAppScreens = createAsyncThunk(
    "androidapps/getappscreens",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAppScreensApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting app screens"));
            throw error;
        }
    }
)

export const getAppCount = createAsyncThunk(
    "androidapps/getappcount",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAppCountApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting android app count"));
            throw error;
        }
    }
)

export const getAppCreateOption = createAsyncThunk(
    "androidapps/getappcreateoptions",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAppCreateOptionApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting android create options"));
            throw error;
        }
    }
)

export const createAppscreen = createAsyncThunk(
    "androidapps/createandroidscreens",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createAppScreenApi();
            return res;
        }catch(error){
            dispatch(showError("Error creating android screen"));
            throw error;
        }
    }
)

// export const getBuildStatus = createAsyncThunk(
//     "androidapps/getbuildstatus",
//     async(payload, {dispatch, rejectWithValue})=>{
//         try{    
            
//         }catch(error){
//             dispatch(showError("Error getting build status"));
//             throw error;
//         }
//     }
// )

export const deleteApp = createAsyncThunk(
    "andriodapps/deleteapp",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteAppApi(payload);
            dispatch(getAndroidApplist());
            return res;
        }catch(error){
            dispatch(showError("Error deleting android application"));
        }
    }
)


export const createKeystore = createAsyncThunk(
    "androidapps/createkeystore",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createKeystoreApi(payload);
            dispatch(getAppDetails(payload.appid));
            return res;
        }catch(error){
            dispatch(showError("Error creating keystore"));
            throw error;
        }
    }
)

export const getAndroidBuilds = createAsyncThunk(
    "androidapps/getandroidbuilds",
    async(payload, {dispatch, rejectWithValue})=>{
        try{    
            let res = await getAndroidBuildsApi(payload);
            return {
                type: payload.type,
                builds: res
            }
        }catch(error){
            dispatch(showError("Error creating androidbuilds"));
            throw error;
        }
    }
)

const waitTimeOut = ()=>{
    return new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve()
        }, 3000);
    })
}

export const pollBuildStatus = createAsyncThunk(
    "androidapps/pollbuildstatus",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getBuildstatusApi(payload);
            if(res.status==1){
                await waitTimeOut();
                dispatch(pollBuildStatus(payload));
                return res;
            }else{
                dispatch(getAppDetails(payload));
                dispatch(getAndroidBuilds({appid: payload, type: "debug"}));
                dispatch(getAndroidBuilds({appid: payload, type: "prod"}));
                return res;
            }
        }catch(error){
            dispatch(showError("Build status polling has failed, please refresh to check the build status"));
            throw error;
        }
        
    }

)

export const saveLauncherIcon = createAsyncThunk(
    "androidapps/savelaunchericon",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let appdetails = {...payload.appdetails}
            let url = await downloadFavicon({url: appdetails.icon, name: payload.iconname})
            appdetails = {...appdetails,
                          icon: url
                         }
            dispatch(updateAndroidApp(appdetails));
            // let url = await downloadFavicon({url: appdetails.favicon, name: appdetails.faviconname});
            // websitedetail = {...websitedetail,
            //                      favicon: url
            //                     }
            // dispatch(updateWebsite(websitedetail));
            return {
                "status": true
            }
        }catch(error){
            dispatch(showError("Error saving launcher icon"));
            throw error;
        }
    }
)


export const startAndroidBuild = createAsyncThunk(
    "androidapps/startandroidbuild",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await startAndroidBuildApi(payload);
            dispatch(getAppDetails(payload.appid));
            return res;
        }catch(error){
            dispatch(showError("Error starting a build"));
            throw error;
        }

    }
)

const androidappslice = createSlice({
    name: "androidapps",
    initialState:{
        "apps":[],
        "appcreated": null,
        "androidcount": 0,
        "appscreens":[],
        "appcreateoptions": [],
        "appdetails": null,
        "appdeleted": null,
        "appscreencreated": null,
        "keystorecreated": null,
        "apkbuilds": [],
        "aabbuilds": [],
        "appupdated": null
    },
    reducers: { 
        "resetappscreencreated":(state, action)=>{
            state.appscreencreated = null;
        } ,
        "resetappcreated": (state, action)=>{
            state.appcreated = null;
        },
        "resetappdeleted": (state, action)=>{
            state.appdeleted = null;
        },
        "resetkeystorecreated": (state, action)=>{
            state.keystorecreated = null;
        },
        "resetappupdated": (state, action)=>{
            state.appupdated = null
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getAndroidApplist.fulfilled, (state, action)=>{
            state.apps = action.payload;
        })
        builder.addCase(createAndroidApp.fulfilled, (state, action)=>{
            state.appcreated = true;
        })
        builder.addCase(getAppCount.fulfilled, (state, action)=>{
            state.androidcount = action.payload.count;
        })
        builder.addCase(getAppDetails.fulfilled, (state, action)=>{
            state.appdetails = action.payload;
        })
        builder.addCase(getAppScreens.fulfilled, (state, action)=>{
            state.appscreens = action.payload;
        })
        builder.addCase(getAppCreateOption.fulfilled, (state, action)=>{
            state.appcreateoptions = action.payload;
        })
        builder.addCase(createAppscreen.fulfilled, (state, action)=>{
            state.appscreencreated = true;
        })
        builder.addCase(deleteApp.fulfilled, (state, action)=>{
            state.appdeleted = true;
        })
        builder.addCase(createKeystore.fulfilled, (state, action)=>{
            state.keystorecreated = true;
        })
        builder.addCase(getAndroidBuilds.fulfilled, (state, action)=>{
            if(action.payload.type=="prod"){
                state.aabbuilds = action.payload.builds;
            }else if(action.payload.type=="debug"){
                state.apkbuilds = action.payload.builds
            }
        })
        builder.addCase(pollBuildStatus.fulfilled, (state, action)=>{

        })
        builder.addCase(updateAndroidApp.fulfilled, (state, action)=>{
            state.appupdated = true;
        })
        builder.addCase(startAndroidBuild.fulfilled, (state, action)=>{
            
        })
    }
})

export const selectAndroidAppDetails = (state) => state.androidapps.appdetails;

export const selectAndroidAppScreens = (state) => state.androidapps.appscreens;

export const selectAndroidApps = (state) => state.androidapps.apps;

export const selectAppCreated = (state) => state.androidapps.appcreated;

export const selectAndroidAppCount = (state) => state.androidapps.androidcount; 

export const selectAppCreateOptions = (state) => state.androidapps.appcreateoptions;

export const selectAppscreenCreated = (state) => state.androidapps.appscreencreated;

export const selectAppdeleted = (state) => state.androidapps.appdeleted;

export const selectKeystorecreated = (state) => state.androidapps.keystorecreated;

export const selectAndroidApks = (state) => state.androidapps.apkbuilds;

export const selectAndroidAabs = (state) => state.androidapps.aabbuilds;

export const selectAppUpdated = (state) => state.androidapps.appupdated;
 
export const {
                resetappscreencreated, 
                resetappcreated, 
                resetappdeleted, 
                resetkeystorecreated,
                resetappupdated
            }  = androidappslice.actions;

export default androidappslice.reducer;