export const baseURL = "https://api.toiler.co/api/"

export const signinURL = "https://api.toiler.co/";

export const appUrl = "https://app.toiler.co/";

export const fileURL = "https://cdn.toiler.co/";

export const signinSuccessUrl = "https://api.toiler.co/google/signin/";

export const env = "dev";

export const emailid = "abhinav054@gmail.com";
