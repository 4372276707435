import { Modal, Box } from "@mui/material";
import InstallManifest from "./InstallManifest";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
            getInstallConfig, 
            resetinstallredirecturl, 
            resetmanifestinstalled, 
            selectInstallConfig, 
            selectInstallRedirectUrl, 
            selectManifestInstalled, 
            installManifest,
            installThirdpartyManifest,
            getInstallthirdpartyTemplate,
            selectThirdpartyinstallconfig,
            selectInstallButtonTemplate
        } from "../../Services/manifest/manifest.slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";


const styles = {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 200,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }

const InstallManifestModal = ({
    open,
    handleClose,
    manifestscope,
    manifestid,
    source,
    sourceid
})=>{

    const dispatch = useDispatch();

    const {workspace} = useParams();

    const [form, setForm] = useState([]);

    const [formvalues, setformvalues] = useState([]);

    const [installtype, setInstalltype] = useState("");

    const [name, setName] = useState("");

    const [installclicked, setinstalledclicked] = useState(false);

    useEffect(()=>{
        if(manifestscope!=""&&manifestid!=""){
            dispatch(getInstallConfig({
                manifestscope: manifestscope,
                manifestid: manifestid
            }))
        }
    },[manifestscope, manifestid])

    const installconfig = useSelector(selectInstallConfig);

    useEffect(()=>{
        if(installconfig?.form!=undefined&&installconfig?.form!=""){
            let formcopy = [...installconfig.form];
            setForm(formcopy);
        }
        if(installconfig?.installtype!=""&&installconfig?.installtype!=undefined){
            setInstalltype(installconfig.installtype);
        }
        if(installconfig?.name!=""&&installconfig?.name!=undefined){
            setName(installconfig.name);
        }
    },[installconfig])

    const location = useLocation();

    const installmanifest = ()=>{
        if(installtype=="form"||installtype=="advanced-form"){
            setinstalledclicked(true);
            dispatch(resetmanifestinstalled());
            dispatch(resetinstallredirecturl());
            dispatch(installManifest({
                                        redirecturl: location.pathname,
                                        formvalues: formvalues,
                                        manifestid: manifestid,
                                        manifestscope: manifestscope,
                                        source: source,
                                        sourceid: sourceid,
                                        workspace: workspace
                                    })
                    )
        }
        if(installtype=="third-party"){
            setinstalledclicked(true);
            dispatch(resetmanifestinstalled());
            dispatch(resetinstallredirecturl());
            dispatch(installThirdpartyManifest({
                                                  redirecturl: location.pathname,
                                                  formvalues: formvalues,
                                                  manifestid: manifestid,
                                                  manifestscope: manifestscope,
                                                  source: source,
                                                  sourceid: sourceid,
                                                  workspace: workspace
                                                }))
        }
        
    }

    const thirdpartyinstallconfig = useSelector(selectThirdpartyinstallconfig);
    
    const manifestinstalled = useSelector(selectManifestInstalled);

    const manifestinstallurl = useSelector(selectInstallRedirectUrl);

    const navigate = useNavigate();

    useEffect(()=>{ 
        if(manifestinstalled&&thirdpartyinstallconfig){
            if(thirdpartyinstallconfig.redirecturl!=null&&thirdpartyinstallconfig.redirecturl!=undefined){
                window.location.href =  thirdpartyinstallconfig.redirecturl;
            }
        }
    },[thirdpartyinstallconfig, manifestinstalled, installclicked])

    useEffect(()=>{
        if(installtype=="advanced-form"||installtype=="form"){
            if(
                manifestinstalled&&
                installclicked
            ){
                if(manifestinstallurl!=location.pathname){
                    handleClose();
                    navigate(manifestinstallurl);
                }else{
                    handleClose();
                    navigate(manifestinstallurl);
                }       
            }
        }
    },[manifestinstalled, installclicked, manifestinstallurl])


    useEffect(()=>{
        if(installtype=="third-party"){
            dispatch(getInstallthirdpartyTemplate({
                                                    manifestscope: manifestscope,
                                                    manifestid: manifestid 
                                                }))
        }
    },[installtype])

    const buttontemplate = useSelector(selectInstallButtonTemplate);

    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <Box
                sx={styles}
            >
                <div style={{marginTop: "5px"}}>
                    <h5>Install {name}</h5>
                </div>
                <div style={{padding: "10px", display: "flex", justifyContent: "center"}}>
                    <InstallManifest
                        form={form}
                        setformvalues={setformvalues}
                        formvalues={formvalues}
                        installtype={installtype}
                        oncancel={handleClose}
                        oninstall={installmanifest}
                        buttontemplate={buttontemplate}
                    >
                    </InstallManifest>
                </div>
            </Box>
        </Modal>
    )
}

export default InstallManifestModal;