import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from "@mui/material/Button";
import { FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {showError} from "../../Services/error.slice";
import { resetfoldercreated, createFolder, selectFolderCreated} from "../../Services/filesystem/filesystem.slice";
import { useParams } from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


const CreateFolderModal = ({open , handleClose})=>{

    const [folderaccess, setFolderaccess] = useState("public");

    const {workspace} = useParams();

    const [folderName, setFolderName] = useState("");

    const dispatch = useDispatch();

    const [saveClicked, setSaveClicked] = useState(false);

    const saveFolder = ()=>{
        if(folderName==""){
            dispatch(showError("Cannot create folder with empty name"))
        }else{
            setSaveClicked(true);
            dispatch(resetfoldercreated());
            dispatch(createFolder({
                                    "path": folderName,
                                    "workspace": workspace,
                                    "access": folderaccess
                                  }));
        }
    }

    const foldercreated = useSelector(selectFolderCreated);

    useEffect(()=>{
        if(saveClicked&&foldercreated){
            handleClose()
        }
    },[saveClicked, foldercreated]);


    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Create Folder
                    </h3>
                    <div>
                        <TextField value={folderName} onChange={(e)=>{setFolderName(e.target.value)}}></TextField>
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <select
                            value={folderaccess}
                            onChange={(e)=>{
                                setFolderaccess(e.target.value)
                            }}
                        >
                            <option value="public">public</option>
                            <option value="private">private</option>
                        </select>
                    </div>
                </div>
                <div style={{display: "flex", "padding": 10}}>
                    <div>
                        <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                    </div>
                    <div>
                        <Button style={{color: "#3A07CD"}} onClick={()=>{saveFolder()}}>Save</Button>
                    </div>
                </div>
            </Box>
      </Modal>
    )
}


export default CreateFolderModal;