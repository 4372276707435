import { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import { 
            Button,
            Modal, 
            Box, 
            TextField, 
            Select, 
            MenuItem, 
            FormControl, 
            InputLabel,
            IconButton,
            Typography,
            CircularProgress,
            LinearProgress
        } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { 
            createWebsite, 
            getAvailableHosts, 
            getWebsiteList, 
            resetcreatewebsiteres, 
            resetwebsitedeleted, 
            selectAvailableHosts, 
            selectCreateWebsiteRes, 
            selectWebsiteList, 
            selectWebsitedeleted,
            deleteWebsite,
            selectWebsiteworkspacepermission,
            selectWebsiteListLoader
        } from "../../Services/websites/website.slice";
import "./Websitelist.css";
import { useNavigate, useParams, Link } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


const CreateWebsiteModal = ({open, handleClose})=>{
    

    const {workspace} = useParams();

    const [websitename, setWebsitename] = useState("");
    
    const [websitehost, setwebsitehost] = useState("");

    const [saveClicked, setSaveClicked] = useState(false);

    const dispatch = useDispatch();

    const availablehosts = useSelector(selectAvailableHosts);


    const saveWebsite = ()=>{
        setSaveClicked(true)
        dispatch(resetcreatewebsiteres());
        dispatch(createWebsite({
            name: websitename,
            host: websitehost,
            workspace: workspace
        }))
    }

    useEffect(()=>{
        dispatch(getAvailableHosts());
    },[])

    const createres = useSelector(selectCreateWebsiteRes);

    useEffect(()=>{
        if(saveClicked&&createres!=null){
            handleClose();
        }
    },[createres, saveClicked])
    

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {saveClicked?
                        <>
                            <CircularProgress></CircularProgress>
                        </>:
                        <div>
                            <div className="create-website-items">
                                <TextField 
                                    value={websitename} 
                                    onChange={(e)=>{setWebsitename(e.target.value)}}
                                    label="Website name"    
                                ></TextField>
                            </div>           
                            <div className="create-website-items">
                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel
                                        id="website-host"
                                        
                                    >
                                        Website host
                                    </InputLabel>
                                    <Select
                                        labelId="website-host"
                                        value={websitehost}
                                        onChange={(e)=>{setwebsitehost(e.target.value)}}
                                        label="Website host"
                                    >
                                        {availablehosts?.map((host)=>{
                                            return(
                                                <MenuItem value={host.value}>{host.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>       
                            <div>
                                <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>Cancel</Button>
                                <Button onClick={()=>{saveWebsite()}} style={{color: "#3A07CD"}}>Save</Button>
                            </div>
                        </div>
                    }
                </Box>
            </Modal>
        </>
    )

}


const DeleteWebsiteModal = ({open, handleClose, website})=>{

    const dispatch = useDispatch();

    const [deleteclicked, setDeleteclicked] = useState(false);

    const {workspace} = useParams();

    const deleteWebsiteAction = (id)=>{
        setDeleteclicked(true);
        dispatch(resetwebsitedeleted());
        dispatch(deleteWebsite({
            id: id,
            workspace: workspace
        }))
    }

    const websitedeleted = useSelector(selectWebsitedeleted);

    useEffect(()=>{
        if(websitedeleted&&deleteclicked){
            handleClose()
        }
    },[deleteclicked, websitedeleted])

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby = "modal-delete-title"
                aria-describedby="modal-delete-description"
            >
                <Box
                    sx={style}
                >
                    <div>
                    <h3>
                        Delete Website {website?.name}
                    </h3>
                    {deleteclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <div>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Are you sure you want to delete website ? 
                                </Typography>
                            </div>
                            <div style={{display: "flex", "padding": 10}}>
                                <div>
                                    <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>Cancel</Button>
                                </div>
                                <div>
                                    <Button onClick={()=>{deleteWebsiteAction(website._id)}} style={{color: "#3A07CD"}}>Yes</Button>
                                </div>
                            </div>   
                        </>
                    }   
                </div>
                
                </Box>
            </Modal>
        </>
    )
}

const Websitelist = ()=>{

    const {workspace} = useParams();

    const [opencreate, setOpencreate] = useState(false);

    const [opendelete, setOpendelete] = useState(false);

    const [websitetodelete, setWebsitetodelete] = useState(null);

    const dispatch = useDispatch();

    const navigate = useNavigate();


    const closeOpenCreate = ()=>{
        setOpencreate(false);
    }

    const closeDeleteModal = ()=>{
        setWebsitetodelete(null);
        setOpendelete(false)
    }

    const openDeleteModal = (website)=>{
        setWebsitetodelete(website);
        setOpendelete(true);
    }

    

    const websites = useSelector(selectWebsiteList); 

    const websiteworkspacepermission = useSelector(selectWebsiteworkspacepermission);


    const navigateback = ()=>{
        navigate("/"+workspace+"/interfaces/");
    }

    useEffect(()=>{
        dispatch(getWebsiteList(workspace));
    },[])

    const websitelistloader = useSelector(selectWebsiteListLoader);

    return (

        <>
            <div className="website-list">
                {websitelistloader>0&&
                    <>
                        <LinearProgress></LinearProgress>
                    </>
                }
                <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?[   
                            {
                            "name": "dashboard",
                            "url": "/"
                            },
                            {
                            "name": "interfaces",
                            "url": "/"+workspace+"/interfaces"
                            }
                        ]:
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "workspace",
                                "url": "/workspaces/"+workspace
                            },
                            {
                                "name": "interface",
                                "url": "/"+workspace+"/interfaces"
                            }
                        ]
                    }
                >
                </BreadCrumbs>
                <div className="website-headers">
                    <div>
                        <h3>Websites</h3>
                    </div>
                    <div>
                        {websiteworkspacepermission=="write"?
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <Button  onClick={()=>{setOpencreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
                                <Button onClick={()=>{navigateback()}} style={{color: "#3A07CD"}}>Close</Button>
                            </div>:
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Button onClick={()=>{navigateback()}} style={{color: "#3A07CD"}}>Close</Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="website-list-container">
                    {websites?.map((website)=>{
                        return(
                            <div className="website-item">
                                <Link to={"/"+workspace+"/website/details/"+website._id} style={{textDecoration:"none"}}>
                                    <div>{website.name}</div>
                                </Link>
                                <div>
                                    {website.access=="write"&&
                                        <IconButton onClick={()=>{openDeleteModal(website)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <CreateWebsiteModal
                open={opencreate}
                handleClose={closeOpenCreate}
            >
            </CreateWebsiteModal>
            <DeleteWebsiteModal
                open={opendelete}
                handleClose={closeDeleteModal}
                website={websitetodelete}
            >
            </DeleteWebsiteModal>
        </>
    )
}

export default Websitelist;