import { baseURL } from "../../env";
import {get, postjson, putjson} from "../signin/axios";

export const templatePipleineApi = async (payload)=>{
    let res = await get(baseURL+"pipelinebytemplate/"+payload.templateid+"/"+payload.source+"/"+payload.active);
    return res;
}

export const templatePipelineProdApi = async(payload)=>{
    let res = await get(baseURL+"pipelinebytemplateprod/"+payload);
    return res;
}

export const interactionpipelinesApi = async(payload)=>{
    let res = await get(baseURL+"interactionpipelines/"+payload);
    return res;
}

export const getTemplateVariablesApi = async(payload)=>{
    let res = await get(baseURL+"variables/templates/"+payload.templateid+"/"+payload.source+"/"+payload.active);
    return res;
}

export const getTemplateVariablesProdApi = async(payload)=>{
    let res = await get(baseURL+"variables/prodtemplates/"+payload);
    return res;
}

export const updateTemplateVariablesApi = async(payload)=>{
    let res = await putjson(baseURL+"variables/templates/"+payload.templateid, 
                                {
                                    "schema": payload.variable.schema, 
                                    "schemamapping": payload.variable.schemamapping,
                                    "depsarray": payload.variable.depsarray
                                });
    return res;
}

export const getTemplateCssClassesApi = async(payload)=>{
    let res = await get(baseURL+"cssclasses/templates/"+payload.templateid+"/"+payload.source+"/"+payload.active);
    return res;
}

export const getTemplateCssClassesProdApi = async(payload)=>{
    let res = await get(baseURL+"cssclasses/prodtemplates/"+payload);
    return res;
}

export const updateTemplateCssClassesApi = async(payload)=>{
    let res = await putjson(baseURL+"cssclasses/templates/"+payload.templateid,
                                {
                                    "classes": payload.classes
                                }
                            );
    return res;

}

export const getRouteParamsApi = async(payload)=>{
    let res = await get(baseURL+"routeparams/templates/"+payload);
    return res;
}

export const updateRouteParamsApi = async(payload)=>{
    let res = await putjson(baseURL+"routeparams/templates/"+payload.templateid,
                                {
                                    "schema": payload.routeparams.schema,
                                    "schemamapping": payload.routeparams.schemamapping
                                }
                            )
    return res;
}

export const updateTemplateApi = async(payload)=>{
    let res = await putjson(baseURL+"templates/app/"+payload.templateid,payload.elements);
    return res;
}

export const updateTemplateChildEnabledApi = async(payload)=>{
    let res = await putjson(baseURL+"templates/childenabled/"+payload.templateid, {val: payload.val});
    return res;
}

export const getTemplateChildEnabledApi = async(payload)=>{
    let res = await get(baseURL+"templates/childenabled/"+payload);
    return res;
}

export const getElementsApi = async(payload)=>{
    let res = await get(baseURL+"templates/"+payload.templateid+"/"+payload.source+"/"+payload.active);
    return res;
}

export const getElementsProdApi = async(payload)=>{
    let res = await get(baseURL+"templatesprod/"+payload);
    return res;
}

export const updateTemplateSettingsApi = async(payload)=>{
    let res = await putjson(baseURL+ "templates/app/settings/"+payload.id, payload.settings);
    return res;
}

export const getTemplateSettingsApi = async(payload)=>{
    let res = await get(baseURL+ "templates/app/settings/"+payload);
    return res;
}

export const getAppTemplatesApi = async(payload)=>{
    let res = await get(baseURL+"variables/gettemplates/html/"+payload);
    return res;
}

export const deployTemplateApi = async(payload)=>{
    let res = get(baseURL+"templates/deploy/"+payload);
    return res;
}

export const deployWebsiteApi = async(payload)=>{
    let res = await get(baseURL+"/websites/deploy/"+payload);
    return res;
}

export const getExportedTemplatePipelinesApi = async(payload)=>{
    let res = await get(baseURL+"");
    return res;
}

export const logRoute = async(payload)=>{
    let res = await get(baseURL+"");
    return res;
}