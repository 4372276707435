import { useEffect, useState } from "react";
import "./Dashboard.css";
import {
        getAppCount, 
        getFolderCount, 
        getInterfaceCount, 
        getWorkspaceCount, 
        getWorkspaceDetails, 
        selectAppCount, 
        selectDashboardLoading, 
        selectFolderCount, 
        selectInterfaceCount, 
        selectWorkspaceCount, 
        selectWorkspaceDetails
        } from "./Services/dashboard/dashboard.slice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { selectClientId } from "./Services/signin/signin.slice";
import EnvironVariables from "./apps/environvariables/Environvariables";
import { getManifestVersion, selectManifestLoader, selectManifestVersion } from "./Services/manifest/manifest.slice";
import {CircularProgress, LinearProgress} from "@mui/material";
import { Button } from "@mui/material";

const Dashboard = ()=>{

    const {workspace} = useParams()
    
    const dispatch = useDispatch();

    const workspacedetails = useSelector(selectWorkspaceDetails)

    const dashboardloading = useSelector(selectDashboardLoading);

    useEffect(()=>{
        dispatch(getWorkspaceDetails(workspace));    
    },[])

    useEffect(()=>{
        dispatch(getManifestVersion({
            source: "workspace",
            sourceid: workspace
       }))
    },[])

    const publishedversion = useSelector(selectManifestVersion);

    const navigate = useNavigate();

    const openPublish = ()=>{
        navigate("/"+workspace+"/manifest/workspace/"+workspace)
    }

    const manifestloader = useSelector(selectManifestLoader);

    return (
        <>  
            {dashboardloading&&
                <div>
                    <LinearProgress></LinearProgress>
                </div>
            }
            {workspacedetails!=null&&
                <div>
                    <div className="dashboard-main-container">
                        <div className="dashboard-tiles">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h5>Workspaces</h5>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h2>
                                    <Link to="/workspaces">{workspacedetails.workspaces}</Link>
                                </h2>
                            </div>
                        </div>
                        <div className="dashboard-tiles">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h5>Apps</h5>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h2>
                                    <Link to={"/"+workspace+"/apps"}>{workspacedetails.apps}</Link>
                                </h2>
                            </div>
                        </div>
                        <div className="dashboard-tiles">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h5>Interfaces</h5>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h2>
                                    <Link to={"/"+workspace+"/interfaces"}>{workspacedetails.interfaces}</Link>
                                </h2>
                            </div>
                        </div>
                        <div className="dashboard-tiles">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h5>Folders</h5>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h2>
                                    <Link to={"/"+workspace+"/filesystem"}>{workspacedetails.folders}</Link>
                                </h2>
                            </div>
                        </div>
                        <div className="dashboard-tiles">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h5>Ml-Models</h5>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h2>
                                    <Link to={"/"+workspace+"/interfaces"}>{0}</Link>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h3>Environment Variable</h3>
                            <EnvironVariables
                                resource={"workspace"}
                                resourceId={workspace}
                            >
                            </EnvironVariables>
                        </div>
                        <div>
                        <div>
                            {manifestloader>0?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <CircularProgress></CircularProgress>
                                </div>:
                                <>
                                    <div className="publish-block">
                                        <div>
                                            {publishedversion.version>1?
                                                <div>
                                                    Status: published , version: <Link to={"/"+workspace+"/savedmanifest/workspace/"+workspace+"/"+publishedversion.id}>{publishedversion.version-1}</Link>
                                                </div>:
                                                <div>
                                                    Status: not published
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <Button onClick={()=>{openPublish()}} style={{"color": "#3A07CD"}}>Publish</Button>
                                        </div>
                                    </div>    
                                </>
                            }
                            
                        </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Dashboard;