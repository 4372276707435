import { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import Readme from "./Readme";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDocument, getDocumentationPermission, resetactivedocumentpermission, resetdocument, selectActiveDocument, selectActiveDocumentPermission, updateDocument } from "../../Services/documentations/documentations.slice";


const ReadmeContainer = ()=>{

    const {
        source,
        sourceid,
        workspace,
        appid,
        id,
        msource,
        msourceid,
    } = useParams();

    const [doc, setdoc] = useState("");

    const dispatch = useDispatch();

    const activedocument = useSelector(selectActiveDocument);

    const activedocumentpermission = useSelector(selectActiveDocumentPermission);

    const setReadme = (val)=>{
        dispatch(updateDocument({
            "source": source,
            "sourceid": sourceid,
            "document": doc
        }))
        setdoc(val)
    }

    useEffect(()=>{
        dispatch(resetdocument());
         dispatch(getDocument({
            "source": source,
            "sourceid": sourceid
         }))
         dispatch(resetactivedocumentpermission())
         if(msource==undefined){
            dispatch(getDocumentationPermission({
                "source": source,
                "sourceid": sourceid
             }))
         }
        },[])
    
    useEffect(()=>{
        if(activedocument!=""){
            setdoc(activedocument);
        }
    },[activedocument])

    const getBreadCrumbsArray = ()=>{
        if(msource!=undefined&&msourceid!=undefined){
            if(msource=="app"){
                let breadcrumbs = workspace=="main"?[
                    {
                        "name": "dashboard",
                        "url": "/"
                    },
                    {
                        "name": "apps",
                        "url": "/"+workspace+"/apps"
                    },
                    {
                        "name": "appdetail",
                        "url": "/"+workspace+"/schemas/"+appid
                    },
                    {
                        "name": "manifest",
                        "url": "/"+workspace+"/savedmanifest/"+msource+"/"+msourceid+"/"+id
                    }
                ]:
                [
                    {
                        "name": "dashboard",
                        "url": "/"
                    },
                    {
                        "name": "workspace",
                        "url": "/workspaces/"+workspace+"/"
                    },
                    {
                        "name": "apps",
                        "url": "/"+workspace+"/apps"
                    },
                    {
                        "name": "appdetail",
                        "url": "/"+workspace+"/schemas/"+appid
                    },
                    {
                        "name": "manifest",
                        "url": "/"+workspace+"/savedmanifest/"+msource+"/"+msourceid+"/"+id
                    }
                ]
                return breadcrumbs;
            }
        }else{
            if(source=="schema"){
                let breadcrumbs = workspace=="main"?[
                                                        {
                                                            "name": "dashboard",
                                                            "url": "/"
                                                        },
                                                        {
                                                            "name": "apps",
                                                            "url": "/"+workspace+"/apps"
                                                        },
                                                        {
                                                            "name": "appdetail",
                                                            "url": "/"+workspace+"/schemas/"+appid
                                                        },
                                                        {
                                                            "name": "schemadetail",
                                                            "url": "/"+workspace+"/schemas/"+appid+"/schemas/"+id
                                                        }
                                                    ]:
                                                    [
                                                        {
                                                            "name": "dashboard",
                                                            "url": "/"
                                                        },
                                                        {
                                                            "name": "workspace",
                                                            "url": "/workspaces/"+workspace+"/"
                                                        },
                                                        {
                                                            "name": "apps",
                                                            "url": "/"+workspace+"/apps"
                                                        },
                                                        {
                                                            "name": "appdetail",
                                                            "url": "/"+workspace+"/schemas/"+appid
                                                        },
                                                        {
                                                            "name":"schemadetail",
                                                            "url": "/"+workspace+"/schemas/"+appid+"/details/"+id
                                                        }
                                                    ]
                return breadcrumbs;    
            }else if(source=="app"){
                let breadcrumbs = workspace=="main"?[
                                                        {
                                                            "name": "dashboard",
                                                            "url": "/"
                                                        },
                                                        {
                                                            "name": "apps",
                                                            "url": "/"+workspace+"/apps"
                                                        },
                                                        {
                                                            "name": "appdetails",
                                                            "url": "/"+workspace+"/schemas/"+appid
                                                        }
                                                    ]:
                                                    [
                                                        {
                                                            "name": "dashboard",
                                                            "url": "/"
                                                        },
                                                        {
                                                            "name": "workspace",
                                                            "url": "/workspaces/"+workspace+"/"
                                                        },
                                                        {
                                                            "name": "apps",
                                                            "url": "/"+workspace+"/apps"
                                                        },
                                                        {
                                                            "name": "appdetails",
                                                            "url": "/"+workspace+"/schemas/"+appid
                                                        }
                                                    ]
                return breadcrumbs;
            }
        }
        
    }


    return (
        <div>
            <BreadCrumbs
                breadcrumbs={getBreadCrumbsArray()}
            >
            </BreadCrumbs>
            <div>
                {msource!=undefined?
                    <Readme
                        readme={doc}
                        setreadme={setReadme}
                        write={"write"}
                        source={source}
                        sourceid={sourceid}
                        mode={"fullscreen"}
                        isManifest={true}
                        msource={msource}
                        msourceid={msourceid}
                        manifestid={id}
                    >
                    </Readme>:
                    <Readme
                        readme={doc}
                        setreadme={setReadme}
                        source={source}
                        sourceid={sourceid}
                        mode={"fullscreen"}
                        write={activedocumentpermission}
                    >

                    </Readme>
                }
                
            </div>
        </div>
    )

}


export default ReadmeContainer;