import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { login, selectAuth, selectClientId, selectDefaultWorkspace, selectSignupcreated, signUp, signin ,selectGoogleSinginRequest, createGoogleSigninRequest } from "./Services/signin/signin.slice";
import { Button, IconButton, TextField } from "@mui/material";
import {signinSuccessUrl} from "./env";
import Toiler from "./Images/logo1.png";
import qs from "qs";
import "./Signin.css";
import CircularLoader from "./apps/utils/CircularLoader";

const Login = ()=>{

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    const [authRequested, setauthRequested] = useState(false);

    const [emailid, setEmailId] = useState("");

    const [password, setPassword] = useState("");

    const [passwordvisibility, setpasswordvisibility] = useState("password");

    const [createsignin, setCreatesignin] = useState(false);

    const formref = useRef(null);

    const signupAction = ()=>{
        dispatch(login({
            "email": emailid,
            "password": password
        }))
        setauthRequested(true);        
    }

    const navigatetosignup = ()=>{
        navigate("/signin");
    }


    const creategooglesiginrequestAction = ()=>{
        setCreatesignin(true);
        dispatch(createGoogleSigninRequest());
    }

    const googlesigninrequest = useSelector(selectGoogleSinginRequest);

    useEffect(()=>{
        if(createsignin&&googlesigninrequest!=""){
            let signinform = formref.current;
            let stateinput = document.createElement("input");
            stateinput.name = "state";
            stateinput.type = "hidden";
            stateinput.value = googlesigninrequest;
            signinform.append(stateinput);
            signinform.submit();
        }
    },[googlesigninrequest])

    const location = useLocation();

    const authtoken = localStorage.getItem("auth");
    const defaultworkspacetoken = localStorage.getItem("defaultworkspace");

    const auth = useSelector(selectAuth);

    useEffect(()=>{
        if(defaultworkspacetoken!=""&&defaultworkspacetoken!=undefined&&defaultworkspacetoken!=null&&authtoken&&auth){
            if(location.state!=null&&location.state?.from!=undefined){
                navigate(location.state.from, {state:{from: "login"}});
            }else{
                navigate("/", {state:{from : "/login"}});
            }
        }
    },[location, defaultworkspacetoken, authtoken])

    return (
        <>
            <div style={{display: "flex", justifyContent:"center", marginTop: 200 }}>
                <div style={{width: 500}}>
                    <div style={{display: "flex", justifyContent:"center", marginBottom: 20}}>
                        <img src={Toiler}></img>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div>
                            <div className="toiler-signup-inputs">
                                <TextField label="Email" value={emailid} onChange={(e)=>{setEmailId(e.target.value)}}></TextField>
                            </div>
                            <div className="toiler-signup-inputs">
                                <TextField label="Password" type={passwordvisibility} value={password} onChange={(e)=>{setPassword(e.target.value)}}></TextField>
                                {passwordvisibility=="text"&&
                                    <IconButton onClick={()=>{setpasswordvisibility("password")}} style={{position: "absolute", right: "0px", margin: "10px"}}>
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </IconButton>
                                }
                                {passwordvisibility=="password"&&
                                    <IconButton onClick={()=>{setpasswordvisibility("text")}} style={{position: "absolute", right: "0px", margin: "10px"}}>
                                        <span className="material-symbols-outlined">
                                            visibility_off
                                        </span>
                                    </IconButton>
                                }
                            </div>
                            <div className="toiler-signup-button">
                                <div>
                                    <Button 
                                        style={{color: "#3A07CD"}}
                                        onClick={()=>{signupAction()}}
                                    >Login</Button>
                                    <span style={{fontSize: "12px"}}>
                                        <a href="/forgotpass">Forgot password</a>
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", paddingBottom: 10, paddingTop: 10}}>
                        <span>
                            Or
                        </span>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button className="gsi-material-button" onClick={()=>{creategooglesiginrequestAction()}}>
                            <div className="gsi-material-button-state"></div>
                            <div className="gsi-material-button-content-wrapper">
                                <div class="gsi-material-button-icon">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{display: "block"}}>
                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                </svg>
                                </div>
                                <span className="gsi-material-button-contents">Sign in with Google</span>
                                <span style={{"display": "none"}}>Sign in with Google</span>
                            </div>
                        </button>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <form ref={formref} style={{display: "flex", "justifyContent": "center"}} method="GET" action="https://accounts.google.com/o/oauth2/v2/auth">
                            <input type="hidden" name="client_id" value="201119847301-0r4ir00r43td0d9nte1hi4fm1tbbqgdh.apps.googleusercontent.com"></input>
                            <input type="hidden" name="redirect_uri" value={signinSuccessUrl}></input>
                            <input type="hidden" name="response_type" value="code"></input>
                            <input type="hidden" name="scope" value="email openid"></input>
                            <input type="hidden" name="include_granted_scopes" value="true"></input>
                            <input type="hidden" name="access_type" value="online"></input>
                        </form>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div>
                            <Button
                                style={{color: "#3A07CD"}}
                                onClick={()=>{navigatetosignup()}}
                            >
                                SignUp
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}



export default Login;