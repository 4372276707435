import { Sketch , Chrome} from "@uiw/react-color";
import { useCallback, useEffect, useRef, useState } from "react";
import "./ColorPicker.css";

const ColorPicker = ({color, setColor})=>{
    
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    
    const [elementRect, setElementRect] = useState();

    const swatchref = useRef(null);

    const popsecondred = useRef(null);

    // const popoverRef = useRef(null);

    const handleClick = ()=>{
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = ()=>{
        setDisplayColorPicker(false)
    }

    const [popoverStyle, setPopoverStyle] = useState({
        left: "auto"
    });


    const setPopoverStyleAction = (elementrect)=>{
        let width = document.body.clientWidth;
        if(width-elementrect.right<0){
            let style = {
                "right": "0px"
            }
            setPopoverStyle(style)
        }
    }

    const popoverRef = useCallback((node)=>{
        setElementRect(node?.getBoundingClientRect());
        if(node){
            setPopoverStyleAction(node?.getBoundingClientRect());
            popsecondred.current = node;
        }
    },[])




    document.addEventListener("click",(event)=>{
        if(displayColorPicker){
            if(swatchref?.current!=undefined&&popsecondred?.current!=undefined){
                if(!swatchref.current.contains(event.target)&&!popsecondred.current.contains(event.target)){
                    setDisplayColorPicker(false);
                }
            }
        }
    })

    return (
        <div style={{position: "relative"}}>
            <div className="swatch" onClick={handleClick} ref={swatchref}>
                <div style={{
                     width: '36px',
                     height: '14px',
                     borderRadius: '2px',
                     background: color,
                     border: "1px solid rgba(0,0,0,.1)"
                }}>
                </div>
            </div>
            { displayColorPicker ? 
                <div  className="popover" ref={popoverRef} style={popoverStyle}>
                    {/* <div className="cover" onClick={ handleClose }/> */}
                    <div>
                        <span className="material-symbols-outlined" style={{fontSize: "14px", cursor: "pointer"}} onClick={ handleClose }>
                            close
                        </span>
                    </div>
                    <Sketch
                        color={color}
                        onChange={(c) => {
                            
                            setColor(c.hex);
                        }}
                    >
                    </Sketch>
                </div> : null 
            }
        </div>    
    )
}

export default ColorPicker