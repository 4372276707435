import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {executeRequestApi} from "./httpstage.service";
import { loading, loadingdone } from "../loader/loader.slice";

export const sendRequest = createAsyncThunk(
    "httpclientslice/sendrequest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await executeRequestApi(payload);
            return {
                status: res.status,
                data: res.data
            };
        }catch(error){
            return rejectWithValue({
                status: error.status,
                data: error.response.data
            });
        }
    }
)


const httpclientslice = createSlice({
    name: "httpclientslice",
    initialState:{
        response:null,
        error: null,
        status: ""
      },
    reducers:{
        resetresponse: (state, action)=>{
            state.response = null;
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(sendRequest.fulfilled, (state, action)=>{
            state.status = action.payload.status;
            state.response = action.payload.data;
            state.error = null;
        })
        .addCase(sendRequest.rejected, (state, action)=>{
            if(action?.payload?.status==undefined){
                state.status = 404;
                state.error = "Server not found";
                state.response = null;
            }else{
                state.status = action.payload.status;
                state.error = action.payload.data;
                state.response = null;
            }
            
        })
    }
})

export const selectResponse = (state) => state.httpclientslice.response;

export const selectError = (state) => state.httpclientslice.error;

export const selectStatus = (state) => state.httpclientslice.status;

export const {resetresponse} = httpclientslice.actions;

export default httpclientslice.reducer;