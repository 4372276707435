import React, { useState, useEffect, useRef, useCallback } from 'react';
import PlatformManifestdetails from '../manifest/PlatformManifestdetails';
import SearchAutoComplete from '../SearchAutoComplete';
import { baseURL, signinURL } from '../../env';
import { get } from '../../Services/signin/axios';
import InstallManifestModal from '../manifest/InstallManifestModal';
import MarketManifestModal from '../manifest/MarketManifestModal';
import * as _ from "lodash";
import { useParams } from 'react-router-dom';
// Constants

// Main App component
const MarketplaceGrid = ({
                            source,
                            sourceid,
                            scrollref
                        }) => {

  const [apps, setApps] = useState([]); // Initial set of apps
  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const [filteredApps, setFilteredApps] = useState([]); // Filtered apps based on search
  const [page, setPage] = useState(1); // Current page for pagination
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // State to check if more data is available

  const {workspace} = useParams();

  // Load more apps when scrolling near the end
  const loadMoreApps = useCallback(() => {
    setPage((prevPage) => prevPage + 1); // Increase page number
  }, []);


  // Ref for the IntersectionObserver
  const observerRef = useCallback((node)=>{
    const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMoreApps();
          }
        },
        {
            root: scrollref.current, 
            threshold: 1
        }
      );
    if (node) {
        observer.observe(node);
    }
    return () => observer.disconnect();
  },[loadMoreApps, hasMore, scrollref]);

  // Set up IntersectionObserver to load more apps
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreApps();
        }
      },
      { threshold: 1 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => observer.disconnect();
  }, [loadMoreApps, hasMore]);

  // Fetch apps when page changes or search query is updated
  useEffect(() => {
    
    async function fetchApps() {

        if(loading!=true&&hasMore&&source!=undefined&&source!=""){
            setLoading(true);
            try {
                const data = await get(`${baseURL}marketplace/manifest/private/search/${source}/${workspace}?q=${encodeURIComponent(searchQuery)}&page=${page}&limit=20`)
                // Append new apps and update state
                setApps((prevApps) => {
                    let newapps = [];
                    for(let i=0; i< data.results.length; i++){
                        let appindex = _.findIndex(prevApps, (app)=>{return data.results[i]._id==app._id});
                        if(appindex==-1){
                            newapps.push(data.results[i]);
                        }
                    }
                    return [...prevApps, ...newapps]
                });
                setHasMore(data.results.length > 0); // Check if more data is available
            } catch (error) {
                console.error('Error fetching apps:', error);
            } finally {
                setLoading(false);
            }
        }
    }
    fetchApps()
  }, [searchQuery, page, hasMore, source]);

  // Filter apps based on search query
  useEffect(() => {
    setFilteredApps(
      apps.filter((app) =>
        app.manifestname.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, apps]);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setApps([]); // Reset apps when search query changes
    setPage(1);  // Reset page when search query changes
    setHasMore(true); // Reset hasMore state for new search
  };


  const handlePreview = (appName) => {
    console.log(`Preview button clicked for ${appName}`);
  };
  // code for installing
  const [openinstallmodal, setOpeninstallmodal] = useState(false);

  const [installManifestId, setInstallManifestid] = useState("");

  const [installManifestScope, setInstallManifestScope] = useState("");

  const handleInstall = (manifestid, manifestscope) => {
    setOpeninstallmodal(true)
    setInstallManifestScope(manifestscope);
    setInstallManifestid(manifestid);
  };

  const handleClose = ()=>{
    setInstallManifestScope("");
    setInstallManifestid("");
    setOpeninstallmodal(false);
  }

  // code for previewing

  const [openpreview, setOpenpreview] = useState(false);

  const [previewManifestId, setPreviewManifestId] = useState("");

  const [previewManifestScope, setPreviewManifestScope] = useState("");

  const openPreview = (manifestid, manifestscope)=>{
     setOpenpreview(true);
     setPreviewManifestId(manifestid);
     setPreviewManifestScope(manifestscope);
  }

  const closePreview = ()=>{
     setOpenpreview(false);
     setPreviewManifestId("");
     setPreviewManifestScope("")
  }

  return (
    <>

        <div style={{ padding: '20px' }}>
            {/* Search Bar with Autocomplete */}
            <SearchAutoComplete
                suggestions={apps.map((app) => app.manifestname)}
                onChange={handleSearchChange}
            />

            {/* Responsive Grid */}
            <div
                style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                        gap: '20px',
                        padding: '20px',
                }}
            >
                {filteredApps.map((app) => (
                <PlatformManifestdetails
                    key={app._id}
                    manifestid={app._id}
                    isInstalled={app.installed}
                    manifestscope={app.manifestscope}
                    appName={app.manifestname}
                    iconSrc={app.favicon || null}
                    onInstall={handleInstall}
                    onPreview={openPreview}
                />
                ))}
            </div>

            {/* Invisible element to trigger loading more apps when scrolled into view */}
            <div ref={observerRef} style={{ height: '20px' }} />
        </div>
        <InstallManifestModal
            source={source}
            sourceid={sourceid}
            open={openinstallmodal}
            handleClose={handleClose}
            manifestscope={installManifestScope}
            manifestid={installManifestId}
        >
        </InstallManifestModal>
        <MarketManifestModal
            open={openpreview}
            handleClose={closePreview}
            preview={false}
            manifestid={previewManifestId}
            manifestscope={previewManifestScope}
        >
        </MarketManifestModal>
    </>
    
  );
};

export default MarketplaceGrid;
