import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIconService } from "./toilericon.service";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const startThrottle = createAsyncThunk(
    "iconslice/startthrottle",
    async(payload,{dispatch})=>{
        await timeout(10);
        return true;
    }
)

export const getIcons = createAsyncThunk(
    "iconslice/geticons",
    async(payload,{dispatch, getState})=>{
        let state = getState();
        if(!state.iconslice.throttle){
            let res = await getIconService(payload);
            dispatch(startThrottle());
            return res;
        }
    }
)




const iconslice = createSlice({
    name: "iconslice",
    initialState:{
        "icons":[],
        "throttle": false
    },
    reducers: {

    },
    extraReducers: (builder)=>{
        builder.addCase(getIcons.fulfilled, (state, action)=>{
            state.throttle = true;
            state.icons = action.payload;
        })
        builder.addCase(startThrottle.fulfilled, (state, action)=>{
            state.throttle = false;
        })
    }
})

export const selecticons = state => state.iconslice.icons;

export default iconslice.reducer;