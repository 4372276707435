import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
         createManifestApi, 
         getEnvoptwebpubApi, 
         getInstallConfigApi, 
         getInstallthirdpartyTemplateApi, 
         getManifestApi, 
         getManifestAppsApi, 
         getManifestDocumentationApi, 
         getManifestPipelineApi, 
         getManifestPublishVarApi, 
         getManifestVersionApi, 
         getSavedManifestApi, 
         getSavedManifestDepsApi, 
         getSavedManifestSourceApi, 
         installManifestApi, 
         installPManifestApi, 
         installThirdpartyManifestApi, 
         manifestExistsApi, 
         publishSavedManifestApi, 
         savePublishedManifestApi, 
         updateManifestApi, 
         updateManifestBannersApi, 
         updateSavedManifestFaviconApi
        } from "./manifest.service";
import { showError } from "../error.slice";
import { downloadFavicon } from "../websites/website.service";

export const getManifest = createAsyncThunk(
    "manifest/getmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest"));
            throw error;
        }
    }
)

export const getEnvoptwebpub = createAsyncThunk(
    "manifest/getenvoptwebpub",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getEnvoptwebpubApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting website options"));
            throw error;
        }
    }
)

export const getManifestPipeline = createAsyncThunk(
    "manifest/getmanifestpipeline",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestPipelineApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifestpipeline"));
            throw error;
        }
    }
)

export const getManifestVersion = createAsyncThunk(
    "manifest/getmanifestversion",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setManifestloader());    
            let res = await getManifestVersionApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest version !!!"));
            throw error;
        }
    }
)

export const installPManifest = createAsyncThunk(
    "manifest/installpmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await installPManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error installing sample manifest"));
            throw error;
        }
    }
)

export const createManifest = createAsyncThunk(
    "manifest/createmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error creating manifest"));
            throw error;
        }
    }
)

export const manifestExist = createAsyncThunk(
    "manifest/manifestexist",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await manifestExistsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest status"));
            throw error;
        }
    }
)

export const getManifestPublishVar = createAsyncThunk(
    "manifest/getmanifestpublishvar",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestPublishVarApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest publish vars"));
            throw error;
        }
    }
)

export const getInstallConfig = createAsyncThunk(
    "manifest/getinstallconfig",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getInstallConfigApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting install config"))
        }
    }
)

export const updateManifest = createAsyncThunk(
    "manifest/updatemanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating the manifest"));
            throw error;
        }
    }
)

export const getManifestApps = createAsyncThunk(
    "manifest/getmanifestapps",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestAppsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting the manifest"));
            throw error;
        }
    }
)

export const savePublihsedManifest = createAsyncThunk(
    "manifest/savepublishedmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await savePublishedManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error saving manifest"));
            throw error;
        }
    }
)

export const getPublishedManifestSource = createAsyncThunk(
    "manifest/getpublishedmanifestsource",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getSavedManifestSourceApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest sources"));
            throw error;
        }
    }
)

// apis for manifest details
export const updateManifestFavicon = createAsyncThunk(
    "manifest/updatemanifestfavicon",
    async(payload, {dispatch, rejectWithValue})=>{  
        try{
            let url = await downloadFavicon({url: payload.url, name: payload.faviconname});
            let requestPayload = {
                                    url: url,
                                    id: payload.id,
                                    manifestscope: payload.manifestscope
                                 }
            let res = await updateSavedManifestFaviconApi(requestPayload);
            return res;
        }catch(error){
            dispatch(showError("Error updating the manifest"));
            throw error;
        }
    }
)

export const updateManifestBanners = createAsyncThunk(
    "manifest/updatemanifestbanners",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateManifestBannersApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating manifest"));
            throw error;
        }
    }
)

export const getSavedManifest =createAsyncThunk(
    "manifest/getsavedmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getSavedManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting the manifest"));
            throw error;
        }
    }
)

export const getManifestSavedDocumentation = createAsyncThunk(
    "manifest/getmanifestsaveddocumentation",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestDocumentationApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest documentation"));
            throw error;
        }
    }
)

export const getSavedManifestDeps = createAsyncThunk(
    "manifest/getsavemanifestdeps",
    async(payload, {dispatch,rejectWithValue})=>{
        try{
            let res = await getSavedManifestDepsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest deps"));
            throw error;
        }
    }
)

export const publishSavedManifest = createAsyncThunk(
    "manifest/publishsavedmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            
            let res = await publishSavedManifestApi(payload);
            dispatch(getSavedManifest(payload.manifestid));
            return res;
        }catch(error){
            dispatch(showError("Error publishing manifest"));
            throw error;
        }

    }
)

export const installManifest = createAsyncThunk(
    "manifest/installmanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await installManifestApi(payload);
            dispatch(showError("Manifest Installed"));
            return res;
        }catch(error){
            dispatch(showError("Error installing manifest, please check if host and paths are not voilating each other."))
        }
    }
)

export const installThirdpartyManifest = createAsyncThunk(
    "manifest/installthirdpartymanifest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await installThirdpartyManifestApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting installationid"))
        }
    }
)


export const getInstallthirdpartyTemplate = createAsyncThunk(
    "manifest/getinstallthirdpartytemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getInstallthirdpartyTemplateApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error geting thirdparty install template"));
        }
    }
)

const manifestSlice = createSlice({
    name: "manifest",
    initialState: {
        manifest: null,
        websiteopt: [],
        manifestform: [],
        manifestthirdpartyurl: "",
        installbuttontemplate: "",
        manifestinstalltype: "",
        manifestpipeline: null,
        version: 0,
        pmanifestinstalled: false,
        manifestcreated: false,
        manifestexist: null,
        manifestupdated: false,
        applist: [],
        manifestsaved: false,
        savedmanifest: null,
        manifestdocumentation: null,
        manifestsource: null,
        manifestfaviconsaved: false,
        savedmanifestdeps: [],
        savedmanifestpublished: false,
        installconfig: null,
        manifestinstalled: false,
        installredirecturl: "",
        thirdpartyinstallconfig: null,
        installbuttontemplate: "",
        manifestloader: 0
    },
    reducers: {
        setManifestloader: (state, action)=>{
            state.manifestloader = state.manifestloader+1;
        },
        resetmanifest: (state, action)=>{
            state.manifest = null;
        },
        resetwebsiteopt: (state, action)=>{
            state.websiteopt = []
        },
        resetPmanifestinstalled: (state, action)=>{
            state.pmanifestinstalled = false
        },
        resetmanifestcreated: (state, action)=>{
            state.manifestcreated = false;
        },
        resetmanifestexist: (state, action)=>{
            state.manifestexist = null;  
        },
        resetmanifestupdated: (state, action)=>{
            state.manifestupdated = false;
        },
        resetmanifestform: (state, action)=>{
            state.manifestform = [];
        },
        resetmanifestthirdurl: (state, action)=>{
            state.manifestthirdpartyurl = "";
        },
        resetinstallbuttontemplate: (state, action)=>{
            state.installbuttontemplate = "";
        },
        resetmanifestapps: (state, action)=>{
            state.applist = [];
        },
        resetmanifestdocumentation: (state, action)=>{
            state.manifestdocumentation = []
        },
        resetmanifestsaved: (state, action)=>{
            state.manifestsaved = false;
        },
        resetmanifestsource: (state, action)=>{
            state.manifestsource = null;
        },
        resetmanifestfaviconsaved: (state, action)=>{
            state.manifestfaviconsaved = false;
        },
        resetmanifestsaveddeps: (state, action)=>{
            state.savedmanifestdeps = [];
        },
        resetsavedmanifestpublished: (state, action)=>{
            state.savedmanifestpublished = false;
        },
        resetinstallconfig: (state, action)=>{
            state.installconfig = action.payload;
        },
        resetmanifestinstalled: (state, action)=>{
            state.manifestinstalled = false
        },
        resetinstallredirecturl: (state, action)=>{
            state.installredirecturl = ""
        },
        resetthirdpartyinstallconfig: (state, action)=>{
            state.thirdpartyinstallconfig = null;
        },
        resetinstallbuttontemplate: (state, action)=>{
            state.installbuttontemplate = ""
        }
    },
    extraReducers : (builder)=>{
        builder.addCase(getInstallConfig.fulfilled,(state, action)=>{
            state.installconfig = action.payload;
        })
        builder.addCase(getManifest.fulfilled, (state, action)=>{
            state.manifest = action.payload;
        })
        builder.addCase(getEnvoptwebpub.fulfilled, (state, action)=>{
            state.websiteopt = action.payload;
        })
        builder.addCase(getManifestPipeline.fulfilled, (state, action)=>{
            if(action.payload.status){
                state.manifestpipeline = action.payload.pipeline;
            }
        })
        builder.addCase(getManifestVersion.fulfilled, (state, action)=>{
            state.version = action.payload;
            state.manifestloader = state.manifestloader-1;
        })
        builder.addCase(installPManifest.fulfilled, (state, action)=>{
            state.manifestinstalled = true;
        })
        builder.addCase(createManifest.fulfilled, (state, action)=>{
            state.manifestcreated = true;
        })
        builder.addCase(manifestExist.fulfilled, (state, action)=>{
            if(action.payload.status){
                state.manifestexist = action.payload.manifest
            }
        })
        builder.addCase(updateManifest.fulfilled, (state, action)=>{
            state.manifestupdated = true;
        })
        builder.addCase(getManifestPublishVar.fulfilled, (state, action)=>{
            let payload = action.payload;
            if(payload.form!=undefined){
                state.manifestform = payload.form;
            }
            if(payload.thirdpartyurl!=undefined){
                state.manifestthirdpartyurl = payload.thirdpartyurl;
            }
            if(payload.installbuttontemplate!=undefined){
                state.installbuttontemplate = payload.installbuttontemplate;
            }
            if(payload.installtype!=""&&payload.installtype!=undefined){
                state.manifestinstalltype = payload.installtype; 
            }

        })
        builder.addCase(getManifestApps.fulfilled, (state, action)=>{
            let payload = action.payload;
            state.applist = payload;
        })
        builder.addCase(getSavedManifest.fulfilled, (state, action)=>{
            // console.log(action.payload);
            state.savedmanifest = action.payload;
        })
        builder.addCase(getManifestSavedDocumentation.fulfilled, (state, action)=>{
            state.manifestdocumentation = action.payload;
        })
        builder.addCase(savePublihsedManifest.fulfilled, (state, action)=>{
            state.manifestsaved = true;
        })
        builder.addCase(getPublishedManifestSource.fulfilled, (state, action)=>{
            state.manifestsource = action.payload;
        })
        builder.addCase(updateManifestBanners.fulfilled, (state, action)=>{

        })
        builder.addCase(updateManifestFavicon.fulfilled, (state, action)=>{
            state.manifestfaviconsaved = true;
        })
        builder.addCase(getSavedManifestDeps.fulfilled, (state, action)=>{
            state.savedmanifestdeps = action.payload;
        })
        builder.addCase(publishSavedManifest.fulfilled, (state, action)=>{
            state.savedmanifestpublished = true;
        })
        builder.addCase(installManifest.fulfilled, (state, action)=>{
            state.installredirecturl = action.payload.redirecturl;
            state.manifestinstalled = true;
        })
        builder.addCase(installThirdpartyManifest.fulfilled, (state, action)=>{
            state.manifestinstalled = true;
            state.thirdpartyinstallconfig = action.payload;
        })
        builder.addCase(getInstallthirdpartyTemplate.fulfilled, (state, action)=>{
            state.installbuttontemplate = action.payload.installbuttontemplate;
        })
    }
})

export const selectManifest = state => state.manifest.manifest;

export const selectWebsiteOpt = state => state.manifest.websiteopt;

export const selectManifestpipeline = state => state.manifest.manifestpipeline;

export const selectManifestVersion = state => state.manifest.version;

export const selectManifestInstalled = state => state.manifest.manifestinstalled;

export const selectManifestcreated = state => state.manifest.manifestcreated;

export const selectManifestexist = state => state.manifest.manifestexist;

export const selectManifestUpdated = state => state.manifest.manifestupdated;

export const selectManifestform = state => state.manifest.manifestform;

export const selectManifestInstalltype = state => state.manifest.manifestinstalltype;

export const selectManifestThirdPartyUrl = state => state.manifest.manifestthirdpartyurl;

export const selectInstallButtonTemplate = state => state.manifest.installbuttontemplate;

export const selectInstallConfig = state => state.manifest.installconfig;

export const selectManifestApplist = state => state.manifest.applist;

export const selectManifestDocumentation = state => state.manifest.manifestdocumentation;

export const selectSavedManifest = state => state.manifest.savedmanifest;

export const selectManifestSaved = state => state.manifest.manifestsaved;

export const selectManifestSource = state => state.manifest.manifestsource;

export const selectManifestFaviconSaved = state => state.manifest.manifestfaviconsaved;

export const selectSavedManifestDeps = state => state.manifest.savedmanifestdeps;

export const selectSavedManofestpublished = state => state.manifest.savePublihsedManifest;

export const selectManifestinstalled = state => state.manifest.manifestinstalled;

export const selectInstallRedirectUrl = state => state.manifest.installredirecturl;

export const selectThirdpartyinstallconfig = state => state.manifest.thirdpartyinstallconfig;

export const selectManifestLoader = state => state.manifest.manifestloader;


export const {
                setManifestloader,
                resetmanifest, 
                resetwebsiteopt,
                resetPmanifestinstalled,
                resetmanifestcreated,
                resetmanifestexist,
                resetmanifestupdated,
                resetmanifestthirdurl,
                resetmanifestform,
                resetmanifestdocumentation,
                resetmanifestsaved,
                resetmanifestsource,
                resetmanifestfaviconsaved,
                resetmanifestsaveddeps,
                resetsavedmanifestpublished,
                resetinstallbuttontemplate,
                resetinstallredirecturl,
                resetmanifestinstalled,
                resetthirdpartyinstallconfig
            } = manifestSlice.actions;

export default manifestSlice.reducer;