import { useEffect, useState } from "react";
import "./Usage.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import { getDatatransfer, getPipelinecount, getpipelinehttpusage, getPipelineusage, getRouteloads, getSchemarecords, getStorage, selectAccountloader, selectDatatransfer, selectPipelinecount, selectPipelinehttpusage, selectPipelineusage, selectRouteloads, selectSchemarecords, selectStorage } from "../../Services/accounts/usage.slice";
import { LinearProgress } from "@mui/material";

const Usage = ()=>{

    const [month, setMonth] = useState(null);

    const [year, setYear] = useState(null);

    const [pipelineusage, setPipelineusage] = useState(0);

    const [pipelinecount, setPipelinecount] = useState(0);

    const [pipelinehttpusage, setPipelinehttpusage] = useState(0);

    const [schemarecords, setSchemarecords] = useState([]);

    const [routeloads, setRouteload] = useState(0);

    const [storage, setStorage] = useState(0);

    const [datatransfer, setDatatransfer] = useState(0);

    useEffect(()=>{
        let currentDate = new Date();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = currentDate.getFullYear();
        setMonth(month);
        setYear(year);
    },[])

    const dispatch = useDispatch();

    useEffect(()=>{
        if(month!=null&&year!=null){
            dispatch(getPipelineusage({
                                        month: month,
                                        year: year
                                      }));
            dispatch(getPipelinecount({
                                        month: month,
                                        year: year
                                      }));
            dispatch(getpipelinehttpusage({
                                            month: month,
                                            year: year
                                          }));
            dispatch(getSchemarecords({
                                        month: month,
                                        year: year
                                      }));
            dispatch(getRouteloads({
                                    month: month,
                                    year: year
                                    }));
            dispatch(getStorage({
                                    month: month,
                                    year: year
                                }));
            dispatch(getDatatransfer({
                                        month: month,
                                        year: year
                                    }))
        }
    },[month, year])


    const remotepipelineusage = useSelector(selectPipelineusage);

    useEffect(()=>{
        if(remotepipelineusage>0){
            setPipelineusage(remotepipelineusage);
        }
    },[remotepipelineusage])

    const remotepipelinecount = useSelector(selectPipelinecount);

    useEffect(()=>{
        if(remotepipelinecount>0){
            setPipelinecount(remotepipelinecount)
        }
    },[remotepipelinecount])

    const remotepipelinehttpusage = useSelector(selectPipelinehttpusage);

    useEffect(()=>{
        if(remotepipelinehttpusage>0){
            setPipelinehttpusage(remotepipelinehttpusage);
        }
    }, [remotepipelinehttpusage])

    const remoteschemarecords = useSelector(selectSchemarecords);

    useEffect(()=>{
        if(remoteschemarecords?.length>0){
            let schemarecordscopy = [...remoteschemarecords];
            setSchemarecords(schemarecordscopy);
        }
    },[remoteschemarecords])

    const remoterouteloads = useSelector(selectRouteloads);

    useEffect(()=>{
        if(remoterouteloads>0){
            setRouteload(remoterouteloads);
        }
    },[remoterouteloads])

    const remotestorage = useSelector(selectStorage);

    useEffect(()=>{
        if(remotestorage>0){
            setStorage(remotestorage)
        }
    },[remotestorage])

    const remotedatatransfer = useSelector(selectDatatransfer);

    useEffect(()=>{
        if(remotedatatransfer>0){
            setDatatransfer(remotedatatransfer)
        }
    },[remotedatatransfer])
    

    const monthList = [
        {
            "value":1,
            "name": "Jan"
        },
        {
            "value":2, 
            "name": "Feb"
        },
        {
            "value": 3,
            "name": "Mar"
        },
        {
            "value": 4,
            "name": "Apr"
        },
        {
            "value": 5,
            "name": "May"
        },
        {
            "value": 6, 
            "name": "Jun"
        },
        {
            "value": 7,
            "name": "Jul"
        },
        {
            "value": 8,
            "name": "Aug"
        },
        {
            "value": 9,
            "name": "Sep"
        },
        {
            "value": 10,
            "name": "Oct"
        },
        {
            "value": 11,
            "name": "Nov"
        },
        {
            "value": 12,
            "name": "Dec"
        }
    ]

    const yearList = [
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030"
    ]

    const accountsloader = useSelector(selectAccountloader);

    return (
        <div>
            {accountsloader>0&&
                <LinearProgress></LinearProgress>
            }
            <div style={{marginLeft: 10}}>
                <h2>Account Usage</h2>
            </div>
            <div className="usage-headers">
                <div className="usage-filter">
                    <div className="usage-filter-box">
                        <select
                            value={month}
                            onChange={(e)=>{setMonth(e.target.value)}}                    
                        >
                            {monthList.map((month)=>{
                                return (
                                    <option value={month.value}>
                                        {month.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="usage-filter-box">
                        <select
                            value={year}
                            onChange={(e)=>{setYear(e.target.value)}}
                        >
                            {yearList.map((year)=>{
                                return (
                                    <option value={year}>
                                        {year}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="usage-containers">
                <div className="usage-container">
                    <h3>App Pipelines</h3>
                    <div className="pipelines-usage-table">
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="pipeline usage table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Metric</TableCell>
                                        <TableCell>Usage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Events Count
                                        </TableCell>
                                        <TableCell>
                                            {pipelinecount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Usage
                                        </TableCell>
                                        <TableCell>
                                            {pipelineusage}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Http Data Transfer
                                        </TableCell>
                                        <TableCell>
                                            {pipelinehttpusage}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="pipeline-http-usage">
                        {/* <div className="usage-label">
                            Http Data Transfer
                        </div>
                        <div className="usage-value">
                            {pipelinehttpusage}
                        </div> */}
                    </div>
                </div>
                <div className="usage-container">
                    <h3>
                        Schema Records
                    </h3>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="schema records">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Id
                                        </TableCell>
                                        <TableCell>
                                            Workspace
                                        </TableCell>
                                        <TableCell>
                                            App
                                        </TableCell>
                                        <TableCell>
                                            Schema slug
                                        </TableCell>
                                        <TableCell>
                                            Records Count
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {schemarecords.map((record)=>{
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    {record._id}
                                                </TableCell>
                                                <TableCell>
                                                    {record.workspace}
                                                </TableCell>
                                                <TableCell>
                                                    {record.appname}
                                                </TableCell>
                                                <TableCell>
                                                    {record.schemaslug}
                                                </TableCell>
                                                <TableCell>
                                                    {record.count}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className="usage-container">
                    <h3>
                        Route Loads
                    </h3>
                    <div className="route-usage">
                    <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="pipeline usage table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Metric</TableCell>
                                        <TableCell>Usage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Route Loads
                                        </TableCell>
                                        <TableCell>
                                            {routeloads}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className="filesystem-usage">
                    <h3>
                        Filesystem usage
                    </h3>
                    <div className="filesystem-usage-table">
                        <TableContainer component={Paper}>
                            <Table component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Metric</TableCell>
                                        <TableCell>Usage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Storage</TableCell>
                                        <TableCell>{storage}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Data Transfer</TableCell>
                                        <TableCell>{datatransfer}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Usage;