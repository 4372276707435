// import axios from "../signin/axios";
import {get, postjson, putjson, deleteM} from "../signin/axios";
import { baseURL } from "../../env";

export const getSchemasApi = async()=>{
    let res = await get(baseURL+"schemas");
    return res;
}

export const getSchemaByAppApi = async(id)=>{
    let res = await get(baseURL+"schemas/apps/"+id);
    return res;
}

export const createSchemaApi = async(payload)=>{
    let res = await postjson(baseURL+"schemas",payload);
    return res;
}

export const updateSchemaApi = async(id,payload)=>{
    let res = await putjson(baseURL+"schemas/"+id,payload);
    return res;
}

export const getSchemaByIdApi = async(id)=>{
    let res = await get(baseURL+"schemas/"+id);
    return res;
}

export const deleteSchemaApi = async(id)=>{
    let res  =  await deleteM(baseURL+ "schemas/"+id);
    return res;
}

export const setSchemaDescApi = async(payload)=>{
    let res = await putjson(baseURL+"schemas/desc/"+payload.id, {desc:payload.desc});
    return res;
}

export const makeSchemaUserActiveApi = async(payload)=>{
    let res = await get(baseURL+"schemas/useractive/"+payload);
    return res;
}

export const getSchemaActiveUsersApi = async(payload)=>{
    let res = await get(baseURL+"schemas/getuseractive/"+payload);
    return res;
}

export const getSchemaActiveUsersCountApi = async(payload)=>{
    let res = await get(baseURL+"schemas/getuseractive/count/"+payload);
    return res;
}

export const makeSchemaUserInactiveApi = async(payload)=>{
    let res = await get(baseURL+"schemas/userinactive/"+payload);
    return res;
}