import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
    name: "loadingbar",
    initialState: {
        "count": 0,
    },
    reducers: {

        loading: (state)=>{
            state.count = state.count + 1; 
        },

        loadingdone: (state) => {
            state.count = state.count - 1;
        },

    }

})

export const loadingSelector = (state) => state.loadingbar.count;

export const {loading, loadingdone} = loadingSlice.actions;

export default loadingSlice.reducer;
