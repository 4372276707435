import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    
    let location = useLocation();
    
    const auth = localStorage.getItem("auth");

    const defaultworkspace = localStorage.getItem("defaultworkspace")

    if(defaultworkspace!=""&&defaultworkspace!=undefined&&defaultworkspace!=null&&auth) {
        return children;
    }else{
        return <Navigate to="/login" state={{"from": location?.pathname}}></Navigate>
    }
};

export default ProtectedRoute;