
import { 
            IconButton, 
            Select, 
            TextField 
        } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {Autocomplete} from '@mui/material';
import "./CssToolbar.css";
import { StyleSettings } from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateClasses, selectTemplateClasses, updateTemplateClasses } from '../../Services/apptemplates/apptemplates.slice';
import { filterValues } from './utils';
import { StyleProperty } from './StylesToolbar';
import { useParams } from 'react-router-dom';

const CssBox = ({
    setCssClass,
    cssclass, 
    index,
    deleteCssClass
})=>{

    const [options, setOptions] = useState([]);

    const [inputvalue, setInputValue] = useState("");

    const getProperties = ()=>{
        let props = [];
        for(let i=0; i< StyleSettings.length; i++){
            props.push(StyleSettings[i].name)
        }
        return props;
    }

    const filteroptions = (phrase)=>{
        let optionsCopy = getProperties();
        let resp = filterValues(optionsCopy, phrase);
        setOptions(resp);
        setInputValue(phrase);
    }

    useEffect(()=>{
        let optionsCopy = getProperties();
        setOptions(optionsCopy);
    },[])

    const availableStates = [
        "hover",
        "active",
        "focus",
        "focus-within",
        "visited",
        "focus-visible"
    ]

    const setCssClassname = (val)=>{
        let cssclassCopy = {...cssclass,
                            name: val 
                           }
        setCssClass(cssclassCopy);
    }

    const setStyleAction = (index)=>{
        return (val)=>{
            let styleCopy = [...cssclass.styles];
            styleCopy[index] = val;
            let cssclassCopy = {...cssclass,
                                styles: styleCopy
                               }
            setCssClass(cssclassCopy);
        }
    }

    const removeStyleAction = (index)=>{
        return ()=>{
            let styleCopy = [...cssclass.styles];
            styleCopy.splice(index, 1);
            let cssclassCopy = {...cssclass,
                                styles: styleCopy
                               }
            setCssClass(cssclassCopy);
        }
    }

    const setStyleValue = (val, index)=>{
        let stylesCopy = [...cssclass.styles];
        stylesCopy[index] = {...stylesCopy[index],
                             value: val
                            }
        let cssclassCopy = {...cssclass,
                            styles: stylesCopy
                            }
        setCssClass(cssclassCopy);
    }

    const setStyleName = (val, index)=>{
        let stylesCopy = [...cssclass.styles];
        stylesCopy[index] = {...stylesCopy[index],
                             name: val
                            }
        let cssclassCopy = {...cssclass,
                            styles: stylesCopy
                           }
        setCssClass(cssclassCopy);
    }

    const addStyle = ()=>{
        let stylesCopy = [...cssclass.styles];
        stylesCopy.push({
            "name": "",
            "val": ""
        })
        let cssclassCopy = {...cssclass,
                            styles: stylesCopy
                            }    
        setCssClass(cssclassCopy);
    }

    const setClassState = (val)=>{
        let cssclassCopy = {...cssclass,
                            state: val
                           }
        setCssClass(cssclassCopy);

    }

    console.log("flickering");

    return (
        <div className="cssbox">
            <div className="cssbox-header">
                <div>
                    <label>
                        name:
                    </label>
                    <input
                        value={cssclass.name} 
                        onChange={(e)=>{setCssClassname(e.target.value)}}
                        id={"classname-"+index}
                        style={{minWidth: "100px"}}
                    ></input>
                </div>
                <div>
                    <label>
                        state:
                    </label>
                    <select
                        variant='standard'
                        value={cssclass.state}
                        onChange={(e)=>{setClassState(e.target.value)}}
                        renderValue={(val)=>{
                            return val;
                        }}
                    >
                        <option value={"default"}>default</option>
                        {availableStates.map((avs)=>{
                            return(
                                <option value={avs} >{avs}</option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <IconButton onClick={()=>{deleteCssClass()}}>
                        <span className="material-symbols-outlined">close</span>
                    </IconButton>
                </div>
            </div>
            <div className='cssbox-styles'>
                {cssclass.styles.map((style, index)=>{
                    return(
                            <StyleProperty
                                style={style}
                                setStylesAction={setStyleAction(index)}
                                removeStyleAction={removeStyleAction(index)}
                                templateid={""}
                                settings={{}}
                            
                            >

                            </StyleProperty>
                          ) 
                })}
                <div className='addition-button'>
                    <IconButton onClick={()=>{addStyle()}}>
                        <span className='material-symbols-outlined'>
                            add
                        </span>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

const CssToolbar = ({templateid}) => {

  const [cssclasses, setCssClasses] = useState([]);

  const [boxinit, setboxinit] = useState(false);

  const remotecssclasses = useSelector(selectTemplateClasses);

  const {__source} = useParams();

  useEffect(()=>{
    if(remotecssclasses!=undefined&&remotecssclasses.length>0&&boxinit==false){
        let remoteclassesCopy = [...remotecssclasses];
        setCssClasses(remoteclassesCopy)
        setboxinit(true);
    }
  },[remotecssclasses])
  
  const dispatch = useDispatch();
  
  const setCssClassesAction = (cssclasses)=>{
        dispatch(updateTemplateClasses({
            templateid: templateid,
            classes: cssclasses
        }))
        setCssClasses(cssclasses);
  }

  const addCssClass = ()=>{
    let cssclassesCopy = [...cssclasses];
    cssclassesCopy.push({
        "name": "",
        "type": "user",
        "state": "default",
        "styles":[

        ]
    })
    setCssClassesAction(cssclassesCopy);
  }

  const setCssClass = (index)=>{
    return (val)=>{
        let cssclassesCopy = [...cssclasses];
        cssclassesCopy[index] = val;
        setCssClassesAction(cssclassesCopy);
    }
  }

  const deleteCssClassAction = (index)=>{
    return ()=>{
        let cssclassCopy = [...cssclasses];
        cssclassCopy.splice(index,1);
        setCssClassesAction(cssclassCopy);
    }
  }

  useEffect(()=>{
    dispatch(getTemplateClasses({
                                    "templateid": templateid,
                                    "source": __source
                                }))
  },[])

  return (
    <div>
        {cssclasses.map((cssclass, index)=>{
            return (
                <>
                    {cssclass.type=="user"&&
                        <CssBox
                            setCssClass={setCssClass(index)}
                            cssclass={cssclass}
                            index={index}
                            deleteCssClass={deleteCssClassAction(index)}
                        >
                        </CssBox>
                    }
                </>
            )
            })
        }
        <div className='addition-button'>
            <IconButton onClick={()=>{addCssClass()}}>
                <span className="material-symbols-outlined" onClick={()=>{}}>
                    add
                </span>
            </IconButton>
        </div>
    </div>
  );
};

export default CssToolbar;