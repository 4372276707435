import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAppCountApi, getFolderCountApi, getInterfaceCountApi, getWorkspaceCountApi, getWorkspaceDetailsApi} from "./dashboard.service";
import { loading, loadingdone } from "../loader/loader.slice";
import { showError } from "../error.slice";

export const getWorkspaceDetails = createAsyncThunk(
    "dashborad/getworkspacedetails",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setdashboardloading())
            let res = await getWorkspaceDetailsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error fetching workspace count"));
            throw error;
        }
    }
)

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        details: null,
        dashboardloading: false
    },
    reducers: {
        setdashboardloading: (state, action)=>{
            state.dashboardloading = true;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getWorkspaceDetails.fulfilled, (state, action)=>{
            state.details = action.payload;
            state.dashboardloading = false;
        })
    }
})

export const selectWorkspaceDetails = state => state.dashboard.details;
export const selectDashboardLoading = state => state.dashboard.dashboardloading;

export const {setdashboardloading} = dashboardSlice.actions;

export default dashboardSlice.reducer;