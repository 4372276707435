import { Button, TableCell, TextField,Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SingleField from "./SingleField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import { showError } from "../../../Services/error.slice";
import * as _ from "lodash";
import "./CustomInput.css";
import { useDispatch } from "react-redux";

const CustomInput = ({
                        close, 
                        initialSchema, 
                        addtoschema,
                        isArray,
                        closeonadd,
                        source
                    })=>{
    
    const [schemaDepth, setSchemaDepth] = useState(0);

    const [schema, setInputSchema] = useState([]);

    const [openchild, setOpenChild] = useState(false);

    const [hiddenparent, setHiddenparent] = useState(false);
    
    const markDone = ()=>{
        addtoschema([...schema])
        if(closeonadd==undefined){
            close();
        }
        
    }

    useEffect(()=>{
        let schemaCopy = [...schema];
        schemaCopy = [...initialSchema];
        setInputSchema(schemaCopy);
    },[initialSchema])

    return(
        <div style={{position: "relative"}}>
            <SchemaLayer 
                schema={schema} 
                zindex={200} 
                setSchema={setInputSchema} 
                close={close} 
                addtoschema={markDone}
                schemaDepth = {schemaDepth}
                setSchemaDepth = {setSchemaDepth}
                isArray={isArray}
                source={source}
            ></SchemaLayer>
        </div>
    )
}

const SchemaLayer = ({
                        schema,
                        zindex, 
                        setSchema, 
                        close, 
                        addtoschema,
                        openchild,
                        hideparent,
                        isArray,
                        source
                    })=>{
    
    const setSchemaField = (index)=>{
        return (sc)=>{
            let schemaCopy = [...schema];
            schemaCopy[index] = sc;
            setSchema(schemaCopy)
        }    
    }

    const addField = ()=>{
        let schemaCopy = [...schema];
        if((source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")){
            schemaCopy.push({
                "key": "",
                "label": "",
                "required": false,
                "type": "string",
                "subschema": [],
                "guided": false,
                "guidefinished": false,
                "default": "",
                "fillallowed": true,
                "value": ""
            })
        }else{
            schemaCopy.push({
                "key": "",
                "required": false,
                "type": "string",
                "subschema": [],
                "guided": false,
                "guidefinished": false,
                "default": "",
                "fillallowed": true,
                "value": ""
            })
        }
        setSchema(schemaCopy);
    }

    // add call to check if schema is valid if current is not valid
    const deleteField = (index)=>{
        return ()=>{
            let schemaCopy = [...schema];
            schemaCopy.splice(index,1);
            setSchema(schemaCopy);
        }
    }

    const [schemaValid, setSchemaValid] = useState(true);

    const validateSchemaKey = (val)=>{
        let keyIndex = _.findIndex(schema, (sc)=>{return sc.key==val});
        if(keyIndex==-1){
            return true
        }else{
            return false
        }
    }

    return (
        <div style={{
                        "position": "absolute",
                        "backgroundColor": "white",
                        zIndex:zindex,
                        "width":"100%",
                        "opacity":1,
                        "border": "2px solid #eee"
                    }}>
            <div>
            <div style={{
                    display:"flex",
                   "postion": "relative",
                   "width": "100%",
                   "justifyContent":"space-between", 
                   "border": "1px solid #eee", 
                   "background":"#eee",
                }}>
                    <div style={{
                       "padding": 10, 
                       "width":"20%", 
                       "display": "flex",
                       "justifyContent": "center",
                       "marginTop": "10px"
                    }}>
                        Key
                    </div>
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{
                            "padding": 10,
                            "width":"20%",
                            "display": "flex",
                            "justifyContent": "center",
                            "marginTop": "10px"
                            }}
                        >
                            Label                   
                        </div>
                    }
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{
                            "padding": 10,
                            "width":"20%",
                            "display": "flex",
                            "justifyContent": "center",
                            "marginTop": "10px"
                            }}
                        >
                            Required
                        </div>
                    }
                    <div style={{
                        "padding": 10,
                        "width": "20%",
                        "display": "flex",
                        "justifyContent": "center",
                        "marginTop": "10px"

                    }}>
                        Type
                    </div>
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{
                            "padding": 10,
                            "width":"20%",
                            "display": "flex",
                            "justifyContent": "center",
                            "marginTop": "10px"
                        }}>
                            Default
                        </div>
                    }
                    
                    <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center", "marginTop": "10px"}}>
                        Delete
                    </div>
                    <div 
                        style={{
                            "position": "absolute", 
                            "display": "flex"
                        }}
                    >
                        {addtoschema!=undefined?
                            <>
                                <div className="custom-input-action" onClick={()=>{close()}}>
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </div>
                                {addtoschema!=undefined&&
                                    <div className="custom-input-action" onClick={()=>{addtoschema()}}>
                                        <span className="material-symbols-outlined">
                                            done
                                        </span>
                                    </div>
                                }
                            </>:
                            <>
                                <div className="custom-input-action" onClick={()=>{close()}}>
                                    <span className="material-symbols-outlined">
                                        done
                                    </span>
                                </div>
                            </>
                        }
                    </div>
            </div>
            {schema?.map((sc, index)=>{
                return(
                    <FieldLayer 
                        sc={sc} 
                        setSchema={setSchemaField(index)} 
                        zindex={zindex} 
                        deleteField={deleteField(index)}
                        validateSchemaKey={validateSchemaKey}
                        isArray={isArray}
                        source={source}
                    ></FieldLayer>
                )
            })}
            <div style={{display:"flex","justifyContent": "flex-end","margin":10}}>
                <Button style={{color: "#3A07CD"}} onClick={()=>{addField()}}>Add Field</Button>
            </div>
            </div>
        </div>
    )
}


const FieldLayer = ({
        sc, 
        setSchema, 
        zindex, 
        deleteField,
        openchild,
        hideparent,
        isArray,
        validateSchemaKey, 
        source

    })=>{

        const [opensubschema,setopensubschema] = useState(false);

        const [openarrayschema, setopenarrayschema] = useState(false);

        const [defaultlabel, setdefaultlabel] = useState("");

        const dispatch = useDispatch();

        // add an extra type enum tobe used internally
        const inputtypes = [
            {"name": "String", "value": "string"},
            {"name": "Text", "value": "text"},
            {"name": "Integer", "value": "integer"},
            {"name": "Number", "value": "number"},
            {"name": "Boolean", "value": "boolean"},
            {"name": "Datetime", "value": "datetime"},
            {"name": "Array", "value": "array"},
            {"name": "Object", "value": "object"},
            {"name": "File", "value": "file"},
            {"name": "Any", "value": "any"}
        ]

        const textinputtypes = [
            {"name": "String", "value": "string"},
            {"name": "Text", "value": "text"},
            {"name": "Integer", "value": "integer"},
            {"name": "Number", "value": "number"},
            {"name": "Boolean", "value": "boolean"},
            {"name": "Datetime", "value": "datetime"},
            {"name": "Array", "value": "array"},
            {"name": "Object", "value": "object"},
        ]

        const setFieldKey = (value)=>{
            let valid = validateSchemaKey(value);
            if(valid){
                let scCopy = {...sc,
                    key: value 
                }
                setSchema(scCopy);
            }else{
                let scCopy = {...sc,
                    key: value 
                }
                setSchema(scCopy);
                dispatch(showError("Duplicate key not allowed in schema"));
            }
        }

        const setFieldLabel = (val)=>{
            let scCopy = {...sc,
                label: val
            }
            setSchema(scCopy)
        }

        const setFieldRequired = ()=>{
            let scCopy = {...sc,
                          required: !sc.required
                        }
            setSchema(scCopy)
        }

        const setFieldType = (val)=>{
            console.log(val);
            if(val=="array"){
                setdefaultlabel("Add comma separated value");
                let scCopy = {...sc,
                                type: val,
                                subschema: [{
                                    "type": "string",
                                    "subschema":[]
                                }]
                            }
                setSchema(scCopy);
            }else{
                let scCopy = {
                                ...sc,
                                type: val
                            };
                setSchema(scCopy);
            }
            if(val=="object"){
                setopenarrayschema(false);
                setopensubschema(true);
            }
            if(val=="array"){
                setopenarrayschema(true);
                setopensubschema(false);
            }
        }

        const setFieldDefault = (val)=>{
            let primitiveTypes = ['string', 'number', 'integer', 'datetime', 'boolean'];
            let primitiveIndex = _.findIndex(primitiveTypes, (pt)=>{return sc.type==pt});
            if(primitiveIndex>-1){
                
            }
        }

        const setSubschema = (schema)=>{
            let scCopy = {...sc,
                    subschema: schema
                };
            setSchema(scCopy);
        }

        const close = ()=>{
            setopensubschema(false)
        }

        const closearray = ()=>{
            setopenarrayschema(false);
        }




        return (
            <div style={{"position": "relative", "width":"100%"}}>
                <div style={{display:"flex","width": "100%","justifyContent": "space-between","border":"1px solid #eee"}}>
                    <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center"}}>
                        <TextField value={sc.key} onChange={(e)=>{setFieldKey(e.target.value)}} label="key" variant="standard"></TextField>
                    </div>
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center"}}>
                            <TextField value={sc.label} onChange={(e)=>{setFieldLabel(e.target.value)}} label="Label" variant="standard"></TextField>                   
                        </div>
                    }
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center"}}>
                            <Checkbox checked={sc.required} onChange={()=>{setFieldRequired()}} variant="standard"></Checkbox>
                        </div>
                    }
                    <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center"}}>
                        <div>
                            <select
                                value={sc.type}
                                onChange={(e)=>{setFieldType(e.target.value)}}
                            >
                                {source=="texttemlate"?
                                    <>
                                        {textinputtypes.map((it)=>{
                                            return (
                                                <option value={it.value}>
                                                    {it.name}
                                                </option>
                                            )
                                        })}
                                    </>:
                                    <>
                                        {inputtypes.map((it)=>{
                                            return (
                                                <option value={it.value}>
                                                    {it.name}
                                                </option>
                                            )
                                        })

                                        }
                                    </>
                                }
                            </select>
                            {/* <FormControl>   
                                <InputLabel id="sc-type" label="Select Type">Select Type</InputLabel>
                                <Select
                                    labelId="sc-type" 
                                    value={sc.type} 
                                    onChange={(e)=>{setFieldType(e.target.value)}} 
                                    variant="standard"
                                >
                                    {source=="texttemplate"?
                                        <>
                                            {textinputtypes.map((it)=>{
                                                return (
                                                    <MenuItem value={it.value}>
                                                        {it.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        
                                        </>:
                                        <>
                                            {inputtypes.map((it)=>{
                                                return(
                                                    <MenuItem value={it.value}>
                                                        {it.name}
                                                    </MenuItem>
                                                )
                                            })}    
                                        </>
                                    }
                                    
                                </Select>
                            </FormControl> */}
                        </div>
                        <div>
                            {sc.type=="object"&&
                                <>
                                    {opensubschema?
                                         <Tooltip title="Expand Schema">
                                            <IconButton onClick={()=>{setopensubschema(false)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_less
                                                </span>
                                            </IconButton>
                                         </Tooltip>
                                        :
                                        <Tooltip title="Expand Schema">
                                            <IconButton onClick={()=>{setopensubschema(true)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_more
                                                </span>
                                            </IconButton>
                                         </Tooltip>
                                    }
                                </>
                            }
                            {sc.type=="array"&&
                                <>
                                    {openarrayschema?
                                        <Tooltip title="Expand Schema">
                                            <IconButton onClick={()=>{setopenarrayschema(false)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_less
                                                </span>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Expand Schema">
                                            <IconButton onClick={()=>{setopenarrayschema(true)}}>
                                                <span className='material-symbols-outlined'>
                                                    expand_more
                                                </span>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    {(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div style={{"padding": 10, "width": "20%" , "display": "flex", "justifyContent": "center"}}>
                            <TextField 
                                variant="standard" 
                                value={sc.default} 
                                onChange={(e)=>{setFieldDefault(e.target.value)}} 
                                label={defaultlabel}
                                multiline
                            ></TextField>
                        </div>
                    }
                    
                    <div style={{"padding": 10,"width":"20%","display": "flex","justifyContent": "center"}}>
                        {(isArray&&sc.type=="array")!=true&&
                            <div style={{cursor: "pointer"}} onClick={()=>{deleteField()}}>
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </div>
                        }
                    </div>
                </div>
                {openarrayschema&&
                    <ArraySchema 
                        zindex={zindex+1} 
                        schema={sc.subschema} 
                        setSchema={setSubschema}
                        close={closearray}
                        source={source}
                    ></ArraySchema>
                }
                {opensubschema&&
                    <SchemaLayer 
                        schema={sc.subschema} 
                        zindex={zindex+1} 
                        setSchema={setSubschema} 
                        close={close}
                        source={source}
                    ></SchemaLayer>
                }
            </div>
        )
}


const ArraySchema = ({
                        schema, 
                        setSchema, 
                        zindex,
                        openchild,
                        hideparent,
                        close,
                        source
                    })=>{
                    
    const [opensubschema,setopensubschema] = useState(false);

    const [openarrayschema, setopenarrayschema] = useState(false);

    const inputtypes = [
        {"name": "String", "value": "string"},
        {"name": "Text", "value": "text"},
        {"name": "Integer", "value": "integer"},
        {"name": "Number", "value": "number"},
        {"name": "Boolean", "value": "boolean"},
        {"name": "Datetime", "value": "datetime"},
        {"name": "Array", "value": "array"},
        {"name": "Object", "value": "object"},
        {"name": "Any", "value": "any"}
    ]


    useEffect(()=>{
        if(schema.length==0){
            let schemaCopy = [{
                "type": "string",
                "subschema":[]
            }]
            setSchema(schemaCopy);
        }
    },[schema])


    const getFieldType = ()=>{
        if(schema.length>0){
            return schema[0].type
        }else{
            return "";
        }
    }

    const setFieldType = (val)=>{
        let schemaCopy = [...schema];
        schemaCopy[0] = {...schemaCopy[0],
                         type: val
                        }
        setSchema(schemaCopy);
        if(val=="object"){
            setopenarrayschema(false);
            setopensubschema(true);
        }
        if(val=="array"){
            setopenarrayschema(true);
            setopensubschema(false);
        }
        
    }

    const getSubschema = ()=>{
        if(schema.length>0){
            return schema[0].subschema
        }else{
            return [];
        }
        
    }

    const setSubschema = (val)=>{
        let schemaCopy = [...schema];
        schemaCopy[0] = {...schemaCopy[0],
                         subschema: val
                        }
        setSchema(schemaCopy)
    }

    return (
        <div style={{"position": "absolute","width":"100%"}}>
            <div style={{"position": "relative", "width":"100%"}}>
                <div style={{display:"flex","width": "100%","justifyContent": "space-between"}}>
                    <div style={{"padding": 10,"width":"25%","display": "flex","justifyContent": "center","opacity":0}}>
                    </div>
                    <div style={{"padding": 10,"width":"25%","display": "flex","justifyContent": "center","opacity":0}}>
                    </div>
                    <div style={{"padding": 10,"width":"25%","display": "flex","justifyContent": "center","opacity":0}}>
                    </div>
                    <div style={{"padding": 10,"width":"25%",zIndex:zindex,"display": "flex","justifyContent": "center", "opacity":1, "background":"white","border":"1px solid #eee"}}>
                        <div>
                            <div>
                                Type
                            </div>
                            <FormControl>
                                <Select value={getFieldType()} onChange={(e)=>{setFieldType(e.target.value)}} variant="standard">
                                    {inputtypes.map((it)=>{
                                        return(
                                            <MenuItem value={it.value}>
                                                {it.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                        <div>
                        {schema[0]?.type=="object"&&
                            <>
                                {opensubschema?
                                    <div onClick={()=>{setopensubschema(false)}} style={{"cursor": "pointer","padding": 10}}>
                                        <span class="material-symbols-outlined">
                                            expand_less
                                        </span>
                                    </div>:
                                    <div onClick={()=>{setopensubschema(true)}} style={{"cursor":"pointer","padding": 10}}>
                                        <span class="material-symbols-outlined">
                                            expand_more
                                        </span>
                                    </div>
                                }
                            </>
                        }
                        {schema[0]?.type=="array"&&
                            <>
                                {openarrayschema?
                                    <div onClick={()=>{setopenarrayschema(false)}} style={{"cursor": "pointer","padding": 10}}>
                                        <span class="material-symbols-outlined">
                                            expand_less
                                        </span>
                                    </div>:
                                    <div onClick={()=>{setopenarrayschema(true)}} style={{"cursor":"pointer","padding": 10}}>
                                        <span class="material-symbols-outlined">
                                            expand_more
                                        </span>
                                    </div>
                                }
                            </>
                        }
                    </div>
                        </div>
                    </div>
                    <div style={{"padding": 10,"width":"25%","display": "flex","justifyContent": "center","opacity":0}}>
                    </div>
                </div>
                {openarrayschema&&
                    <ArraySchema 
                        zindex={zindex+1} 
                        schema={getSubschema()} 
                        setSchema={setSubschema}
                        source={source}
                    ></ArraySchema>
                }
                {opensubschema&&
                    <SchemaLayer 
                        schema={getSubschema()} 
                        zindex={zindex+1} 
                        setSchema={setSubschema}
                        close={close} 
                        source={source}
                    ></SchemaLayer>
                }
            </div>
        </div>
    )
}

export default CustomInput;