import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { getDocumentationApi, getDocumentationPermissionApi, updateDocumentationApi } from "./documentations.service";


export const updateDocument = createAsyncThunk(
    "documentations/updatedocument",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = updateDocumentationApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error in updating document"));
            throw error;
        }
    }
)

export const getDocument =createAsyncThunk(
    "documentations/getdocument",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setDocumentLoader());
            let res = await getDocumentationApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting document"));
            throw error;
        }
    }
)

export const getDocumentationPermission = createAsyncThunk(
    "documentations/getdocumenatationpermission",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setDocumentLoader());
            let res = await getDocumentationPermissionApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting document permission"));
            throw error;
        }
    }
)


const documentations = createSlice({
    "name": "documentations",
    "initialState":{
        "activedocument": "",
        "activedocumentpermission": "",
        "documentloader": 0
    },
    "reducers":{
        resetdocument: (state, action)=>{
            state.activedocument = "";
        },
        resetactivedocumentpermission: (state, action)=>{
            state.activedocumentpermission = "";
        },
        setDocumentLoader: (state, action)=>{
            state.documentloader = state.documentloader+1;
        }
    },
    "extraReducers":(builder)=>{
        builder.addCase(getDocument.fulfilled, (state, action)=>{
            state.activedocument = action.payload.document;
            state.documentloader = state.documentloader-1;
        })
        builder.addCase(updateDocument.fulfilled, (state, action)=>{

        })
        builder.addCase(getDocumentationPermission.fulfilled, (state, action)=>{
            state.activedocumentpermission = action.payload.permission;
            state.documentloader = state.documentloader-1;
        })
    }
})


export const selectActiveDocument = (state) => state.documentations.activedocument;
export const selectActiveDocumentPermission = (state) => state.documentations.activedocumentpermission;
export const selectDocumentLoader = (state) => state.documentations.documentloader;

export const {
                resetdocument, 
                resetactivedocumentpermission,
                setDocumentLoader
            } = documentations.actions;

export default documentations.reducer;