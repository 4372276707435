const MlModels = ()=>{

    return (

        <div>
            <h1>Coming Soon !!!</h1>
        </div>
    )

}

export default MlModels;