import { IconButton, useScrollTrigger } from "@mui/material";
import { Editor } from "draft-js";
import { useState } from "react";
import  Markdown  from "react-markdown";
import remarkGfm from "remark-gfm";
import "./Readme.css";
import { useNavigate, useParams } from "react-router-dom";
import { MarkdownEditor, MarkdownViewer } from 'react-github-markdown';

const Readme = ({
    readme,
    setreadme,
    write,
    source,
    sourceid,
    mode,
    isManifest,
    msource,
    msourceid
})=>{
    
    // const [readme, setreadme] = useState("")
    const {
            workspace,
            appid,
            id
         } = useParams();

    const [edit, setEdit] = useState(false);

    const navigate = useNavigate();

    const navigateFullscreen = ()=>{
        if(isManifest){
            navigate("/"+workspace+"/manifest/"+msource+"/"+msourceid+"/"+id+"/"+source+"/"+sourceid);
        }else{
            if(source=="app"){
                navigate("/"+workspace+"/schemas"+"/"+appid+"/app/"+sourceid);
            }else if(source=="schema"){
                navigate("/"+workspace+"/schemas"+"/"+appid+"/details/"+id+"/schema/"+sourceid);
            }else if(source=="website"){
                navigate();
            }else if(source=="template"){
                navigate();
            }
        }
        
    }

    const navigateComponent = ()=>{
        if(isManifest){
            navigate("/"+workspace+"/savedmanifest/"+msource+"/"+msourceid+"/"+id);
        }else{
            if(source=="app"){
                navigate("/"+workspace+"/schemas"+"/"+appid);
            }else if(source=="schema"){
                navigate("/"+workspace+"/schemas"+"/"+appid+"/details/"+id);
            }else if(source=="website"){
                navigate();
            }else if(source=="template"){
                navigate();
            }
        }
        
    }

    return (
        <div>
            <div className="readme-header">
                <div>
                    Readme
                </div>
                <div>
                    {mode=="fullscreen"?
                            <>
                                <IconButton onClick={()=>{navigateComponent()}}>
                                    <span class="material-symbols-outlined">
                                        fullscreen_exit
                                    </span>
                                </IconButton>
                            </>:
                            <>
                                <IconButton onClick={()=>{navigateFullscreen()}}>
                                    <span class="material-symbols-outlined">
                                        fullscreen  
                                    </span>
                                </IconButton>
                                {edit?
                                <IconButton 
                                    disabled={write!="write"} 
                                    onClick={()=>{setEdit(false)}}
                                >
                                    <span className="material-symbols-outlined">
                                        done
                                    </span>
                                </IconButton>:
                                <IconButton
                                    onClick={()=>{setEdit(true)}}
                                >
                                    <span className="material-symbols-outlined">
                                        edit
                                    </span>
                                </IconButton>
                            }
                            </>
                    }                   
                </div>
            </div>
            <div className="readme-container">
                {mode=="fullscreen"?
                    <>
                        <div className="fullscreen-container">
                            <div className="fullscreen-editor">
                                <MarkdownEditor
                                    value={readme}
                                    onChange={setreadme}
                                    minLines={100}
                                >
                                </MarkdownEditor>
                            </div>
                            <div className="fullscreen-display">
                                <Markdown
                                    remarkPlugins={[remarkGfm]}
                                >
                                    {readme}
                                </Markdown>
                            </div>
                        </div>
                    </>:
                    <>
                        <>
                            {edit?
                                <div className="component-editor">
                                    <MarkdownEditor
                                        value={readme}
                                        onChange={setreadme}
                                    >
                                    </MarkdownEditor>
                                </div>:
                                <div className="component-display">
                                    <Markdown 
                                        remarkPlugins={[remarkGfm]}>
                                            {readme}
                                    </Markdown>
                                </div>
                            }
                        </>
                    </>
                }
            </div>
        </div>
    )


}


export default Readme;