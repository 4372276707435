import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector, useDispatch } from 'react-redux';
import { setDocument, setError } from '../../Services/marketplace/document.slice'; // Adjust the path based on your project structure
import { get } from '../../Services/signin/axios';
import remarkGfm from "remark-gfm";
import { baseURL, signinURL } from '../../env';


const DocumentFetcher = ({ source, sourceid }) => {
  const dispatch = useDispatch();
  const documentData = useSelector((state) => state.document.document); // Replace with your actual state structure
  const error = useSelector((state) => state.document.error); // Replace with your actual state structure

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const url = `${signinURL}marketplace/itemdoc/${source}/${sourceid}`; // URL formation based on parameters
        const result = await get(url); // Use the custom fetch function to get the data
        dispatch(setDocument(result)); // Dispatch action to set the document data in the Redux store
      } catch (err) {
        dispatch(setError(err.message)); // Dispatch action to set the error in the Redux store
      }
    };

    // Fetch the document only if both source and sourceid are available
    if (source && sourceid) {
      fetchDocument();
    }
  }, [source, sourceid, dispatch]);

  // Display error message if an error occurs
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Show a loading state while the data is being fetched
  if (!documentData) {
    return <div>Loading...</div>;
  }

  // Render the fetched document data using ReactMarkdown
  return (
    <div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
      >
        {documentData}
    </ReactMarkdown>
    </div>
  );
};

export default DocumentFetcher;
