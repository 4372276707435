import { useRef } from "react";
import MarketplaceGrid from "./MarketplaceGrid";
import "./MarketplaceGridPage.css";


const MarketplaceGridPage = ()=>{

    const scrollRef  = useRef(null);

    return (
        <div
            className="market-place-grid"
        >
            <MarketplaceGrid
                source={"marketplace"}
                sourceid={"marketplace"}
                scrollref={scrollRef}
            >
            </MarketplaceGrid>
        </div>   
    )
}


export default MarketplaceGridPage;