import { baseURL } from "../../env";
import { deleteM, get, postjson, putjson } from "../signin/axios"
import { uploadFileApi } from "../pipelines/pipeline.service";


export const getAvailableHostsApi = async()=>{
    let res = await get(baseURL+"client/websitehosts/");
    return res;
}


export const createWebsiteApi = async(payload)=>{
    let res = await postjson(baseURL+"websites/",payload);
    return res;
}


export const getWebsiteListApi = async(payload)=>{
    let res = await get(baseURL+"websites/getlist/"+payload);
    return res;
}

export const getWebsiteCountApi = async(workspace)=>{
    let res = await get(baseURL+"websites/count/"+workspace);
    return res;
}

export const getWebsiteDetailsApi = async(payload)=>{
    let res = await get(baseURL+"websites/"+payload);
    return res;

}

export const deleteWebsiteApi = async(payload)=>{
    let res = await deleteM(baseURL+"websites/"+payload);
    return res;
}

export const updateWebsiteApi = async(payload)=>{
    let res = await putjson(baseURL+"websites/"+payload._id, payload);
    return res;
}


export const getWebsiteTemplatesApi = async(payload)=>{
    let res = await get(baseURL+"templates/wesbites/"+payload);
    return res;
}

export const getWebsiteCreateOptionsApi = async(payload)=>{
    let res = await get(baseURL+"websites/create/options/"+payload);
    return res;
}

export const getWebsiteCreateWorkspaceOptionsApi = async(payload)=>{
    let res = await get(baseURL+"websites/create/options/other/"+payload);
    return res;
}

export const getWebsiteCreateWebsiteOptionsApi = async(payload)=>{
    let res = await get(baseURL+"websites/create/options/website/"+payload);
    return res;
}

export const createWebsiteTemplateApi = async(payload)=>{
    let res = await postjson(baseURL+"website/template/create", payload);
    return res;
}

export const downloadFavicon = async(payload)=>{
    try{
        let res = await fetch(payload.url);
        let blob = await res.blob();
        let file = new File([blob], payload.name, { lastModified: new Date().getTime(), type: blob.type })
        let resp = await uploadFileApi({"file": file});
        return resp.url;
    }catch(error){
        throw error;
    }
}