import { useEffect, useState } from "react";
import { HtmlComponents, HtmlGenericInteractions, InteractionSettings, NativeElements } from "./Settings";
import {useDispatch, useSelector} from "react-redux";
import DeclareVariable from "../apps/Fields/DeclareVariable";
import * as _ from "lodash";
import { getsubschemamapping } from "../apps/Fields/Utils";
import { IconButton, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
            selectActiveElement, 
            selectActiveTemplatePipelines, 
            setElement, 
            selectActivePosition, 
            getExportedTemplatePipelines,
            selectTemplateInteractions
        } from "../../Services/apptemplates/apptemplates.slice";
import { useParams } from "react-router-dom";

const InteractionBox = ({
                         interaction,
                         setInteraction,
                         removeInteractions,
                         elementtag,
                         elemtype,
                         templateid,
                         elementtempid
                        })=>{

    const {type} = useParams();
    
    const [options, setOptions] = useState([]);

    const [pipelines, setPipelines] = useState([]);

    const remotepipelines = useSelector(selectActiveTemplatePipelines);

    const [pschema, setPschema] = useState([]);

    const dispatch = useDispatch();

    const setValue = (val)=>{
        let interactionCopy = {...interaction,
                               name: val,
                               type: "browser"
                               };
        setInteraction(interactionCopy);
    }

    const setTemplateInteractionValue = (val)=>{
        let interactionCopy = {...interaction,
                                name: val.name,
                                id: val.id,
                                type: "template"
                                }
        setInteraction(interactionCopy);
    }

    const selectPipeline = (val)=>{
        let pipelineIndex = _.findIndex(remotepipelines, (pipeline)=>{return pipeline._id==val});
        if(pipelineIndex>-1){
            let pipelineCopy = {...remotepipelines[pipelineIndex]};
            let inputschema = pipelineCopy.pipeline[0].inputschema;
            let inputschemamapping = [];
            getsubschemamapping(inputschema, inputschemamapping);
            let interactionCopy = {...interaction,
                                   pipeline: val,
                                   schemamapping: inputschemamapping
                                  }
            setPschema(inputschema);
            setInteraction(interactionCopy);
        }else{

        }
        
    }

    useEffect(()=>{
        if(remotepipelines.length>0&&interaction!=undefined){
            if(interaction.pipeline!=""){
                let pipelineIndex = _.findIndex(remotepipelines, (pipeline)=>{return pipeline._id==interaction.pipeline});
                if(pipelineIndex>-1){
                    let pipelineCopy = {...remotepipelines[pipelineIndex]};
                    let inputschema = pipelineCopy.pipeline[0].inputschema;
                    setPschema(inputschema);
                }
            }
        }
    },[interaction, remotepipelines])

    const modifyVariable = (sc, scm)=>{
        let interactionCopy = {...interaction,
                                schemamapping: scm
                              }    
        setInteraction(interactionCopy);
    }

    useEffect(()=>{
        if(type!=undefined&&type!=null){
            if(type=="android"){
                if(elementtag==undefined&&elementtag==null){
                    return;
                }
                let optionsCopy = [];
                
                let elementIndex = _.findIndex(NativeElements, (elm)=>{return elementtag==elm.tag});   
                
                let availableInteractions = NativeElements[elementIndex].interactions;

                for(let i=0; i < availableInteractions.length; i++){
                    optionsCopy.push(availableInteractions[i].name);
                }
                setOptions(optionsCopy);
            }else{
                if(elemtype!="template"){
                    if(elementtag==undefined&&elementtag==null){
                        let optionsCopy = [];
                        let availableInteractions = [...HtmlGenericInteractions];
                        for(let i=0; i<availableInteractions.length; i++){
                            optionsCopy.push(availableInteractions[i].name);
                        }
                        setOptions(optionsCopy);
                    }else{
                        let optionsCopy = [];
                        let elementIndex = _.findIndex(HtmlComponents, (elm)=>{return elementtag==elm.tag});
                        let availableInteractions = [];
                        if(elementIndex>-1){
                            availableInteractions = HtmlComponents[elementIndex].interactions;
                        }else{
                            availableInteractions = [...HtmlGenericInteractions];
                        }
                        for(let i=0; i< availableInteractions.length; i++){
                            optionsCopy.push(availableInteractions[i].name)
                        }
                        setOptions(optionsCopy);
                    }
                }else{
                   dispatch(getExportedTemplatePipelines(elementtempid));
                }
            }
        }
    },[type, elemtype])

    const interactions = useSelector(selectTemplateInteractions)

    useEffect(()=>{
        if(elemtype=="template"&&interactions.length>0){
            let optionsCopy = [];
            for(let i=0; i< interactions.length; i++){
                let eleminteract = {
                    "name": interactions[i].name,
                    "id": interactions[i].id
                }
                optionsCopy.push(eleminteract);
            }
            setOptions(optionsCopy);
        }
    },
    [
        interactions, 
        elemtype
    ]
    )

    useEffect(()=>{
        let pipelinesCopy = [];
        for(let i=0; i< remotepipelines.length; i++){
            pipelinesCopy.push({
                "id": remotepipelines[i]._id,
                "name": remotepipelines[i].name
            })
        }
        setPipelines(pipelinesCopy);
    },[])

    return (
        <>
            <div style={{display: "flex", alignItems: "center"}}>
                {elemtype!="template"?
                    <Autocomplete
                        id="combo-box-demo"
                        options={options}
                        value={interaction.name}
                        onChange={(e, val)=>{setValue(val)}}
                        sx={{width:200}}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref} style={{minWidth: "100px"}}>
                                <input style={{width: "180px"}} type="text" {...params.inputProps} />
                            </div>
                        )}
                    />:
                    <Autocomplete
                        id="combo-box-demo"
                        options={options}
                        value={{
                            name: interaction.name,
                            id: interaction.id
                        }}
                        onChange={(e, val)=>{setTemplateInteractionValue(val)}}
                        getOptionLabel={(option)=>{return option.name}}
                        sx={{width: 200}}
                        renderInput={(params)=>{
                            return (<div ref={params.InputProps.ref} style={{minWidth: "100px"}}>
                                        <input style={{width: "180px"}} type="text" {...params.inputProps}></input>
                                    </div>)
                        }}
                    >
                    </Autocomplete>
                }
                
                <div>
                    <select
                        onChange={(e)=>{selectPipeline(e.target.value)}}
                        value={interaction.pipeline}
                    >
                        <option></option>
                        {pipelines.map((pipeline)=>{
                            return (
                                <option 
                                    value={pipeline.id}
                                >
                                    {pipeline.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <IconButton  onClick={()=>{removeInteractions()}}>
                    <span className="material-symbols-outlined">delete</span>
                </IconButton>

            </div>    
            <div>
                {interaction.pipeline!=""&&interaction.pipeline!=undefined&&pschema.length>0&&
                        <DeclareVariable
                            schema={pschema} 
                            modifySchema={modifyVariable}
                            schemamapping={interaction.schemamapping} 
                            pipelinetype={""}
                            stageindex={0}
                            stagetype={"single"}
                            layout={"vertical"}
                            pipelinestage={undefined}
                            source={"appinteractions"}
                            editable={false}
                            eventname={interaction.name}
                            sourceid={templateid}
                        >
                        </DeclareVariable>
                }
            </div>
        </>    
    )
}

const InteractionToolbar = ({
                             templateid
                            })=>{

    const {type} = useParams();

    const dispatch = useDispatch();

    const position = useSelector(selectActivePosition);

    const element = useSelector(selectActiveElement(position));

    const setInteractions = (interactions)=>{
        let elementCopy = {...element,
                           interactions: interactions
                          };
        dispatch(
            setElement({
                position: position,
                element: elementCopy
            })
        )
    }

    const addInteractions = ()=>{
        let interaction = {
            "name": "",
            "pipeline": "",
            "schemamapping":[]
        }
        let interactionsCopy = [...element.interactions];
        interactionsCopy.push(interaction)
        setInteractions(interactionsCopy);
    }

    const setInteraction = (i)=>{
        return (interaction)=>{
            let interactionsCopy = [...element.interactions];
            interactionsCopy[i] = interaction;
            setInteractions(interactionsCopy)
        }
    }

    const removeInteractions = (i)=>{
        return ()=>{
            let interactionsCopy = [...element.interactions];
            interactionsCopy.splice(i,1);
            setInteractions(interactionsCopy);
        }
    }

    return (
        <div>
            {element!=undefined&&
                <>
                    {element.interactions.map((interaction, i)=>{
                        return (
                            <div style={{"boxShadow": "0 4px 8px 0 rgba(0,0,0,0.2)", paddingBottom: "10px"}}>
                                <InteractionBox
                                    interaction = {interaction}
                                    setInteraction = {setInteraction(i)}
                                    removeInteractions = {removeInteractions(i)}
                                    elementtag={element.tag}
                                    elementtempid = {element._id}
                                    elemtype={element.elemtype}
                                    templateid={templateid}
                                >
                                </InteractionBox>
                            </div>
                        )
                    })}
                    <div style={{display: "flex", "justifyContent": "center"}}>
                        <Tooltip>
                            <IconButton onClick={()=>{addInteractions()}}>
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            }
        </div>
    )
}

export default InteractionToolbar;