import { IconButton, TextField, Button, LinearProgress} from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { createCollaboration, getCollabWorkspaces, getCollaborator, getDomains, resetcollaborationcreated, resetcollaborationupdated, resetcollaborator, selectCollabWorkspaces, selectCollaborationcreated, selectCollaborationupdated, selectCollaborator, selectDomains, updateCollaboration } from "../../Services/collaborators/collaborators.slice";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import "./CollaboratorDetail.css";
import { showError } from "../../Services/error.slice";

const WorkspaceBlock = ({
                            workspace, 
                            setworkspace,
                            create,
                            disabled
                        })=>{

    const [workspaceopen, setWorkspaceOpen] = useState(false);

    const [init, setInit] = useState(false);

    useEffect(()=>{
        if(init){
            return
        }
        if(create==false){
            if(workspace==undefined){
                return
            }
            if(workspace.details==undefined){
                return
            }
            if(workspace.read||workspace.write){
    
            }else{
                let permissiondefined = false;
                for(let i=0; i<workspace.details.apps.length; i++){
                    if(workspace.details.apps[i].read||workspace.details.apps[i].write){
                        permissiondefined = true
                    }
                }
                for(let i=0; i<workspace.details.websites.length; i++){
                    if(workspace.details.websites[i].read||workspace.details.websites[i].write){
                        permissiondefined = true
                    }
                }
                for(let i=0; i<workspace.details.folders.length; i++){
                    if(workspace.details.folders[i].read||workspace.details.folders[i].write){
                        permissiondefined = true
                    }
                }
                if(permissiondefined){
                    setWorkspaceOpen(true);
                }
            }
        }else{
            setWorkspaceOpen(false);
        }
        setInit(true);
    },[workspace, create])

    const setWorkspaceread = (val)=>{
        let workspacecopy = {...workspace,
                             read: val
                            }
        setworkspace(workspacecopy);
    }

    const setWorkspacewrite = (val)=>{
        let workspacecopy = {...workspace,
                             write: val
                            }
        setworkspace(workspacecopy)
    }

    const setWorkspaceappRead = (val, index)=>{
        let apps = [...workspace.details.apps];
        apps[index] = {...apps[index],
                        read: val
                      }        
        let detailscopy = {...workspace.details,
                            apps: apps
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy);

    }

    const setWorkspaceappWrite = (val, index)=>{
        let apps = [...workspace.details.apps];
        apps[index] = {...apps[index],
                        write: val
                      }        
        let detailscopy = {...workspace.details,
                            apps: apps
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy);

    }

    const setWrokspacewebsiteRead = (val, index)=>{
        let websites = [...workspace.details.websites];
        websites[index] = {...websites[index],
                            read: val
                           }
        let detailscopy = {...workspace.details,
                            websites: websites
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy);

    }

    const setWorkspacewebsiteWrite = (val, index)=>{
        let websites = [...workspace.details.websites];
        websites[index] = {...websites[index],
                            write: val
                           }
        let detailscopy = {...workspace.details,
                            websites: websites
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy);
    }

    const setWorkspacefolderRead = (val, index)=>{
        let folders = [...workspace.details.folders];
        folders[index] = {...folders[index],
                          read: val
                         }
        let detailscopy = {...workspace.details,
                            folders: folders
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy)
    }

    const setWorkspacefolderWrite = (val, index)=>{
        let folders = [...workspace.details.folders];
        folders[index] = {...folders[index],
                          write: val
                         }
        let detailscopy = {...workspace.details,
                            folders: folders
                          }
        let workspacecopy = {...workspace,
                             details: detailscopy,
                             read: false,
                             write: false
                            }
        setworkspace(workspacecopy)
    }

    return (
        <div className="workspace-container">
            {workspaceopen?
                <div>
                    <div className="workspace-detail-item">
                        <div className="item-name">
                            {workspace.name}
                        </div>
                        <div>
                            <IconButton onClick={()=>{setWorkspaceOpen(false)}}>
                                <span className="material-symbols-outlined">
                                    keyboard_arrow_up
                                </span>
                            </IconButton>
                        </div>
                    </div>
                    {workspace.details!=undefined&&
                        <div className="workspace-detail-detail">
                            <div>
                                <div className="workspace-subitems">
                                    Apps
                                </div>
                                <div className="workspace-item-list">
                                    {workspace.details.apps.map((app, index)=>{
                                        return (
                                            <div className="workspace-item-detail">
                                                <div className="permission-select-box">
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={app.read} 
                                                            onChange={(e)=>{setWorkspaceappRead(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                            >
                                                        </input>
                                                        <span>Read</span>
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={app.write}
                                                            onChange={(e)=>{setWorkspaceappWrite(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                        >
                                                        </input>
                                                        <span>Write</span>
                                                    </div>
                                                </div>
                                                <div className="item-name">
                                                    {app.name}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="workspace-subitems">
                                    Websites
                                </div>
                                <div className="workspace-item-list">
                                    {workspace.details.websites.map((website, index)=>{
                                        return (
                                            <div className="workspace-item-detail">
                                                <div className="permission-select-box">
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={website.read}
                                                            onChange={(e)=>{setWrokspacewebsiteRead(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                        ></input>
                                                        <span>Read</span>
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={website.write}
                                                            onChange={(e)=>{setWorkspacewebsiteWrite(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                        ></input>
                                                        <span>Write</span>
                                                    </div>
                                                </div>
                                                <div className="item-name">
                                                    {website.name}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="workspace-subitems">
                                    Folders
                                </div>
                                <div className="workspace-item-list">
                                    {workspace.details.folders.map((folder, index)=>{
                                        return (
                                            <div className="workspace-item-detail">
                                                <div className="permission-select-box">
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={folder.read}
                                                            onChange={(e)=>{setWorkspacefolderRead(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                        ></input>
                                                        <span>Read</span>
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={folder.write}
                                                            onChange={(e)=>{setWorkspacefolderWrite(e.target.checked, index)}}
                                                            disabled={!disabled}
                                                        ></input>
                                                        <span>Write</span>
                                                    </div>
                                                </div>
                                                <div className="item-name">
                                                    {folder.path}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <div className="workspace-select">
                    <div className="workspace-details">
                        <div className="permission-select-box">
                            <div>
                                <input 
                                    type="checkbox" 
                                    checked={workspace.read} 
                                    onChange={(e)=>{setWorkspaceread(e.target.checked)}}
                                    disabled={!disabled}
                                ></input>
                                <span>Read</span>
                            </div>
                            <div>
                                <input 
                                    type="checkbox" 
                                    checked={workspace.write} 
                                    onChange={(e)=>{setWorkspacewrite(e.target.checked)}}
                                    disabled={!disabled}
                                ></input> 
                                <span>Write</span>
                            </div>
                        </div>
                        <div className="item-name">
                            {workspace.name}
                        </div>
                    </div>
                    <div>
                        <IconButton onClick={()=>{setWorkspaceOpen(true)}}>
                            <span className="material-symbols-outlined">
                                keyboard_arrow_down
                            </span>
                        </IconButton>
                    </div>
                </div>
            }
        </div>
    )
}


const CollaboratorDetail = ()=>{

    const {id} = useParams();

    const [initialized, setInitialized] = useState(false);

    const [create, setcreate] = useState(false);

    const [edit , setEdit] = useState(false);

    const [emailid, setEmailid] = useState("");

    const [editableWorkspace, setEditWorkspace] = useState([]);

    const [editabledomains, setEditabledomains] = useState([]);

    const collabworkspaces = useSelector(selectCollabWorkspaces);

    const collaborator = useSelector(selectCollaborator);

    const dispatch = useDispatch();

    const setEWorkspace = (index)=>{
        return (workspace)=>{
            let editableWorkspaceCopy = [...editableWorkspace];
            editableWorkspaceCopy[index] = workspace;
            setEditWorkspace(editableWorkspaceCopy);
        }
    }

    const [createclicked, setCreateclicked] = useState(false);

    const createCollaborationAction = ()=>{
        if(emailid==""){
            dispatch(showError("Please enter email to invite for collaboration"));
        }else{
            let payload = {
                "email": emailid,
                "permissionset": editableWorkspace,
                "domains": editabledomains
            }
            setCreateclicked(true);
            dispatch(resetcollaborationcreated());
            dispatch(createCollaboration(payload));
        }
    }

    const [updateclicked, setUpdateclicked] = useState(false);

    const updateCollaborationAction = ()=>{
        let payload ={
            "id": id,
            "permissionset": editableWorkspace,
            "domains": editabledomains
        }
        dispatch(resetcollaborationupdated());
        setUpdateclicked(true);
        dispatch(updateCollaboration(payload));
    }

    const collaborationupdated = useSelector(selectCollaborationupdated);

    useEffect(()=>{
        if(updateclicked&&collaborationupdated){
            dispatch(getCollaborator(id));
            setUpdateclicked(false);
            setEdit(false);
        }
    },[collaborationupdated])

    const collaborationcreated = useSelector(selectCollaborationcreated);

    const domains = useSelector(selectDomains);

    useEffect(()=>{
        if(createclicked&&collaborationcreated){
            backtocollaborators()
        }
    },[collaborationcreated, createclicked])

    useEffect(()=>{
        if(create){
            dispatch(getCollabWorkspaces());
            dispatch(getDomains());
        }else{
            dispatch(getCollabWorkspaces());
            dispatch(getDomains());
            if(id!=undefined){
                dispatch(getCollaborator(id));
            }
            
        }
        return ()=>{
            setEmailid("");
            setEditabledomains([]);
            setEditWorkspace([]);

        }
    },[create])

    useEffect(()=>{
        return ()=>{
            setEmailid("");
            setEditabledomains([])
            setEditWorkspace([])
        }
    },[])

    const mergeUserWorkspaces = (userworkspaces, allworkspaces)=>{
        for(let i=0; i<allworkspaces.length; i++){
            let workspace = allworkspaces[i];
            let userworkspaceindex = _.findIndex(userworkspaces, (uwk)=>{return uwk._id==workspace._id});
            if(userworkspaceindex>-1){
                let workspacedetails = {...workspace.details};
                let uwkdetails = {...userworkspaces[userworkspaceindex].details};
                let apps = workspacedetails.apps;
                let uapps = uwkdetails.apps;
                for(let j=0; j<apps.length; j++){
                    let appindex = _.findIndex(uapps, (app)=>{return apps[j]._id==app._id})
                    if(appindex>-1){
                        apps[j] = {...apps[j],
                                    read: uapps[appindex].read,
                                    write: uapps[appindex].write
                                  }
                    }
                }
                let websites = workspacedetails.websites;
                let uwebsites = uwkdetails.websites;
                for(let j=0; j<websites.length; j++){
                    let appindex = _.findIndex(uwebsites, (app)=>{return websites[j]._id==app._id})
                    if(appindex>-1){
                        websites[j] = {...websites[j],
                                    read: uwebsites[appindex].read,
                                    write: uwebsites[appindex].write
                                  }
                    }
                }
                let folders = workspacedetails.folders;
                let ufolders = uwkdetails.folders;
                for(let j=0; j<folders.length; j++){
                    let appindex = _.findIndex(ufolders, (app)=>{return folders[j]._id==app._id})
                    if(appindex>-1){
                        folders[j] = {...folders[j],
                                        read: ufolders[appindex].read,
                                        write: ufolders[appindex].write
                                    }
                    }
                }
                allworkspaces[i] = {...allworkspaces[i],
                                    details: workspacedetails,
                                    read: userworkspaces[userworkspaceindex].read,
                                    write: userworkspaces[userworkspaceindex].write
                                   }
            }
        }
    }

    useEffect(()=>{
        if(collaborator!={}&&collaborator!=null&&collaborator!=undefined){
            let collaboratorCopy = {...collaborator};
            setEmailid(collaboratorCopy.inivitationmail)
            let userworkspaces = JSON.parse(JSON.stringify(collaboratorCopy.permissionset));
            let collabworkspacescopy = JSON.parse(JSON.stringify(collabworkspaces));
            mergeUserWorkspaces(userworkspaces, collabworkspacescopy);
            setEditWorkspace(collabworkspacescopy);
        }
    },[collaborator, collabworkspaces])

    useEffect(()=>{
        if(collaborator!={}&&collaborator!=null&&collaborator!=undefined){
            let collaboratorCopy = {...collaborator};
            let userdomains = JSON.parse(JSON.stringify(collaboratorCopy.domains)); 
            let editabledomains  = JSON.parse(JSON.stringify(domains));
            for(let i=0; i< editabledomains.length; i++){
                let domainindex = _.findIndex(userdomains, (domain)=>{return domain.name==editabledomains[i].name});
                if(domainindex>-1){
                    editabledomains[i] = {...editabledomains[i],
                                          read: userdomains[domainindex].read
                                         }
                }
            }
            setEditabledomains(editabledomains);
        }
    },[collaborator, domains])

    useEffect(()=>{
        if(create){
            let domainsCopy = [...domains];
            setEditabledomains(domainsCopy);
        }
    },[domains])

    useEffect(()=>{
        if(create){
            let collabworkspacesCopy = [...collabworkspaces];
            setEditWorkspace(collabworkspacesCopy);
        }
    },[collabworkspaces])

    useEffect(()=>{
        if(id==undefined){
            setcreate(true);
            setEmailid("");
            setEditWorkspace([]);
            setEditabledomains([]);
        }else{
            
        }
    },[id])

    const navigate = useNavigate();

    const backtocollaborators = ()=>{
        dispatch(resetcollaborator());
        navigate("/settings/collaborators");
    }

    const setDomainRead = (index, val)=>{
        let editabledomainsCopy = [...editabledomains];
        editabledomainsCopy[index] = {...editabledomainsCopy[index],
                                      read: val
                                     }
        setEditabledomains(editabledomainsCopy)
    }

    return (
        <div className="collaboration-container">
            {createclicked&&!collaborationcreated&&
                <>
                    <LinearProgress></LinearProgress>
                </>
            }
            {updateclicked&&!collaborationupdated&&
                <>
                    <LinearProgress></LinearProgress>
                </>
            }
            <div className="collaboration-headers">
                <div>
                    <h4>
                        Collaboration Details
                    </h4>
                </div>
                {create?
                    <div style={{display: "flex"}}>
                        <div>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{createCollaborationAction()}}>Create</Button>
                        </div>
                        <div>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{backtocollaborators()}}>Cancel</Button>
                        </div>
                    </div>:
                    <>
                        {edit?
                            <div style={{display: "flex"}}>
                                <div>
                                    <Button style={{color: "#3A07CD"}} onClick={()=>{updateCollaborationAction()}}>Save</Button>
                                </div>
                                <div>
                                    <Button style={{color: "#3A07CD"}} onClick={()=>{setEdit(false)}}>Cancel</Button>
                                </div>
                            </div>:
                            <div style={{display: "flex"}}>
                                <div>
                                    <Button style={{color: "#3A07CD"}} onClick={()=>{setEdit(true)}}>Edit</Button>
                                </div>
                                <div>
                                    <Button style={{color: "#3A07CD"}} onClick={()=>{backtocollaborators()}}>Close</Button>
                                </div>
                            </div>
                        }
                    </>
                }
                  
            </div>
            <div>
                <TextField value={emailid} onChange={(e)=>{setEmailid(e.target.value)}} label="Enter email" disabled={!create}></TextField>
            </div>
            <div>
                <div>
                    <h3>Permissions</h3>
                </div>
                <div>
                    <div>
                        <h5>Workspaces</h5>
                    </div>
                    {editableWorkspace.map((workspace, index)=>{
                        return(
                            <WorkspaceBlock
                                workspace={workspace}
                                create={create}
                                setworkspace={setEWorkspace(index)}
                                disabled = {create||edit}
                            >
                            </WorkspaceBlock>
                        )
                    })}
                </div>
                <div>
                    <div>
                        <h5>Domains</h5>
                    </div>
                    <div>
                        {editabledomains.map((domain, index)=>{
                            return (
                                <div className="permission-select-box">
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            checked={domain.read} 
                                            onChange={(e)=>{setDomainRead(index, e.target.checked)}}
                                            disabled= {!(create||edit)}
                                        ></input>
                                    </div>
                                    <div>
                                        {domain.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CollaboratorDetail;