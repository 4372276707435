import { Button, IconButton } from "@mui/material";
import Folder from "../../Images/folder.svg";
import CreateFolderModal from "./FolderCreateModal";
import FolderView from "./FolderView";
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useState } from "react";
import { changePrivacy, deleteFolder, getFolders , selectFolderWorkspacepermission, selectFolders} from "../../Services/filesystem/filesystem.slice";
import "./FileSystem.css";
import BreadCrumbs from "../BreadCrumbs";
import { useParams } from "react-router-dom";

const FileSystem = ({pipelinemode, setSchemaMappingData, schemaMapping, pipelinestage})=>{

    const {workspace} = useParams();

    const [openCreateFolder, setOpenCreateFolder] = useState(false);

    const [folderOpen, setFolderOpen] = useState("");

    const [folderId, setFolderId] = useState("");

    const dispatch = useDispatch();

    const folders = useSelector(selectFolders);

    const [viewinit, setviewinit] = useState(false);

    const folderworkspacepermission = useSelector(selectFolderWorkspacepermission);

    useEffect(()=>{
        dispatch(getFolders(workspace));
    },[])

    useEffect(()=>{
        if(pipelinemode){
            if(schemaMapping.mapping.val!=undefined||schemaMapping.mapping.val!=""||schemaMapping.mapping.val!=null){
                if(schemaMapping.mapping.inputtype==2){
                    if(!viewinit){
                        if(schemaMapping.mapping.val.path!=folderOpen){
                            setFolderOpen(schemaMapping.mapping.val.path);
                        }
                        setviewinit(true);
                    }
                }
            }
        }
    },[[pipelinemode,schemaMapping]])

    const handleCloseCreate = ()=>{
        setOpenCreateFolder(false);
    }

    const openFolder = (path, folderId)=>{
        setFolderOpen(path);
    }

    const closeFolder = ()=>{
        setFolderOpen("");
    }

    const changePrivacyAction = (id,privacy)=>{
        dispatch(changePrivacy({
            id: id,
            private: privacy,
            workspace: workspace
        }))
    }

    const deleteFolderAction = (id)=>{
        dispatch(deleteFolder({id:id, workspace: workspace}));
    }

    return (
        <>
            {!pipelinemode&&
                <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?
                        [{
                        "name": "dashboard",
                        "url": "/"
                        }]:
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "workspace",
                                "url": "/workspaces/"+workspace
                            }
                        ]
                }
                >
                </BreadCrumbs>
            
            }
            <div style={{padding: 10}}>
                {folderOpen==""?
                    <div>
                        {!pipelinemode&&
                            <div style={{display: "flex", "justifyContent": "flex-end", "padding": 5, "marginRight": 5}}>
                                {folderworkspacepermission=="write"&&
                                    <Button style={{color: "#3A07CD"}} onClick={()=>{setOpenCreateFolder(true)}}>Create</Button>
                                }
                            </div>
                        }
                        <div className="filesystem-wrapper">
                            {folders.map((folder)=>{
                                return(
                                    <>
                                        <div style={{textAlign: "center" , cursor: "pointer"}}>
                                            <div onClick={()=>{openFolder(folder.path)}}>
                                                <img style={{width:100, height:100}} src={Folder}></img>
                                            </div>
                                            <div>
                                                <div>
                                                    <a style={{textDecoration: "underline", color: "#3A07CD"}} onClick={()=>{openFolder(folder.path, folder._id)}}>{folder.path}</a>
                                                </div>
                                                <div>
                                                    {folderworkspacepermission=="write"&&
                                                        <IconButton onClick={()=>{deleteFolderAction(folder._id)}}>
                                                            <span className="material-symbols-outlined">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    }
                                                    {folderworkspacepermission=="write"?
                                                        <>
                                                            <IconButton onClick={()=>{changePrivacyAction(folder._id, true)}}>
                                                                <span className={folder.private?"material-symbols-outlined active":"material-symbols-outlined"}>
                                                                    lock
                                                                </span>
                                                            </IconButton>
                                                            <IconButton onClick={()=>{changePrivacyAction(folder._id, false)}}>
                                                                <span className={folder.private?"material-symbols-outlined":"material-symbols-outlined active"}>
                                                                    public
                                                                </span>
                                                            </IconButton>
                                                            
                                                        </>:
                                                        <>
                                                            {folder.private?
                                                                <IconButton disabled>
                                                                    <span className="material-symbols-outlined active">
                                                                        lock
                                                                    </span>
                                                                </IconButton>:
                                                                <IconButton disabled>
                                                                    <span className="material-symbols-outlined active">
                                                                        public
                                                                    </span>
                                                                </IconButton>
                                                            }
                                                        </>                                                 
                                                    }
                                                </div>
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>:
                    <>
                        <FolderView
                            path={folderOpen}
                            folderId = {folderId}
                            handleClose={closeFolder}
                            pipelinemode={pipelinemode}
                            setSchemaMappingData={setSchemaMappingData}
                            schemaMapping={schemaMapping}
                            pipelinestage={pipelinestage}
                        >
                        </FolderView>
                    </>
                }  
                <CreateFolderModal
                    open={openCreateFolder}
                    handleClose={handleCloseCreate}
                >
                </CreateFolderModal>

            </div>
        </>
        
    )
}


export default FileSystem;