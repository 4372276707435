import React from 'react';
import { 
            Modal, 
            Box, 
            Button, 
            Typography, 
            IconButton 
        } from '@mui/material';
import PlatformManifestdetails from './PlatformManifestdetails';

const PlatformManifestModal = ({ 
                                open, 
                                handleClose, 
                                appName, 
                                iconSrc, 
                                onInstall, 
                                onPreview 
                               }) => {
  return (
    <div>
      {/* MUI Modal Component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 200,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <IconButton
                onClick={()=>{handleClose()}}
            >
                <span className='material-symbols-outlined'>
                    close
                </span>
            </IconButton>
          </div>
          {/* Pass the manifestid prop to MarketManifestDetails component */}
          <PlatformManifestdetails
            appName={appName}
            iconSrc={iconSrc}
            onInstall={onInstall}
            onPreview={onPreview}
          >
          </PlatformManifestdetails>
        </Box>
      </Modal>
    </div>
  );
};

export default PlatformManifestModal;