import { useState, useEffect } from "react";
import BreadCrumbs from "../BreadCrumbs";
import "./Androidapp.css";
import { 
        IconButton, 
        TextField, 
        Button, 
        TableContainer, 
        TableHead, 
        TableRow, 
        TableCell,
        TableBody,
        Badge,
        Modal,
        Box
    } from "@mui/material";


import { useDispatch , useSelector} from "react-redux";
import { useNavigate, useParams,  } from "react-router-dom";

import DeclareVariable from "../apps/Fields/DeclareVariable";
import { 
        createAppscreen, 
        createKeystore, 
        getAppCreateOption, 
        getAppDetails, 
        getAppScreens, 
        pollBuildStatus, 
        resetappscreencreated, 
        resetappupdated, 
        resetkeystorecreated, 
        saveLauncherIcon, 
        selectAndroidAabs, 
        selectAndroidApks, 
        selectAndroidAppDetails, 
        selectAndroidAppScreens, 
        selectAppCreateOptions, 
        selectAppUpdated, 
        selectAppscreenCreated, 
        selectKeystorecreated, 
        updateAndroidApp,
        startAndroidBuild, 
        getAndroidBuilds
        } from "../../Services/androidapps/android.slice";
import * as _ from "lodash";
import { showError } from "../../Services/error.slice";
import CircularLoader from "../utils/CircularLoader";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


const AndroidTemplate = ({open, handleClose})=>{
    const dispatch = useDispatch();

    const [createmode, setCreateMode] = useState("all");

    const [templatename, setTemplateName] = useState("");

    const [tempateid, setTemplateid] = useState(null);

    const [websiteid, setWebsiteid] = useState(null);

    const [templates, setTemplates] = useState([]);

    const {id} = useParams();

    const [saveclicked, setSaveClicked] = useState(false);

    const websitecreateoption = useSelector(selectAppCreateOptions);

    useEffect(()=>{
        dispatch(getAppCreateOption())
    },[])

    

    const moveback = ()=>{
        if(createmode=="app"){
            setWebsiteid(null);
            setTemplates([]);
            setCreateMode("all");
        }else if(createmode=="new"){
            setTemplateName("");
            setCreateMode("all")
        }else if(createmode=="appscreen"){
            setTemplateid(null);
            setTemplateName("");
            setCreateMode("app");
        }
    }

    const moveMode = (mode, data)=>{
        if(mode=="app"){
            setWebsiteid(data);
            let websiteobjIndex = _.findIndex(websitecreateoption, (wco)=>{return wco._id==data});
            if(websiteobjIndex>-1){
                let templatesCopy = [...websitecreateoption[websiteobjIndex].templates];
                setTemplates(templatesCopy);
            }
            setCreateMode("app");
        }else if(mode=="new"){
            setCreateMode("new")
        }else if(mode=="template"){
            setTemplateid(data);
            setCreateMode("template");
        }
    }

    const save = ()=>{
        if(createmode=="new"){
            let payload = {
                "type": "new",
                "source": id,
                "name": templatename
            }
            setSaveClicked(true);
            dispatch(resetappscreencreated());
            dispatch(createAppscreen(payload));
        }else if(createmode=="app"){
            let payload = {
                "type": "template",
                "source": id,
                "name": templatename,
                "templateid": tempateid
            }
            setSaveClicked(true);
            dispatch(resetappscreencreated());
            dispatch(createAppscreen(payload));
        }
    }


    const templatecreated = useSelector(selectAppscreenCreated);


    useEffect(()=>{
        if(templatecreated&&saveclicked){
            setSaveClicked(false);
            setCreateMode("all");
            setTemplateName("");
            setTemplateid(null);
            setWebsiteid(null);
            handleClose()
        }
    },[saveclicked, templatecreated])
    
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <h4>Create Template</h4>
                            {createmode!="all"&&
                                <IconButton onClick={()=>{moveback()}}>
                                    <span className="material-symbols-outlined">
                                        arrow_back
                                    </span>
                                </IconButton>
                            }
                        </div>
                        <div>
                            <IconButton onClick={()=>{handleClose()}}>
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </IconButton>
                        </div>
                        
                    </div>
                    {createmode=="all"&&
                        <div className="website-create-conatiner">
                            <div style={{textAlign: "center" , cursor: "pointer"}} onClick={()=>{moveMode("new")}}>
                                <div>
                                    <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                        create_new_folder
                                    </span>
                                </div>
                                <div>
                                    New
                                </div>
                            </div>
                            {websitecreateoption?.map((wco)=>{
                                return (
                                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode(wco.type, wco._id)}}>
                                        <div>
                                            {wco.type=="template"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder
                                                </span>
                                            }
                                            {wco.type=="website"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder_open
                                                </span>

                                            }
                                        </div>
                                        <div>
                                            {wco.name}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {createmode=="template"&&
                        <>
                            <div>
                                <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                            </div>
                            <div style={{"display": "flex", "justifyContent": "center"}}>
                                <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                    Save
                                </Button>
                            </div>
                        </>
                    }
                    {createmode=="new"&&
                        <>
                            <div>
                                <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                            </div>
                            <div style={{"display": "flex", "justifyContent": "center"}}>
                                <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                    Save
                                </Button>
                            </div>
                        </>
                    }
                    {createmode=="website"&&
                        <div className="website-create-conatiner">
                            {templates?.map((t)=>{
                                return(
                                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode(t.type, t._id)}}>
                                        <div>
                                            {t.type=="template"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder
                                                </span>
                                            }
                                            {t.type=="website"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder_open
                                                </span>

                                            }
                                        </div>
                                        <div>
                                            {t.name}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {createmode=="websitetemplate"&&
                        <>
                            <div>
                                <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                            </div>
                            <div style={{"display": "flex", "justifyContent": "center"}}>
                                <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                    Save
                                </Button>
                            </div>
                        </>

                    }
                    
                </Box>

            </Modal>
        </>
    )
}


const UploadKeystoreModal = ({open, handleClose})=>{

    const [alias, setAlias] = useState("");

    const [password, setPassword] = useState("");

    const [verifypassword, setVerifyPassword] = useState("");

    const [keystorestring, setkeystorestring] = useState("");

    const [saveclicked, setSaveclicked] = useState(false);

    // const [keystorefile, setkeystorefile] = useState(null);
    const {id} = useParams();

    const dispatch = useDispatch();

    const setKeystore = (e)=>{
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = ()=>{
            let resString = reader.result;
            let base64 = btoa(resString); 
            console.log(base64);
            setkeystorestring(base64);
        }
        reader.readAsBinaryString(file);
    }

    const save = ()=>{
        if(keystorestring!=""){
            if(password!=""&&password==verifypassword){
                setSaveclicked(true);
                dispatch(resetkeystorecreated());
                dispatch(createKeystore({
                    "appid": id,
                    "keystore": keystorestring,
                    "keystorepassword": password,
                    "alias": alias
                }))
            }else{
                dispatch(showError("Password is not same"));
            }
            
        }else{
            dispatch(showError("No file selected"));        
        }
    }

    const keystorecreated = useSelector(selectKeystorecreated);

    useEffect(()=>{ 
        if(saveclicked&&keystorecreated){
            setSaveclicked(false);
            handleClose();
        }
    },[keystorecreated, saveclicked])


    
    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <Box sx={style}>
                <div>
                    <div className="uploadkeystoreitems">
                        <TextField value={alias} onChange={(e)=>{setAlias(e.target.value)}} label="Alias"></TextField>
                    </div>
                    <div className="uploadkeystoreitems">
                        <TextField type="password" label="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}></TextField>
                    </div>
                    <div className="uploadkeystoreitems">
                        <TextField label="Confirm Password" type="password" value={verifypassword} onChange={(e)=>{setVerifyPassword(e.target.value)}}></TextField>
                        {password!=""&&password==verifypassword?
                            <span className="material-symbols-outlined active">
                                check
                            </span>:
                            <span className="material-symbols-outlined error">
                                close
                            </span>
                        }
                    </div>
                    <div className="uploadkeystoreitems">
                        keystore: <input type="file" onChange={(e)=>{setKeystore(e)}}></input>
                    </div>
                </div>
                <div style={{display: "flex", "padding": 10}}>
                    <div>
                        <Button onClick={()=>{handleClose()}}>Cancel</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{save()}}>Yes</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}


const BuildErrorModal = ({open, handleClose, error})=>{
    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <Box sx={style}>
                <div style={{padding: 10}}>
                    {error}
                </div>
                <div style={{textAlign: "center"}}>
                    <Button onClick={()=>{handleClose()}}>Close</Button>
                </div>
            </Box>
        </Modal>
    )
}


const Fileversions = ({data})=>{
    
    const [openhistory, setHistoryOpen] = useState(false);

    const [error, setError] = useState("");

    const [openerror, setOpenError] = useState(false);

    const closeError = ()=>{
        setError("");
        setOpenError(false);
    }

    const setOpenErrorAction = (error)=>{
            setError(error);
            setOpenError(true);
    }

    return (
        <>
            {data!=undefined&&data!==null&&
                <div className="version-conatiner">
                    {data?.length>0&&
                        <div style={{display: "flex", alignItems: "center"}}>
                            {data[0]?.status==0&&
                                <div>
                                    <a href={data[0].file} target="_blank">{data[0].version}</a>
                                </div>
                            }
                            {data[0].status==1&&
                                <div style={{color: "red", textDecoration: "underline", cursor: "pointer"}} onClick={()=>{setOpenErrorAction(data[0].error)}}>
                                    <span>{data[0].version}</span>
                                </div>
                            }
                            <div>
                                {openhistory?
                                    <IconButton onClick={()=>{setHistoryOpen(false)}}>
                                        <span className="material-symbols-outlined">
                                            expand_less
                                        </span>
                                    </IconButton>:
                                    <IconButton onClick={()=>{setHistoryOpen(true)}}>
                                        <span className="material-symbols-outlined">
                                            expand_more
                                        </span>
                                    </IconButton>
                                }
                            </div>
                        </div>
                    }
                    {openhistory&&
                        <div className="history-container">
                            {data.map((d)=>{
                                return(
                                    <>
                                        {d?.status==0&&
                                            <div>
                                                <a href={d.file} target="_blank">{d.version}</a>
                                            </div>
                                        }
                                        {d?.status==1&&
                                            <div onClick={()=>{setOpenErrorAction(d.error)}} style={{color: "red", textDecoration: "underline", cursor: "pointer"}}>
                                                <span>{d.version}</span>
                                            </div>
                                        }       
                                    </>
                                )
                            })}
                        </div>
                    }
                    <BuildErrorModal
                        open={openerror}
                        handleClose={closeError}
                        error={error}
                    >
                    </BuildErrorModal>
                </div>
            }
            
        </>
    )
}

const AndroidApp = ()=>{

    const [openCreate, setOpenCreate] = useState(false);

    const [edit, setEdit] = useState(false);

    const [appdetails, setAppdetails] = useState(undefined);

    const [launcherIconEdit, setLauncherIconEdit] = useState(false);

    const [splashcreenEdit, setSplashscreenEdit] = useState(false);

    const [librariesEdit, setLibrariesEdit] = useState(false);

    const [keystoreCreate, setKeystorecreate] = useState(false);

    const [launcherIconName, setLauncherIconName] = useState("");

    const apkbuilds = useSelector(selectAndroidApks);

    const aabbuilds = useSelector(selectAndroidAabs);

    // const [apkbuilds, setApkBuilds] = useState([
    //     {
    //         "version": "v1.0",
    //         "status": 0,
    //         "file": "http://localhost:9090/uploads/app-release-debug.apk",
    //         "error": ""
    //     },
    //     {
    //         "version": "v1.1",
    //         "status": 1,
    //         "file":[],
    //         "error": "this is the test error"
    //     }
    // ])

    const navigate = useNavigate();
    
    const appdetailsremote = useSelector(selectAndroidAppDetails);

    useEffect(()=>{
        if(appdetailsremote==null||appdetailsremote==undefined){
            return
        }
        if(appdetailsremote.buildstatus==1){
            dispatch(pollBuildStatus(appdetailsremote._id));
        }
        let appdetailscopy = {...appdetailsremote};
        setAppdetails(appdetailscopy);
        dispatch(getAndroidBuilds({"appid": id, "type": "prod"}));
        dispatch(getAndroidBuilds({"appid": id, "type": "debug"}));
    },[appdetailsremote])

    const screens = useSelector(selectAndroidAppScreens)

    const dispatch = useDispatch();

    const {id, workspace} = useParams();

    const closeDetails = ()=>{
        navigate("/"+workspace+"/androidapps/");
    }

    const closeOpenCreate =()=>{
        setOpenCreate(false);
    }

    const openTemplateEditor = (templateid)=>{
        navigate("/"+workspace+"/appdesigner/android/"+id+"/"+templateid);
    }

    const deployScreen = ()=>{

    }

    const deleteScreenAction = ()=>{

    }

    const appupdated = useSelector(selectAppUpdated);

    const setAndroidAppName = (appname)=>{
        let appdetailscopy = {...appdetails,
                              name: appname
                             };
        setAppdetails(appdetailscopy);
        
    }

    const setPackageName = (packagename)=>{
        let appdetailscopy = {...appdetails,
                              packagename: packagename
                             }
        setAppdetails(appdetailscopy)
    }

    const changeLauncherIcon = (e)=>{
        let file = e.target.files[0];
        if(file!=undefined){
            let filedetails = file.name;
            let fileurl = URL.createObjectURL(file);
            let image = new Image();
            image.src = fileurl;
            image.onload = ()=>{
                if((image.width==512&&image.height==512)){
                    setLauncherIconName(filedetails);
                    let appdetailsCopy = {...appdetails,
                                          icon: fileurl
                                         };
                    setAppdetails(appdetailsCopy);

                }else{
                    dispatch(showError("Favicon is not valid"));
                }
            }
        }
    }

    const [iconsaveClicked, setIconsaveClicked] = useState(false);

    const saveLauncherIconAction = ()=>{
        let payload = {
            appdetails: appdetails,
            iconname: launcherIconName
        }
        dispatch(resetappupdated());
        setIconsaveClicked(true);
        dispatch(saveLauncherIcon(payload));
    }

    useEffect(()=>{
        if(appupdated, iconsaveClicked){
            setIconsaveClicked(false);
            setLauncherIconEdit(false);
        }
    },[appupdated, iconsaveClicked])

    const changeSplashScreen = ()=>{

    }

    const saveSplashScreen = ()=>{

    }

    const modifySchema = (sc, scm)=>{
        let settingsCopy = {...appdetails.settings};
        settingsCopy = {...settingsCopy,
                        schema: sc,
                        schemamapping: scm
                        }
        let appdetailscopy = {...appdetails,
                              settings: settingsCopy
                             }
        dispatch(updateAndroidApp(appdetailscopy));
        setAppdetails(appdetailscopy);
    }

    useEffect(()=>{
        if(id!=undefined){
            dispatch(getAppDetails(id));
            dispatch(getAppScreens(id));
        }
    },[id])

    const [saveclicked, setSaveClicked] = useState(false);

    const save = ()=>{
        let appdetailsCopy = {...appdetails};
        setSaveClicked(true);
        dispatch(resetappupdated());
        dispatch(updateAndroidApp(appdetailsCopy))
    }

    useEffect(()=>{
        if(saveclicked&&appupdated){
            setSaveClicked(false);
            setEdit(false);
        }
    },[saveclicked, appupdated])

    const buildAction = (type)=>{
        if(appdetails.icon==""){
            dispatch(showError("Upload icon for building the application"))
            return
        }
        if(appdetails.keystore==""){
            dispatch(showError("Upload a keystore for building the application"))
            return
        }
        dispatch(startAndroidBuild({
            type: type,
            appid: id
        }))
    }

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {
                        "name": "interfaces",
                        "url": "/interfaces"
                    },
                    {
                        "name": "androidapps",
                        "url": "/androidapps"
                    }
                ]}
            >
            </BreadCrumbs>
            <div style={{
                            display: "flex", 
                            justifyContent: "flex-end", 
                            marginRight: "10px"
                        }}>
                <Button sx={{color: "#3A07CD"}} onClick={()=>{closeDetails()}}>
                    Close
                </Button>
            </div>
            <div className="androidapp-detail-box">
                <div className="androidapp-detail-section">
                    <h4>Screens</h4>
                    <div className="androidapp-action-headers">
                        <Button sx={{color: "#3A07CD"}} onClick={()=>{setOpenCreate(true)}}>Create</Button>
                    </div>
                    <div>
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Updated At</TableCell>
                                    <TableCell>DeployedAt</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {screens.map((screen)=>{
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {screen._id==appdetails?.mainscreen?
                                                    <Badge badgeContent="main">
                                                        <a style={{"cursor": "pointer"}} onClick={()=>{openTemplateEditor(screen._id)}}>{screen.name}</a>    
                                                    </Badge>:
                                                    <a style={{"cursor": "pointer"}} onClick={()=>{openTemplateEditor(screen._id)}}>{screen.name}</a>
                                                }
                                            </TableCell>
                                            <TableCell>{screen.type}</TableCell>
                                            <TableCell>{screen.createdAt}</TableCell>
                                            <TableCell>{screen.updatedAt}</TableCell>
                                            <TableCell>{screen.deployedAt}</TableCell>
                                            <TableCell>
                                                <Button sx={{color: "#3A07CD"}} onClick={()=>{deployScreen(screen._id)}}>
                                                    Deploy
                                                </Button>
                                                {screen._id!=appdetails?.mainscreen&&
                                                    <IconButton onClick={()=>{deleteScreenAction(screen._id)}}>
                                                        <span className="material-symbols-outlined">
                                                            delete
                                                        </span>
                                                    </IconButton>
                                                }
                                            </TableCell>
                                    </TableRow>
                                    )
                                })}
                            </TableBody>
                        </TableContainer>
                    </div>
                </div>
                <div className="androidapp-detail-section">
                    <h4>Settings</h4>
                    <div className="androidapp-action-headers">
                        {edit?
                            <Button onClick={()=>{save()}} sx={{color: "#3A07CD"}}>Save</Button>:
                            <Button onClick={()=>{setEdit(true)}} sx={{color: "#3A07CD"}}>Edit</Button>
                        }            
                    </div>
                    {appdetails!=null&&appdetails!=undefined&&
                        <div>
                            {edit?
                                <div className="androidapp-details-row">
                                    <TextField
                                        fullWidth 
                                        value={appdetails?.name} 
                                        onChange={(e)=>{setAndroidAppName(e.target.value)}}
                                        label="App name"    
                                    ></TextField>
                                </div>:
                                <div className="androidapp-details-row">
                                    <div className="androidapp-details-row-item">
                                        Name: 
                                    </div>
                                    <div className="androidapp-details-row-item">
                                        {appdetails?.name}
                                    </div>
                                </div>
                            }
                            {edit?
                                <div className="androidapp-details-row">
                                    <TextField
                                        fullWidth
                                        value={appdetails?.packagename}
                                        onChange={(e)=>{setPackageName(e.target.value)}}
                                        label="App Package Name"                                    
                                    >
                                    </TextField>
                                </div>:
                                <div className="androidapp-details-row">
                                    <div className="androidapp-details-row-item">
                                        Package Name:
                                    </div>
                                    <div className="androidapp-details-row-item">
                                        {appdetails?.packagename}
                                    </div>
                                </div>
                            }
                            <div className="androidapp-details-row">
                                <div className="androidapp-details-row-item">
                                    Keystore:
                                </div>
                                <div className="androidapp-details-row-item">
                                    {appdetails?.keystore}
                                </div>
                                <div className="androidapp-details-row-item">
                                    <Button sx={{color: "#3A07CD"}} onClick={()=>{setKeystorecreate(true)}}>Upload New</Button>
                                </div>
                            </div>
                            <div className="androidapp-details-row">
                                <div className="androidapp-details-row-item">
                                    Apk Build
                                </div>
                                <div className="androidapp-details-row-item">
                                    <Fileversions
                                        data={apkbuilds}
                                    >
                                    </Fileversions>
                                </div>
                                <div className="androidapp-details-row-item">
                                    {/* <Button >build</Button> */}
                                    {appdetails?.buildstatus==1?
                                        <CircularLoader>
                                        </CircularLoader>:
                                        <Button sx={{color: "#3A07CD"}} onClick={()=>{buildAction("debug")}}>
                                            build
                                        </Button>
                                    }
                                    
                                </div>
                            </div>
                            <div className="androidapp-details-row">
                                <div className="androidapp-details-row-item">
                                    Aab Builds
                                </div>
                                <div className="androidapp-details-row-item">
                                    <Fileversions
                                        data={aabbuilds}
                                    >
                                    </Fileversions>
                                </div>
                                <div className="androidapp-details-row-item">
                                    {appdetails?.buildstatus==1?
                                        <CircularLoader>
                                        </CircularLoader>:
                                        <Button sx={{color: "#3A07CD"}} onClick={()=>{buildAction("prod")}}>
                                            build
                                        </Button>
                                    }
                                </div>
                            </div>
                            <div>
                                <h5>
                                    Settings/env
                                </h5>
                                <div className="androidapp-settings">
                                    <div className="androidapp-settings-item">
                                        <h6>
                                            Launcher Icon
                                        </h6>
                                        <div style={{fontSize:"8px"}}>
                                            512x512
                                        </div>
                                    </div>
                                    <div style={{display: "flex", "alignItems": "center"}}>
                                        <div style={{textAlign: "center", padding: "10px"}}>
                                            <img style={{width: 50, height: 50}} src={appdetails?.icon}></img>
                                        </div>

                                        {launcherIconEdit&&
                                            <>
                                                <input type="file" accept=".png" onChange={(e)=>{changeLauncherIcon(e)}}></input>
                                            </>
                                        }
                                        {launcherIconEdit?
                                            <>
                                                <IconButton
                                                    onClick={()=>{saveLauncherIconAction()}}
                                                >
                                                    <span className="material-symbols-outlined">
                                                        done
                                                    </span>
                                                </IconButton>
                                                <IconButton
                                                    onClick={()=>{setLauncherIconEdit(false)}}
                                                >
                                                    <span className="material-symbols-outlined">
                                                        close
                                                    </span>
                                                </IconButton>
                                            </>:
                                            <>
                                                <IconButton
                                                    onClick={()=>{setLauncherIconEdit(true)}}
                                                >
                                                    <span className="material-symbols-outlined">
                                                        edit
                                                    </span>
                                                </IconButton>
                                            </>
                                        }
                                    </div>
                                    {/* <div>

                                        {appdetails!=null&&appdetails!=undefined&&
                                            <>
                                                {appdetails?.icons.map((icon, index)=>{
                                                    return (
                                                        
                                                    )
                                                })}   
                                            </>
                                        }
                                    </div> */}
                                    {/* <div style={{display: "flex", "alignItems": "center"}}>
                                        <>
                                            {appdetails.icons.map((icon, index)=>{
                                                return (
                                                    <div style={{display: "flex", "alignItems": "center"}}>
                                                        {icon.value!=""&&
                                                            <div style={{textAlign: "center", padding: "10px"}}>
                                                                <img style={{width: 50, height: 50}} src={icon.value}></img>
                                                            </div>
                                                        }
                                                        {launcherIconEdit[index].value&&
                                                            <>
                                                                <input type="file" accept=".png" onChange={(e)=>{changeLauncherIcon(e, index)}}></input>
                                                            </>
                                                        }
                                                        {launcherIconEdit[index].value&&
                                                            <>
                                                                <IconButton
                                                                    onClick={()=>{saveLauncherIconAction()}}

                                                                >

                                                                </IconButton>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                )
                                            })}
                                        </>
                                        {appdetails.launchericon!=""&&
                                            <div style={{textAlign: "center", padding: "10px"}}>
                                                <img style={{width: 50, height: 50}} src={appdetails.launchericon}></img>
                                            </div>
                                            
                                        }
                                        {launcherIconEdit&&
                                            <>
                                                <input type="file" accept=".png" onChange={(e)=>{changeLauncherIcon(e)}}></input>
                                            </>
                                        }
                                    </div> */}
                                    {/* <div>
                                        {launcherIconEdit?
                                            <>
                                                <IconButton onClick={()=>{saveLauncherIconAction()}}>
                                                    <span className="material-symbols-outlined">
                                                        done
                                                    </span>
                                                </IconButton>
                                                <IconButton onClick={()=>{setLauncherIconEdit(false)}}>
                                                    <span className="material-symbols-outlined">
                                                        close
                                                    </span>
                                                </IconButton>
                                            </>:
                                            <>
                                                <IconButton onClick={()=>{setLauncherIconEdit(true)}}>
                                                    <span className="material-symbols-outlined">
                                                        edit
                                                    </span>
                                                </IconButton>
                                            </>
                                        }
                                    </div> */}
                                </div>
                                <div className="androidapp-settings">
                                    <div className="androidapp-settings-item">
                                        <h6>
                                            Splash Screen
                                        </h6>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {appdetails.splashcreen!=""&&
                                            <div style={{textAlign: "center", padding: "10px"}}>
                                                <img style={{width: 30, height: 100}} src={appdetails.splashscreen}></img>
                                            </div>
                                        }
                                        {splashcreenEdit&&
                                            <>
                                                <input type="file" accept=".png" onChange={(e)=>{changeSplashScreen(e)}}></input>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div style={{"display": "flex", "justifyContent": "space-between"}}>
                                        <div>
                                            <h6>
                                                Libraries
                                            </h6>
                                        </div>
                                        <div>
                                            {librariesEdit?
                                                <>
                                                    <IconButton>
                                                        <span className="material-symbols-outlined">
                                                            done
                                                        </span>
                                                    </IconButton>
                                                    <IconButton onClick={()=>{setLibrariesEdit(false)}}>
                                                        <span className="material-symbols-outlined">
                                                            close
                                                        </span>
                                                    </IconButton>   
                                                </>:
                                                <>
                                                    <IconButton onClick={()=>{setLibrariesEdit(true)}}>
                                                        <span className="material-symbols-outlined">
                                                            edit
                                                        </span>
                                                    </IconButton>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <DeclareVariable
                                        schema={appdetails?.settings?.schema} 
                                        modifySchema={modifySchema}
                                        schemamapping={appdetails?.settings?.schemamapping} 
                                        pipelinetype={""}
                                        stageindex={0}
                                        stagetype={"single"}
                                        layout={"vertical"}
                                        pipelinestage={undefined}
                                        source={"apps"}
                                    >
                                    </DeclareVariable>
                                </div>
                            </div>
                        </div>
                    }        
                </div>
            </div>
            <AndroidTemplate
                open={openCreate}
                handleClose={closeOpenCreate}
            >
            </AndroidTemplate>
            <UploadKeystoreModal
                open={keystoreCreate}
                handleClose={()=>{setKeystorecreate(false)}}
            >
            </UploadKeystoreModal>
        </div>
    )

}

export default AndroidApp;