import { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import { Button, IconButton , Modal, Box, TextField, Typography} from "@mui/material";
import { showError } from "../../Services/error.slice";
import { useDispatch, useSelector } from "react-redux";
import { createAndroidApp, resetappcreated, selectAppCreated, selectAndroidApps, getAndroidApplist, resetappdeleted, deleteApp, selectAppdeleted } from "../../Services/androidapps/android.slice";
import usePagination from "@mui/material/usePagination/usePagination";
import { useParams , Link} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
const CreateAndroidApp = ({open, handleClose})=>{

    const [appname, setAppName] = useState("");

    const [packageName, setPackageName] = useState("");

    const [saveClicked, setSaveClicked] = useState(false);

    const dispatch = useDispatch();

    const validatepackagename = (pkname)=>{
        let valid = true
        if(pkname==""){
            valid = false
            dispatch(showError("Package name cannot be empty"));
        }else{
            let segments = pkname.split(".");
            if(segments.length>=2){
                for(let i=0; i< segments.length; i++){
                    let letterregex = new RegExp("[A-Za-z]");
                    let firstchar = segments[i][0];
                    valid = letterregex.test(firstchar);
                    if(valid){
                        let segementregex = new RegExp("[a-zA-Z0-9_]+");
                        valid = segementregex.test(segments[i]);
                        if(valid==false){
                            dispatch(showError("All characters must be alphanumeric or an underscore [a-zA-Z0-9_]"));
                        }
                    }else{
                        dispatch(showError("Each segment must start with a letter."));
                    }
                }
            }else{
                dispatch(showError("Package name should have at least two segments (one or more dots)."))
            }
            
        }
        return valid;
    }

    const save = ()=>{
        let valid = validatepackagename(packageName);
        if(valid){
            if(appname!=""){
                setSaveClicked(true);
                dispatch(resetappcreated());
                dispatch(createAndroidApp({
                    name: appname,
                    packagename: packageName
                }));
            }else{
                dispatch(showError("App name cannot be empty"));
            }
        }
    }

    const appcreated = useSelector(selectAppCreated);

    useEffect(()=>{
        if(saveClicked&&appcreated!=null){
            handleClose();
        }
    },[appcreated, saveClicked])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{padding: 10}}>
                        <TextField label="Application Name" value={appname} onChange={(e)=>{setAppName(e.target.value)}}>
                        </TextField>
                    </div>
                    <div style={{padding: 10}}>
                        <TextField label="Application package name" value={packageName} onChange={(e)=>{setPackageName(e.target.value)}}>
                        </TextField>
                    </div>
                    <div style={{display: "flex"}}>
                        <Button onClick={()=>{handleClose()}}>Cancel</Button>
                        <Button onClick={()=>{save()}}>Save</Button>
                    </div>
                </Box>
            </Modal>
        </>
    )

}


const DeleteAppModal = ({open, handleClose, app})=>{

    const dispatch = useDispatch();

    const [deleteclicked, setDeleteclicked] = useState(false);


    const deleteAppAction = (id)=>{
        setDeleteclicked(true);
        dispatch(resetappdeleted());
        dispatch(deleteApp(id))
    }

    const appdeleted = useSelector(selectAppdeleted);

    useEffect(()=>{
        if(appdeleted&&deleteclicked){
            handleClose()
        }
    },[deleteclicked, appdeleted])

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby = "modal-delete-title"
                aria-describedby="modal-delete-description"
            >
                <Box
                    sx={style}
                >
                    <div>
                    <h3>
                        Delete Application {app?.name}
                    </h3>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure you want to delete application ? 
                    </Typography>
                </div>
                <div style={{display: "flex", "padding": 10}}>
                    <div>
                        <Button onClick={()=>{handleClose()}}>Cancel</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{deleteAppAction(app._id)}}>Yes</Button>
                    </div>
                </div>
                </Box>

            </Modal>
        
        </>
    )

}

const Androidlist = ()=>{

    const {workspaces} = useParams();

    const [opencreate, setOpencreate] = useState(false);

    const [opendelete, setOpendelete] = useState(false);

    const [apptobedeleted, setApptobedeleted] = useState(null);

    const dispatch = useDispatch();

    const apps = useSelector(selectAndroidApps)

    const openDeleteModal = (app)=>{
        setApptobedeleted(app);
        setOpendelete(true);
    }

    useEffect(()=>{
        dispatch(getAndroidApplist())
    },[])

    return (
        <>
            <div className="website-list">
                {/* <BreadCrumbs
                    breadcrumbs={[{
                        "name": "interfaces",
                        "url": "/interfaces"
                    }]}
                >
                </BreadCrumbs> */}
                <div className="website-headers">
                    <h3>Android Apps</h3>
                    <Button disabled  onClick={()=>{setOpencreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
                </div>
                <h3>
                    Coming Soon !!!
                </h3>
                <div className="website-list-container">
                    {apps?.map((app)=>{
                        return(
                            <div className="website-item">
                                <Link href={"/"+workspaces+"/apps/details/"+app._id} style={{textDecoration:"none"}}>
                                    <div>{app.name}</div>
                                </Link>
                                <div>
                                    <IconButton onClick={()=>{openDeleteModal(app)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <CreateAndroidApp
                    open={opencreate}
                    handleClose={()=>{setOpencreate(false)}}
                >
                </CreateAndroidApp>
                <DeleteAppModal
                    open={opendelete}
                    handleClose={()=>{setOpendelete(false);}}
                    app={apptobedeleted}
                >
                </DeleteAppModal>
            </div>
        </>
      );


}
export default Androidlist;

