import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ContentEditable from 'react-contenteditable'
import {
    sendRequest,
    selectResponse,
    selectError,
    selectStatus

} from "../../../Services/httpstagetest/httpstage.slice";
import { useDispatch, useSelector } from "react-redux";
import * as qs from "qs";
import "./HttpTestStage.css";
import { getOutput } from "../../utils";


const HttpTestStage = ({loop})=>{

    const [action, setAction] = useState("GET");

    const [url, setUrl] = useState("");

    const [requestCompActive, setRequestCompActive] = useState("params");

    const [requesBodyType, setRequestBodyType] = useState("raw");

    const [requestParams, setRequestParams] = useState("");

    const [urlParams, setUrlParams] = useState([{"key":"","value":""}]);

    const [requestHeaders, setRequestHeaders] = useState([{"key": "", "value": ""}]);


    const [formData, setFormdata] =  useState([{
        "key": "",
        "value":"",
        "type": "text"
    }]);

    const [requestRawBodyType, setRequestRawBodyType] = useState("text");

    const [rawBody, setrawBody] = useState("");

    const setParamKey = (val, index)=>{
        let requestParamsCopy = [...requestParams];
        requestParamsCopy[index] = {...requestParamsCopy[index],
                                    key: val
                                    }
        setRequestParams(requestParamsCopy);
    }

    const setParamValue = (val, index)=>{
        let requestParamsCopy = [...requestParams];
        requestParamsCopy[index] = {...requestParamsCopy[index],
                                    value: val
                                    }
        setRequestParams(requestParamsCopy);
    }

    const setUrlParamsKey = (val, index)=>{
        let urlParamsCopy = [...urlParams];
        urlParamsCopy[index] = {...urlParamsCopy[index],
                                key: val
                                }
        setUrlParams(urlParamsCopy);
    }

    const setUrlParamsValue = (val, index)=>{
        let urlParamsCopy = [...urlParams];
        urlParamsCopy[index] = {...urlParamsCopy[index],
                                value: val
                                }
        setUrlParams(urlParamsCopy); 
    }

    const deleteUrlParams = (index)=>{
        let urlParamsCopy = [...urlParams];
        urlParamsCopy.splice(index,1);
    }

    const addUrlParam = ()=>{
        let urlParamsCopy = [...urlParams];
        urlParamsCopy.push({key: "", value: ""});
        setUrlParams(urlParamsCopy);
    }

    const deleteRequestParam = (index)=>{
        let requestParamsCopy = [...requestParams];
        requestParamsCopy.splice(index,1);
        setRequestParams(requestParamsCopy);
    }

    const addRequestParam = ()=>{
        let requestParamsCopy = [...requestParams];
        requestParamsCopy.push({"key": "", "value": ""});
        setRequestParams(requestParamsCopy);
    }

    const setHeaderKey = (val, index)=>{
        let requestHeadersCopy = [...requestHeaders];
        requestHeadersCopy[index] = {...requestHeadersCopy[index],
                                     key: val
                                    }
        setRequestHeaders(requestHeaders);
    }

    const setHeaderValue = (val, index)=>{
        let requestHeadersCopy = [...requestHeaders];
        requestHeadersCopy[index] = {...requestHeadersCopy[index],
                                     value: val
                                    }
        setRequestHeaders(requestHeadersCopy);
    }

    const deleteHeader = (index)=>{
        let requestHeadersCopy = [...requestHeaders];
        requestHeadersCopy.splice(index, 1);
        setRequestHeaders(requestHeadersCopy);
    }

    const addHeader = ()=>{
        let requestHeadersCopy = [...requestHeaders];
        requestHeadersCopy.push({key:"", value: ""});
        setRequestHeaders(requestHeadersCopy);
    }

    const setFormDataKey = (val, index)=>{
        let formDataCopy = [...formData];
        formDataCopy[index] = {...formDataCopy[index],
                               key: val
                              };
        setFormdata(formDataCopy);  
    }

    const setFormDataValueText = (val, index)=>{
        let formDataCopy = [...formData];
        formDataCopy[index] = {...formDataCopy[index],
                               value: val
                               }
        setFormdata(formDataCopy);
    }

    const setFormDataValueFile = (e, index)=>{
        let formDataCopy = [...formData];
        let file = e.target.files[0];
        formDataCopy[index] = {...formDataCopy[index],
                               value: file
                              }
        setFormdata(formDataCopy);
    }

    const setFormDataValueType = (val, index)=>{
        let formDataCopy = [...formData];
        formDataCopy[index] = {...formDataCopy[index],
                        type: val
                        };
        setFormdata(formDataCopy);
    }

    const deleteFormDataField = (index)=>{
        let formDataCopy = [...formData];
        formDataCopy.splice(index,1);
        setFormdata(formDataCopy);
    }

    const addFormDataField = ()=>{
        let formDataCopy = [...formData];
        formDataCopy.push({
            "key": "",
            "value": "",
            "type": "text"
        })
        setFormdata(formDataCopy);
    }

    const dispatch = useDispatch();
    
    const send = ()=>{
        if(requesBodyType=="raw"){
            let payload = {
                url: url,
                method: action,
                query: requestParams,
                formData: formData,
                rawBody: rawBody,
                type: requesBodyType,
                subreqtype: requestRawBodyType,
                headers: requestHeaders

              };
            dispatch(sendRequest(payload));
        }else{
            let payload = {
                url: url,
                method: action,
                query: requestParams,
                formData: formData,
                rawBody: rawBody,
                type: requesBodyType,
                headers: requestHeaders          
              };
            dispatch(sendRequest(payload));
        }
        
    }

    const response = useSelector(selectResponse);

    const error = useSelector(selectError);

    const status = useSelector(selectStatus);

    const getSampleObject = (inputschema)=>{
        let obj = {};
        for(let i=0; i< inputschema.length; i++){
            
            if(inputschema[i].type=="object"){
                let innerobj = getSampleObject(inputschema[i].subschema);
                obj[inputschema[i].key] = innerobj;
            }else{
                obj[inputschema[i].key] = ""
            }
            // obj[inputschema[i]] = 
        }
        return obj;
    }

    const checkFileExist = (inputschema)=>{
        let fileexist = false;
        for(let i=0; i< inputschema.length; i++){
            if(inputschema[i].type=="file"){
                fileexist = true
            }
        }
        return fileexist;
    }

    const loadQuery = (loopOutput)=>{
        let query = qs.stringify(loopOutput.query);
        setRequestParams(query);
    }

    const loadBody = (bodySchema)=>{
        let fileExist = checkFileExist(bodySchema);
        if(fileExist){
            setRequestBodyType("form-data");
            let formDataCopy = [];
            for(let i=0; i< bodySchema.length; i++){
                if(bodySchema[i].type=="file"){
                    formDataCopy.push({
                        "key": bodySchema[i].key,
                        "value": null,
                        "type": "file"
                    })
                
                }else{
                    if(bodySchema[i].type=="object"){
                        formDataCopy.push({
                            "key": bodySchema[i].key,
                            "value": getSampleObject(bodySchema[i].subschema),
                            "type": "text"
                        })
                    }else{
                        formDataCopy.push({
                            "key": bodySchema[i].key,
                            "value":"",
                            "type": "text"
                        })
                    }
                }
            }
            setFormdata(formDataCopy);
        }
        else{
            setRequestRawBodyType("json");
            setRequestBodyType("raw");
            let rawBodyCopy = JSON.stringify(getSampleObject(bodySchema));
            setrawBody(rawBodyCopy);
        }
    }

    useEffect(()=>{
        if(loop==undefined){
            return
        }
        let inputschema = [...loop.inputschema];
        let loopOutput = getOutput(loop.stageindex);
        let url = "https://"+loopOutput.host+loopOutput.devpath;
        setUrl(url);
        if(loopOutput.method=="GET"){
            setAction("GET")
            loadQuery(loopOutput);
        }

        if(loopOutput.method=="POST"){
            setAction("POST")
            loadQuery(loopOutput);
            loadBody([...loop.inputschema[3].subschema]);
        }
        if(loopOutput.method=="PUT"){
            setAction("PUT");
            loadQuery(loopOutput);
            loadBody([...loop.inputschema[3].subschema])
        }
        if(loopOutput.method=="DELETE"){
            setAction("DELETE");
            loadQuery(loopOutput);
        }
    },[loop])

    return(
        <div style={{width: "100%"}}>
            <div className="stage-test-headers">
                <div style={{width: "20%", padding: 10}}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={action}
                            label="Age"
                            onChange={(e)=>{setAction(e.target.value)}}
                        >
                            <MenuItem value="GET">GET</MenuItem>
                            <MenuItem value="POST">POST</MenuItem>
                            <MenuItem value="PUT">PUT</MenuItem>
                            <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{width: "80%", padding: 10}}>
                    <TextField style={{width: "100%"}} label="url" value={url} onChange={(e)=>{setUrl(e.target.value)}} fullWidth></TextField>
                </div>
                <div style={{width: "10%", padding: 10}}>
                    <Button onClick={()=>{send()}}>Send</Button>
                </div>
            </div>
            <div style={{display: "flex", padding: 10}}>
                {/* <div className={requestCompActive=="url-params"?"request-comp active":"request-comp"} onClick={()=>{setRequestCompActive("url-params")}}>Url Params</div> */}
                <div className={requestCompActive=="params"?"request-comp active":"request-comp"} onClick={()=>{setRequestCompActive("params")}}>Params</div>
                <div className={requestCompActive=="headers"?"request-comp active":"request-comp"} onClick={()=>{setRequestCompActive("headers")}}>Headers</div>
                <div className={requestCompActive=="body"?"request-comp active":"request-comp"} onClick={()=>{setRequestCompActive("body")}}>Body</div>
            </div>
            <div style={{padding:10}}>
                {requestCompActive=="params"&&
                    <>
                        <TextField
                            value={requestParams}
                            onChange={(e)=>{setRequestParams(e.target.value)}}
                            fullWidth
                        >

                        </TextField>
                    </>
                }
                {requestCompActive=="headers"&&
                    <>
                        {requestHeaders.map((header, index)=>{
                            return(
                                <>
                                    <div className="request-params-row">
                                        <div className="request-params-row-block">
                                            <TextField value={header.key} label="key" onChange={(e)=>{setHeaderKey(e.target.value, index)}}></TextField>
                                        </div>
                                        <div className="request-params-row-block">
                                            <TextField value={header.value} label="value" onChange={(e)=>{setHeaderValue(e.target.value, index)}}></TextField>
                                        </div>
                                        <IconButton onClick={()=>{deleteHeader(index)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>
                                </>
                            )
                        })}
                        <div className="request-headers-add-button">
                            <div>
                                <Button onClick={()=>{addHeader()}}>Add Header</Button>
                            </div>
                        </div>
                    </>
                }
                {requestCompActive=="body"&&
                    <div>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <div>
                                <input value="none" checked={requesBodyType=="none"} type="radio" onChange={(e)=>{setRequestBodyType("none")}}></input> none
                            </div>
                            <div>
                                <input value="form-data" checked={requesBodyType=="form-data"} type="radio" onChange={(e)=>{setRequestBodyType("form-data")}}></input>  form-data
                            </div>
                            <div>
                                <input value="x-www-form-urlencoded" checked={requesBodyType=="x-www-form-urlencoded"} type="radio" onChange={(e)=>{setRequestBodyType("x-www-form-url-urlencoded")}}></input>  x-www-form-urlencoded
                            </div>
                            <div>
                                <input value="raw" checked={requesBodyType=="raw"} type="radio" onChange={(e)=>{setRequestBodyType("raw")}}></input>  raw
                            </div>
                            {requesBodyType=="raw"&&
                                <div style={{padding:10}}>
                                    <FormControl>
                                        <Select
                                            value={requestRawBodyType}
                                            onChange={(e)=>{setRequestRawBodyType(e.target.value)}}
                                            label="rawtype"
                                            variant="standard"
                                        >
                                            <MenuItem value="text">text</MenuItem>
                                            <MenuItem value="json">json</MenuItem>
                                            <MenuItem value="xml">xml</MenuItem>
                                            <MenuItem value="html">html</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>    
                            }
                        </div>
                        <div>
                            {requesBodyType=="none"&&
                                <>
                                    This request body is undefined
                                </>
                            }
                            {requesBodyType=="form-data"&&
                                <div>
                                    {formData.map((field, index)=>{
                                        return(
                                            <div className="form-data-row">
                                                <div className="form-data-row-block">
                                                    <TextField value={field.key} onChange={(e)=>{setFormDataKey(e.target.value, index)}}></TextField>
                                                </div>
                                                <div className="form-data-row-block">
                                                    {field.type=="text"?
                                                        <>
                                                            <TextField value={field.value} onChange={(e)=>{setFormDataValueText(e.target.value, index)}}></TextField>
                                                        </>:
                                                        <>
                                                            <input type="file" onChange={(e)=>{setFormDataValueFile(e, index)}}></input>
                                                        </>

                                                    }
                                                </div>
                                                <div className="form-data-row-block">
                                                    <FormControl>
                                                        <Select
                                                            value={field.type}
                                                            onChange={(e)=>{setFormDataValueType(e.target.value, index)}}
                                                        >
                                                            <MenuItem value="text">text</MenuItem>
                                                            <MenuItem value="file">file</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="form-data-row-block">
                                                    <IconButton onClick={()=>{deleteFormDataField(index)}}>
                                                        <span className="material-symbols-outlined">
                                                            delete
                                                        </span>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div>
                                        <Button onClick={()=>{addFormDataField()}}>Add Field</Button>
                                    </div>
                                </div>
                            }
                            {requesBodyType=="raw"&&
                                <div>
                                    <TextField
                                        value={rawBody}
                                        onChange={(e)=>{setrawBody(e.target.value)}}
                                        multiline
                                        fullWidth
                                        minRows={6}
                                    >
                                    </TextField>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div style={{padding: 10}}>
                <div style={{display: "flex", padding: 10}}>
                    <div style={{paddingRight: 10}}>
                        Status
                    </div>
                    <div>
                        {status}
                    </div>
                </div>
                <div>
                    Response
                </div>
                <div style={{width:"100%", minHeight: 200}}>
                    {response!=null&&
                        <>
                            <pre>
                                {JSON.stringify(response)}
                            </pre>
                        </>
                    }
                    {error!=null&&
                        <>
                            <pre>
                                {JSON.stringify(error)}
                            </pre>
                        </>
                    }
                </div>
            </div>
        </div>
    )

}


export default HttpTestStage;