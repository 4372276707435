import { useEffect, useState } from "react";
import CustomInput from "../../apps/CustomInput/CustomInput";
import { InsertSchemaContainer } from "../../apps/Output/Output";
import DeclareVariable from "../../apps/Fields/DeclareVariable";
import CreateMotors from "../CreateMotors";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    selectStage,
    selectTestActionOutput,
    selecttestActionIndex,
    setStage,
    setStageAsync,
    testAction,
    getVariablePipeline,
    selectTestactionError,
    changeLoopIndex
} from "../../../Services/pipelines/pipeline.slice";
import {Delineatedpipeline, getOutput} from "../../utils";

const LoopBlock = ({
                        width,
                        pipelineIndex,
                        pipelinetype,
                        pipelinestage,
                        stageindex,
                        index,
                        stage,
                        isLog,
                        source,
                        templateid,
                        pipelineid
                    })=>{

    const dispatch = useDispatch();
    
    const [blockinit, setblockinit] = useState(true);

    const [currentindex, setcurrentindex] = useState(0);

    const [cont, setCont]= useState(false);

    const [loopvariable, setLoopvariable] = useState(null);
    
    const [pipelinemode, setpipelinemode] = useState(false);

    const [initialLoad, setInitialLoad] = useState(false);
    
    useEffect(()=>{
        if(pipelinetype=="loop"){
            setLoopvariable(stage.loopvariable);
        }
    },[stage])


    //called on initial load of already executed pipeline
    useEffect(()=>{
        if(stage.currentindex!=currentindex&&currentindex==0){
            setcurrentindex(stage.currentindex);
        }
    },[stage])

    //called on initital load of already executed pipeline
    useEffect(()=>{

        let array_to_loop = Delineatedpipeline[stage.stageindex]?.output?.array_to_loop;
        if(array_to_loop!=undefined&&initialLoad==false){
            setCont(true);
            setInitialLoad(true);
        }
        
    },[stage])

    const addtoschema = (schema)=>{
        let stageCopy = {...stage,
                        blockschema: schema
                    }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
    }

    const closeBlockVariable = ()=>{
        setblockinit(false);
    }

    const setInputSchemaMapping = (schemamapping)=>{
        let stageCopy = {...stage,
                      inputschemamapping: schemamapping
                    };
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
    }

    const setInputSchemaStage = (schema)=>{
        let stageCopy = {...stage,
                         inputschema: schema
                        }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
    }

    const modifyInputSchema = (schema, schemamapping)=>{
        let stageCopy = { 
                        ...stage,
                        inputschema: schema,
                        inputschemamapping: schemamapping
                    }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const testaction = ()=>{
        dispatch(testAction({
            stageindex: pipelinestage.stageindex,
            source: source
        }));
    }

    const actionOutput = useSelector(selectTestActionOutput);
    
    useEffect(()=>{
        if(actionOutput==null){
            return
        }
        if(stage.stageindex==actionOutput.stageindex){
            setCont(true)
            if(initialLoad==false){
                setInitialLoad(true);
            }
        }
    },[actionOutput])

    const actionError = useSelector(selectTestactionError);

    useEffect(()=>{
        if(actionError==null){
            return;
        }
        if(stage.stageindex==actionError.stageindex){
            setCont(true);
            if(initialLoad==false){
                setInitialLoad(true);
            }
        }
    },[actionError])

    const setCurrentIndex= (val)=>{
        let stageCopy = {...stage};
        stageCopy.currentindex=val;
        setcurrentindex(val);
        dispatch(changeLoopIndex({position: pipelinestage.position, stage: stageCopy, source: source}))
    }

    return (
        <>
            <div className="stage-params">
                <div style={{marginTop: 10}}>
                    <div className="stage-params-header">
                        <div>Loop variable</div>
                    </div>
                    <div style={{marginTop: 10}}>
                        <DeclareVariable
                            schema={stage.inputschema}
                            schemamapping={stage.inputschemamapping}
                            setschemaMapping={setInputSchemaMapping}
                            modifySchema = {modifyInputSchema}
                            setSchema={setInputSchemaStage}
                            pipelinetype={pipelinetype}
                            stageindex={stage.stageindex}
                            stagetype={stage.type}
                            layout="vertical"
                            editable={false}
                            pipelinestage = {pipelinestage}
                            source={source}
                            templateid={templateid}
                            sourceid={pipelineid}
                        >
                        </DeclareVariable>
                    </div>
                    {isLog==undefined&&
                        <div>
                            {cont?
                                <>
                                    <Button onClick={()=>{
                                                            setCont(false)
                                                        }}
                                                        style={{color: "#3A07CD"}}
                                                        >Edit</Button>
                                </>:
                                <>
                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                </>
                            }
                        </div>
                    }
                    {currentindex!=null&&
                        <>
                            <div style={{display: "flex"}}>
                                {getOutput(stage.stageindex)?.array_to_loop?.map((at,i)=>{
                                    return(
                                        <>
                                            {(i==currentindex)?
                                                <span style={{padding: 4,margin:2,"cursor": "pointer", border: "1px solid blue"}} onClick={()=>{setCurrentIndex(i)}}>
                                                    <pre>{JSON.stringify(at)}</pre> 
                                                </span>:
                                                <span style={{padding:4, border:"1px solid #eee",margin:2,"cursor": "pointer"}} onClick={()=>{setCurrentIndex(i)}}>
                                                    <pre>{JSON.stringify(at)}</pre>
                                                </span>
                                            }   
                                        </>
                                    )
                                })}
                            </div>
                            {getOutput(stage.stageindex)?.array_to_loop?.length>0&&
                                <div>
                                    <CreateMotors
                                        pipelinetype="loop"
                                        pipelineIndex={pipelineIndex}
                                        width={(width-150)}
                                        fullscreenmode={pipelinemode}
                                        setfullscreenmode={setpipelinemode}
                                        position={[...pipelinestage.position]}
                                        isLog={isLog}
                                        source={source}
                                        templateid={templateid}
                                    >
                                    </CreateMotors>
                                </div>
                            }
                        </>
                    }
                </div>
                <div>  
                </div>
            </div>
        </>
    )
}

export default LoopBlock;