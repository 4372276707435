import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createWorkspaceApi, deleteWorkspaceApi, getWorkspaceApi, getWorkspacesApi } from "./workspace.service";
import { showError } from "../error.slice";

export const createWorkspace = createAsyncThunk(
    "workspaces/createworkspace",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createWorkspaceApi(payload);
            if(res.status){
                dispatch(getWorkspaces());
                return true;
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message);
            }
        }catch(error){
            dispatch(showError("Error creating workspace"))
            throw error;
        }
        
    }
)

export const getWorkspaces = createAsyncThunk(
    "workspaces/getworkspaces",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setworkspaceloader());
            let res = await getWorkspacesApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting workspaces"));
            throw error;
        }
        
    }
)

export const deleteWorkspace = createAsyncThunk(
    "workspaces/deleteworkspace",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteWorkspaceApi(payload);
            dispatch(getWorkspaces());
            return res;
        }catch(error){
            dispatch(showError("Error deleting workspaces"));
            throw error;
        }
    }
)


export const getWorkspace = createAsyncThunk(
    "workspaces/getworkspace",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setworkspaceloader());
            let res = await getWorkspaceApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error deleting workspaces"));
            throw error;
        }
    }
)

export const setWorkspaceVariable = createAsyncThunk(
    "workspaces/setworkspacevariables",
    async(payload, {dispatch,getState})=>{

    }
)

export const setEnvironVariables = createAsyncThunk(
    "workspaces/setenvironvariables",
    async(payload,{dispatch, getState})=>{

    }
)

export const updateWorkspaceVariables = createAsyncThunk(
    "workspaces/updateworkspacevariables",
    async(payload, {dispatch, getState})=>{

    }
)

export const updateEnvironVariables = createAsyncThunk(
    "workspaces/updateenvironvariables",
    async(payload, {dispatch, rejectWithValue})=>{

    }
)

const workspaces = createSlice({
    name: "workspaces",
    initialState:{
        workspacecreated: false,
        workspacedeleted: false,
        workspaces: [],
        workspace: {},
        workspacevariables:[],
        environvariables: [],
        workspaceaccess: "",
        workspaceloader: 0
    },
    reducers:{
        resetworkspacecreated: (state, action)=>{
            state.workspacecreated = false;
        },
        resetworkspacedeleted: (state, action)=>{
            state.workspacedeleted = false
        },
        setworkspaceloader: (state, action)=>{
            state.workspaceloader = state.workspaceloader+1;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(createWorkspace.fulfilled, (state, action)=>{
            state.workspacecreated = true;
        })
        builder.addCase(deleteWorkspace.fulfilled, (state, action)=>{
            state.workspacedeleted = true
        })
        builder.addCase(getWorkspaces.fulfilled, (state, action)=>{
            state.workspaces = action.payload;
            state.workspaceloader = state.workspaceloader-1;
        })
        builder.addCase(getWorkspace.fulfilled, (state, action)=>{
            state.workspace = action.payload;
            state.workspaceloader = state.workspaceloader-1;
        })
    }
})

export const selectWorkspacecreated = (state) => state.workspaces.workspacecreated; 
export const selectWorkspacedeleted = (state) => state.workspaces.workspacedeleted;
export const selectWorkspaces = (state) => state.workspaces.workspaces;
export const selectWorkspace = (state) => state.workspaces.workspace;
export const selectWorkspaceaccess = (state) => state.workspaces.workspaceaccess;
export const selectWorkspaceLoader = (state) => state.workspaces.workspaceloader;

export const {
                resetworkspacecreated, 
                resetworkspacedeleted,
                setworkspaceloader
            } = workspaces.actions;

export default workspaces.reducer;