import axios from "axios";
import { signout } from "./signin.slice";
import { showError } from "../error.slice";


const instance = axios.create();

let dispatchobject;

function HttpError(status,error){
    this.response = {
        "data":error
    };
    this.status = status;
}

export const initDispatch = (dispatch)=>{
    dispatchobject = dispatch;
}


export const get = async(url, headers)=>{
    let token = localStorage.getItem("token");
    try{
        let res = await fetch(url,{
            "method": "GET",
            "headers":{
                "Authorization": token,
                ...headers
            }
        });
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(res.status,err);
            }else{
                dispatchobject(showError("403 Unuthorized"));
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
    
}

export const postform = async(url,body, headers)=>{
    try{
        let token = localStorage.getItem("token");
        let res = await fetch(url,{
            "method": "POST",
            "headers":{
                "Authorization": token,
                ...headers
            },
            body: body
        })
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(500,err);
            }else{
                dispatchobject(showError("403 unauthorized"));
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
}


export const postjson = async(url, body, headers)=>{
    try{
        let token = localStorage.getItem("token");
        let res = await fetch(url,{
            "method": "POST",
            "headers":{
                "Authorization": token,
                "Content-type": "application/json",
                ...headers
            },
            "body": JSON.stringify(body)
        })
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(showError());
            }else{
                dispatchobject(showError("403 unauthorized"));
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
}

export const putform = async(url, body, headers)=>{
    try{
        let token = localStorage.getItem("token");
        let res = await fetch(url, {
            "method": "PUT",
            "headers":{
                "Authorization": token,
                ...headers
            },
            "body": JSON.stringify(body)
        });
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(500,err);
            }else{
                dispatchobject(showError("403 Uauthorized"))
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
}

export const putjson = async(url, body, headers)=>{
    try{
        let token = localStorage.getItem("token");
        let res = await fetch(url, {
            "method": "PUT",
            "headers":{
                "Authorization": token,
                "Content-type": "application/json",
                ...headers
            },
            "body": JSON.stringify(body)
        })
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(500, err);
            }else{
                dispatchobject(showError("403 Unauthorized"))
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
}


export const deleteM = async(url, headers)=>{
    try{
        let token = localStorage.getItem("token");
        let res = await fetch(url,{
            "method": "DELETE",
            "headers":{
                "Authorization": token,
                ...headers
            }
        })
        if (!res.ok) {
            if(res.status!=403&&res.status!=401){
                let err = await res.json();
                throw new HttpError(500, err);
            }else{
                dispatchobject(showError("403 Unauthorized"));
            }
        }
        return res.json();
    }catch(err){
        throw err;
    }
}

export const downloadFile = async()=>{
    
}


export default instance;

