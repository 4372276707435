import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { makeCollaborationActive, resetcollabactiveaction, selectCollabActiveAction } from "./Services/collaborators/collaborators.slice";


const CollaborationActive = ()=>{

    const navigate = useNavigate();

    const {collaborationId} = useParams();


    const action = useSelector(selectCollabActiveAction);

    const dispatch = useDispatch();

    useEffect(()=>{
        if(collaborationId!=""){
            dispatch(makeCollaborationActive(collaborationId));
        }
    },[])

    useEffect(()=>{
        if(action=="init"){
            return
        }else{
            if(action=="signup"){
                dispatch(resetcollabactiveaction());
                navigate("/signin");
            }else if(action=="dashboard"){
                dispatch(resetcollabactiveaction());
                navigate("/");
            }
        }
    },[action])
    

    return(
        <>
        </>
    )
}

export default CollaborationActive;