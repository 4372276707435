import TemplateDetailTile from "./TemplateDetailtile"
import { 
            useState, 
            useCallback, 
            useEffect 
        } from "react";
import { get } from "../../Services/signin/axios";
import { baseURL } from "../../env";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import SearchAutoComplete from "../SearchAutoComplete";
import { 
            Button, 
            TextField,
            Modal,
            Box,
            CircularProgress
        } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
            resetwebsitetemplatecreated, 
            createWebsiteTemplate,
            selectWebsitetemplateCreated
        } from "../../Services/websites/website.slice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
}


const TemplateCreateModal = ({
    open,
    handleClose,
    templatename,
    settemplatename,
    type,
    save,
    saveclicked
})=>{

    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <Box
                sx={style}
            >
                {saveclicked?
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <CircularProgress>
                        </CircularProgress>
                    </div>:
                    <div>
                        <TextField 
                            value={templatename} 
                            onChange={(e)=>{settemplatename(e.target.value)}}
                        ></TextField>
                        <div>
                            <Button onClick={()=>{handleClose()}}>Cancel</Button>
                            {type=="new"&&
                                <Button onClick={
                                            ()=>{
                                                save()
                                            }
                                        }
                                >
                                    Save
                                </Button>
                            }
                            {type=="clone"&&
                                <Button
                                    onClick={
                                        ()=>{
                                            save()
                                        }
                                    }
                                >
                                    Clone
                                </Button>
                            }
                        </div>
                    </div>
                }
            </Box>
        </Modal>
    )
}



const TemplateGrid = ({
                        scrollref,
                        source,
                        addElementAction,
                        handleClose
                      })=>{
    
        const [
                templates, 
                setTemplates
            ] = useState([]); // Initial set of apps
        const [searchQuery, setSearchQuery] = useState(''); // Search query state
        const [filteredTemplates, setFilteredTemplates] = useState([]); // Filtered apps based on search
        const [page, setPage] = useState(1); // Current page for pagination
        const [loading, setLoading] = useState(false); // Loading state
        const [hasMore, setHasMore] = useState(true); // State to check if more data is available

        const [
                opentemplatecreate, 
                setopentemplatecreate
              ] = useState(false);
        
        const [
                templatename, 
                setTemplateName
              ] = useState("");
        
        const [
                clonetemplateid, 
                setCloneTemplateId
              ] = useState("");
        
        const [
                createtType,
                setCreateType
              ] = useState("");

        const {workspace} = useParams();

        // Load more apps when scrolling near the end
        const loadMoreTemplates = useCallback(() => {
            setPage((prevPage) => prevPage + 1); // Increase page number
        }, []);

        // Ref for the IntersectionObserver
        const observerRef = useCallback((node)=>{
            const observer = new IntersectionObserver(
                (entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreTemplates();
                }
                },
                {
                    root: scrollref.current, 
                    threshold: 1
                }
            );
            if (node) {
                observer.observe(node);
            }
            return () => observer.disconnect();
        },[loadMoreTemplates, hasMore, scrollref]);

        // Fetch apps when page changes or search query is updated
        useEffect(() => {
            async function fetchTemplates() {
                if(loading!=true&&hasMore&&source!=undefined&&source!=""){
                    setLoading(true);
                    try {
                        const data = await get(`${baseURL}variables/gettemplates/${workspace}?q=${encodeURIComponent(searchQuery)}&page=${page}&limit=15`)
                        // Append new apps and update state
                        setTemplates((prevTemplates) => {
                            let newtemplates = [];
                            for(let i=0; i< data.results.length; i++){
                                let appindex = _.findIndex(prevTemplates, (app)=>{return data.results[i]._id==app._id});
                                if(appindex==-1){
                                    newtemplates.push(data.results[i]);
                                }
                            }
                            return [...prevTemplates, ...newtemplates]
                        });
                        setHasMore(data.results.length > 0); // Check if more data is available
                    } catch (error) {
                        console.error('Error fetching apps:', error);
                    } finally {
                        setLoading(false);
                    }
                }
            }
            fetchTemplates()
        }, [searchQuery, page, hasMore, source]);

        useEffect(() => {
            setFilteredTemplates(
              templates.filter((template) =>{
                let searchstring = template.name.toLowerCase();
                if(template.workspacename!=undefined&&template.workspacename!=""){
                    searchstring = searchstring+template.workspacename.toLowerCase()
                }
                if(template.websitename!=undefined&&template.websitename!=""){
                    searchstring = searchstring+template.websitename.toLowerCase();
                }
                return searchstring.includes(searchQuery.toLowerCase());
              }  
              )
            );
          }, [searchQuery, templates]);

        const handleSearchChange = (value) => {
            setSearchQuery(value);
            setTemplates([]); // Reset apps when search query changes
            setPage(1);  // Reset page when search query changes
            setHasMore(true); // Reset hasMore state for new search
          };

        useEffect(()=>{

        },[])

        const openCreateModalAction = (
            type,
            templateid
        )=>{
            if(type=="clone"){
                setCloneTemplateId(templateid);
                setCreateType("clone");
                setopentemplatecreate(true)
            }else if(type=="new"){
                setCreateType("new");
                setopentemplatecreate(true);
            }
        }
        
        const [
                saveclicked, 
                setSaveClicked
            ] = useState(false);

        const dispatch = useDispatch();
        
        const save = ()=>{
            if(createtType=="new"){
                    let payload = {
                                    "type": "new",
                                    "source": source,
                                    "name": templatename,
                                    "workspace": workspace
                                  }
                    setSaveClicked(true);
                    dispatch(resetwebsitetemplatecreated());
                    dispatch(createWebsiteTemplate(payload));
            }else
                {
                    let payload = {
                                    "type": "clone",
                                    "source": source,
                                    "name": templatename,
                                    "templateid": clonetemplateid,
                                    "workspace": workspace
                                  }
                    setSaveClicked(true);
                    dispatch(resetwebsitetemplatecreated());
                    dispatch(createWebsiteTemplate(payload));
            }
        }

        const templatecreated = useSelector(selectWebsitetemplateCreated);

        const navigate = useNavigate();

        useEffect(()=>{
            if(templatecreated&&saveclicked){
                setSaveClicked(false);
                setopentemplatecreate(false);
                dispatch(resetwebsitetemplatecreated());
                handleClose();
                let url = "/"+workspace+"/appdesigner/html/"+source+"/"+templatecreated+"/";
                navigate(url, {state: {fromdesigner: true}});
            }
        },[saveclicked, templatecreated])


    return (
        <>

        <div style={{ padding: '20px' }}>
            {/* Search Bar with Autocomplete */}
            <div style={{
                            "display": "flex",
                            "alignItems": "center"
                        }}
            >
                <div>
                    <SearchAutoComplete
                        suggestions={templates.map((template) => template.name)}
                        onChange={handleSearchChange}
                    />
                </div>
                <div
                    style={{
                                "marginLeft": "20px"
                            }}
                >
                    <Button
                      onClick={()=>{openCreateModalAction(
                                                            "new"
                                                        )}}
                    >New</Button>
                </div>
            </div>
            {/* Responsive Grid */}
            <div
                style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                        gap: '20px',
                        padding: '20px',
                      }}
            >
                {filteredTemplates.map((app) => (
                    <>
                        <TemplateDetailTile
                            element={app}
                            addElementAction={addElementAction}
                            cloneElementAction={(templateid)=>{openCreateModalAction(
                                                                                        "clone", 
                                                                                        templateid
                                                                                    )}}
                        >
                        </TemplateDetailTile>
                    </>
                ))}
            </div>

            {/* Invisible element to trigger loading more apps when scrolled into view */}
            <div 
                ref={observerRef} 
                style={{ height: '20px' }} 
            />
        </div>
        <TemplateCreateModal
            open={opentemplatecreate}
            handleClose={()=>{setopentemplatecreate(false)}}
            templatename={templatename}
            settemplatename={setTemplateName}
            type={createtType}
            save={save}
            saveclicked={saveclicked}
        >

        </TemplateCreateModal>
    </>
    )
}

export default TemplateGrid;