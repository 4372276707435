import { useSelector } from "react-redux";
import SingleBlock from "./SingleBlock";
import { jsoncompare, selectLoopVariableValue } from "../../../Services/apptemplates/apptemplates.slice";

const LoopBlock = ({
    element,
    elmkey,
    indexarr,
    position,
    type,
    childs,
    parentkey,
    parentposition,
    parentindexarr,
    path,
    preview,
    indesign
})=>{
    
    const loopvariable = useSelector((state)=>{
                                               return selectLoopVariableValue(
                                                        state, 
                                                        {sc: element.loopvariable, sm: element.loopvariablemapping}, 
                                                        elmkey,
                                                        position
                                                        )}, jsoncompare);

    const getpositionprefix = ()=>{
        let positionPrefix = "";
        for(let i=0; i< position.length; i++){
            if(position[i].toString().startsWith("ci__")){
                positionPrefix = position[i]
            }
        }
        if(positionPrefix==""){
            positionPrefix = "ci__"
        }else{
            positionPrefix = positionPrefix+"__"
        }
        return positionPrefix;
    }

    return (
        <>
            {loopvariable!=undefined&&loopvariable.length>0&&
                loopvariable.map((vb, index)=>{
                    return (
                        <>
                            <SingleBlock
                                element={element}
                                elmkey={elmkey}
                                indexarr={indexarr}
                                position={[...position, getpositionprefix()+index]}
                                type={type}
                                childs={childs}
                                parentkey={parentkey}
                                parentposition={parentposition}
                                parentindexarr={parentindexarr}
                                path={path}
                                preview={preview}
                                indesign={indesign}
                            >
                            </SingleBlock>
                        </>
                    )
                })
            }
        </>
    )

}


export default LoopBlock;