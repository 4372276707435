import { baseURL } from "../../env"
import { get, postform, postjson, putjson } from "../signin/axios"

export const getManifestApi = async(payload)=>{
    let res = await get(baseURL+"publish/getmanifest/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const getEnvoptwebpubApi = async(payload)=>{
    let res = await get(baseURL+"envoptwebpub/"+payload);
    return res;
}

export const getManifestPipelineApi = async(payload)=>{
    let res = await get(baseURL+"publish/getmanifestpipes/"+payload);
    return res;
}

export const getManifestVersionApi = async(payload)=>{
    let res = await get(baseURL+"publish/getversion/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const installPManifestApi = async(payload)=>{
    let res = await get(baseURL+"publish/installmanifest/"+payload.source+"/"+payload.sourceid);
    return  res;
    
}

export const createManifestApi = async(payload) =>{
    let res = await postjson(baseURL+"publish/create/"+payload.source+"/"+payload.sourceid,payload);
    return res;
}

export const manifestExistsApi = async(payload)=>{
    let res = await get(baseURL+"publish/manifestexists/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const updateManifestApi = async(payload)=>{
    let res = await putjson(baseURL+"publish/manifestexists/"+payload.id, payload);
    return res;
}

export const getManifestPublishVarApi = async(payload)=>{
    let res = await get(baseURL+"publish/manifest/getpublishvars/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const getManifestAppsApi = async(payload)=>{
    let res = await get(baseURL+"manifest/apps/"+payload);
    return res;
}

export const getSavedManifestSourceApi = async(payload)=>{
    let res = await get(baseURL+"manifest/saved/source/"+payload);
    // console.log(res);
    return res;
}

export const savePublishedManifestApi = async(payload)=>{
    let res = await postjson(baseURL+"publish/publishmanifest/save/"+payload.id, payload);
    return res;
}

export const getSavedManifestApi = async(payload)=>{
    let res = await get(baseURL+"manifest/details/"+payload);
    return res;
}

export const getManifestDocumentationApi = async(payload)=>{
    let res = await get(baseURL+"manifest/documentation/"+payload.manifestscope+"/"+payload.id);
    return res;
}

export const updateManifestBannersApi = async(payload)=>{
    let res = await putjson(baseURL+"manifest/details/banners/"+payload.manifestscope+"/"+payload._id, payload);
    return res;
}

export const updateSavedManifestFaviconApi = async(payload)=>{
    let res = await putjson(baseURL+"manifest/details/favicon/"+payload.manifestscope+"/"+payload.id, payload);
    return res;
}

export const getSavedManifestDepsApi = async(payload)=>{
    let res = await get(baseURL+"manifest/details/deps/"+payload);
    return res;
}

export const publishSavedManifestApi = async(payload)=>{
    let res = await get(baseURL+"manifest/details/publish/"+payload.manifestscope+"/"+payload.id);
    return res;
}

export const getInstallConfigApi = async(payload)=>{
    let res = await get(baseURL+"marketplace/manifest/form/"+payload.manifestscope+"/"+payload.manifestid);
    return res;
}

export const installManifestApi = async(payload)=>{
    let res = await postjson(baseURL+"/marketplace/manifest/install/"+payload.workspace+"/"+payload.source+"/"+payload.sourceid+"/"+payload.manifestscope+"/"+payload.manifestid,payload);
    return res;
}

export const installThirdpartyManifestApi = async(payload)=>{
    let res = await postjson(baseURL+"marketplace/manifest/getinstallationid/"+payload.workspace+"/"+payload.source+"/"+payload.sourceid+"/"+payload.manifestscope+"/"+payload.manifestid, payload);
    return res;
}

export const getInstallthirdpartyTemplateApi = async(payload)=>{
    let res = await get(baseURL+"marketplace/manifest/template/"+payload.manifestscope+"/"+payload.manifestid);
    return res;
}