import { useEffect, useState, useRef } from "react"
import { Button, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AutomataLogo from "../../../Images/automata_logo.png";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from "react-redux";
import {getApps, selectApplist} from "../../../Services/apps/app.slice";
import {getAppSchemas,selectAppSchemas} from "../../../Services/schemas/schema.slice";
import {setmappingkey, getVariablePipeline} from "../../../Services/pipelines/pipeline.slice";
import {getSchemaTable, getMappingValue} from "../../utils";
import "./QueryBuilder.css";
import * as _ from "lodash";
import {DbConditionalOperators} from "../../Settings";
import Output from "../Output/Output";
import 'draft-js/dist/Draft.css';
import DeclareVariable from "../Fields/DeclareVariable";
import { EditorState, Modifier, Editor } from "draft-js";
import { useParams } from "react-router-dom";

export const FieldSelectBox = ({val, onChange, options, deleteArg})=>{

    const [expanded, setExpanded] = useState(false);

    const internalOnChange = (val)=>{
        setExpanded(false);
        onChange(val);        
    }

    return (
        <div className="field-select-component">
            <div className="field-select-box">
                <div className="field-select-input" onClick={()=>{setExpanded(!expanded)}}>
                    <div>
                        {val.app+"."+val.schema+"."+val.key}
                    </div>
                    <div className="field-select-actions">
                        {expanded?
                            <>
                                <span className="material-symbols-outlined">
                                    expand_less
                                </span>
                            </>:
                            <>
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </>
                        }
                    </div>
                </div>   
                <div className="field-delete-action">
                    <IconButton onClick={()=>{deleteArg()}}>
                        <span className="material-symbols-outlined">
                            delete
                        </span>
                    </IconButton>
                </div>
            </div>
            {expanded&&
                <div className="options-container">
                    {options.map((op)=>{
                        return(
                            <div onClick={()=>{internalOnChange(op)}} style={{padding:10, borderBottom: "1px solid #eee"}}>
                                {op.app+"."+op.schema+"."+op.key}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

const SelectBlock = ({availableFields, settings,setSettings})=>{
    const operatorlist = [
        {"name": "Select", "val": "automata_select"},
        {"name": "Select Count", "val": "automata_count"},
        {"name": "Select Sum", "val": "automata_sum"},
        {"name": "Select Average", "val": "automata_average"},
        {"name": "Select Min", "val": "automata_min"},
        {"name": "Select Max", "val": "automata_max"},
        {"name": "Select Distinct", "val": "automata_distinct"},
    ];

    const selectOperator = (index,val)=>{
        let fieldsCopy = [...settings.fields]
        fieldsCopy[index] = {...fieldsCopy[index],
                                  type: val
                                 };
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                           }
        setSettings(settingsCopy);
    }

    const selectArugments = (fieldindex, argindex)=>{
        return (val)=>{
            let fieldsCopy = [...settings.fields];
            let argsCopy = [...fieldsCopy[fieldindex].args];
            argsCopy[argindex] = val;
            fieldsCopy[fieldindex] = {...fieldsCopy[fieldindex],
                                  args: argsCopy
                                 };
            let settingsCopy = {...settings,
                            fields: fieldsCopy
                            }
            setSettings(settingsCopy);
        }
        
    }

    const deleteArguments = (fieldindex, argindex)=>{
        return ()=>{
            let fieldsCopy = [...settings.fields];
            let argsCopy = [...fieldsCopy[fieldindex].args];
            argsCopy.splice(argindex,1);
            fieldsCopy[fieldindex] = {...fieldsCopy[fieldindex],
                                      args: argsCopy
                                     }
            let settingsCopy = {...settings,
                                fields: fieldsCopy
                                }
            setSettings(settingsCopy);
        }
    }

    const addArgument = (fieldindex)=>{
        let fieldsCopy = [...settings.fields];
        let argsCopy = [...fieldsCopy[fieldindex].args];
        argsCopy.push("");
        fieldsCopy[fieldindex] = {...fieldsCopy[fieldindex],
                                  args: argsCopy
                                 };
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                            };
        setSettings(settingsCopy);
    }

    const addField = ()=>{
        let fieldsCopy = [...settings.fields];
        fieldsCopy.push({"type": "automata_select", "args":[""]});
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                            };
        setSettings(settingsCopy)
    }

    const deleteField = (index)=>{
        let fieldsCopy = [...settings.fields];
        fieldsCopy.splice(index, 1);
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                            };
        setSettings(settingsCopy);
    }

    return (
        <div className="query-block">
            <div>
                Select
            </div>
            <div>
                {settings.fields.map((field,fieldindex)=>{
                    return (
                        <div className="field-row">
                            <div className="field-row-block">
                                <FormControl>
                                    <InputLabel id="select-operator-label" label="Select Operator"></InputLabel>
                                    <Select
                                        id="select-operator-label"
                                        value={field.type}
                                        onChange={(e)=>{selectOperator(fieldindex, e.target.value)}}
                                    >
                                        {operatorlist.map((arg)=>{
                                            return(
                                                <MenuItem value={arg.val}>
                                                    {arg.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {field.args.map((arg, argindex)=>{
                                return(
                                    <div className="field-row-block">
                                        <FieldSelectBox 
                                            val={arg} 
                                            onChange={selectArugments(fieldindex, argindex)} 
                                            options={availableFields}
                                            deleteArg={deleteArguments(fieldindex, argindex)}

                                        ></FieldSelectBox>
                                    </div>
                                )       
                            })}
                            <div className="field-row-block">
                                <Tooltip>
                                    <IconButton onClick={()=>{addArgument(fieldindex)}}>
                                        <span className="material-symbols-outlined">
                                            add
                                        </span>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip>
                                    <IconButton onClick={()=>{deleteField(fieldindex)}}>
                                        <span className="material-symbols-outlined">
                                             delete
                                        </span>
                                    </IconButton>
                                </Tooltip>               
                            </div>
                        </div>
                    )
                })}
                <Button onClick={()=>{addField()}}>Add Field</Button>
            </div>
        </div>
    )
}

export const AppSchemaBlock = ({app, setSettings})=>{

    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    {app.appname}
                </div>
                <div>
                    {expanded?
                        <>
                            <Tooltip>
                                <IconButton onClick={()=>{setExpanded(false)}}>
                                    <span className="material-symbols-outlined">
                                        expand_less
                                    </span>
                                </IconButton>
                            </Tooltip>
                        </>:
                        <>
                            <Tooltip>
                                <IconButton onClick={()=>{setExpanded(true)}}>
                                    <span className="material-symbols-outlined">
                                        expand_more
                                    </span>
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </div>
            </div>
            {expanded&&
                <>
                    {app.schemalist.map((schema)=>{
                        return (
                            <MenuItem  onClick={()=>{setSettings({"app":app.appname,"schemaslug":schema.schemaslug})}}>{schema.schemaname}</MenuItem>
                        )
                    })}
                </>
            }
        </div>
    )

}

const FromSchemaBlock = ({availableSchemas, schema,setSchema})=>{
    const [appschemaopen, setAppSchemaOpen] = useState(false);

    const toggleAppSchema = ()=>{
        let appschemaopencopy = !appschemaopen;
        setAppSchemaOpen(appschemaopencopy);
    }

    const setInternalSchema = (val)=>{
        setSchema(val);
        setAppSchemaOpen(false);
    }


    return (
            <div style={{minWidth: 100, "position": "relative"}}>
                <div style={{"display": "flex",alignContent: "center",padding: 10,justifyContent:"space-between", border: "1px solid #eee"}} onClick={()=>{
                        setAppSchemaOpen(!appschemaopen)
                    }}>
                    <div>
                        {schema.app+"."+schema.schemaslug}
                    </div>
                    <div onClick={()=>{toggleAppSchema()}}>
                        <span className="material-symbols-outlined">
                            expand_more
                        </span>
                    </div>
                </div>
                {appschemaopen&&
                <div style={{"position": "absolute", "backgroundColor": "white", zIndex: 101}}>
                    {availableSchemas.map((app)=>{
                        return (
                                <AppSchemaBlock app={app} setSettings={setInternalSchema}></AppSchemaBlock>
                        )
                    })}
                </div>
                }
            </div>
    )
}

export const FromBlock = ({settings, availableSchemas,setSettings})=>{
    
    const [fromB, setFromB] = useState(0);

    const [appschemaopen, setAppSchemaOpen] = useState(false);

    const setInternalSettings = (table)=>{
                    let  settingsCopy = {...settings,
                                         table: table
                                        }
                    setSettings(settingsCopy);
                    setAppSchemaOpen(false);
    }

    const toggleAppSchema = ()=>{
        let appschemaopencopy = !appschemaopen;
        setAppSchemaOpen(appschemaopencopy);
    }

    return (
        <div className="query-block">
            <div>
                From
            </div>
            <div className="query-block-selector">
                <div className={fromB==0?"query-block-selection active":"query-block-selection"} onClick={()=>{setFromB(0)}}>
                    From Table
                </div>
                <div className={fromB==1?"query-block-selection active":"query-block-selection"} onClick={()=>{setFromB(1)}}>
                    From Query
                </div>
            </div>
            {fromB==0?
                <>
                    <div style={{width: 300}}>
                        <div style={{"display": "flex",alignContent: "center",padding: 10,justifyContent:"space-between", border: "1px solid #eee"}} onClick={()=>{
                                setAppSchemaOpen(!appschemaopen)
                            }}>
                            <div>
                                {settings.table.app+"."+settings.table.schemaslug}
                            </div>
                            <div onClick={()=>{toggleAppSchema()}}>
                               <span className="material-symbols-outlined">
                                    expand_more
                               </span>
                            </div>
                        </div>
                        {appschemaopen&&
                        <>
                            {availableSchemas.map((app)=>{
                                return (
                                    <AppSchemaBlock app={app} setSettings={setInternalSettings}></AppSchemaBlock>
                                )
                            })}
                        </>
                        }
                    </div>
                </>:
                <></>

            }

        </div>
    )
}

const InOperatorSelect = ({
                            val, 
                            accessPipeline, 
                            pipelinetype, 
                            deleteField,
                            selectConstElementCompareto,
                            selectGetElementCompareto
                         })=>{

    const [openoutput, setOpenOutput] = useState(false);

    const [inputtype, setinputtype] = useState(0);

    return (
        <>
            <div style={{display: "flex",alignItems: "center",padding:10}}>
                <div>
                    <TextField
                        value={val}
                        onClick={()=>{setOpenOutput(true)}}
                        onChange={(e)=>{selectConstElementCompareto(e.target.value)}}
                    >
                    </TextField>
                    {openoutput&&
                        <Output
                            type="string"
                            setschemaMapping={selectGetElementCompareto}
                            closeFieldSelector={()=>{setOpenOutput(false)}}
                            mapping=""
                            pipeline={accessPipeline}
                            pipelinetype={pipelinetype}
                            source={"apps"}
                        >
                        </Output>
                    }
                </div>
                <div>
                    <IconButton onClick={()=>{deleteField()}}>
                        <span class="material-symbols-outlined">
                            delete
                        </span>
                    </IconButton>
                </div>
            </div>
        </>
        
    )

}

const InOperatorBlock = ({con, 
                            i, 
                            j, 
                            accessPipeline, 
                            pipelinetype, 
                            deleteElementCompareto, 
                            selectConstElementCompareto, 
                            selectGetElementCompareto,
                            addElementCompareto,
                            setGetValueCompareTo,
                            getValueCompareTo,
                            initGetElementComapreto,
                            initCustomInputElementCompareto,
                            pipelinestage
                        })=>{

    const [inputtype, setinputtype] = useState(0);
    
    const [openoutput, setOpenOutput] = useState(false);

    const changeInputType = (inputtype)=>{
        if(inputtype==0){
            initCustomInputElementCompareto(i,j);
            setinputtype(0);
        }
        if(inputtype==1){
            initGetElementComapreto(i,j);
            setinputtype(1);
        }
    }

    return (
        <div>
            <div style={{display: "flex"}}>
                <div className={inputtype==0?"inoperator-input-types active":"inoperator-input-types"} onClick={()=>{changeInputType(0)}}>
                    Custom Input
                </div>
                <div className={inputtype==1?"inoperator-input-types active":"inoperator-input-types"} onClick={()=>{changeInputType(1)}}>
                    From Pipeline
                </div>
            </div>
            <div>
                {inputtype==0&&
                    <>
                        {con.valuecompareto.map((c,k)=>{
                            return(
                                    <InOperatorSelect
                                        val={c}
                                        accessPipeline={accessPipeline}
                                        pipelinetype={pipelinetype}
                                        deleteField={deleteElementCompareto(i,j,k)}
                                        selectConstElementCompareto={selectConstElementCompareto(i,j,k)}
                                        selectGetElementCompareto={selectGetElementCompareto(i,j,k)}
                                    >
                                    </InOperatorSelect>
                                )
                        })}
                        <div>
                            <Button onClick={()=>{addElementCompareto(i,j)}}>Add Element</Button>
                        </div>

                    </>
                }
                {inputtype==1&&
                    <>
                        <TextField
                            onClick={()=>{setOpenOutput(!openoutput)}}
                            value={getValueCompareTo(i,j)}
                        >
                        </TextField>
                        {openoutput&&
                            <Output
                                type="array"
                                setschemaMapping={setGetValueCompareTo(i,j)}
                                closeFieldSelector={()=>{setOpenOutput(false)}}
                                mapping=""
                                pipeline={accessPipeline}
                                pipelinetype={pipelinetype}
                                pipelinestage={pipelinestage}
                                source={"apps"}
                            >
                            </Output>   
                        }                     
                    </>
                }
            </div>
        </div>
        
    )
}


const SelectConditionalValue = ({
                                    con,
                                    i,
                                    j,
                                    getValueCompareTo,
                                    setConstValueCompareTo,
                                    setGetValueCompareTo,
                                    variablePipeline,
                                    pipelinetype,
                                    pipelinestage
                                })=>{

    const [openFieldSelector, setOpenFieldSelector] = useState(false);
    
    const closeFieldSelector = ()=>{
        setOpenFieldSelector(false);
    }


    return (
        <div style={{position: "relative"}}>

            <TextField
                value={getValueCompareTo(i,j)} 
                onClick={()=>{setOpenFieldSelector(!openFieldSelector)}}
                onChange={(e)=>{setConstValueCompareTo(i,j,e.target.value)}}
            ></TextField>
            {openFieldSelector&&
                <div style={{position: "absolute", background: "#fff", width: "100%"}}>
                    <Output
                        type={con.valuetocomparetype!=undefined?con.valuetocomparetype:""}
                        setschemaMapping={setGetValueCompareTo(i,j)}
                        closeFieldSelector={closeFieldSelector}
                        mapping=""
                        pipeline={variablePipeline}
                        pipelinetype={pipelinetype}
                        pipelinestage={pipelinestage}
                        source={"apps"}
                    >
                    </Output>
                </div>
            }
        </div>
    )

}


const SelectLikeConditionalValue = ({
                                        i,
                                        j,
                                        con,
                                        setGetValueCompareToLike,
                                        setConstValueCompareToLike,
                                        variablePipeline,
                                        pipelinetype,
                                        pipelinestage                                    
                                    })=>{
    
    const [openFieldSelector, setOpenFieldSelector] = useState(false);
    
    const closeFieldSelector = ()=>{
        setOpenFieldSelector(false);
    }


    return (
        <>
            <div style={{display: "flex", alignItems:"center", padding: 10, border: "1px solid #eee", "position": "relative"}}>
                <div style={{
                                minHeight: "1.5em",
                                fontSize:"1em",
                                minWidth: 200,
                                alignItems: "center"
                            }}
                    onClick={()=>{setOpenFieldSelector(!openFieldSelector)}}
                >
                    
                    <Editor editorState={con.valuecomparetoeditor} onChange={(editorstate)=>{setConstValueCompareToLike(i, j, editorstate)}} />
                </div>
            </div>
            {openFieldSelector&&
            <div style={{position: "absolute", background: "#fff", width: "100%"}}>
                <Output
                    type="any"
                    setschemaMapping={setGetValueCompareToLike(i,j)}
                    closeFieldSelector={closeFieldSelector}
                    mapping=""
                    pipeline={variablePipeline}
                    pipelinetype={pipelinetype}
                    pipelinestage={pipelinestage}
                    source={"apps"}
                >
                </Output>
            </div>
                
            }
        </>
    )
}

export const ConditionBlock = ({
                                    availableFields, 
                                    setschemaMapping,
                                    modifySchema,
                                    pipelinetype,
                                    stageindex,
                                    schema,
                                    schemamapping,
                                    stagetype,
                                    settings,
                                    setSettings,
                                    setQuery,
                                    index,
                                    query,
                                    pipelinestage,
                                    component
                                })=>{

    const [avlFields, setavlFields] = useState([]);

    const dispatch = useDispatch();
    
    useEffect(()=>{
        let availableFieldsCopy = [...availableFields];
        setavlFields(availableFieldsCopy);
    },[availableFields])

    const deleteSchemamapping = (valList)=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        for(let i=0; i< valList.length; i++){
            schemaCopy[valueKeyIndex].subschema.splice(valList[i],1);        
        }
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let smValueIndex = _.findIndex(schemamappingCopy, (sm)=>{return sm.key=="values"});
        for(let i=0; i< valList.length; i++){
            schemamappingCopy[smValueIndex].mapping.splice(valList[i],1);
        }
        return {
            schema: schemaCopy,
            schemamapping: schemamappingCopy
        }
    }

    const deleteConditionBlock = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let valList = [];
        let toBeDeletetedBlock = conditionsCopy[i];
        for(let j=0; j< toBeDeletetedBlock.length; j++){
            valList.push(toBeDeletetedBlock[j].valueindex);
        }
        let updatedObj = deleteSchemamapping(valList);
        conditionsCopy.splice(i,1);
        let conditionalSettings = {...settings,
            conditions: conditionsCopy
           }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                          };
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
                                mapping: {"action": "const", "val": query} 
                               }
        modifySchema(updatedObj.schema,schemamappingCopy);
        setQuery(queryCopy);
    }

    const addValuetoSchema = ()=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        let valueIndex = schemaCopy[valueKeyIndex].subschema.length;
        schemaCopy[valueKeyIndex].subschema.push({
            "key": "value"+valueIndex,
            "label": "Value "+valueIndex,
            "required": false,
            "type": "any",
            "subschema":[],
            "fillallowed": true,
            "guided": false,
            "editallowed": false,
            "typechange": false
        });
        // setSchema(schemaCopy);
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueIndexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        schemamappingCopy[valueIndexsm].mapping.push(
            {
                "key": "value"+valueIndex,
                "mapping": ""
            }
        )
        return {
            valueIndex: valueIndex,
            schema: schemaCopy,
            schemamapping: schemamappingCopy
        };
    }

    const updateallowedschematype = (valindex, valtype)=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        let keyindex = _.findIndex(schemaCopy[valueKeyIndex].subschema,(sc)=>{return sc.key=="value"+valindex});
        if(valtype=="auto"){
            schemaCopy[valueKeyIndex].subschema[keyindex] = {...schemaCopy[valueKeyIndex].subschema[keyindex],
                type: "integer"
               }
        }
        return schemaCopy;
    }

    const updateinoperatorschematype = (valindex)=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        let keyindex = _.findIndex(schemaCopy[valueKeyIndex].subschema,(sc)=>{return sc.key=="value"+valindex});
        schemaCopy[valueKeyIndex].subschema[keyindex] = {...schemaCopy[valueKeyIndex].subschema[keyindex],
                                                            type: "array",
                                                            subschema:[{
                                                            "type": schemaCopy[valueKeyIndex].subschema[keyindex].type
                                                            }]
                                                        }
        
        return schemaCopy;
        
    }

    const setValuetoCompare = (i,j,val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                valuetocompare: val,
                                valuetocomparetype: val.type  
                               }
        let schemaCopy = updateallowedschematype(val.valueindex, val.type);
        let conditionalSettings = {...settings,
            conditions: conditionsCopy
           }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                          };
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        schemamappingCopy[2] = {...schemamappingCopy[2],
                                mapping: {"action": "const", "val": queryCopy} 
                               }
        modifySchema(schemaCopy,schemamappingCopy);
        setQuery(queryCopy);
    }

    const getValueCompareTo = (i,j)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let valueindex = conditionsCopy[i][j].valueindex;
        let valueindexsm = _.findIndex(schemamapping,(sm)=>{return sm.key=="values"});
        let keyindex = _.findIndex(schemamapping[valueindexsm].mapping, (sm)=>{return sm.key=="value"+valueindex});
        // if(schemamapping[valueindexsm].mapping[keyindex]){

        // }
        if(
            schemamapping[valueindexsm].mapping!=undefined&&
            schemamapping[valueindexsm].mapping[keyindex]!=undefined&&
            schemamapping[valueindexsm].mapping[keyindex].mapping!=undefined
          ){
            return schemamapping[valueindexsm].mapping[keyindex].mapping.val;
        }else{
            return ""
        }   
    }

    const deleteCondition = (i,j)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let valList = [conditionsCopy[i][j].valueindex];
        let updatedObj = deleteSchemamapping(valList);
        conditionsCopy[i].splice(j,1);
        let conditionalSettings = {...settings,
            conditions: conditionsCopy
           }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                          };
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
                                mapping: {"action": "const", "val": queryCopy} 
                               }
        modifySchema(JSON.parse(JSON.stringify(updatedObj.schema)),JSON.parse(JSON.stringify(schemamappingCopy)));
        setQuery(queryCopy);
    }

    const addCondition = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let updatedObj = addValuetoSchema();
        conditionsCopy[i].push(
            {
                "valuetocompare":"",
                "valuetocomparetype": "any",
                "tocompareopen":false,
                "condition": "",
                "valuecompareto":"",
                "comparetoopen":false,
                "valueindex": updatedObj.valueIndex
            }  
        )
        let conditionalSettings = {...settings,
            conditions: conditionsCopy
           }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                          };
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
                                mapping: {"action": "const", "val": queryCopy} 
                               }
        modifySchema(updatedObj.schema,schemamappingCopy);
        setQuery(queryCopy);
    }

    const addConBlock = ()=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let updatedObj = addValuetoSchema();
        conditionsCopy.push(
            [{
                "valuetocompare": "",
                "valuetocomparetype": "any",
                "tocompareopen": false,
                "condition": "",
                "valuecompareto": "",
                "comparetoopen": false,
                "valueindex": updatedObj.valueIndex
            }]
        )
        let conditionalSettings = {...settings,
                                   conditions: conditionsCopy
                                  }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
            settings: conditionalSettings
            };
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
            mapping: {"action": "const", "val": queryCopy} 
           }
        modifySchema(updatedObj.schema,schemamappingCopy);
        setQuery(queryCopy);

    }

    const selectCondition = (i,j,val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let oldval = conditionsCopy[i][j].val;
        conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                condition: val
                               }
        let schemaCopy = [...schema];
        if(val=="LIKE"){
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                    valuecompareto: ""
                                   }
            let conditionalSettings = {...settings,
                                        conditions: conditionsCopy
                                      }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                                };
            let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
            schemamappingCopy[2] = {...schemamappingCopy[2],
                                     mapping: {"action": "const", "val": queryCopy} 
                                   }
            
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                    valuecomparetoeditor: EditorState.createEmpty()
                                   }
            conditionalSettings = {...settings,
                                    conditions: conditionsCopy
                                  }
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                                }
            setQuery(queryCopy);
            modifySchema(schemaCopy,schemamappingCopy);
            
        }else if(val=="IN"){
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                    valuecompareto: [""]
                                   }
            schemaCopy = updateinoperatorschematype(conditionsCopy[i][j].valueindex);
            let schemamappingCopy = updateInOperatorComparetoMapping(conditionsCopy[i][j].valueindex, 0, "", "");
            let conditionalSettings = {...settings,
                conditions: conditionsCopy
               }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                              };
            schemamappingCopy[2] = {...schemamappingCopy[2],
                                    mapping: {"action": "const", "val": queryCopy} 
                                   }
            modifySchema(schemaCopy,schemamappingCopy);
            setQuery(queryCopy);
        }else{
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                valuecompareto: [""]
               }
            schemaCopy = updateallowedschematype(conditionsCopy[i][j].valueindex);
            let conditionalSettings = {...settings,
                                        conditions: conditionsCopy
                                       }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                        settings: conditionalSettings
                    };
            let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
            schemamappingCopy[2] = {...schemamappingCopy[2],
                            mapping: {"action": "const", "val": queryCopy} 
                        }
            modifySchema(schemaCopy,schemamappingCopy);
            setQuery(queryCopy);
        }
    }
    
    // special conditions mapping handlers
    const updateComparetoSchemamapping = (valueindex, val, action, key)=>{
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueindexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        let keyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping, (mp)=>{return mp.key=="value"+valueindex});
        if(action=="template"){
            if(schemamappingCopy[valueindexsm].mapping[keyindex].mapping.datakeys!=undefined){
                let datakeysCopy = [...schemamappingCopy[valueindexsm].mapping[keyindex].mapping.datakeys];
                if(key!=""){
                    let datakeyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping[keyindex].datakeys, (k)=>{return k==key});
                    if(datakeyindex==-1){
                        datakeysCopy.push(key);
                        dispatch(setmappingkey({
                            oldkey: "",
                            key: key,
                            stageindex:stageindex, 
                            stagetype: stagetype
                        }))
                    }
                }
                schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                                        mapping:{
                                                                                "action": action, 
                                                                                "val": val,
                                                                                "datakeys": datakeysCopy
                                                                            }
                                                                    }
            }else{
                let datakeys = []
                datakeys.push(key);
                dispatch(setmappingkey({
                    oldkey: "",
                    key: key,
                    stageindex:stageindex, 
                    stagetype: stagetype
                }))
                schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                                        mapping:{
                                                                                "action": action, 
                                                                                "val": val,
                                                                                "datakeys": datakeys
                                                                            }
                                                                    }
            }
            
        }else{
            if(schemamappingCopy[valueindexsm].mapping[keyindex].mapping?.action!="get"){
                dispatch(setmappingkey({
                    oldkey: "",
                    key:val.schemaval,
                    stageindex:stageindex, 
                    stagetype: stagetype
                }))
            }else{
                dispatch(setmappingkey({
                    oldkey: schemamappingCopy[valueindexsm].mapping[keyindex].mapping.val,
                    key:val.schemaval,
                    stageindex:stageindex, 
                    stagetype: stagetype
                }))
            }
            schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                                 mapping:{"action": action, "val": val}
                                                                }

        }
        return schemamappingCopy;
    }

    const updateInOperatorComparetoMapping = (valindex,argindex ,val, key)=>{
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueindexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        let keyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping, (mp)=>{return mp.key=="value"+valindex});
        let valcopy = [];
        if(schemamappingCopy[valueindexsm].mapping[keyindex].mapping!=""&&schemamappingCopy[valueindexsm].mapping[keyindex].mapping.action=="template"){
            valcopy = [...schemamappingCopy[valueindexsm].mapping[keyindex].mapping.val]
        }
        valcopy[argindex] = val;
        if(schemamappingCopy[valueindexsm].mapping[keyindex].mapping.datakeys!=undefined){
            let datakeyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping[keyindex].mapping.datakeys, (k)=>{return k==key});
            let datakeycopy = [...schemamappingCopy[valueindexsm].mapping[keyindex].mapping.datakeys];
            if(datakeyindex==-1){
                datakeycopy.push(key);
                dispatch(setmappingkey({
                    oldkey: "",
                    key:key,
                    stageindex:stageindex, 
                    stagetype: stagetype
                   }))
            }
            schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                              mapping: {"action": "template", "val": valcopy, "datakeys":datakeycopy}
                                                            }
        }else{
            let datakeycopy = [];
            datakeycopy.push(key);
            dispatch(setmappingkey({
                oldkey: "",
                key:key,
                stageindex:stageindex, 
                stagetype: stagetype
               }))
            schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                              mapping: {"action": "template", "val": valcopy, "datakeys":datakeycopy}
                                                            }   
        }
        return schemamappingCopy;
    }

    const deleteInOperatorComparetoMapping = (valindex, argindex)=>{
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueindexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        let keyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping, (mp)=>{return mp.key=="value"+valindex});
        schemamappingCopy[valueindexsm].mapping[keyindex].mapping.val.splice(argindex,1);
        return schemamappingCopy;
    }

    const setConstValueCompareTo = (i,j, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        let valueindex = conditionsCopy[i][j].valueindex;
        let schemaMappingCopy = updateComparetoSchemamapping(valueindex, val, "const", "");
        setschemaMapping(schemaMappingCopy);
    }

    const setGetValueCompareTo = (i,j)=>{
        return (mapping)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
            let valueindex = conditionsCopy[i][j].valueindex;
            let schemaMappingCopy = updateComparetoSchemamapping(valueindex, mapping.schemaval, "get");
            setschemaMapping(schemaMappingCopy)
            // dispatch(setmappingkey({
            //     key:mapping.schemaval,
            //     stageindex:stageindex, 
            //     stagetype: stagetype
            //    }))
        }
    }

    // IN OPERATOR HANDLER
    const initCustomInputElementCompareto = (i,j)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        conditionsCopy[i][j].valuecompareto = [""];
        let schemaMappingCopy = updateInOperatorComparetoMapping(conditionsCopy[i][j].valueindex,"","","");
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                           };
        let conditionalSettings = {...settings,
                                    conditions: conditionsCopy
                                  }
        schemaMappingCopy[2] = {...schemaMappingCopy[2],
                                mapping: {"action": "const", "val": queryCopy} 
                               }
        setschemaMapping(schemaMappingCopy)
    }

    const initGetElementComapreto = (i,j)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        conditionsCopy[i][j].valuecompareto = "";
        setSettings({...settings,
                     conditions: conditionsCopy
                    })
        let schemaMappingCopy = updateComparetoSchemamapping(conditionsCopy[i][j].valueindex, "", "GET", "");
        setschemaMapping(schemaMappingCopy);
    }

    const addElementCompareto = (i,j)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        conditionsCopy[i][j].valuecompareto.push("");
        setSettings({...settings,
                     conditions: conditionsCopy
                    })
    }

    const deleteElementCompareto = (i,j,k)=>{
        return ()=>{
            let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
            let valuecompareto = [...conditionsCopy[i][j].valuecompareto];
            valuecompareto.splice(k,1);
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                    valuecompareto: valuecompareto
                                    };
            let schemaMappingCopy = deleteInOperatorComparetoMapping(conditionsCopy[i][j].valueindex, k);
            let conditionalSettings = {...settings,
                                       conditions: conditionsCopy
                                      }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                            };
            schemaMappingCopy[2] = {...schemaMappingCopy[2],
                                    mapping: {"action": "const", "val": queryCopy} 
                                    }
            setschemaMapping(schemaMappingCopy)
            setQuery(queryCopy);
        }
    }

    const selectConstElementCompareto = (i,j,k)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
            let valuecompareto = [...conditionsCopy[i][j].valuecompareto];
            valuecompareto[k] = val;
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                     valuecompareto: valuecompareto
                                    }
            let schemaMappingCopy = updateInOperatorComparetoMapping(conditionsCopy[i][j].valueindex,k,val,"");
            let conditionalSettings = {...settings,
                                       conditions: conditionsCopy
                                      }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                            };
            schemaMappingCopy[2] = {...schemaMappingCopy[2],
                                    mapping: {"action": "const", "val": queryCopy} 
                                    }
            setschemaMapping(schemaMappingCopy)
            setQuery(queryCopy)
        }
    }

    const selectGetElementCompareto = (i,j,k)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
            let valuecompareto = [...conditionsCopy[i][j].valuecompareto];
            valuecompareto[k] = val.schemaval;
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                    valuecompareto: valuecompareto
                                    }
            let schemaMappingCopy = updateInOperatorComparetoMapping(conditionsCopy[i][j].valueindex, k, "<%="+val.schemaval+"%>",val.schemaval);
            let conditionalSettings = {...settings,
                                       conditions: conditionsCopy
                                      }
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                                }
            schemaMappingCopy[2] = {...schemaMappingCopy[2],
                                    mapping: {"action": "const", "val": queryCopy}
                                    }
            setschemaMapping(schemaMappingCopy);
            setQuery(queryCopy);
        }
    }

    // LIKE OPERATOR HANDLER
    const setConstValueCompareToLike = (i,j,editorstate)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(settings.conditions));
        conditionsCopy[i][j] = {...conditionsCopy[i][j],
                                valuecomparetoeditor: editorstate
                               }
        const contentState = editorstate.getCurrentContent();
        let schemaMappingCopy = updateComparetoSchemamapping(conditionsCopy[i][j].valueindex, contentState.getPlainText(),"template","");
        let conditionalSettings = {...settings,
                                   conditions: conditionsCopy
                                  }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: conditionalSettings
                          };
        setQuery(queryCopy);
        setschemaMapping(schemaMappingCopy)

    }

    const setGetValueCompareToLike = (i,j)=>{
        return (mapping)=>{
            let queryCopy = [...query];
            let conditionsCopy = [...queryCopy[index].settings.conditions];
            let editor = conditionsCopy[i][j].valuecomparetoeditor; 
            let updatedContent = Modifier.insertText(editor.getCurrentContent(),editor.getSelection(),"<%="+ mapping.schemaval +"%>");
            editor = EditorState.set(editor, {currentContent: updatedContent});
            let valueindex = conditionsCopy[i][j].valueindex;
            let schemaMappingCopy = JSON.parse(JSON.stringify(schemamapping));
            if(mapping.schemaval!=undefined){
                schemaMappingCopy = updateComparetoSchemamapping(valueindex, editor.getCurrentContent().getPlainText(),'template',mapping.schemaval);
            }
            conditionsCopy[i][j] = {...conditionsCopy[i][j],
                valuecomparetoeditor: editor
               }
            let conditionalSettings = {...settings,
                                       conditions: conditionsCopy
                                      }
            queryCopy[index] = {...queryCopy[index],
                                settings: conditionalSettings
                            };
            setQuery(queryCopy);
            setschemaMapping(schemaMappingCopy)

        }
    }
    
    //get the value_compare_to_input_value
    const getValComToInputValue = (i,j)=>{
        let conditionsCopy = [...settings.conditions];
        let valueindex = conditionsCopy[i][j].valueindex;
        let valueindexsm = _.findIndex(schemamapping,(sm)=>{return sm.key=="values"});
        let keyindex = _.findIndex(schemamapping[valueindexsm].mapping, (sm)=>{return sm.key=="value"+valueindex});
        if(schemamapping[valueindexsm].mapping!=undefined&&schemamapping[valueindexsm].mapping[keyindex]!=undefined){
            let val = getMappingValue(schemamapping[valueindexsm].mapping[keyindex].mapping);
            return val;
        }else{
            return "";
        }
        
    }

    const changeExcludeUndefined = (val)=>{
        let conditionalSettings = {...settings,
                                    excludeundefined: val
                                  }
        setSettings(conditionalSettings);
    }


    return (
        <div className="query-block">
            {component=="query"&&
                <div style={{padding: "10px"}}>
                    Exclude undefined filters <input type="checkbox" checked={settings.excludeundefined} onChange={(e)=>{changeExcludeUndefined(e.target.checked)}}></input>
                </div>
            }
            {settings.conditions.map((conblock, i)=>{
                return(
                    <>
                        {i==0?
                            <>Where</>:
                            <div className="or-block-desc">
                                <div>Or</div>
                                <div>
                                    <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                    <div className="condition-line">
                                        <div>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id="select-field-value-to-compare"> Select Field</InputLabel>
                                                <Select
                                                    labelId="select-field-value-to-compare"
                                                    id="select-field"
                                                    value={con.valuetocompare}
                                                    onChange={(e)=>{setValuetoCompare(i,j,e.target.value)}}
                                                    renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                                >
                                                    {avlFields.map((field)=>{
                                                        return (<MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={con.condition}
                                                    label="Condition"
                                                    onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                                >
                                                    {DbConditionalOperators.map((op)=>{
                                                        return(
                                                            <MenuItem value={op.val}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    <div>
                                        <div>
                                            <div className="decalare-variable-data-type">
                                                <span style={{padding: 10}}>
                                                    type: {con.valuetocomparetype}
                                                </span>
                                                <span style={{display: "flex", alignItems: "center"}}>
                                                    input: <pre>{JSON.stringify(getValComToInputValue(i,j))}</pre>
                                                </span>
                                            </div>
                                        </div>
                                        {con.condition=="LIKE"&&
                                            <>
                                                <SelectLikeConditionalValue
                                                    con={con}
                                                    i={i}
                                                    j={j}
                                                    pipelinetype={pipelinetype}
                                                    setGetValueCompareToLike={setGetValueCompareToLike}
                                                    setConstValueCompareToLike={setConstValueCompareToLike}
                                                    getValueCompareTo={getValueCompareTo}
                                                    pipelinestage={pipelinestage}
                                                >
                                                </SelectLikeConditionalValue>
                                                <div>
                                                    <span style={{fontSize: 10}}>Supports '%' and '_' wildcard operators</span>
                                                </div>
                                            </>
                                            
                                        }
                                        {con.condition=="IN"&&
                                            <>
                                                <InOperatorBlock
                                                    con={con}
                                                    i={i}
                                                    j={j}
                                                    pipelinetype={pipelinetype}
                                                    deleteElementCompareto={deleteElementCompareto}
                                                    selectConstElementCompareto={selectConstElementCompareto}
                                                    selectGetElementCompareto={selectGetElementCompareto}
                                                    addElementCompareto={addElementCompareto}
                                                    setGetValueCompareTo={setGetValueCompareTo}
                                                    getValueCompareTo={getValueCompareTo}
                                                    initGetElementComapreto={initGetElementComapreto}
                                                    initCustomInputElementCompareto={initCustomInputElementCompareto}
                                                    pipelinestage={pipelinestage}
                                                >
                                                </InOperatorBlock>
                                            </>
                                        }
                                        {(con.condition!="LIKE"&&con.condition!="BETWEEN"&&con.condition!="IN")&&
                                            <>
                                                <SelectConditionalValue
                                                    con={con}
                                                    i={i}
                                                    j={j}
                                                    getValueCompareTo={getValueCompareTo}
                                                    setConstValueCompareTo={setConstValueCompareTo}
                                                    setGetValueCompareTo={setGetValueCompareTo}
                                                    // variablePipeline={variablePipeline}
                                                    pipelinetype={pipelinetype}
                                                    pipelinestage={pipelinestage}
                                                >
                                                </SelectConditionalValue>
                                            </>
                                        }
                                    </div>
                                    <div className="close-button" onClick={()=>{deleteCondition(i,j)}}>
                                        <span class="material-symbols-outlined">
                                            close
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {i==(settings.conditions.length-1)?
                        <>
                            <div className="condition-operators">
                                <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                        And
                                </div>
                                <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                        Or
                                </div>
                            </div>
                        </>:
                        <>
                            <div className="condition-operators">
                                <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                        And
                                </div>
                            </div>
                        </>
                    }
                    <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                </>
            )
        })}
        </div>       
    )

};

const GroupByBlock = ({availableFields, settings, setSettings})=>{

    const setColumn = (i, val)=>{
        let columnsCopy = [...settings.columns];
        columnsCopy[i] = val;
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }


    const addColumn = ()=>{
        let columnsCopy = [...settings.columns];
        columnsCopy.push("");
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }

    const deleteColumn = (i)=>{
        let columnsCopy = [...settings.columns];
        columnsCopy.splice(i,1);
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }

    return(
        <div className="query-block">
            <div>
                Group By
            </div>
            <div>
                {settings.columns.map((column, index)=>{
                    return(
                        <div className="group-by-block-row">
                            <div className="group-by-row-block">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id="select-field-groupby">Select Field</InputLabel>
                                    <Select
                                        id="select-field-groupby"
                                        label="Select Field"
                                        value={column}
                                        onChange={(e)=>{setColumn(index, e.target.value)}}
                                        renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                    >
                                        {availableFields.map((field)=>{
                                            return(
                                                <MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="group-by-row-block">
                                <IconButton onClick={()=>{deleteColumn(index)}}>
                                    <span className="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                            </div>
                            
                        </div>
                    )
                })}
            </div>
            <div>
                <Button onClick={()=>{addColumn()}}>Add Field</Button>
            </div>
        </div>
    )
}

const OrderbyBlock = ({availableFields, settings, setSettings})=>{
    
    const setColumnsField = (i, val)=>{
        let columnsCopy = [...settings.columns];
        columnsCopy[i] = {...columnsCopy[i],
                          field: val
                         };
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }

    const setColumnDirection = (i, val)=>{
        let columnsCopy = [...settings.columns];
        columnsCopy[i] = {...columnsCopy[i],
                          direction: val
                         };
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }

    const addColumn = ()=>{
        let columnsCopy = [...settings.columns];
        columnsCopy.push({"field": "", "direction": "desc"});
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }


    const deleteColumn = (i)=>{
        let columnsCopy = [...settings.columns];
        columnsCopy.splice(i);
        setSettings({...settings,
                     columns: columnsCopy
                    })
    }

    return (
        <div className="query-block">
            <div>
                Order by
            </div>
            <div>
                {settings.columns.map((column,i)=>{
                    return (
                        <div className="order-by-block-row">
                            <div className="order-by-row-block">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id="select-field-order-by">Select Field</InputLabel>
                                    <Select
                                        value={column.field}
                                        onChange={(e)=>{setColumnsField(i, e.target.value)}}
                                        id="select-field-order-by"
                                        label="Select Field"
                                        renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                    >
                                        {availableFields.map((field)=>{
                                            return (
                                                <MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="order-by-row-block">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id="select-direction-by">Select direction</InputLabel>
                                    <Select
                                        value={column.direction}
                                        onChange={(e)=>{setColumnDirection(i, e.target.value)}}
                                        id="select-direction-by"
                                        label="Select Direction"
                                    >
                                        <MenuItem value="desc">Descending</MenuItem>
                                        <MenuItem value="asc">Ascending</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="order-by-row-block">
                                <IconButton onClick={()=>{deleteColumn(i)}}>
                                    <span className="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <Button onClick={()=>{addColumn()}} style={{color: "#3A07CD"}}>Add Field</Button>
            </div>
        </div>
    )
}

const JoinBlock = ({
                        availableSchemas, 
                        availableFields, 
                        setAvailableFields,
                        settings,
                        setSettings
                    })=>{
    
    const getAvailableFields = (i)=>{
        return availableFields.filter((field)=>{
                let source  = field.source;
                if(i==0){
                    return field.source=="from";
                }else{
                    if(field.source=="from"){
                        return true;
                    }else{
                        let joinIndex = field.source.split("_");
                        if(joinIndex[0]=="join"){
                            if(joinIndex[1]<i){
                                return true;
                            }else{
                                return false;
                            }
                        }
                        
                    }
                } 
            })
    }

    const getJoineeAvailableFields = (i)=>{
        return availableFields.filter((field)=>{
            let source  = field.source;
            let joinIndex = source.split("_");
            if(joinIndex[0]=="join"){
                if(parseInt(joinIndex[1])==i){
                    return true;
                }else{
                    return false;
                }
            }
            return false;
        })
    }

    const addJoins = ()=>{
        let joinsCopy = [...settings.joins];
        joinsCopy.push({
            "schema": {"app": "", "schemaslug": ""},
            "joinfield": "",
            "jointofield": "",
            "type": "inner"
        })
        setSettings({...settings,
                     joins: joinsCopy
                    })
    }

    const setJoinField = (val, index)=>{
        let joinsCopy = [...settings.joins];
        joinsCopy[index] = {...joinsCopy[index],
                            joinfield: val
                            }
        setSettings({...settings,
                     joins: joinsCopy
                    })
    }

    const setJointoField = (val, index)=>{
        let joinsCopy = [...settings.joins];
        joinsCopy[index] = {...joinsCopy[index],
                            jointofield: val
                            }
        setSettings({...settings,
                     joins: joinsCopy
                    })
    }

    const resetFromAvailableFields = (table, source)=>{
        let appindex = _.findIndex(availableSchemas,(as)=>{   
                                                            return as.appname==table.app
                                                          });
        let app = {...availableSchemas[appindex]};
        let schemaindex = _.findIndex(app.schemalist, (sh)=>{ return sh.schemaslug==table.schemaslug});
        let schema = {...app.schemalist[schemaindex]};
        let avlFeilds = [];
        for(let i=0; i< schema.schema.length; i++){
            avlFeilds.push({
                "key": schema.schema[i].inputkey,
                "type": schema.schema[i].inputtype,
                "app": app.appname,
                "schema": schema.schemaslug,
                "source":  source
            })
        }
        // remove from the available fields
        for(let i=0; i< availableFields.length; i++){
            if(availableFields[i].source!=source){
                avlFeilds.push(availableFields[i]);
            }
        }
        // console.log(avlFeilds);
        setAvailableFields(avlFeilds);
    }

    const setSchema = (i)=>{
        return (val)=>{
            let joinsCopy = [...settings.joins];
            joinsCopy[i] = {...joinsCopy[i],
                            schema: val
                           };
            resetFromAvailableFields(val, "join_"+i.toString());
            setSettings({...settings,
                         joins: joinsCopy
                        });
        }
    }

    const deleteJoins = (i)=>{
        let joinsCopy = [...settings.joins];
        joinsCopy.splice(i);
        setSettings({...settings,
                     joins: joinsCopy
                    })
    }

    const setJoinType = (val, index)=>{
        let joinsCopy = [...settings.joins];
        joinsCopy[index] = {...joinsCopy[index],
                            type: val
                           }                
        setSettings({...settings,
                     joins: joinsCopy
                    })
    }

    return (
        <div className="query-block">
            <div>
                Join
            </div>
            <div>
                {settings.joins.map((join,i)=>{
                    return(
                        <div className="join-block-row">
                            <div className="join-row-block">
                                <FormControl sx={{width: 200}}>
                                    <Select
                                        value={join.type}
                                        onChange={(e)=>{setJoinType(e.target.value, i)}}
                                    >
                                        <MenuItem value="inner">INNER</MenuItem>
                                        <MenuItem value="left">LEFT</MenuItem>
                                        <MenuItem value="right">RIGHT</MenuItem>
                                        <MenuItem value="full">FULL</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="join-row-block">
                                <FromSchemaBlock availableSchemas={availableSchemas} setSchema={setSchema(i)} schema={join.schema}></FromSchemaBlock>
                            </div>
                            <div className="join-row-block">
                                ON
                            </div>
                            <div className="join-row-block">
                                <FormControl
                                    sx={{width: 200}}
                                >
                                    <Select
                                        value={join.joinfield}
                                        onChange={(e)=>{setJoinField(e.target.value, i)}}
                                        renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                    >
                                        {getAvailableFields(i).map((field)=>{
                                            return (<MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="join-row-block">
                                =
                            </div>
                            <div className="join-row-block">
                                <FormControl sx={{width: 200}}>
                                    <Select
                                        value={join.jointofield}
                                        onChange={(e)=>{setJointoField(e.target.value, i)}}
                                        renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                    >
                                        {getJoineeAvailableFields(i).map((field)=>{
                                            return (<MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <IconButton onClick={()=>{deleteJoins(i)}}>
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                            </div>
                        </div>
                    )
                })

                }
            </div>
            <div>
                <Button onClick={()=>{addJoins()}}>Add Join</Button>
            </div>
        </div>
    )
}

const LimitBlock = ({
                        settings, 
                        setSettings, 
                        pipelinetype,
                        stageindex,
                        stagetype,
                        pipelinestage,
                        pipelineid
                    })=>{

    const setSchema = (schema)=>{
            let settingsCopy = {...settings,
                                schema: schema
                                };
            setSettings(settingsCopy)
    }     
    
    const setSchemaMapping = (mapping)=>{
        let settingsCopy = {...settings,
                            schemamapping: mapping
                            }
        setSettings(settingsCopy);
    }

    const modifySchemaLimit = (sc, sm)=>{
        let settingsCopy = {...settings,
                            schema: sc,
                            schemamapping: sm
                            }
        setSettings(settingsCopy);
    }


    return (
        <div className="query-block">
            <div>
                Limit
            </div>
            <div className="limit-block-row">
                <DeclareVariable
                    schema={settings.schema} 
                    setSchema={setSchema}
                    modifySchema={modifySchemaLimit}
                    schemamapping={settings.schemamapping} 
                    setschemaMapping={setSchemaMapping}
                    pipelinetype={pipelinetype}
                    stageindex={stageindex}
                    stagetype={stagetype}
                    layout="horizontal"
                    editable={false}
                    pipelinestage = {pipelinestage}
                    source="apps"
                    sourceid={pipelineid}
                >
                </DeclareVariable>
            </div>
        </div>
    )
}

const QueryBuilder = ({
    initapp,
    initschema,
    schema,
    schemamapping,
    setschemaMapping,
    modifySchema,
    setSchema,
    pipelinetype,
    stageindex,
    stagetype,
    pipelinestage,
    pipelineid
})=>{

    const {workspace} = useParams();

    const [availableFields, setAvailableFields] = useState([{
        "key":"*",
        "type": "",
        "app":"",
        "schema":"",
        "source":""
    }]);

    const [initialized, setInitialized] = useState(false);

    const [buildertype, setBuilderType] = useState("standard");

    const [query, setQuery] = useState([
        {
            "type": "select", 
            "settings":{
                "fields":[{
                            type:"automata_select",
                            "args":[{
                                    "key": "",
                                    "app": "",
                                    "schema": "",
                                    "source": "" 
                            }]}]
                }, 
            "enabled": true, 
            "expanded": true
        },
        {
            "type": "from", 
            "settings":{
                "table": {
                    "app": "",
                    "schemaslug": ""
                }
            },
            "enabled": true,
            "expanded": true
        },       
        {
            "type": "join", 
            "settings":{
                "joins":[]
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "where",
             "settings": {
                "conditions":[[]],
                "excludeundefined": false
             },
             "enabled": true,
             "expanded": true
        },
        {
            "type": "groupby", 
            "settings":{
                "columns":[]
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "orderby", 
            "settings":{
                "columns":[]
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "limit", 
            "settings":{
                "schema":[
                    {
                        "key": "limit",
                        "label":"Limit",
                        "required":true,
                        "type": "integer",
                        "help": "",
                        "default": 10,
                        "subschema":[],
                        "fillallowed": true
                    },
                    {
                        "key": "offset",
                        "label": "Offset",
                        "help": "",
                        "default": 0,
                        "required": true,
                        "type": "integer",
                        "subschema":[],
                        "fillallowed": true
                    }
                ],
                "schemamapping":[
                    {
                        "key": "limit",
                        "mapping":""
                    },
                    {
                        "key": "offset",
                        "mapping": ""
                    }
                ]
            },
            "enabled": true,
            "expanded": true
        },
    ]);

    const dispatch = useDispatch();

    const applist = useSelector(selectApplist);

    const appschemas = useSelector(selectAppSchemas);

    const setSettings=(index)=>{
        return (settings)=>{
            let queryCopy = [...query];
            if(queryCopy[index].type=="from"){
                let table = settings.table;
                resetFromAvailableFields(table)
            }
            queryCopy[index] = {...queryCopy[index],
                                settings: settings
                                };
            updateQuerySettingsMapping(queryCopy);
            setQuery(queryCopy);
        }
    }

    const updateQuerySettingsMapping = (query)=>{
            let schemamappingCopy = [...schemamapping];
            schemamappingCopy[2] = {...schemamappingCopy[2],
                                    mapping: {"action": "const", "val": query} 
                                   }
            setschemaMapping(schemamappingCopy);
    }

    // if available field change trickle down the effect to select , where , order by , group by , join on 
    const removeOldFields = ()=>{

    }

    const resetFromAvailableFields = (table)=>{
        let appindex = _.findIndex(appschemas,(as)=>{   
                                                        return as.appname==table.app
                                                    });
        let app = {...appschemas[appindex]};
        let schemaindex = _.findIndex(app.schemalist, (sh)=>{ return sh.schemaslug==table.schemaslug});
        let schema = {...app.schemalist[schemaindex]};
        let avlFeilds = [];
        for(let i=0; i< schema.schema.length; i++){
            avlFeilds.push({
                "key": schema.schema[i].inputkey,
                "app": app.appname,
                "schema": schema.schemaslug,
                "type": schema.schema[i].inputtype,
                "source": "from" 
            })
        }
        // remove from the available fields
        for(let i=0; i< availableFields.length; i++){
            if(availableFields[i].source!="from"){
                avlFeilds.push(availableFields[i]);
            }
        }
        setAvailableFields(avlFeilds);        
    }

    const initializeAvailableFields = (query)=>{
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        let fromapp = queryCopy[fromqueryindex].settings.table.app;
        let fromschemaslug = queryCopy[fromqueryindex].settings.table.schemaslug;
        let avlFeilds = [];
        let fromappindex = _.findIndex(appschemas,(as)=>{   
            return as.appname==fromapp
          });
        let fromschemaapp = {...appschemas[fromappindex]};
        let fromschemaindex = _.findIndex(fromschemaapp.schemalist, (sh)=>{ return sh.schemaslug==fromschemaslug});
        let fromschema = {...fromschemaapp.schemalist[fromschemaindex]};
        for(let i=0; i< fromschema.schema.length; i++){
            avlFeilds.push({
                "key": fromschema.schema[i].inputkey,
                "type": fromschema.schema[i].inputtype,
                "app": fromschemaapp.appname,
                "schema": fromschema.schemaslug,
                "source":  "from"
            })
        }
        let joinblock = queryCopy[2];
        if(joinblock.settings.joins.length>0){
            for(let i=0; i< joinblock.settings.joins.length; i++){
                let join = joinblock.settings.joins[i];
                let source = "join"+i.toString();
                let joinappindex = _.findIndex(appschemas, (as)=>{ return as.appname==join.schema.app});
                let joinapp = {...appschemas[joinappindex]};
                let joinschemaindex = _.findIndex(joinapp.schemalist, (sh)=>{return sh.schemaslug==join.schema.schemaslug});
                let joinschema = {...joinapp.schemalist[joinschemaindex]};
                for(let j=0; j< joinschema.schema.length; j++){
                    avlFeilds.push({
                        "key": joinschema.schema[j].inputkey,
                        "type": joinschema.schema[j].inputtype,
                        "app": joinapp.appname,
                        "schema": joinschema.schemaslug,
                        "source": source
                    })
                }
            }
        }
        setAvailableFields(avlFeilds);
    }

    useEffect(()=>{
        if(schemamapping==undefined||schemamapping.length==0){
            return
        }
        if(!initialized){
            if(Array.prototype.isPrototypeOf(schemamapping[2].mapping.val)&&schemamapping[2].mapping.val.length>0){
                let queryCopy = [...schemamapping[2].mapping.val];
                setQuery(queryCopy);
                if(appschemas.length>0){
                    initializeAvailableFields(queryCopy)
                }
            }
            setInitialized(true);  
        }
    },[schemamapping])
    


    useEffect(()=>{
        if(initapp==""||initschema?.schemaslug==""||initschema?.schemaslug==undefined||appschemas?.length==0){
            return;
        }
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        if(queryCopy[fromqueryindex].settings.table.app==""&&queryCopy[fromqueryindex].settings.table.schemaslug==""){
            queryCopy[fromqueryindex].settings = {...queryCopy[fromqueryindex].settings,
                table: {
                    "app" : initapp,
                    "schemaslug": initschema.schemaslug
                }   
            }
            queryCopy[0].settings = {...queryCopy[0].settings,
                                        "fields":[{
                                            type:"automata_select",
                                            "args":[{
                                                    "key": "*",
                                                    "app": "",
                                                    "schema": "",
                                                    "source": "" 
                                            }]}]
                                    }

                                     
            resetFromAvailableFields({
                                        "app" : initapp,
                                        "schemaslug": initschema.schemaslug
                                    })
        }else{
            initializeAvailableFields(queryCopy);
        }
        updateQuerySettingsMapping(queryCopy)
        setQuery(queryCopy);
    },[initschema,appschemas])

    useEffect(()=>{
        for(let i=0; i< applist.length; i++){
            dispatch(getAppSchemas(applist[i]._id));
        }
    },[applist])

    useEffect(()=>{
        dispatch(getApps({
            workspace: workspace
        }))
    },[])

    return (
        <div>
            <div>
                <div className="builder-types">
                    <div className={buildertype=="standard"?"builder-types-item active":"builder-types-item"} onClick={()=>{setBuilderType("standard")}}>
                        Standard
                    </div>
                    <div className={buildertype=="advanced"?"builder-types-item active":"builder-types-item"} onClick={()=>{setBuilderType("advanced")}}>
                        Advanced
                    </div>
                </div>
                {buildertype=="advanced"&&
                    <>
                        {query.map((block, index)=>{
                            return(
                                <>
                                    {block.type=="select"&&
                                        <SelectBlock 
                                            setSettings={setSettings(index)} 
                                            settings={block.settings} 
                                            availableFields={availableFields}
                                        ></SelectBlock>
                                    }
                                    {block.type=="from"&&
                                        <FromBlock
                                            settings={block.settings} 
                                            availableSchemas={appschemas} 
                                            setSettings={setSettings(index)}
                                        ></FromBlock>
                                    }
                                    {block.type=="join"&&
                                        <JoinBlock 
                                            availableSchemas={appschemas} 
                                            availableFields={availableFields} 
                                            setAvailableFields={setAvailableFields}
                                            settings={block.settings}
                                            setSettings={setSettings(index)}
                                        ></JoinBlock>
                                    }
                                    {block.type=="where"&&
                                        <ConditionBlock 
                                            availableFields={availableFields} 
                                            setSchema={setSchema}
                                            setschemaMapping={setschemaMapping}
                                            modifySchema={modifySchema}
                                            pipelinetype={pipelinetype}
                                            stageindex={stageindex}
                                            schema={schema}
                                            schemamapping={schemamapping}
                                            stagetype={stagetype}
                                            settings={block.settings}
                                            setSettings={setSettings(index)}
                                            setQuery={setQuery}
                                            query={query}
                                            index={index}
                                            pipelinestage={pipelinestage}
                                            component="query"
                                        ></ConditionBlock>
                                    }
                                    {block.type=="orderby"&&
                                        <OrderbyBlock 
                                            availableFields={availableFields}
                                            settings = {block.settings}
                                            setSettings={setSettings(index)}
                                        ></OrderbyBlock>
                                    }
                                    {block.type=="limit"&&
                                        <LimitBlock 
                                            settings={block.settings}
                                            setSettings={setSettings(index)}
                                            pipelinestage={pipelinestage}
                                            pipelineid={pipelineid}
                                        ></LimitBlock>
                                    }
                                    {block.type=="groupby"&&
                                        <GroupByBlock 
                                            availableFields={availableFields}
                                            settings={block.settings}
                                            setSettings={setSettings(index)}
                                        ></GroupByBlock>
                                    }
                                    {block.type=="union"&&
                                        <QueryBuilder></QueryBuilder>
                                    }
                                </>
                            )
                        })}    
                    </>
                }
                {buildertype=="standard"&&
                    <>
                        <ConditionBlock 
                            availableFields={availableFields} 
                            setSchema={setSchema}
                            setschemaMapping={setschemaMapping}
                            modifySchema={modifySchema}
                            pipelinetype={pipelinetype}
                            stageindex={stageindex}
                            schema={schema}
                            schemamapping={schemamapping}
                            stagetype={stagetype}
                            settings={query[3].settings}
                            setSettings={setSettings(3)}
                            setQuery={setQuery}
                            query={query}
                            index={3}
                            pipelinestage={pipelinestage}
                        ></ConditionBlock>
                        <OrderbyBlock 
                            availableFields={availableFields}
                            settings = {query[5].settings}
                            setSettings={setSettings(5)}
                        ></OrderbyBlock>
                        <LimitBlock 
                            settings={query[6].settings}
                            setSettings={setSettings(6)}
                            pipelinestage={pipelinestage}
                            pipelineid={pipelineid}
                        ></LimitBlock>
                    </>
                }
            </div>
        </div>
    )
}

export default QueryBuilder;