import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { getDatatransferApi, getPipelinecountApi, getpipelinehttpusageApi, getPipelineusageApi, getRouteloadsApi, getSchemarecordsApi, getStorageApi } from "./usage.service";


export const getPipelineusage = createAsyncThunk(
    "usage/getpipelineusage",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getPipelineusageApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error fetching function usage"));
            throw error;
        }
    }
)


export const getPipelinecount = createAsyncThunk(
    "usage/getpipelinecount",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getPipelinecountApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error fetching events count"));
            throw error;
        }
    }
)


export const getpipelinehttpusage = createAsyncThunk(
    "usage/pipelinehttpusage",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getpipelinehttpusageApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error getting pipeline http usage"));
            throw error;
        }
    }
)


export const getSchemarecords = createAsyncThunk(
    "usage/getschemarecords",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getSchemarecordsApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error getting schema records"));
            throw error;
        }
    }
)

export const getRouteloads = createAsyncThunk(
    "usage/getrouteloads",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getRouteloadsApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error getting route loads"));
            throw error;
        }
    }
)


export const getStorage = createAsyncThunk(
    "usage/getstorage",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getStorageApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error getting storage"));
            throw error;
        }
    }
)


export const getDatatransfer = createAsyncThunk(
    "usage/getdatatransfer",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(setloader());
            let res = await getDatatransferApi(payload);
            dispatch(resetloader());
            return res;
        }catch(error){
            dispatch(resetloader());
            dispatch(showError("Error getting data transfer"));
            throw error;
        }
    }
)


const usageSlice = createSlice({
    "name": "usageslice",
    "initialState":{
        pipelineusage: 0,
        pipelinecount: 0,
        pipelinehttpusage: 0,
        schemarecords: 0,
        routeloads: 0,
        storage: 0,
        datatransfer: 0,
        accountsloader: 0
    },
    reducers: {
        setloader: (state, action)=>{
            state.accountsloader = state.accountsloader+1;
        },
        resetloader: (state, action)=>{
            state.accountsloader = state.accountsloader-1;
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(getPipelinecount.fulfilled, (state, action)=>{
            state.pipelinecount = action.payload.count;
        })
        builder.addCase(getPipelineusage.fulfilled, (state, action)=>{
            state.pipelineusage = action.payload.count;
        })
        builder.addCase(getpipelinehttpusage.fulfilled, (state,action)=>{
            state.pipelinehttpusage = action.payload.count;
        })
        builder.addCase(getSchemarecords.fulfilled, (state, action)=>{
            state.schemarecords = action.payload;
        })
        builder.addCase(getRouteloads.fulfilled, (state, action)=>{
            state.routeloads = action.payload.count;
        })
        builder.addCase(getStorage.fulfilled, (state, action)=>{
            state.storage = action.payload.count;
        })
        builder.addCase(getDatatransfer.fulfilled, (state, action)=>{
            state.datatransfer = action.payload.count;
        })
    }
})

export const selectPipelineusage = (state) => state.usage.pipelineusage;
export const selectPipelinecount = (state) => state.usage.pipelinecount;
export const selectPipelinehttpusage = (state) => state.usage.pipelinehttpusage;
export const selectSchemarecords = (state) => state.usage.schemarecords;
export const selectRouteloads = (state) => state.usage.routeloads;
export const selectStorage = (state) => state.usage.storage;
export const selectDatatransfer = (state) => state.usage.datatransfer;
export const selectAccountloader = (state) => state.usage.accountsloader;

const {setloader, resetloader} = usageSlice.actions;

export default usageSlice.reducer;