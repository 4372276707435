import StageContainer from "./StageContainer";

const MotorBodyTileLeft = ({
                            
                        })=>{

    // const createNextStage = ()=>{
    //     addStage([index]);
    // }

    return (
        <>
        </>
        // <div className="motor-body-tile">
        //     {(pipeline[index].last==false)&&
        //         <div className="motor-next-divider">
        //             {last==false&&
        //                 <div className="motor-divider-line">
        //                 </div>
        //             }
        //             <div className="add-icon" onClick={()=>{addStage(index)}}>
        //                 <span class="material-symbols-outlined">
        //                     add_circle
        //                 </span>
        //             </div>
        //             <div className="motor-divider-line">
        //             </div>
        //         </div>
        //     }


        //     <div className="motor-tile-body">
        //         {/* <div className="motor-type-header">
        //             <div className="motor-type-name">
        //                 Input
        //             </div>
        //             <div>
        //                 <span class="material-symbols-outlined" style={{fontSize:40}}>
        //                     fullscreen
        //                 </span>
        //             </div>
        //         </div> */}
        //         {pipeline[index]!=undefined&&
        //             <StageContainer 
        //                 pipeline={pipeline} 
        //                 pipelinetype={pipelinetype} 
        //                 setStage = {setStage}
        //                 addStage={addStage}
        //                 deleteStage = {deleteStage} 
        //                 index={index}
        //                 variablePipeline={variablePipeline}
        //                 width={width}
        //                 updatePipeline={updatePipeline}
        //             ></StageContainer>
        //         }
        //     </div>
        // </div>
    )
}

export default MotorBodyTileLeft;