import { useEffect, useState } from "react";
import { 
    getOutputSchema, 
    getStageError
} from "../../utils";
import "./BlockOutput.css";
import DisplayObject from "../../apps/DisplayOutputs/DisplayObject";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../utils/CircularLoader";
import { getFullStageOutput, selectFullStageOutput } from "../../../Services/pipelines/pipeline.slice";

const BlockOutput = ({
                        stageindex,
                        pipelineid
                    })=>{

    const [outstate, setOutstate] = useState(0);

    const [isError, setIsError] = useState(false);

    const [stageoutput, setStageOutput] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(getStageError(stageindex)!=undefined){
            setOutstate(1);
        }
    },[stageindex])


    const dispatch = useDispatch();

    const setFullState = ()=>{
        dispatch(getFullStageOutput({
            stageindex: stageindex,
            pipelineid: pipelineid
        }));
        setOutstate(2);
        setLoading(true)
    }

    const fullstageoutput = useSelector(selectFullStageOutput);

    useEffect(()=>{
        if(fullstageoutput?.stageindex==stageindex){
            setStageOutput({...fullstageoutput.result});
            if(loading){
                setLoading(false);
            }
        }
    },[fullstageoutput])

    return (
        <>
            <div className="stage-params-header">
                <div className={outstate==0?"stage-params-header-option active":"stage-params-header-option"} onClick={()=>{setOutstate(0)}}>
                    Output
                </div>
                <div className={outstate==1?"stage-params-header-option active":"stage-params-header-option"} onClick={()=>{setOutstate(1)}}>
                    Error
                </div>
                <div className={outstate==2?"stage-params-header-option active":"stage-params-header-option"} onClick={()=>{setFullState()}}>
                    Full Output
                </div>
            </div>
            <div>
                {outstate==0&&
                    <>
                        <pre>
                            {getOutputSchema(stageindex).length>0&&
                                <DisplayObject
                                    schema={getOutputSchema(stageindex)}
                                    spacer={""}
                                    location={""}
                                    stageindex={stageindex}
                                >
                                </DisplayObject>
                            }
                        </pre>            
                    </>
                }
                {outstate==1&&
                    <>
                        <pre>
                            {getStageError(stageindex)!==undefined&&
                                JSON.stringify(getStageError(stageindex), null, 2)
                            }
                        </pre>
                    </>
                }
                {outstate==2&&
                    <>
                        {loading?
                            <>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <CircularLoader></CircularLoader>
                                </div>
                            </>:
                            <>
                                <pre>
                                    {JSON.stringify(stageoutput, null, 2)}
                                </pre>
                            </>
                        }
                    </>
                }
                
                
                                                    
            </div>
        </>
    )

}


export default BlockOutput;