import {get} from "../signin/axios";
import { baseURL } from "../../env";


export const getEnumOptionsApi = async (payload)=>{
    let res = await get(payload.url);
    return res;
}


export const getPipelineInputSchemaApi = async(payload)=>{
    let res = await get(baseURL+"")
}