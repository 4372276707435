import { useState } from "react";
import {ValueCompareToField, ValuetoCompareField} from "../Motors/blocks/ConditionalStageBlock";
import * as _ from "lodash";
import {conditionalActions} from "../Settings";
import { FormControl, IconButton, TextField } from "@mui/material";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from "react-redux";
import { setElement } from "../../Services/apptemplates/apptemplates.slice";


const ElementConditions = ({
    templateid, 
    element,
    position
})=>{

    const dispatch = useDispatch();

    const setConditions = (conditions)=>{
        let elementCopy = {...element,
                           conditions: conditions
                          }
        dispatch(setElement(
            {
                position: position,
                element: elementCopy
            }
        ))
    }


    const selectCondition = (blockindex, conditionindex, condition)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      condition: condition
                                                     }
        setConditions(conditionsCopy);
    }

    const setGetValuetocompare = (blockindex, conditionindex)=>{
        return (val, type)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
            let allconditions = [...conditionalActions];
            let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type==type})
            let options = [...allconditions[selectedtypeindex].operators];
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                          valuetocompare:{"action": "get", "val": val.schemaval},
                                                          options: options,
                                                          "comparetotype": type
                                                         }
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValuetocompare = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        let allconditions = [...conditionalActions];
        let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type=="string"})
        let options = [...allconditions[selectedtypeindex].operators];
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      valuetocompare:{"action": "const", "val": val},
                                                      options: options,
                                                      "comparetotype": "string"
                                                    }
        setConditions(conditionsCopy);
    }

    const getValueToCompare = (blockindex, conditionindex)=>{
        if(element.conditions[blockindex][conditionindex].valuetocompare.val){
            return element.conditions[blockindex][conditionindex].valuetocompare.val;
        }else{
            return ""
        }
        
    }

    const setGetValueCompareTo = (blockindex, conditionindex)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                            valuecompareto:{"action": "get", "val": val.schemaval}
                                                         }    
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValueCompareTo = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                        valuecompareto:{"action": "const", "val": val}
                                                     }
        setConditions(conditionsCopy);
    }

    const getValueCompareTo = (blockindex, conditionindex)=>{
        if(element.conditions[blockindex][conditionindex].valuecompareto.val){
            return element.conditions[blockindex][conditionindex].valuecompareto.val
        }else{
            return "";
        }
    }

    const deleteConditionBlock = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        conditionsCopy.splice(i,1);
        setConditions(conditionsCopy);
    }

    const deleteCondition = (blockindex, conditionindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        let conditionBlockCopy= [...conditionsCopy[blockindex]];
        conditionBlockCopy.splice(conditionindex, 1);
        conditionsCopy[blockindex] = conditionBlockCopy;
        setConditions(conditionsCopy);    
    }

    const addConBlock = ()=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        conditionsCopy.push([]);
        setConditions(conditionsCopy);
    }

    const addCondition = (blockindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(element.conditions));
        conditionsCopy[blockindex].push(
            {
                "valuetocompare":"",
                "tocompareopen":false,
                "comparetotype": "any",
                "options":[],
                "condition": "",
                "valuecompareto":"",
                "comparetoopen":false
            }   
        )
        setConditions(conditionsCopy);
    }

    return (
        <>
            {element.conditions.map((conblock, i)=>{
                return (
                    <>
                        {i==0?
                            <div>Only visible if</div>:
                            <div className="or-block-desc">
                                <div>Or visible if</div>
                                <div>
                                    <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                    <div className="condition-line">

                                        <div style={{padding: "10px"}}>
                                            <ValuetoCompareField
                                                i={i}
                                                j={j}
                                                setConstValuetocompare={setConstValuetocompare}
                                                setGetValuetocompare={setGetValuetocompare}
                                                pipelinestage={0}
                                                pipelinetype={""}
                                                getValueToCompare={getValueToCompare}
                                                source={"appprops"} 
                                                templateid={templateid}                                           
                                            >
                                            </ValuetoCompareField>
                                        </div>
                                        <div style={{padding: "10px"}}>
                                            <FormControl sx={{minWidth: 200}}>
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={con.condition}
                                                    label="Condition"
                                                    onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                                >
                                                    {con.options.map((op)=>{
                                                        return(
                                                            <MenuItem value={op.value}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div style={{padding: "10px"}}>
                                            <ValueCompareToField
                                                i={i}
                                                j={j}
                                                getValueCompareTo={getValueCompareTo}
                                                setConstValueCompareTo={setConstValueCompareTo}
                                                setGetValueCompareTo={setGetValueCompareTo}
                                                pipelinestage={undefined}
                                                pipelinetype={""}
                                                templateid={templateid}
                                                source={"appprops"}
                                            >
                                            </ValueCompareToField>
                                        </div>
                                        <div className="close-button" onClick={()=>{deleteCondition(i,j)}}>
                                            <span class="material-symbols-outlined">
                                                close
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {i==(element.conditions.length-1)?
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            Or
                                    </div>
                                </div>
                            </>:
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                </div>
                            </>
                        }
                        <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                    </>
                )
            })}
        </>
    )

}

export default ElementConditions;