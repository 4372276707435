import { useEffect } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { selectDefaultWorkspace } from "./Services/signin/signin.slice";

const Permanentredirect = ()=>{

    const navigate = useNavigate();

    const defaultworkspace = useSelector(selectDefaultWorkspace);

    useEffect(()=>{
        if(defaultworkspace!=""&&defaultworkspace!=null&&defaultworkspace!=undefined){
            navigate("/"+defaultworkspace+"/");
        }else{
            navigate("/login");
        }
    },[defaultworkspace])

    return(
        <div>

        </div>
    )
}


export default Permanentredirect;