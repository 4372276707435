
import { useDispatch } from "react-redux";
import "./InputPropstoolbar.css";
import * as _ from "lodash";
import { useState } from "react";
import {getInputProps, setElement, setInputType} from "../../Services/apptemplates/apptemplates.slice";

const InputPropstoolbar = ({
                        position, 
                        element, 
                        templateid
                    })=>{

    const inputTypes = [
        "button",
        "checkbox",
        "color",
        "date",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "image",
        "month",
        "number",
        "password",
        "radio",
        "range",
        "reset",
        "search",
        "submit",
        "tel",
        "text",
        "time",
        "url",
        "week"
    ]

    const dispatch = useDispatch();

    const setElementType = (type)=>{
        let elementCopy = {...element,
                           inputtype: type,
                          }
        dispatch(setInputType({
            element: elementCopy,
            position: position,
            act: "change_type"
        }))   
        
    }

    const setElementName = (name)=>{
        let elementCopy = {...element,
                           name: name
                          }
        dispatch(setInputType({
            element: elementCopy,
            position: position,
            act: "change_name"
        }))   
    }

    const setPropvalue = (index, value)=>{
        let propsCopy = [...element.props];
        propsCopy[index] = {...propsCopy[index],
                             value: value
                            }
        let elementCopy = {...element,
                           props: propsCopy
                          }
        dispatch(setElement({
            element: elementCopy,
            position: position
        }))
    }


    return (
        <div>
            <div className="input-property-container">
                <div className="input-property-label">
                    type
                </div>
                <div className="input-property-value">
                    <select 
                        value={element.inputtype}
                        onChange={(e)=>{
                            setElementType(e.target.value)
                        }}
                    >
                        {inputTypes.map((inputType)=>{
                            return (
                                <option value={inputType}>
                                    {inputType}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className="input-property-container">
                <div className="input-property-label">
                    name
                </div>
                <div className="input-property-value">
                    <input
                        type="text"
                        value={element.name}
                        onChange={(e)=>{setElementName(e.target.value)}}
                    >
                    </input>
                </div>
            </div>
            <div>
                {element.props.map((prop, index)=>{
                    return (
                        <>
                            {prop.type=="boolean"&&
                                <div className="input-property-container">
                                    <div className="input-property-label">
                                        {prop.name}
                                    </div>
                                    <div className="input-propery-value">
                                        <input type="checkbox" value={prop.value} onChange={(e)=>{setPropvalue(index, e.target.value)}}></input>
                                    </div>
                                </div>
                            }
                            {prop.type=="text"&&
                                <div className="input-property-container">
                                    <div className="input-property-label">
                                        {prop.name}
                                    </div>
                                    <div className="input-property-value">
                                        <input type="text" value={prop.value} onChange={(e)=>{setPropvalue(index, e.target.value)}}></input>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })}
            </div>
        </div>
    )


}



export default InputPropstoolbar;