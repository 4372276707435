import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetmanifestcreated, selectManifestcreated, getManifestVersion, selectManifestVersion, manifestExist, selectManifestexist, resetmanifestupdated, selectManifestUpdated, createManifest, updateManifest, resetmanifest } from '../../Services/manifest/manifest.slice';
import { showError } from '../../Services/error.slice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ManifestStarter = ({
    open,
    handleclose,
    source,
    sourceid
})=>{
    
    const {workspace} = useParams();

    const [publishscope, setpublishscope] = useState("public");
    
    const [manifeststarter, setmanifeststarter] = useState("");

    const [manifestname, setmanifestname] = useState("");

    const [createclicked, setCreateclicked] = useState(false);

    const [updateclicked, setupdateclicked] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(()=>{
        if(source!=""&&sourceid!=""){
            dispatch(getManifestVersion({source: source, sourceid: sourceid}));
            dispatch(manifestExist({source: source, sourceid: sourceid}));
        }
    },[])


    const manifestexist = useSelector(selectManifestexist);

    useEffect(()=>{
        if(manifestexist){
            let manifestexistCopy = {...manifestexist};
            if(manifestexistCopy.manifeststarter!=""&&manifestexistCopy.manifeststarter!=undefined){
                let manifeststarter = manifestexistCopy.manifeststarter;
                setmanifeststarter(manifeststarter);
            }
            if(manifestexistCopy.manifeststarter!=""&&manifestexistCopy.manifeststarter!=undefined){
                let manifestscope = manifestexistCopy.manifestscope;
                setpublishscope(manifestscope);
            }
            if(manifestexistCopy.manifestname!=""&&manifestexistCopy.manifestname!=undefined){
                let manifestname = manifestexistCopy.manifestname;
                setmanifestname(manifestname);
            }
        }
    },[manifestexist])

    const version = useSelector(selectManifestVersion);

    const createmanifest = ()=>{
        if(manifeststarter!=""&&manifestname!=""){
            setCreateclicked(true)
            dispatch(resetmanifestcreated())
            dispatch(createManifest({
                source: source,
                sourceid: sourceid,
                manifeststarter: manifeststarter,
                manifestname: manifestname,
                manifestscope: publishscope
            }))
        }else{
            dispatch(showError("Manifest starter and name cannot be empty"));
        }
        
    }

    const manifestcreated = useSelector(selectManifestcreated);

    useEffect(()=>{
        if(manifestcreated&&createclicked){
            handleclose()
        }
    },[manifestcreated, createclicked])

    const updatemanifest = ()=>{
        if(manifeststarter!=""&&manifestname!=""){
            setupdateclicked(true);
            dispatch(resetmanifestupdated());
            dispatch(updateManifest({
                                        id: manifestexist._id,
                                        source: source,
                                        sourceid: sourceid,
                                        manifeststarter: manifeststarter,
                                        manifestscope: publishscope,
                                        manifestname: manifestname
                                    }))
        }else{
            dispatch(showError("Manifest starter and name cannot be empty"));
        }
        
    }

    const manifestupdated = useSelector(selectManifestUpdated);

    useEffect(()=>{
        if(manifestupdated&&updateclicked){
            handleclose()
        }
    },[manifestupdated, updateclicked])

   
    const sendNextAction = ()=>{
        if(manifestexist){
            updatemanifest();
        }else{
            createmanifest();
        }        
    }

    const cancel = ()=>{
        if(source=="app"){
            dispatch(resetmanifest());
            navigate("/"+workspace+"/schemas/"+sourceid);
        }else if(source=="workspace"){
            dispatch(resetmanifest());
            navigate("/workspaces/"+sourceid)
        }else if(source=="website"){
            dispatch(resetmanifest());
            navigate("/"+workspace+"/website/details/"+sourceid)
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}        
        >
            <Box
                sx={style}
            >
                <h3>Publish package</h3>
                <div>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Options</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={publishscope}
                            onChange={(e)=>{setpublishscope(e.target.value)}}
                        >
                            <FormControlLabel value="public" control={<Radio />} label="Public" />
                            <FormControlLabel value="private" control={<Radio />} label="Private" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{marginBottom: "10px"}}>
                    <TextField 
                        label="Suffix of the package" 
                        value={manifeststarter} 
                        onChange={(e)=>{setmanifeststarter(e.target.value)}}
                    ></TextField>
                </div>
                <div>
                    <TextField
                        label="Display name of package"
                        value={manifestname}
                        onChange={(e)=>{setmanifestname(e.target.value)}}
                    >
                    </TextField>
                </div>
                <div>
                    <Button onClick={()=>{cancel()}}>Cancel</Button>
                    <Button onClick={()=>{sendNextAction()}}>Next</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ManifestStarter;