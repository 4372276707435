import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from "../../../Images/search_icon.png";
import "./ChooseApp.css";
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import MarketplaceGrid from "../../marketplace/MarketplaceGrid";
import {
         useDispatch, 
         useSelector 
       } from 'react-redux';
import { 
        getLoops, 
        getWorkspaceLoops, 
        resetworkspaceloopapps, 
        selectAvailableLoops, 
        selectWorkspaceloops,
        selectWorspaceloopapps,
        getWorkspaceLoopApps
       } from '../../../Services/events/event.slice';

import { fileURL } from '../../../env';
import { IconButton } from '@mui/material';
import { 
            getWorkspaceApps, 
            getWorkspaceAppsInit, 
            resetworkspaceappsinit, 
            selectWorkspaceApps, 
            selectWorkspaceAppsInit 
       } from '../../../Services/apps/app.slice';
import { getManifestApps, selectManifestApplist } from '../../../Services/manifest/manifest.slice';
import WebIcon from "../../../Images/Web-Icon.png";
const ChooseLoop = ({
                        systemapps, 
                        index, 
                        selectApp,
                        pipelinetype,
                        source
                    })=>{

    const marketplacescrollref = useRef(null);

    const [toilerapps, settoilerapps] = useState("toiler-apps");

    const {workspace, appid} = useParams();

    const [displayWidget, setDisplaywidget] = useState(0);

    const [apps, setApps] = useState([]);

    const [appfetched, setAppfetched] = useState(false);

    const dispatch = useDispatch();

    const workspaceLoops = useSelector(selectWorkspaceloops);
    
    const workspaceLoopApps = useSelector(selectWorspaceloopapps);

    const workspaceApps = useSelector(selectWorkspaceApps);

    const workspaceAppsInit = useSelector(selectWorkspaceAppsInit);

    const manifestApps = useSelector(selectManifestApplist);

    useEffect(()=>{
        if(source=="installmanifest"){
            if(index>0){
                dispatch(getManifestApps(workspace));
            }
        }else{
            if(index==0&&pipelinetype=="main"){
                dispatch(getWorkspaceLoops(workspace))
            }else{
                dispatch(getWorkspaceApps(workspace));
            }
        }
        
    },[index])


    const closeDisplayWidgetLoop = ()=>{
        dispatch(resetworkspaceloopapps());
        setDisplaywidget(0);
    }

    const openDisplayWidgetLoop = (id)=>{
        dispatch(getWorkspaceLoopApps(id));
        setDisplaywidget(1);
    }


    const openDisplayWidgetApp = (id)=>{
        dispatch(getWorkspaceAppsInit(id));
        setDisplaywidget(1)
    }


    const closeDisplayWidgetApp = ()=>{
        dispatch(resetworkspaceappsinit());
        setDisplaywidget(0);
    }

    const availableLoops = [];

    return (
        <div style={{"justifyContent": "center"}}>
            <div className="stage-app">
                <div>
                    {/* <TextField
                        id="input-with-icon-textfield"
                        label="Search Apps"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={SearchIcon} style={{width: 20,height:20}}></img>
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                    <div className="app-list">
                        <div className="app-list-wrapper-container">
                            <div>
                                System Apps
                            </div>
                            <div className="app-list-wrapper">
                                {systemapps.map((app)=>{
                                    return(
                                        <div className="app-tile" onClick={()=>{selectApp(app.name, app.type, app.app, workspace)}}>
                                            <img src={app?.logo} style={{width:40,height:40}}></img>
                                            <div>
                                                {app?.name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {source=="apptemplates"&&!(index==0&&pipelinetype=="main")&&
                            <div className='app-list-wrapper-container'>
                                <div>
                                    Template App
                                </div>
                                <div className='app-list-wrapper'>
                                    <div className="app-tile" onClick={()=>{selectApp("Template", "template","template_pipes",workspace )}}>
                                        <img src={WebIcon} style={{width: 40, height: 40}}></img>
                                        <div>
                                            Template
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {source=="apps"&&
                            <div className="app-list-wrapper-container">
                                <div className="toiler-app-block">
                                    <div className={toilerapps=="toiler-apps"?"toiler-app-block-item active":"toiler-app-block-item"} onClick={()=>{settoilerapps("toiler-apps")}}> 
                                        Toiler Apps
                                    </div>
                                    <div className={toilerapps=="marketplace"?"toiler-app-block-item active":"toiler-app-block-item"} onClick={()=>{settoilerapps("marketplace")}}>
                                        Marketplace
                                    </div>
                                </div>
                                {toilerapps=="toiler-apps"?
                                    <div>
                                      {index==0&&pipelinetype=="main"?
                                        <div className="app-list-wrapper">
                                            {displayWidget==0&&
                                                <>
                                                    {workspaceLoops.map((item)=>{
                                                        return (
                                                            <>
                                                                {item.displaytype=="workspace"&&
                                                                    <div className="app-tile" onClick={()=>{openDisplayWidgetLoop(item.id)}}>
                                                                        <div>
                                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                                folder_open
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {item?.name}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {item.displaytype=="app"&&
                                                                    <div className='app-tile' onClick={()=>{selectApp(item.name, item.type, item.id, workspace)}}>
                                                                        <img src={fileURL+item?.logo} style={{width: 40, height: 40}}></img>
                                                                        <div>
                                                                            {item?.name}
                                                                        </div>                                                        
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                        
                                                    })}
                                                </>
                                            }
                                            {displayWidget==1&&
                                                <>
                                                    <div>
                                                        <div style={{display: "flex" , justifyContent: "flex-end"}}>
                                                            <IconButton onClick={()=>{closeDisplayWidgetLoop()}}>
                                                                <span className="material-symbols-outlined">
                                                                    close
                                                                </span>
                                                            </IconButton>
                                                        </div>
                                                        <div>
                                                            {workspaceLoopApps.map((item)=>{
                                                                return (
                                                                    <div className="app-title" onClick={()=>{selectApp(item.name, item.type, item.id, workspace)}}>
                                                                        <img src={fileURL+item?.logo} style={{width: 40, height: 40}}></img>
                                                                        <div>
                                                                            {item.name}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>:
                                        <div className="app-list-wrapper">
                                            {displayWidget==0&&
                                                <>
                                                    {workspaceApps.map((item)=>{
                                                        return (
                                                            <>
                                                                {item.displaytype=="workspace"&&
                                                                    <div className="app-tile" onClick={()=>{openDisplayWidgetApp(item.id)}}>
                                                                        <div>
                                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                                folder_open
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {item?.name}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {item.displaytype=="app"&&
                                                                    <div className='app-tile' onClick={()=>{selectApp(item.name, item.type, item.id, workspace)}}>
                                                                        <img src={fileURL+item?.logo} style={{width: 40, height: 40}}></img>
                                                                        <div>
                                                                            {item?.name}
                                                                        </div>                                                        
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }
                                            {displayWidget==1&&
                                                <>
                                                    <div>
                                                        <div style={{display: "flex" , justifyContent: "flex-end"}}>
                                                            <IconButton onClick={()=>{closeDisplayWidgetApp()}}>
                                                                <span className="material-symbols-outlined">
                                                                    close
                                                                </span>
                                                            </IconButton>
                                                        </div>
                                                        <div>
                                                            {workspaceAppsInit.map((item)=>{
                                                                return (
                                                                    <div className="app-tile" onClick={()=>{selectApp(item.name, item.type, item.id, workspace)}}>
                                                                        <img src={fileURL+item.logo}></img>
                                                                        <div>
                                                                            {item?.name}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                      }  
                                    </div>:
                                    <div>
                                        <div ref={marketplacescrollref}>
                                            <MarketplaceGrid
                                                source={"marketplace"}
                                                sourceid={"marketplace"}
                                                scrollref={marketplacescrollref}
                                            >
                                            </MarketplaceGrid>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {source=="installmanifest"&&
                            <div className="app-list-wrapper-container">
                                <div className="toiler-app-block">
                                    <div className="toiler-app-block-item">
                                        Toiler Apps
                                    </div>
                                </div>
                                <div className="app-list-wrapper">
                                    {manifestApps.map((item)=>{
                                        return (
                                            <>
                                                {item.displaytype=="workspace"&&
                                                    <div className="app-tile" onClick={()=>{openDisplayWidgetApp(item.id)}}>
                                                        <div>
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder_open
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {item?.name}
                                                        </div>
                                                    </div>
                                                }
                                                {item.displaytype=="app"&&
                                                    <div className='app-tile' onClick={()=>{selectApp(item.name, item.type, item.id, workspace)}}>
                                                        <img src={fileURL+item?.logo} style={{width: 40, height: 40}}></img>
                                                        <div>
                                                            {item?.name}
                                                        </div>                                                        
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ChooseLoop;