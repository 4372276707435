import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useParams} from "react-router-dom";
import DeclareVariable from "../../apps/Fields/DeclareVariable";
import { Button, TextField } from "@mui/material";
import {renderTextTemplate, generateDataKeys, getObjectVal} from "../../utils";
import { getTextTemplate, resetTextTemplate, selectTextTemplate, updateTextTemplate } from "../../../Services/templates/template.slice";
import "./TextTemplate.css";

const TextTemplate = ()=>{

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [templateText, setTemplateText] = useState("");

    const [templateName, setTemplateName] = useState("");

    const [helpText, setHelpText] = useState("Use '<%=input.test%>' delimiters to insert variable data into templates" );

    const {id, workspace} = useParams();

    const [variable, setVariable] = useState({
        "schema":[{
            "key": "input",
            "label": "Input",
            "type": "object",
            "required": false,
            "fillallowed": true,
            "guided": true,
            "guidefinsihed": false,
            "subschema":[],
            editallowed: true
        }],
        "schemamapping":[{
            "key": "input",
            "mapping": ""
        }]
    })



    const modifyVariable = (schema, schemaMapping)=>{
        let variableCopy = {...variable};
        variableCopy.schema = schema;
        variableCopy.schemamapping = schemaMapping;
        dispatch(updateTextTemplate({
            "id": id,
            "template":{
                "name": templateName,
                "templatetext": templateText,
                "variable": variableCopy
            }
        }))
        setVariable(variableCopy);  
    }

    const getRenderedText = ()=>{
        if(templateText!==""&&templateText!=undefined){
            let templateString = templateText;
            if(variable.schema!=undefined&&variable.schemamapping!=undefined){
                let data = getObjectVal(variable.schema, variable.schemamapping,{});
                let datakeys = [];
                generateDataKeys(data, "", datakeys);
                let renderedText = renderTextTemplate(templateString, datakeys, data);
                return renderedText;
            }else{
                return "";
            }
            
        }else{
            return "";
        }
        
    }

    const setTempText = (val)=>{
        dispatch(updateTextTemplate({
            "id": id,
            "template":{
                "name": templateName,
                "templatetext": val,
                "variable": variable
            }
        }))
        setTemplateText(val);
    }

    const [initialized, setInitialized] = useState(false);

    const remoteTemplate = useSelector(selectTextTemplate);

    useEffect(()=>{
        if(initialized==false){
            if(remoteTemplate!=null){
                let remoteTemplateCopy = {...remoteTemplate};
                setTemplateText(remoteTemplateCopy.templatetext);
                setVariable(remoteTemplateCopy.variable);
                setTemplateName(remoteTemplateCopy.name);
                setInitialized(true);
            }
        }
    },[remoteTemplate, initialized])

    useEffect(()=>{
        if(id==undefined||id==null){
            return
        }
        dispatch(getTextTemplate(id));
    },[id])

    const close = ()=>{
        dispatch(resetTextTemplate());
        navigate("/"+workspace+"/templates");
    }

    const changeTemplateName = (val)=>{
        dispatch(updateTextTemplate({
            "id": id,
            "template":{
                "name": val,
                "templatetext": templateText,
                "variable": variable
            }
        }))
        setTemplateName(val);
    }

    return (
        <div className="text-template-container">
            <div className="text-template-headers">
                <div>
                    <TextField variant="standard" value={templateName} onChange={(e)=>{changeTemplateName(e.target.value)}}></TextField>
                </div>
                <div>
                    <Button style={{color: "#3A07CD"}} onClick={()=>{close()}}>Close</Button>
                </div>
            </div>
            <div>
                <div>
                    <div>
                        <span>{helpText}</span>
                    </div>
                    <TextField
                        fullWidth
                        multiline
                        rows={8}
                        value={templateText}
                        onChange={(e)=>{setTempText(e.target.value)}}
                    >
                    </TextField>
                </div>
                <div>
                    <h3>
                        Rendered
                    </h3>
                    <div>
                        {getRenderedText()}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <h3>Variable</h3>
                </div>
                <div>
                    <DeclareVariable
                        schema={variable?.schema} 
                        modifySchema={modifyVariable}
                        schemamapping={variable?.schemamapping} 
                        pipelinetype={""}
                        stageindex={0}
                        stagetype={"single"}
                        layout={"vertical"}
                        pipelinestage={undefined}
                        source={"texttemplates"}
                        sourceid={id}
                    >
                    </DeclareVariable>
                </div>
            </div>
        </div>
    )


}



export default TextTemplate;