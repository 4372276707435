import { baseURL } from "../../env";
import { deleteM, get, postjson, putjson } from "../signin/axios"


export const getAndroidApplistApi = async()=>{
    let res = await get(baseURL+"android/");
    return res;
}

export const createAndroidAppApi = async(payload)=>{
    let res = await postjson(baseURL+"android/", payload);
    return res;
}

export const getAppScreensApi = async(payload)=>{
    let res = await get(baseURL+"android/screens/"+payload);
    return res;
}

export const getAppDetailsApi = async(payload)=>{
    let res = await get(baseURL+ "android/"+payload);
    return res;
}

export const deleteAppApi = async(payload)=>{
    let res = await deleteM(baseURL+"android/"+payload);
    return res;
}

export const getAppCountApi = async(payload)=>{
    let res = await get(baseURL+ "android/count");
    return res;
}

export const getAppCreateOptionApi = async(payload)=>{
    let res = await get(baseURL+ "android/create/options");
    return res;
}

export const createAppScreenApi = async(payload)=>{
    let res = await postjson(baseURL+"android/template/create", payload);
    return res;
}

export const getBuildstatusApi = async(payload)=>{
    let res = await get(baseURL+"android/buildstatus/"+payload);
    return res;
}

export const createKeystoreApi = async(payload)=>{
    let res = await postjson(baseURL+ "android/create/keystore", payload);
    return res;
}

export const getAndroidBuildsApi = async(payload)=>{
    let res = await get(baseURL+"android/builds/"+payload.appid+"/"+payload.type);
    return res;
}

export const startAndroidBuildApi = async(payload)=>{
    let res = await get(baseURL+"android/startbuild/"+payload.appid+"/"+payload.type);
    return res;
}


export const updateAndroidAppApi = async(payload)=>{
    let res = await putjson(baseURL+"android/"+payload._id, payload);
    return res;
}

