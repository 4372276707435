export function StageError(stageindex,error){
    this.error = new Error(
        error
    )
    this.stageindex = stageindex;
    this.type = "StageError";
}

export function ResponseNotDefinedError(){
    this.error = new Error("Reponse not defined for the function");
    this.type = "ResponseNotDefinedError";
}