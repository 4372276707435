import { useEffect } from "react";
import { resetlog, selectLog, getLog } from "../../Services/pipelinelogs/pipelinelogs.slice";
import { useDispatch, useSelector } from "react-redux";
import { stringify } from "qs";
import { IconButton, Tooltip, Modal } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: "#fff",
    p: 4,
};

const PipelineLogitem = ({
                            logid,
                            open,
                            handleClose
                        })=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(logid!=undefined&&logid!=null&&logid!=""){
            dispatch(resetlog()); 
            dispatch(getLog(logid));
        }
    },[logid])

    const log = useSelector(selectLog);

    return (
        <>
            <Modal
                open={open}
                handleClose={handleClose}
            >
                <div
                    style={style}
                >
                    <div>
                        <Tooltip title="close">
                            <IconButton onClick={()=>{handleClose()}}>
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div>
                        <h3>
                            {logid}
                        </h3>
                        <pre style={{whiteSpace: "pre-wrap"}}>
                            {JSON.stringify(log, null, 2)}
                        </pre>
                    </div>
                </div>
            </Modal>
        </>
    )


}


export default PipelineLogitem;