
// import PagerView from "react-native-pager-view";
import { 
            ActivityIndicator, 
            Button, 
            Image, 
            KeyboardAvoidingView, 
            Modal, 
            RefreshControl, 
            ScrollView, 
            SectionList, 
            StatusBar, 
            Switch, 
            TextInput, 
            View,
            StyleSheet,
            Text
        } from "react-native-web";
import { useDispatch , useSelector} from "react-redux";
import { jsoncompare, selectInnerHtml, selectElementStyles, selectProps } from "../../../Services/apptemplates/apptemplates.slice";
import { runPipelines } from "../../../Services/pipelines/pipeline.slice";
import { useState, useEffect } from "react";
import * as _ from "lodash";
import ElementContainer from "../ElementContainer";



const AndroidImage = ({
    props, 
    styles,
    interactions
})=>{

    const getExtraprops = ()=>{
        if(props==undefined){
            return {}
        }else{
            let propsCopy = {...props};
            delete propsCopy.source;
            return propsCopy;
        }
    }

    const getImageSource = ()=>{
        if(props==undefined){
            let source = {
                uri: ""
            }
            return source;
        }else{
            let source = {
                "source": {
                    uri: props.source
                }
            }
            return source
        }
    }

    return (
        <Image
            {...interactions}
            {...getExtraprops()}
            {...getImageSource()}
            style={[styles]}
        >
        </Image>
    )
}


const AndroidNativeElements = ({
    element,
    elmkey,
    indexarr,
    position
})=>{

    const innerhtml = useSelector((state)=>
                                    selectInnerHtml(
                                                    state,
                                                    {schema: element.innerhtml, schemamapping: element.innerhtmlmapping, key:elmkey},
                                                    [],
                                                    elmkey,
                                                    position
                                                    ), jsoncompare);
    const [elementinit, setElementInit]= useState(false);

    const [style, setStyle] = useState(null);
                                                
    const dispatch = useDispatch();

    const styles = useSelector((state)=>{
                                                                                        
                                            return selectElementStyles(state, element.styles, elmkey, position)
                                        }, jsoncompare)

    useEffect(()=>{
        let stylecopy = StyleSheet.compose(style, styles);
        setStyle(stylecopy);
    },[styles])

    useEffect(()=>{
        if(elementinit){
            return;
        }
        let stylecopy = StyleSheet.create({});
        setStyle(stylecopy);
        setElementInit(true)
    },[elementinit])

    const props = useSelector((state)=>{return selectProps(
                                                            state, 
                                                            {
                                                                schema: element.props, 
                                                                schemamapping: element.propsmapping
                                                            },
                                                            elmkey,
                                                            position
                                                            )}, jsoncompare )
    
    const getInteractions = ()=>{
        let interactions = {};
        for(let i=0; i< element.interactions.length; i++){
            if(element.interactions[i].name!="onMount"){
                interactions[element.interactions[i].name] = (e)=>{
                    dispatch(runPipelines({
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping
                    }))
                }
            }
            
        }
        return interactions;
    }

    const [elementLoaded, setElementLoaded] = useState(false);

    // useEffect(()=>{
    //     if(element==null||element==undefined){
    //         return
    //     }
    //     if(elementLoaded==false){
    //         let onloadindex = _.findIndex(element.interactions, (interaction)=>{return interaction.name=="onMount"});
    //         if(onloadindex>-1){
    //             let pipeline = element.interactions[onloadindex].pipeline;
    //             let schemamapping = element.interactions[onloadindex].schemamapping;
    //             dispatch(runPipelines({
    //                 key: elmkey,
    //                 pipelineid: pipeline,
    //                 schemamapping: schemamapping
    //             }))
    //         }
    //         setElementLoaded(true);
    //     }

    // },[element])

    return (
        <>
            {element.tag=="ActivityIndicator"&&
                <ActivityIndicator
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </ActivityIndicator>
            }
            {element.tag=="Button"&&
                <Button
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </Button>
            }
            {element.tag=="Image"&&
                <AndroidImage
                    interactions={getInteractions()}
                    props={props}
                    styles={styles}
                >
                </AndroidImage>
            }
            {element.tag=="KeyboardAvoidingView"&&
                <KeyboardAvoidingView
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </KeyboardAvoidingView>
            }
            {element.tag=="Modal"&&
                <Modal
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </Modal>
            }
            {element.tag=="RefereshControl"&&
                <RefreshControl
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </RefreshControl>
            }
            {element.tag=="ScrollView"&&
                <ScrollView
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </ScrollView>
            }
            {element.tag=="SectionList"&&
                <SectionList
                    {...getInteractions()}
                    {...props}
                    style={[styles]}  
                >
                </SectionList>
            }
            {element.tag=="StatusBar"&&
                <StatusBar
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </StatusBar>
            }
            {element.tag=="Switch"&&
                <Switch
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </Switch>
            }
            {element.tag=="Text"&&
                <Text
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                    {innerhtml}
                </Text>
            }
            {element.tag=="TextInput"&&
                <TextInput
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                </TextInput>
            }
            {element.tag=="View"&&
                <View
                    {...getInteractions()}
                    {...props}
                    style={[styles]}
                >
                    {element.childs.length>0&&
                        <>
                            {element?.childs.map((elm, index)=>{
                                return (
                                        <ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position={[...position, index]}
                                        >
                                        </ElementContainer>
                                    )
                            })}
                        </>
                    }
                </View>
            }
            {/* {element.tag=="PagerView"&&
                <PagerView
                    {...getInteractions()}
                    {...props}
                    style={style}
                >
                </PagerView>
            } */}
            {/* {element.tag=="WebView"&&
                <WebView
                    {...getInteractions()}
                    {...props}
                    style={styles}
                >
                </WebView>
            } */}
            {element.tag=="DrawerLayout"&&
                <>
                </>
            }
        </>
    )

}


export default AndroidNativeElements;