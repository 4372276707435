

const Iosapps = ()=>{

    return (
        <h1>
            Coming Soon !!!
        </h1>
    )

}

export default Iosapps;