import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { getManifestDetailsApi, getManifestDocItemsApi } from "./marketplace.service";


export const getManifestDetails = createAsyncThunk(
    "marketplace/getmanifestdetails",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestDetailsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest"));
            throw error;
        }
    }
)


export const getManifestDocItems = createAsyncThunk(
    "marketplace/getmarketdocitems",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getManifestDocItemsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting manifest items"))
            throw error;
        }
    }
)



const marketplaceslice = createSlice({
    name: "marketplace",
    initialState: {
        manifestdetails: null,
        manifestdocitems: []
    },
    reducers: {

    },
    extraReducers: (builder)=>{
        builder.addCase(getManifestDetails.fulfilled, (state, action)=>{
            state.manifestdetails = action.payload;
        })
        builder.addCase(getManifestDocItems.fulfilled, (state, action)=>{
            state.manifestdocitems = action.payload;
        })
    }

})

export const selectManifestDetails = state => state.marketplace.manifestdetails;

export const selectManifestDocItems = state => state.marketplace.manifestdocitems;


export default marketplaceslice.reducer;