import { useRef, useState } from "react";
import SingleField from "./SingleField";
import SingleFieldPreview from "./SingleFieldPreview";
import "./InputDesigner.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { CircularProgress, IconButton, LinearProgress, Switch, TextField, Tooltip } from "@mui/material";
import {useParams, redirect, useNavigate, Link} from "react-router-dom";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import BreadCrumbs from "./BreadCrumbs";
import {
            selectSchemaById, 
            getSchemaById, 
            createSchemas, 
            selectCreateschema, 
            updateSchema, 
            selectSchemaapppermission, 
            selectschemaactiveusers, 
            selectschemaactive, 
            getSchemaActiveUsersCount, 
            setschemaactive,
            selectSchemaLoader
        } from "./../Services/schemas/schema.slice";
import {
    deactivatecollaborator,
    getActiveCollaborators,
    selectActiveCollaborators
} from "./../Services/collaborators/collaborators.slice";

import {getApps, selectApplist} from  "./../Services/apps/app.slice";
import Editevent from "./Editevent";
import {
    resetactivepipeline,
    resetactivepipelineid,
    selectActivepipelineid,
    createPipeline,
    getPipelinebySchema,
    selectPipelinelist, 
    getPipelinebyId, 
    setactivepipelineid,
    deletePipelineById,
    resetpipelinedeleted,
    selectPipelineDeleted,
    deployPipeline,
    activatePipeline,
    pausePipeline,
    selectPipelinedeployed,
    selectPipelineactivated,
    selectPipelinepaused,
    resetpipelinedeployed,
    resetpipelinepaused,
    resetpipelineactivated,
    selectInputStage,
    getInputStage,
    selectGetSchemapipeLoader
} from "../Services/pipelines/pipeline.slice";
import {
    getEvents,
    selectEvents,
    deleteEvent,
    selectEventdeleted,
    reseteventdeleted,
    selectGetEventsLoader,

} from "../Services/events/event.slice";
import RegisterEvent from "./Registerevent";
import * as _ from "lodash";
import 'draft-js/dist/Draft.css';
import TestStage from "./apps/TestStage/TestStage";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DataDisplay from "./data/Datadisplay";
import Readme from "./readme/Readme";
import { getDocument, selectActiveDocument, updateDocument } from "../Services/documentations/documentations.slice";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const FunctionDeleteModal = ({
                                open, 
                                handleClose,
                                func,
                                deleteFunction,
                                deletepipelineclicked
                            })=>{
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Delete Function {func?.name}
                    </h3>
                    {deletepipelineclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Do you really want to delete function?
                            </Typography>
                            <div style={{display: "flex", "padding": 10}}>
                                <div>
                                    <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>No</Button>
                                </div>
                                <div>
                                    <Button onClick={()=>{deleteFunction(func._id)}} style={{color: "#3A07CD"}}>Yes</Button>
                                </div>
                            </div>    
                        </>
                    }
                </div>
            </Box>
      </Modal>
    )
}

const EventDeleteModal = ({
                            open, 
                            handleClose, 
                            event, 
                            deleteEvent,
                            eventdeleteclicked
                        })=>{
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Delete Event {event?.name}
                    </h3>
                    {eventdeleteclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <div>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Deleting event will result in deleting all the related pipelines too. Do really want to delete the event?
                            </Typography>
                            <div style={{display: "flex", "padding": 10}}>
                                <div>
                                    <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>No</Button>
                                </div>
                                <div>
                                    <Button onClick={()=>{deleteEvent(event._id)}} style={{color: "#3A07CD"}}>Yes</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    )
}

const SchemaFieldDeleteEvent = ({
                                    open,
                                    handleClose,
                                    deleteField,
                                    fieldname
                                })=>{

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box
                sx={style}
            >
                <div>
                    <h3>
                        Delete Schema Key {fieldname}
                    </h3>
                    <div>
                        Delete Schema Key, deleting the key will delete the data associated with the key and it is a non reversible event, you will not be able recover the data back.
                    </div>
                    <div style={{}}>
                        <Button onClick={()=>{handleClose()}}>Cancel</Button>
                        <Button onClick={()=>{deleteField()}}>Delete</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

const InputDesigner = ()=>{
    
    const {id} = useParams();

    const {appid, workspace} = useParams();

    const dispatch = useDispatch();

    let navigate = useNavigate();

    const [displayapplist, setDisplayapplist] = useState([]);

    const [schema, setSchema] = useState([
                                            {
                                                "inputtype": "auto",
                                                "inputkey": "id",
                                                "inputlabel": "Id",
                                                "inputhelp": "Id of the schema object",
                                                "required": true,
                                                "unique": true,
                                                "optionlist": [],
                                                "defaultValue": "",
                                                "type": "system"
                                            },
                                            {
                                                "inputtype": "datetime",
                                                "inputkey": "createdAt",
                                                "inputlabel": "Created At",
                                                "inputhelp": "Datetime of creation of object",
                                                "required": true,
                                                "unique": false,
                                                "optionlist":[],
                                                "defaultValue": ()=>{return new Date()},
                                                "type": "system"
                                            },
                                            {
                                                "inputtype": "datetime",
                                                "inputkey": "updatedAt",
                                                "inputlabel": "Updated At",
                                                "inputhelp": "Datetime of updation of object",
                                                "required": true,
                                                "unique": false,
                                                "optionlist":[],
                                                "defaultValue": ()=>{return new Date()},
                                                "type": "system"
                                            }
                                        ]);

    const [schemaname, setSchemaName] = useState("");

    const [schemaSlug, setSchemaSlug] = useState("");

    const [opensinglefield, setopensinglefield] = useState(false);

    const [schematoedit, setSchemaToEdit] = useState(null);

    const [schemaeditindex, setSchemaEditIndex] = useState(null);

    const [newpipeline, setnewpipeline] = useState(false);

    const [openDeleteSchemaField, setOpenDeleteSchemaField] = useState(false);

    const [indextodelete, setIndextoDelete] = useState(null);

    const [fieldtodelete, setFieldtoDelete] = useState(null);

    const [editorState, setEditorState] = useState("Write description and documentation about the schema, events and functions");

    const applist = useSelector(selectApplist);

    const pipelinelist = useSelector(selectPipelinelist);

    const schemaapppermission = useSelector(selectSchemaapppermission);

    const addPipeline = ()=>{
        dispatch(resetactivepipelineid(""));
        dispatch(resetactivepipeline(""));
        setnewpipeline(true);
        dispatch(createPipeline({schemaid: id, source: "apps", "workspace": workspace}));
    }

    const openPipeline = (id)=>{
        dispatch(setactivepipelineid(id));
        dispatch(getPipelinebyId({id:id}));
        setnewpipeline(true);
        // setopenmotorcreate(true);
    }

    // pipeline delete controls
    const [openDeletePipeline, setOpenDeletePipeline] = useState(false);

    const [pipelineToDelete, setPipelineToDelete] = useState(null);

    const [deletepipelineclicked, setDeletepipelineclicked] = useState(false);
    
    const deletepipelineack = useSelector(selectPipelineDeleted);

    const closePipelineDeleteModal = ()=>{
        setOpenDeletePipeline(false);
        setPipelineToDelete(null);
    }

    const openDeletePipelineModal = (pipeline)=>{
        setPipelineToDelete(pipeline);
        setOpenDeletePipeline(true);
    }

    const deletePipeline = (id)=>{
        dispatch(resetpipelinedeleted());
        setDeletepipelineclicked(true);
        dispatch(deletePipelineById({id:id}))
    }

    useEffect(()=>{
        if(deletepipelineack&&deletepipelineclicked){
            setDeletepipelineclicked(false);
            setOpenDeletePipeline(false);
            setPipelineToDelete(null);
            dispatch(getPipelinebySchema({schemaid: id}))
        }
    },[deletepipelineack])

    const activepipleineid = useSelector(selectActivepipelineid);

    useEffect(()=>{
        if(newpipeline&&activepipleineid!=""){
            navigate("/"+workspace+"/schemas/"+appid+"/details/"+id+"/"+activepipleineid+"/apps");
        }
    },[activepipleineid,newpipeline])
    
    const [appsdispatched, setAppsdispatched] = useState(true);

    useEffect(()=>{
        if(applist.length==0&&appsdispatched==false){
            dispatch(getApps({
                workspace: workspace
            }));
            setAppsdispatched(true);
        }else{
            setDisplayapplist(applist);
        }
    },[applist])

    // functions related to registering an event
    const [openRegisterEvent, setOpenRegisterEvent] = useState(false);

    const [editeventid, setediteventid] = useState(null);

    const [editeventmodal, setEditeventmodal] = useState(false);

    const closeRegisterEvent = ()=>{
        setOpenRegisterEvent(false);
    }

    const closeEditEvent = ()=>{
        setEditeventmodal(false);
        setediteventid(null);
    }

    const openEditEvent = (id)=>{
        setEditeventmodal(true);
        setediteventid(id);
    }

    const [openDeleteEvent, setOpenDeleteEvent] = useState(false);

    const [eventToDelete, setEventToDelete] = useState(null);

    const openEventDeleteModal = (event)=>{
        setEventToDelete(event);
        setOpenDeleteEvent(true);
    }

    const [eventdeleteclicked, seteventdeleteclicked] = useState(false);

    const delEvent = (eventid)=>{
        seteventdeleteclicked(true)
        dispatch(reseteventdeleted());
        dispatch(deleteEvent({eventid: eventid, schemaid: id}));
    }

    const eventdeleted = useSelector(selectEventdeleted);

    useEffect(()=>{
        if(eventdeleteclicked&&eventdeleted){
            seteventdeleteclicked(false);
            setOpenDeleteEvent(false);
        }
    },[eventdeleteclicked, eventdeleted])

    const events = useSelector(selectEvents);

    // functions related to remote schema creation
    const addtoschema = (field)=>{
        let schemaCopy = [...schema];
        if(schemaeditindex!=null){
            schemaCopy[schemaeditindex] = field
        }else{
            schemaCopy.push(field);
        }
        setopensinglefield(false);
        setSchemaToEdit(null);
        setSchemaEditIndex(null);
        dispatch(updateSchema({
                                    id: id, 
                                    payload: {
                                                "app": appid, 
                                                "schemaname": schemaname, 
                                                "schemaslug": schemaSlug, 
                                                "schema": schemaCopy
                                             }
                             }));
    }

    const closeSingleField = ()=>{
        setopensinglefield(false);
        setSchemaToEdit(null)
    }

    const editField = (index)=>{
        setSchemaToEdit({...schema[index]});
        setSchemaEditIndex(index);
        setopensinglefield(true);
    }

    const deleteFieldAction = (index, fieldname)=>{
        setOpenDeleteSchemaField(true);
        setIndextoDelete(index);
        setFieldtoDelete(fieldname);
    }

    const deleteField = ()=>{
        if(indextodelete!=null){
            let schemaCopy = [...schema];
            schemaCopy.splice(indextodelete,1);
            dispatch(updateSchema({
                payload: {
                    "app": appid,
                    "schemaname": schemaname,
                    "schemaslug": schemaSlug,
                    "schema": schemaCopy
                },
                id: id
            }))
        }
    }

    const remoteschema = useSelector(selectSchemaById);

    useEffect(()=>{
        if(Object.keys(remoteschema).length==0){
            return
        }
        let remoteschemacopy = {...remoteschema};
        setSchema(remoteschemacopy.schema);
        setSchemaName(remoteschemacopy.schemaname);
        setSchemaSlug(remoteschemacopy.schemaslug);
        if(openDeleteSchemaField==true){
            setOpenDeleteSchemaField(false);
        }
    },[remoteschema])

    useEffect(()=>{
        if(id==undefined){
            return;
        }
        dispatch(getSchemaById(id));
        dispatch(getPipelinebySchema({schemaid: id}));
        dispatch(getEvents(id));
        dispatch(getDocument({
            source: "schema",
            sourceid: id
        }))

    },[])

    const [saveClicked, setSaveClicked] = useState(false);

    const schemasaved = useSelector(selectCreateschema);

    const save = (schemaobject)=>{
        if(schemaobject==undefined){
            if(id==undefined){
                let schmeaobj = {
                    "app": appid,
                    "schemaname": schemaname,
                    "schemaslug": schemaSlug,
                    "schema": schema
                }
                // setSaveClicked(true);
                dispatch(createSchemas(schmeaobj));
            }else{

                let schmeaobj = {
                    "app": appid,
                    "schemaname": schemaname,
                    "schemaslug": schemaSlug,
                    "schema": schema
                }
                // setSaveClicked(true);
                dispatch(updateSchema({id:id,payload: schmeaobj}));
            }
        }else{
            let schemaobj = {
                "app": schemaobject.appid,
                "schemaname": schemaobject.schemaname,
                "schemaslug": schemaobject.schemaslug,
                "schema": schemaobject.schema

            }
            dispatch(updateSchema({id:id, payload: schemaobj}))
        }        
    }

    useEffect(()=>{
        if(schemasaved&&saveClicked){
            navigate("/"+workspace+"/schemas")
        }
    },[schemasaved,saveClicked])

    // pipeline deployment controls
    const [deployclicked, setDeployclicked] = useState(false);

    const deployPipelineAction = (id)=>{
        setDeployclicked(true);
        dispatch(resetpipelinedeployed());
        dispatch(deployPipeline(id))
    }

    const pipelinedeployed = useSelector(selectPipelinedeployed);

    useEffect(()=>{
        if(deployclicked&&pipelinedeployed){
            dispatch(getPipelinebySchema({schemaid: id}));
            setDeployclicked(false);
        }
    },[deployclicked, pipelinedeployed]);

    
    const [activeclicked, setActiveclicked] = useState(false);

    const activeClickAction = (id)=>{
        setActiveclicked(true);
        dispatch(resetpipelineactivated());
        dispatch(activatePipeline(id));
    }

    const pipelineactivated = useSelector(selectPipelineactivated);

    useEffect(()=>{
        if(activeclicked&&pipelineactivated){
            dispatch(getPipelinebySchema({schemaid: id}));
            setActiveclicked(false);
        }
    },[pipelineactivated, activeclicked])

    const [pauseclicked, setPauseclicked] = useState(false);

    const pauseClickedAction = (id)=>{
        setPauseclicked(true);
        dispatch(resetpipelinepaused());
        dispatch(pausePipeline(id));
    }

    const pipelinepaused = useSelector(selectPipelinepaused);

    useEffect(()=>{
        if(pauseclicked&&pipelinepaused){
            dispatch(getPipelinebySchema({schemaid: id}));
            setPauseclicked(false);
        }
    },[pauseclicked, pipelinepaused])

    const togglePipeline = (checked, id)=>{
        if(checked){
            activeClickAction(id);
        }else{
            pauseClickedAction(id);
        }
    }
    // manual pipeline 

    const [openInput, setOpenInput] = useState(false);

    const [pipelineRunning, setPipelineRunning] = useState("");

    const [inputstage, setInputstage] = useState(null);

    const inputstageremote = useSelector(selectInputStage);

    useEffect(()=>{
        if(inputstageremote!=null){
            setInputstage(inputstageremote);
        }
    },[inputstageremote])

    const openInputModal = (id)=>{
        setPipelineRunning(id);
        dispatch(getInputStage(id));
        setOpenInput(true);
    }

    // description controls
    const remotedoc = useSelector(selectActiveDocument);
    
    // called on load
    useEffect(()=>{
        if(remotedoc!=""||remotedoc!=undefined){
            setEditorState(remotedoc);
        }

    },[remotedoc]);

    const setSchemaDescAction = (doc)=>{
        let desc = doc;
        dispatch(updateDocument({
                                    source: "schema",
                                    sourceid: id,
                                    document: desc
                                }));
        setEditorState(doc);
    }

    useEffect(()=>{
        dispatch(getActiveCollaborators({
                                            "resource": "schema",
                                            "resourceid": id
                                        }))
        return ()=>{
            dispatch(
                deactivatecollaborator({
                    "resource": "schema",
                    "resourceid": id
                })
            )
        }
    },[id])

    const activeusers = useSelector((state)=>{
        return selectActiveCollaborators(state, "schema", id)
    }); 


    const schemaloader = useSelector(selectSchemaLoader);

    const schemapipesloader = useSelector(selectGetSchemapipeLoader);

    const geteventsloader = useSelector(selectGetEventsLoader);

    return(
        <div className="input-container">
            {(schemaloader>0||schemapipesloader>0||geteventsloader>0)&&
                <>
                    <LinearProgress></LinearProgress>
                </>
            }
            <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "apps",
                                "url": "/"+workspace+"/apps"
                            }
                        ]:
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "workspace",
                                "url": "/workspaces/"+workspace+"/"
                            },
                            {
                                "name": "apps",
                                "url": "/"+workspace+"/apps"
                            },
                            {
                                "name": "appdetail",
                                "url": "/"+workspace+"/schemas/"+appid
                            }
                        ]
                    }
                >
                </BreadCrumbs>
            <div className="input-container-headers">
                <div style={{display: "flex" , justifyContent: "flex-end"}}>
                    {activeusers!=undefined&&
                        <>
                            {activeusers.map((activeuser)=>{
                                return (
                                    <Tooltip title={activeuser.emailid}>
                                        <IconButton>
                                            <span className="material-symbols-outlined">
                                                account_circle
                                            </span>
                                        </IconButton>
                                    </Tooltip> 
                                )
                            })}
                        </>
                    }
                    
                </div>
                <div className="schema-action-button">
                    <Link to={"/"+workspace+"/schemas/"+appid} style={{textDecoration: "none"}}><Button style={{color: "#3A07CD"}}>Close</Button></Link>
                </div>
            </div>
            <div className="input-fields-container">
                <div className="input-list">
                    <div className="input-meta-data">
                        <div className="schema-inputs">
                            {schemaapppermission=="write"?
                                <TextField fullWidth value={schemaname} onChange={(e)=>{setSchemaName(e.target.value)}} onBlur={()=>{save()}} label="Schema Name" ></TextField>:
                                <TextField fullWidth value={schemaname} label="Schema Name" ></TextField>
                            }
                            
                        </div>
                        <div className="schema-inputs">
                            {schemaapppermission=="write"?
                                <TextField fullWidth value={schemaSlug} onChange={(e)=>{setSchemaSlug(e.target.value)}} disabled={true} label="Schema Slug"></TextField>:
                                <TextField fullWidth value={schemaSlug} label="Schema Slug"></TextField>
                            }
                        </div>
                    </div>
                    <div>
                        <h3>Schema</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                        <TableRow>
                                            <TableCell>Label</TableCell>
                                            <TableCell align="right">Key</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {schema.map((sc,index) => (
                                            <TableRow
                                                key={sc.inputkey}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {sc.inputlabel}
                                                </TableCell>
                                                <TableCell align="right">{sc.inputkey}</TableCell>
                                                <TableCell align="right">{sc.inputtype}</TableCell>
                                                <TableCell align="right">
                                                    
                                                    {sc.type=="system"?
                                                        <>
                                                        </>:
                                                        <>
                                                            {schemaapppermission=="write"&&
                                                                <IconButton onClick={()=>{deleteFieldAction(index, sc.inputkey)}}>
                                                                    <span class="material-symbols-outlined">
                                                                        delete
                                                                    </span>
                                                                </IconButton>
                                                            }
                                                        </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))} 
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="save-button-block">
                            {schemaapppermission=="write"&&
                                <Button  onClick={()=>{setopensinglefield(true)}} style={{color: "#3A07CD"}}>Add Field</Button>
                            }
                        </div>
                    </div>
                    <div>
                        <h3>Events</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Counter</TableCell>
                                        <TableCell>Last Event Time</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events.map((event)=>{
                                        return(
                                            <TableRow>
                                                <TableCell>
                                                    {event.name}
                                                </TableCell>
                                                <TableCell>
                                                    {event?.eventcount}
                                                </TableCell>
                                                <TableCell>
                                                    {event?.eventtime}
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{display: "flex"}}>
                                                        {schemaapppermission=="write"&&
                                                            <>
                                                                <IconButton onClick={()=>{openEventDeleteModal(event)}}>
                                                                    <span className="material-symbols-outlined">
                                                                        delete
                                                                    </span>
                                                                </IconButton>
                                                                <IconButton onClick={()=>{openEditEvent(event._id)}}>
                                                                    <span className="material-symbols-outlined">
                                                                        edit
                                                                    </span>
                                                                </IconButton>    
                                                            </>
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="save-button-block">
                            {schemaapppermission=="write"&&
                                <Button  onClick={()=>{setOpenRegisterEvent(true)}} style={{color: "#3A07CD"}}>Add Event</Button>
                            }
                        </div>
                    </div>
                    <div>
                        <h3>Functions</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Success</TableCell>
                                        <TableCell>Errors</TableCell>
                                        <TableCell>Last Edit</TableCell>
                                        <TableCell>Last Deployed</TableCell>
                                        <TableCell>Running</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pipelinelist.map((pipeline)=>{
                                        return(
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {pipeline._id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {schemaapppermission=="write"?
                                                        <a href="" onClick={()=>{openPipeline(pipeline._id)}}>
                                                            {pipeline.name}
                                                        </a>:
                                                        <a href="">
                                                            {pipeline.name}
                                                        </a>
                                                    }
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={"/"+workspace+"/pipelinelogs/"+appid+"/"+id+"/"+pipeline._id+"/"+true}>{pipeline.success}</Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={"/"+workspace+"/pipelinelogs/"+appid+"/"+id+"/"+pipeline._id+"/"+false}>{pipeline.error}</Link>
                                                </TableCell>
                                                <TableCell>
                                                    {pipeline.updatedAt}
                                                </TableCell>
                                                <TableCell>
                                                    {pipeline.deployedAt?
                                                        <>
                                                            {pipeline.deployedAt}
                                                        </>:
                                                        <>
                                                            {schemaapppermission=="write"&&
                                                                <Button style={{color: "#3A07CD"}} onClick={()=>{deployPipelineAction(pipeline._id)}}>Deploy</Button>
                                                            }
                                                        </>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {pipeline.manual?
                                                        <>
                                                            {pipeline.deployed?
                                                                <Tooltip title="Run pipeline">
                                                                    <Button style={{color: "#3A07CD"}} onClick={()=>{openInputModal(pipeline._id)}}>Run</Button>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="Deploy pipeline first">
                                                                    <Button style={{color: "#afafaf"}}>Run</Button>
                                                                </Tooltip>
                                                            }
                                                            
                                                        </>:
                                                        <>
                                                            {pipeline.deployed?
                                                                <>
                                                                    {schemaapppermission=="write"&&
                                                                        <Switch checked={pipeline.active} onChange={(e)=>{togglePipeline(e.target.checked, pipeline._id)}}></Switch>
                                                                    }
                                                                </>:
                                                                <>
                                                                    <Switch checked={pipeline.active} disabled={true}></Switch>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {schemaapppermission=="write"&&
                                                        <IconButton onClick={()=>{openDeletePipelineModal(pipeline)}}>
                                                            <span class="material-symbols-outlined">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="save-button-block">
                            {schemaapppermission=="write"&&
                                <Button style={{color: "#3A07CD"}} onClick={()=>{addPipeline()}}>Add Function</Button>
                            }
                        </div>
                    </div>
                </div>
                <div className="input-form">
                    <div>
                        <DataDisplay schemaid={id}></DataDisplay>
                    </div>
                    <div className="schema-reports">
                        <div>
                            <Readme 
                                readme={editorState}
                                setreadme={setSchemaDescAction}
                                write={schemaapppermission}
                                mode={"component"}
                            >
                            </Readme>
                        </div>
                    </div>
                    <div className="schema-reports">
                        <h3>
                            Reports
                        </h3>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
            {opensinglefield&&<SingleField addtoschema={addtoschema} sc={schematoedit} close={closeSingleField}></SingleField>}
            <RegisterEvent open={openRegisterEvent} handleClose={closeRegisterEvent} schemaid={id}></RegisterEvent>
            <Editevent open={editeventmodal} handleClose={closeEditEvent} eventid={editeventid}></Editevent>
            <TestStage  open={openInput} 
                        handleClose={()=>{setOpenInput(false)}}
                        loop={inputstage}
                        pipelineid={pipelineRunning}
                        header={"run"}
                    >        
            </TestStage>
            <FunctionDeleteModal
                open={openDeletePipeline}
                handleClose={closePipelineDeleteModal}
                deleteFunction={deletePipeline}
                func={pipelineToDelete}
                deletepipelineclicked={deletepipelineclicked}
            >
            </FunctionDeleteModal>
            <EventDeleteModal
                open={openDeleteEvent}
                handleClose={()=>{setOpenDeleteEvent(false)}}
                deleteEvent={delEvent}
                event={eventToDelete}
                eventdeleteclicked={eventdeleteclicked}   
            >
            </EventDeleteModal>
            <SchemaFieldDeleteEvent
                open={openDeleteSchemaField}
                handleClose={()=>{setOpenDeleteSchemaField(true)}}
                deleteField={deleteField}
            >

            </SchemaFieldDeleteEvent>
        </div>
        
    )

}

export default InputDesigner;