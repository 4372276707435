import { Button, TextField } from "@mui/material";
import CustomInput from "./apps/CustomInput/CustomInput"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {createEvent, resetcreatedevent, selectEventcreated} from "../Services/events/event.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    backgroundColor: "#fff"
  };

const RegisterEvent = ({
                            open, 
                            handleClose, 
                            schemaid 
                        })=>{

    const {workspace} = useParams();
    
    const [eventName, setEventName] = useState("");

    const [schema, setSchema] = useState([]);

    const [step, setStep] = useState(0);

    const [saveclicked, setSaveclicked] = useState(false);

    const dispatch = useDispatch();

    const registerEvent = (schema)=>{
        setSchema(schema);
        setStep(step+1);
    }

    const save = ()=>{
        let payload = {
            name: eventName,
            schema: schema,
            schemaid: schemaid,
            workspace: workspace
        }
        dispatch(resetcreatedevent());
        setSaveclicked(true);
        dispatch(createEvent(payload));
    }

    const eventcreated = useSelector(selectEventcreated);

    useEffect(()=>{
        if(saveclicked&&eventcreated){
            setSaveclicked(false);
            setEventName("")
            setSchema([])
            setStep(0);
            handleClose();
            
        }

    },[eventcreated])

    const intHandleClose = ()=>{
        setEventName("")
        setSchema([])
        setStep(0);
        handleClose()
    }

    return (
        <>
            <Modal
                open={open}
                onClose={intHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {step==0&&
                        <>
                            <CustomInput close={()=>{intHandleClose()}} initialSchema={[]} addtoschema={registerEvent} closeonadd={true}></CustomInput>    
                        </>
                    }
                    {step==1&&
                        <>
                            <div>
                                <IconButton onClick={()=>{intHandleClose()}}>
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </IconButton>
                            </div>
                            <div style={{padding:10}}>
                                <TextField label="Event name" onChange={(e)=>{setEventName(e.target.value)}} value={eventName}></TextField>
                            </div> 
                            <div style={{padding: 10}}>
                                <Button onClick={()=>{save()}} style={{color: "#3A07CD"}}>Save</Button>
                            </div>
                        </>
                    }
                </Box>
            </Modal>
        </>
    )

}

export default RegisterEvent;