import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLogApi, getLoglistApi , getLogDetailApi} from "./pipelinelogs.service";
import { loading, loadingdone } from "../loader/loader.slice";

export const getLogs = createAsyncThunk(
    "pipelinelogs/getlogs",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading())
            let res = await getLoglistApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            throw error;
        }
    }
)

export const getLog = createAsyncThunk(
    "pipelinelogs/getlog",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await getLogApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(loadingdone());
            throw error;
        }
    }   
)

const pipelinelogSlice = createSlice({
    name: "pipelinelogs",
    initialState:{
        loglist : [],
        log: null,
        activelog: {}   
    },
    reducers:{
        resetlog: (state, payload)=>{
            state.log = null
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getLogs.fulfilled, (state, action)=>{
            state.loglist= action.payload;
        })
        .addCase(getLog.fulfilled, (state, action)=>{
            state.log = action.payload;
        })
    }
})

export const selectLogs = (state) =>  state.pipelinelogs.loglist;

export const selectLog = (state) => state.pipelinelogs.log;

export const {resetlog} = pipelinelogSlice.actions;

export default pipelinelogSlice.reducer;