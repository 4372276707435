import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import { 
            getManifestDetails, 
            getManifestDocItems, 
            selectManifestDetails, 
            selectManifestDocItems, 
            selectManifestItems 
        } from "../../Services/marketplace/marketplace.slice";
import DocumentFetcher from "./ReactDocumentViewer";
import "./MarketManifestDetails.css";

const ManifestDocBar = ({items, showdoc})=>{

    return (
        <div>
            {items.map((item)=>{
                return (
                    <div className="doc-menubar-item">
                        <div onClick={()=>{showdoc(item.type, item.id)}}>
                            {item.name}
                        </div>
                        {item.childs.length>0&&
                            <ManifestDocBar
                                items={item.childs}
                                showdoc={showdoc}
                            >
                            </ManifestDocBar>
                        }
                    </div>
                )
            })}
        </div>
    )
}

const ManifestDocumentation = ({items})=>{

    const [docparams, setdocparams] = useState({
                            source: "",
                            sourceid: ""
                        })
    const showdoc = (source, sourceid)=>{
        let docparamscopy = {...docparams,
                              source: source,
                              sourceid: sourceid
                            }
        setdocparams(docparamscopy);
    }

    useEffect(()=>{
        if(items!=undefined&&items.length>0){
            let source = items[0].type;
            let sourceid = items[0].id;
            let docparamscopy = {...docparams,
                                  source: source,
                                  sourceid: sourceid
                                }
            setdocparams(docparamscopy);
        }
    },[items])

    return(
        <div className="manifest-doc-container">
            <div className="manifest-doc-bar">
                <ManifestDocBar
                    items={items}
                    showdoc={showdoc}
                >
                </ManifestDocBar>
            </div>
            <div className="manifest-doc-display">
                <DocumentFetcher
                    source={docparams.source}
                    sourceid={docparams.sourceid}
                >
                </DocumentFetcher>
            </div>
        </div>
    )
}

const MarketManifestDetails = ({
                                manifestid,
                                preview,
                                manifestscope
                              })=>{

    const manifestdetails = useSelector(selectManifestDetails);

    const manifestitemdocs = useSelector(selectManifestDocItems)


    const dispatch = useDispatch();

    useEffect(()=>{
        if(
            manifestid!=""&&
            manifestscope!=""&&
            manifestid!=undefined&&
            manifestscope!=undefined
        ){
            if(preview===true||preview===false){
                dispatch(getManifestDetails({manifestid:manifestid, preview:preview, manifestscope:manifestscope}));
                dispatch(getManifestDocItems({manifestid:manifestid, preview:preview, manifestscope:manifestscope}));
            }
            
        }
    },[manifestid, preview, manifestscope])

    return (
        <div>
            <div className="manifest-top-section">
                <div className="manifest-details">
                    <h1>
                        {manifestdetails?.manifestname}
                    </h1>
                    <h6>
                        {manifestdetails?.publishername}
                    </h6>
                    <div>
                        <div>
                            <div>{manifestdetails?.installs}</div>
                            <div>installs</div>
                        </div>
                    </div>
                    <div>
                        <Button>Install</Button>
                        {preview&&
                            <Button>Preview</Button>
                        }
                    </div>
                </div>
                <div className="manifest-logo-container">
                    {manifestdetails?.favicon&&
                        <div className="manifest-logo">
                            <img style={{maxWidth: "100%", minWidth: "100%"}} src={manifestdetails.favicon}></img>
                        </div>
                    }
                </div>
            </div>
            <div className="manifest-banners">
                <div>
                    <h3 style={{marginLeft: "10px"}}>
                        Banners
                    </h3>
                </div>
                <div className="manifest-banners-outer-box">
                    <div className="manifest-banners-box">
                        {manifestdetails?.banners.map((banner, index)=>{
                            return (
                                <div style={{margin: "20px"}}>
                                    <div className="banner-tile">
                                        <img style={{maxWidth: "100%", maxHeight: "100%"}} src={banner}></img>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>      
            </div>
            <div className="manifest-documentation">
                <div>
                    <h3>Readme</h3>
                </div>
                <ManifestDocumentation
                    items={manifestitemdocs}
                >
                </ManifestDocumentation>
            </div>
        </div>
    )
}


export default MarketManifestDetails;