import axios from "axios";
import { baseURL } from "../../env";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEnumOptionsApi } from "./utils.service";
import { loading, loadingdone } from "../loader/loader.slice";
import { getObjectVal } from "../../apps/utils";

const executeRequest = (req)=>{
    let host = req.host;
    let path = req.path;
}


const fillUrl = (urlstring, data)=>{
    let keys = Object.keys(data);
    for(let i=0; i<keys.length; i++){
        urlstring = urlstring.replace("<%="+keys[i]+"%>",data[keys[i]]);
    }
    return urlstring;
}

const getObjectKeys = ()=>{

}

export const getEnumOptions = createAsyncThunk(
    "utils/getenumoptions",
    async(payload, {
                    dispatch, 
                    rejectWithValue,
                    getState
                })=>{
        try{
            dispatch(loading());
            let type = payload.type;

            if(type=="selector"){
                let state = getState();
                
            }else if(type=="keys"){
                let sc = payload.sc;
                let sm = payload.sm;
                let objectval = {};
                getObjectVal(sc, sm, objectval);
                let keys = Object.keys(objectval.object);
                let options = [];
                for(let i=0; i<keys.length; i++){
                    options.push(
                                  {
                                    "name": keys[i],
                                    "value": keys[i]
                                  }
                                )
                }
                return {
                         "id": payload.id,
                         "options": options
                       }
            }
            else if(type=="http"){
                let url = fillUrl(payload.url, payload.data);
                let res = await getEnumOptionsApi({url: url});
                dispatch(loadingdone());
                return {
                            "id": payload.id,
                            "options": res
                        }
            }
        }catch(error){
            dispatch(loadingdone());
            throw error;
        }
    }
)

export const getPipelineInputSchema = createAsyncThunk(
    "utils/getpipelineinputschema",
    async(payload, {
                     dispatch,
                     rejectWithValue,
                     getState
                    })=>{
        try{
            
        }catch(error){

        }
    }
)

const utilSlice = createSlice({
    "name": "utils",
    "initialState":{
        enumResults:{

        }
    },
    "reducers":{
        "resetenums":(state, action)=>{
            state.enumResults = {};
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getEnumOptions.fulfilled, (state, action)=>{
            let enumResultsCopy = {...state.enumResults};
            let payload = action.payload;
            enumResultsCopy[payload.id] = payload.options;
            state.enumResults = enumResultsCopy;
        })
    }
})

export const selectEnumResults = (id)=>{return (state) => {
    if(id==""||id==undefined){
        return [];
    }else{
        return state.utils.enumResults[id]
    }
}}


export const {resetenums} = utilSlice.actions;

export default utilSlice.reducer;