import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getWorkspace, selectWorkspace, selectWorkspaceLoader } from "../../Services/workspaces/workspace.slice";
import "./WorkspaceDetail.css";
import EnvironVariables from "../environvariables/Environvariables";
import BreadCrumbs from "../BreadCrumbs";
import { getManifestVersion, selectManifestLoader, selectManifestVersion } from "../../Services/manifest/manifest.slice";
import { Button, CircularProgress, LinearProgress } from "@mui/material";
import Readme from "../readme/Readme";
import { getDocument, 
    getDocumentationPermission, 
    resetactivedocumentpermission, 
    resetdocument, 
    selectActiveDocument, 
    selectActiveDocumentPermission, 
    selectDocumentLoader, 
    updateDocument 
   } from "../../Services/documentations/documentations.slice";

const WorkspaceDetails = ()=>{

    const [editorState, setEditorState] = useState(
        ""
      );
    const setAppDoc = (doc)=>{
        let desc = doc;
        dispatch(updateDocument({
            source: "workspace",
            sourceid: workspace,
            document: desc
        }));
        setEditorState(doc);
    }

    const {workspace} = useParams();

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getWorkspace(workspace));
        dispatch(resetdocument());
        dispatch(resetactivedocumentpermission());
        dispatch((getDocument({
            "source": "workspace",
            "sourceid": workspace
        })))
        dispatch(getDocumentationPermission({
            "source": "workspace",
            "sourceid": workspace
        }))
    },[])

    const workspacedetail = useSelector(selectWorkspace);

    const resourcepermission = useSelector(selectActiveDocumentPermission);
    
    useEffect(()=>{
        dispatch(getManifestVersion({
            source: "workspace",
            sourceid: workspace
       }))
    },[])

    const publishedversion = useSelector(selectManifestVersion);

    const navigate = useNavigate();

    const openPublish = ()=>{
        navigate("/"+workspace+"/manifest/workspace/"+workspace)
    }

    const workspaceloader = useSelector(selectWorkspaceLoader);

    const documentloader = useSelector(selectDocumentLoader);

    const manifestloader = useSelector(selectManifestLoader);

    return (
        <div>
            {workspaceloader>0&&
              <div>
                <LinearProgress></LinearProgress>
              </div>  
            }
            <BreadCrumbs
                    breadcrumbs={
                        [
                        {
                            "name": "dashboard",
                            "url": "/"
                        },
                        {
                            "name": "workspaces",
                            "url": "/workspaces/"
                        }
                ]}
                
                >
            </BreadCrumbs>
            <div className="dashboard-main-container">
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Apps</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/apps"}>{workspacedetail.details?.apps}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Interfaces</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/interfaces"}>{workspacedetail.details?.interfaces}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Folders</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/filesystem"}>{workspacedetail.details?.folders}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Ml-Models</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/"}>{0}</Link>
                        </h2>
                    </div>
                </div>
            </div>
            <div>
                <h3>Environment Variable</h3>
                <EnvironVariables
                    resource={"workspace"}
                    resourceId={workspace}
                >
                </EnvironVariables>
            </div>
            <div>
                <div className="publish-block">
                    {manifestloader>0?
                        <>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <CircularProgress></CircularProgress>
                            </div> 
                        </>:
                        <>
                            <div>
                                {publishedversion.version>1?
                                    <div>
                                        Status: published , version: <Link to={"/"+workspace+"/savedmanifest/workspace/"+workspace+"/"+publishedversion.id}>{publishedversion.version-1}</Link>
                                    </div>:
                                    <div>
                                        Status: not published
                                    </div>
                                }
                            </div>
                            <div>
                                <Button onClick={()=>{openPublish()}} style={{"color": "#3A07CD"}}>Publish</Button>
                            </div>   
                        </>
                    }
                </div>
            </div>
            <div>
                {documentloader>0?
                    <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>
                    </>:
                    <>
                        <Readme
                            readme={editorState}
                            setreadme={setAppDoc}
                            write={resourcepermission}
                            source={"workspace"}
                            sourceid={workspace}
                        >
                        </Readme>
                    </>
                }
                
            </div>
        </div>
    )

}


export default WorkspaceDetails;