import { useSelector } from "react-redux";
import { jsoncompare, selectConditions } from "../../../Services/apptemplates/apptemplates.slice";
import SingleBlock from "./SingleBlock";

const ConditionalBlock = ({
    element,
    elmkey,
    indexarr,
    position,
    type,
    childs,
    parentkey,
    parentposition,
    parentindexarr,
    path,
    preview,
    indesign
})=>{


    const cond = useSelector((state)=>{ return selectConditions(state, element.conditions, elmkey, position)}, jsoncompare);


    return(
        <>
            {cond&&
                <SingleBlock
                    element={element}
                    elmkey={elmkey}
                    indexarr={indexarr}
                    position={position}
                    type={type}
                    childs={childs}
                    parentkey={parentkey}
                    parentposition={parentposition}
                    parentindexarr={parentindexarr}
                    path={path}
                    preview={preview}
                    indesign={indesign}
                >
                </SingleBlock>
            }
        </>
    )

}


export default ConditionalBlock;