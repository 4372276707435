import {ConditionBlock, FromBlock, AppSchemaBlock, FieldSelectBox} from "./QueryBuilder";
import { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux";
import {getAppSchemas,selectAppSchemas} from "../../../Services/schemas/schema.slice";
import {getApps, selectApplist} from "../../../Services/apps/app.slice";
import * as _ from "lodash";
import { Button, IconButton, TextField } from "@mui/material";
import Output from "../Output/Output";
import {setmappingkey} from "../../../Services/pipelines/pipeline.slice";
import {getSchemaTable, getMappingValue} from "../../utils";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import "./UpdateBuilder.css";
import { useParams } from "react-router-dom";

const UpdateTableBlock = ({settings, availableSchemas,setSettings})=>{
    const [appschemaopen, setAppSchemaOpen] = useState(false);

    const setInternalSettings = (table)=>{
                    let  settingsCopy = {...settings,
                                         table: table
                                        }
                    setSettings(settingsCopy);
                    setAppSchemaOpen(false);
    }

    const toggleAppSchema = ()=>{
        let appschemaopencopy = !appschemaopen;
        setAppSchemaOpen(appschemaopencopy);
    }

    return (
        <>
            <div className="query-block">
            <div className="query-block-selector">
                <div className={"query-block-selection"} onClick={()=>{}}>
                    Update Table
                </div>
                {/* <div className={fromB==1?"query-block-selection active":"query-block-selection"} onClick={()=>{setFromB(1)}}>
                        From Query
                    </div> 
                */}
            </div>
            <>
                <div style={{width: 300}}>
                    <div style={{"display": "flex",alignContent: "center",padding: 10,justifyContent:"space-between", border: "1px solid #eee"}} onClick={()=>{
                            setAppSchemaOpen(!appschemaopen)
                        }}>
                        <div>
                            {settings.table.app+"."+settings.table.schemaslug}
                        </div>
                        <div onClick={()=>{toggleAppSchema()}}>
                            <span className="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    </div>
                    {appschemaopen&&
                    <>
                        {availableSchemas.map((app)=>{
                            return (
                                <AppSchemaBlock app={app} setSettings={setInternalSettings}></AppSchemaBlock>
                            )
                        })}
                    </>
                    }
                </div>
            </>
        </div>
        </>
    )
}


const UpdateValueSelect = ({
                            field, 
                            getFieldValue, 
                            setFieldValConst,
                            setFieldValGet,
                            pipelinetype,
                            i,
                            pipelinestage
                        })=>{

    const [openFieldDec, setOpenFieldDec] = useState(false);

    const closeFieldDec = ()=>{
            setOpenFieldDec(false)
    }

    return(
        <div>
            <div>
                <div className="decalare-variable-data-type">
                    <span style={{padding: 10}}>
                        type: {field.type}
                    </span>
                    <span style={{display: "flex", alignItems: "center"}}>
                        input: <pre>{JSON.stringify(getFieldValue(i))}</pre>
                    </span>
                    
                </div>
            </div>
            <div>
                <TextField
                    value={field.value}
                    onClick={()=>{setOpenFieldDec(true)}}
                    onChange={(e)=>{setFieldValConst(i, e.target.value)}}
                >
                </TextField>
                <div style={{position: "relative"}}>
                    {openFieldDec&&
                        <div style={{position: "absolute", background: "#fff", width: "100%"}}>
                            <Output
                                type={field.type!=undefined?field.type:""}
                                setschemaMapping={setFieldValGet(i)}
                                closeFieldSelector={closeFieldDec}
                                mapping=""
                                pipelinetype={pipelinetype}
                                pipelinestage={pipelinestage}
                                source={"apps"}
                            >
                            </Output>
                        </div>
                    }
                </div>
                
            </div>
        </div>
    )

}


const UpdateValueBlock = ({ settings, 
                            setSettings, 
                            availableFields,
                            schema,
                            schemamapping,
                            setSchema,
                            modifySchema,
                            setschemaMapping,
                            pipelinetype,
                            stageindex,
                            stagetype,
                            index,
                            query,
                            setQuery,
                            pipelinestage
                        })=>{
    

    const dispatch = useDispatch();

    const addValuetoSchema = ()=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        let valueIndex = schemaCopy[valueKeyIndex].subschema.length;
        schemaCopy[valueKeyIndex].subschema.push({
            "key": "value"+valueIndex,
            "label": "Value "+valueIndex,
            "required": false,
            "type": "any",
            "subschema":[],
            "fillallowed": true,
            "guided": false,
            "editallowed": false,
            "typechange": false
        });
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueIndexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        schemamappingCopy[valueIndexsm].mapping.push(
            {
                "key": "value"+valueIndex,
                "mapping": ""
            }
        )
        return {
            valueIndex: valueIndex,
            schema: schemaCopy,
            schemamapping: schemamappingCopy
        };
    }


    const updateallowedschematype = (valindex, valtype)=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valueKeyIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        let keyindex = _.findIndex(schemaCopy[valueKeyIndex].subschema,(sc)=>{return sc.key=="value"+valindex});
        schemaCopy[valueKeyIndex].subschema[keyindex] = {...schemaCopy[valueKeyIndex].subschema[keyindex],
                                                         type: valtype
                                                        }
        setSchema(schemaCopy);
    }


    const addField = ()=>{
        let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
        let updatedObj = addValuetoSchema();
        fieldsCopy.push({
            arg:"",
            value:"",
            type:"",
            valindex: updatedObj.valueIndex
        })
        let updateValueSettings = {...settings,
                                   fields: fieldsCopy
                                  };
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: updateValueSettings
                            };
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
            mapping: {"action": "const", "val": query} 
           }
        modifySchema(updatedObj.schema,schemamappingCopy);
        setQuery(queryCopy);
    }

    const setField = (i,val)=>{
        let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
        fieldsCopy[i] = {...fieldsCopy[i],
                         field: val,
                         type: val.type
                        }
        updateallowedschematype(i, val.type);
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                            }
        setSettings(settingsCopy);
    }

    const updateSchemamapping = (valueindex, val, action, key)=>{
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valueindexsm = _.findIndex(schemamappingCopy, (smc)=>{return smc.key=="values"});
        let keyindex = _.findIndex(schemamappingCopy[valueindexsm].mapping, (mp)=>{return mp.key=="value"+valueindex});
        if(schemamappingCopy[valueindexsm].mapping[keyindex].mapping?.action!="get"){
            dispatch(setmappingkey({
                oldkey: "",
                key: "",
                stageindex:stageindex, 
                stagetype: stagetype
            }))
        }else{
            dispatch(setmappingkey({
                oldkey: schemamappingCopy[valueindexsm].mapping[keyindex].mapping.val,
                key: val,
                stageindex:stageindex, 
                stagetype: stagetype
            }))
        }
        schemamappingCopy[valueindexsm].mapping[keyindex] = {...schemamappingCopy[valueindexsm].mapping[keyindex],
                                                             mapping:{"action": action, "val": val}
                                                            }
        return schemamappingCopy;
    }

    const deleteScheMappping = (valindex)=>{
        let schemaCopy = JSON.parse(JSON.stringify(schema));
        let valuesIndex = _.findIndex(schemaCopy, (sc)=>{return sc.key=="values"});
        schemaCopy[valuesIndex].subschema.splice(valindex,1);
        let schemamappingCopy = JSON.parse(JSON.stringify(schemamapping));
        let valuesIndexsm = _.findIndex(schemamappingCopy, (sm)=>{return sm.key=="values"});
        schemamappingCopy[valuesIndexsm].mapping.splice(valindex,1);
        return {
            schema: schemaCopy,
            schemamapping: schemamappingCopy
        }
    }

    const deleteField = (i)=>{
        let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
        let indextobedeleted = fieldsCopy[i].valindex;
        let updatedObj = deleteScheMappping(indextobedeleted); 
        fieldsCopy.splice(i,1);
        let updateValueSettings = {...settings,
                                   fields: fieldsCopy
                                  }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: updateValueSettings
                            }
        let schemamappingCopy = updatedObj.schemamapping;
        schemamappingCopy[2] = {...schemamappingCopy[2],
            mapping: {"action": "const", "val": query} 
            }
        modifySchema(updatedObj.schema,schemamappingCopy);
        setQuery(queryCopy);
    }


    const setFieldValConst  = (i, val)=>{
        let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
        fieldsCopy[i] = {...fieldsCopy[i],
                         value: val
                        }
        let settingsCopy = {...settings,
                            fields: fieldsCopy
                            }
        let queryCopy = JSON.parse(JSON.stringify(query));
        queryCopy[index] = {...queryCopy[index],
                            settings: settingsCopy
                            }
        let schemamappingCopy = updateSchemamapping(fieldsCopy[i].valindex, val, "const","");
        schemamappingCopy[2] = {...schemamappingCopy[2],
            mapping: {"action": "const", "val": query} 
            }
        setschemaMapping(schemamappingCopy);
        setQuery(queryCopy);

    }

    const setFieldValGet = (i)=>{
        return (val)=>{
            let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
            fieldsCopy[i] = {...fieldsCopy[i],
                            value: val.schemaval
                            }
            let settingsCopy = {...settings,
                                fields: fieldsCopy
                                };
            let schemamappingCopy = updateSchemamapping(fieldsCopy[i].valindex, val.schemaval, "get", "");
            let queryCopy = JSON.parse(JSON.stringify(query));
            queryCopy[index] = {...queryCopy[index],
                            settings: settingsCopy
                            }
            schemamappingCopy[2] = {...schemamappingCopy[2],
                mapping: {"action": "const", "val": query} 
            }
            setschemaMapping(schemamappingCopy);
            setQuery(queryCopy);
        }
    }   

    const getFieldValue = (i)=>{        
        let fieldsCopy = JSON.parse(JSON.stringify(settings.fields));
        let valueindex = fieldsCopy[i].valindex;
        let valueindexsm = _.findIndex(schemamapping,(sm)=>{return sm.key=="values"});
        let keyindex = _.findIndex(schemamapping[valueindexsm].mapping, (sm)=>{return sm.key=="value"+valueindex});
        let val = getMappingValue(schemamapping[valueindexsm].mapping[keyindex].mapping);
        return val;
    }

    const getAvailableFields = ()=>{
        let avlfields = availableFields.filter((field)=>{return field.key!="id"&&field.key!="updatedAt"&&field.key!="createdAt"});
        return avlfields;
    }

    return(
        
        <>
            <div>
                <div>
                    Set
                </div>
            </div>
            <div>
                {settings.fields.map((field, i)=>{
                    return(
                        <>
                            <div className="update-field-row">
                                <div className="update-field-block">
                                    <div>
                                        Field
                                    </div>
                                    <div>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id="select-field-value-to-compare"> Select Field</InputLabel>
                                            <Select
                                                labelId="select-field-value-to-compare"
                                                id="select-field"
                                                value={field.field}
                                                onChange={(e)=>{setField(i,e.target.value)}}
                                                renderValue={(val)=>{return val.app+"."+val.schema+"."+val.key}}
                                            >
                                                {getAvailableFields().map((field)=>{
                                                    return (<MenuItem value={field}>{field.app+"."+field.schema+"."+field.key}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="update-field-block">
                                    <div>
                                        Value
                                    </div>
                                    <div>
                                        <UpdateValueSelect
                                             field={field} 
                                             getFieldValue={getFieldValue} 
                                             setFieldValConst={setFieldValConst}
                                             setFieldValGet={setFieldValGet}
                                             pipelinestage={pipelinestage}
                                             pipelinetype={pipelinetype}
                                             i={i}
                                        ></UpdateValueSelect>
                                    </div>
                                </div>
                                <div className="update-field-block">
                                    <IconButton onClick={()=>{deleteField(i)}}>
                                        <span className="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        </>
                    )
                })}
                <div>
                    <Button onClick={()=>{addField()}}>Add Field</Button>
                </div>
            </div>
        </>
    )

}


const UpdateBuilder = ({
    initapp,
    initschema,
    schema,
    schemamapping,
    setschemaMapping,
    modifySchema,
    setSchema,
    pipelinetype,
    stageindex,
    stagetype,
    pipelinestage
})=>{
    const dispatch = useDispatch();

    const applist = useSelector(selectApplist);

    const appschemas = useSelector(selectAppSchemas);
    
    const [availableFields, setAvailableFields] = useState([]);

    const [query, setQuery] = useState([
        {
            "type": "from", 
            "settings":{
                "table": {
                    "app": "",
                    "schemaslug": ""
                }
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "update",
            "settings":{
                "fields":[]
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "where",
             "settings": {
                "conditions":[[]],
                excludeundefined: false
             },
             "enabled": true,
             "expanded": true
        }
    ])
    

    const resetFromAvailableFields = (table)=>{
        let appindex = _.findIndex(appschemas,(as)=>{   
                                                        // console.log(as.appname);
                                                        return as.appname==table.app
                                                    });
        let app = {...appschemas[appindex]};
        let schemaindex = _.findIndex(app.schemalist, (sh)=>{ return sh.schemaslug==table.schemaslug});
        if(schemaindex>-1){
            let schema = {...app.schemalist[schemaindex]};
            let avlFeilds = [];
            for(let i=0; i< schema.schema.length; i++){
                avlFeilds.push({
                    "key": schema.schema[i].inputkey,
                    "app": app.appname,
                    "schema": schema.schemaslug,
                    "type": schema.schema[i].inputtype,
                    "source": "from" 
                })
            }
            // remove from the available fields
            for(let i=0; i< availableFields.length; i++){
                if(availableFields[i].source!="from"){
                    avlFeilds.push(availableFields[i]);
                }
            }
            setAvailableFields(avlFeilds);
        }
        
        
    }

    const setSettings=(index)=>{
        return (settings)=>{
            let queryCopy = [...query];
            if(queryCopy[index].type=="from"){
                let table = settings.table;
                resetFromAvailableFields(table)
            }
            queryCopy[index] = {...queryCopy[index],
                                settings: settings
                                };
            updateQuerySettingsMapping(queryCopy);
            setQuery(queryCopy);
        }
    }

    const updateQuerySettingsMapping = (query)=>{
            let schemamappingCopy = [...schemamapping];
            schemamappingCopy[2] = {...schemamappingCopy[2],
                                    mapping: {"action": "const", "val": query} 
                                   }
            setschemaMapping(schemamappingCopy);
    }

    const initializeAvailableFields = (query)=>{
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        let fromapp = queryCopy[fromqueryindex].settings.table.app;
        let fromschemaslug = queryCopy[fromqueryindex].settings.table.schemaslug;
        let avlFeilds = [];
        let fromappindex = _.findIndex(appschemas,(as)=>{   
            return as.appname==fromapp
          });
        let fromschemaapp = {...appschemas[fromappindex]};
        let fromschemaindex = _.findIndex(fromschemaapp.schemalist, (sh)=>{ return sh.schemaslug==fromschemaslug});
        let fromschema = {...fromschemaapp.schemalist[fromschemaindex]};
        for(let i=0; i< fromschema.schema.length; i++){
            avlFeilds.push({
                "key": fromschema.schema[i].inputkey,
                "type": fromschema.schema[i].inputtype,
                "app": fromschemaapp.appname,
                "schema": fromschema.schemaslug,
                "source":  "from"
            })
        }
        setAvailableFields(avlFeilds);
    }

    useEffect(()=>{
        if(initapp==""||initschema?.schemaslug==""||initschema?.schemaslug==undefined||appschemas?.length==0){
            return;
        }
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        if(queryCopy[fromqueryindex].settings.table.app==""&&queryCopy[fromqueryindex].settings.table.schemaslug==""){
            if(queryCopy[fromqueryindex].settings.table.app==""&&queryCopy[fromqueryindex].settings.table.schemaslug==""){
                queryCopy[fromqueryindex].settings = {...queryCopy[fromqueryindex].settings,
                    table: {
                        "app" : initapp,
                        "schemaslug": initschema.schemaslug
                    }   
                }
                resetFromAvailableFields({
                                            "app" : initapp,
                                            "schemaslug": initschema.schemaslug
                                        })
            }else{
                initializeAvailableFields(queryCopy);
            }
            setQuery(queryCopy);
        }

    },[initapp, appschemas])

    useEffect(()=>{
        for(let i=0; i< applist.length; i++){
            dispatch(getAppSchemas(applist[i]._id));
        }
    },[applist])


    const {workspace} = useParams();

    useEffect(()=>{
        dispatch(getApps({
            workspace: workspace,
        }))
    },[])
    
    
    return (
        <div>
            <UpdateTableBlock
                settings={query[0].settings}
                availableSchemas={appschemas}
                setSettings={setSettings(0)}
            >
            </UpdateTableBlock>
            <UpdateValueBlock
                settings={query[1].settings}
                setSettings={setSettings(1)}
                availableFields={availableFields}
                schema={schema}
                schemamapping={schemamapping}
                setSchema={setSchema}
                pipelinestage={pipelinestage}
                setschemaMapping={setschemaMapping}
                modifySchema={modifySchema}
                pipelinetype={pipelinetype}
                stageindex={stageindex}
                stagetype={stagetype}
                index={1}
                query={query}
                setQuery={setQuery}
            >
            </UpdateValueBlock>
            <ConditionBlock
                availableFields={availableFields}
                pipelinestage={pipelinestage} 
                setSchema={setSchema}
                setschemaMapping={setschemaMapping}
                modifySchema={modifySchema}
                pipelinetype={pipelinetype}
                stageindex={stageindex}
                schema={schema}
                schemamapping={schemamapping}
                stagetype={stagetype}
                settings={query[2].settings}
                setSettings={setSettings(2)}
                index={2}
                query={query} 
                setQuery={setQuery}
                component="update"
            >
            </ConditionBlock>
        </div>
    )
}

export default UpdateBuilder;