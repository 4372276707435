import { 
        Box, 
        Modal, 
        Button, 
        TextField, 
        Checkbox, 
        IconButton 
       } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from "react-redux";
import { 
            getEnvoptwebpub, 
            getManifest, 
            getManifestPipeline, 
            getManifestPublishVar, 
            getManifestVersion, 
            installPManifest, 
            resetmanifest, 
            resetPmanifestinstalled, 
            resetmanifestsaved, 
            savePublihsedManifest, 
            selectInstallButtonTemplate, 
            selectManifest, 
            selectManifestform, 
            selectManifestInstalled, 
            selectManifestpipeline, 
            selectManifestSaved, 
            selectManifestThirdPartyUrl, 
            selectManifestVersion, 
            selectWebsiteOpt, 
            selectManifestInstalltype
        } from "../../Services/manifest/manifest.slice";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from "react";
import { getEnvironVar, selectCurrentAppvar, selectCurrentWebsiteVar, selectCurrentWorkspacevar } from "../../Services/environvariables/environvariables.slice";
import { getWorkspaceApps, selectWorkspaceApps } from "../../Services/apps/app.slice";
import { getInterfaceNonJsTemplates, getInterfaceTemplates, selectInterfaceTemplates, selectNonjstemplates } from "../../Services/templates/template.slice";
import { 
        resetactivepipeline, 
        resetactivepipelineid,
        createPipeline,
        selectActivepipelineid,
        setactivepipelineid,
        getPipelinebyId
       } from "../../Services/pipelines/pipeline.slice";
import { useNavigate, useParams, Link } from "react-router-dom";
import "./Manifest.css";
import ManifestStarter from "./ManifestStarter";
import * as _ from "lodash";
import AppContainer from "../appdesigner/AppContainer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    minHeight: 400,
    maxHeight: 800,
    overflow: "auto",
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const EnvironFormElementBuilder = ({
    source,
    sourceid,
    element,
    setelement
})=>{

    const dispatch = useDispatch();

    const currentworkspacevar = useSelector(selectCurrentWorkspacevar)

    const currentwebsitevar = useSelector(selectCurrentWebsiteVar);

    const currentappvar = useSelector(selectCurrentAppvar);

    const workspaceapps = useSelector(selectWorkspaceApps);

    const workspacewebsites = useSelector(selectWebsiteOpt);

    useEffect(()=>{
        if(source=="workspace"){
            dispatch(getEnvironVar({
                "source": source,
                "sourceid": sourceid
            }));

            // get apps
            dispatch(getWorkspaceApps(sourceid))
            // get websites
            dispatch(getEnvoptwebpub(sourceid))
        }else if(source=="app"){
            dispatch(getEnvironVar({
                "source": source,
                "sourceid": sourceid
            }))
        }else if(source=="website"){
            dispatch(getEnvironVar({
                "source": source,
                "sourceid": sourceid
            }))
        }
    },[])

    const selectScope = (scope)=>{
        let elementCopy = {...element,
                           scope: scope
                          }
        if(scope=="app"){
            dispatch(getWorkspaceApps(sourceid))
        }else if(scope=="website"){
            dispatch(getEnvoptwebpub(sourceid))
        }
        
        setelement(elementCopy);
    }

    const selectWorkspaceApp = (appid)=>{
        let elementCopy = {...element,
                           appid: appid
                          }
        setelement(elementCopy);
        dispatch(getEnvironVar({
            "source": "app",
            "sourceid": appid
        }))
    }


    const selectWorkspaceWebsite = (websiteid)=>{
        let elementCopy = {...element,
                           websiteid: websiteid
                          }
        dispatch(getEnvironVar({
            "source": "website",
            "sourceid": websiteid
        }))
        setelement(elementCopy)
    }

    const selectVar = (varobj)=>{
        let elementCopy = {...element,
                           varobj: varobj
                          }
        setelement(elementCopy)
    }

    return (
        <div>
            {source=="workspace"&&
                <div className="form-line-item">
                    <div className="form-line-item-item">
                        <FormControl sx={{width: 200}}>
                            <InputLabel id="select-scope">Select Scope</InputLabel>
                            <Select
                                labelId="select-scope"
                                label="Scope"
                                value={element.scope}
                                onChange={(e)=>{selectScope(e.target.value)}}
                            >
                                <MenuItem value="workspace">Worspace</MenuItem>
                                <MenuItem value="app">App</MenuItem>
                                <MenuItem value="website">Website</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {element.scope=="app"&&
                        <div className="form-sub-line-item">
                            <div className="form-line-item-item">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id="select-app-id">Select App</InputLabel>
                                    <Select
                                        labelId="select-app-id"
                                        label="App"
                                        value={element.appid}
                                        onChange={(e)=>{selectWorkspaceApp(e.target.value)}}
                                    >
                                        {workspaceapps.map((app)=>{
                                            return (
                                                <MenuItem value={app._id}>{app.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {element.appid!=undefined&&element.appid!=""&&
                                <div className="form-line-item-item">
                                    <FormControl sx={{width: 200}}>
                                        <InputLabel id="select-app-var-name">Select Variable Name</InputLabel>
                                        <Select
                                            labelId="select-app-var-name"
                                            label="Variable Name"
                                            value={element.varobj}
                                            renderValue={(obj)=>{return obj.name}}
                                            onChange={(e)=>{selectVar(e.target.value)}}
                                        >
                                            {currentappvar.map((appvar)=>{
                                                return (
                                                    <MenuItem value={{name: appvar.name, type: appvar.type}}>{appvar.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                        </div>
                    }
                    {element.scope=="website"&&
                        <div className="form-sub-line-item">
                            <div className="form-line-item-item">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id="select-website-id">Select Website</InputLabel>
                                    <Select
                                        labelId="select-website-id"
                                        label="Website"
                                        value={element.websiteid}
                                        onChange={(e)=>{selectWorkspaceWebsite(e.target.value)}}
                                    >
                                        {workspacewebsites.map((workspacewebsite)=>{
                                            return(
                                                <MenuItem value={workspacewebsite._id}>{workspacewebsite.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {element.websiteid!=undefined&&element.websiteid!=""&&
                                <div className="form-line-item-item">
                                    <FormControl sx={{width: 200}}>
                                        <InputLabel id="select-var-website-name">Select Variable Name</InputLabel>
                                        <Select
                                            labelId="select-var-website-name"
                                            label="Variable Name"
                                            value={element.varobj}
                                            renderValue={(obj)=>{return obj.name}}
                                            onChange={(e)=>{selectVar(e.target.value)}}
                                        >
                                            {currentwebsitevar.map((websitevar)=>{
                                                return (
                                                    <MenuItem value={{name: websitevar.name, type: websitevar.type}}>{websitevar.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                        </div>
                    }
                    {element.scope=="workspace"&&
                        <div className="form-line-item-item">
                              <FormControl sx={{width: 200}}>
                                 <InputLabel id="select-workspace-var-name">Select Variable Name</InputLabel>
                                 <Select
                                    labelId="select-workspace-var-name"
                                    label="Variable Name"
                                    value={element.varobj}
                                    renderValue={(obj)=>{return obj.name}}
                                    onChange={(e)=>{selectVar(e.target.value)}}
                                 >
                                    {currentworkspacevar.map((workspacevar)=>{
                                        return (
                                            <MenuItem value={{name: workspacevar.name, type: workspacevar.type}}>{workspacevar.name}</MenuItem>
                                        )
                                    })}
                                 </Select>
                              </FormControl>
                        </div>
                    }
                </div>
            }
            {source=="app"&&
                <div className="form-sub-line-item">
                    <div className="form-line-item-item">
                        <FormControl sx={{width: 200}}>
                            <InputLabel id="select-app-var-name">Select Variable Name</InputLabel>
                            <Select
                                labelId="select-app-var-name"
                                label="Variable Name"
                                value={element.varobj}
                                renderValue={(obj)=>{return obj.name}}
                                onChange={(e)=>{selectVar(e.target.value)}}
                            >
                                {currentappvar.map((appvar)=>{
                                    return (
                                        <MenuItem value={{name: appvar.name, type: appvar.type}}>{appvar.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                
            }   
            {source=="website"&&
                <div className="form-line-item-item">
                    <FormControl sx={{width: 200}}>
                        <InputLabel id="select-website-var-name">Select Variable Name</InputLabel>
                        <Select
                            labelId="select-website-var-name"
                            label="Variable Name"
                            value={element.varobj}
                            renderValue={(obj)=>{return obj.name}}
                            onChange={(e)=>{selectVar(e.target.value)}}
                        >
                            {currentwebsitevar.map((websitevar)=>{
                                return (
                                    <MenuItem value={{name: websitevar.name, type: websitevar.type}}>{websitevar.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            }
        </div>
    )
}

const SimpleForm = ({
    elements,
    setElements,
    source,
    sourceid
})=>{

    const setElement = (i)=>{
        return (element)=>{
            let elementsCopy = [...elements];
            elementsCopy[i] = element;
            setElements(elementsCopy)
        }
    }

    const setElementVarName = (index, val)=>{
        let elementsCopy = [...elements];
        elementsCopy[index] = {...elementsCopy[index],
                            varname: val
                          }
        setElements(elementsCopy);
    }

    const setElementVarType = (index, val)=>{
        let elementsCopy = [...elements];
        elementsCopy[index] = {...elementsCopy[index],
                                type: val
                              }
        setElements(elementsCopy);
        
    }

    const deleteElement = (index)=>{
        let elementsCopy = [...elements];
        elementsCopy.splice(index, 1);
        setElements(elementsCopy);
    }

    const addElement = ()=>{
        let elementsCopy = [...elements];
        elementsCopy.push({
            "type": "",
            "varname": "",
            "varobj":{
                "type": "",
                "name": ""
            },
            "appid": "",
            "websiteid": ""
        })
        setElements(elementsCopy)
    }

    return (
        <div>
            {elements.map((element, index)=>{
                return (
                    <div className="form-line-item">
                        <div className="form-line-item-item">
                            <FormControl sx={{width: 200}}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={element.type}
                                    label="Type"
                                    onChange={(e)=>{setElementVarType(index, e.target.value)}}
                                >
                                    <MenuItem value="string">String</MenuItem>
                                    <MenuItem value="number">Number</MenuItem>
                                    <MenuItem value="boolean">Boolean</MenuItem>
                                    <MenuItem value="datetime">Datetime</MenuItem>
                                    <MenuItem value="host">Host</MenuItem>
                                    <MenuItem value="folder">Folder</MenuItem>
                                    <MenuItem value="template">Template</MenuItem>
                                    <MenuItem value="envvar">EnvVariable</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {element.type=="string"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name">
                                    </TextField>
                                </div>
                            </div>
                        }
                        {element.type=="number"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name">
                                    </TextField>
                                </div>
                            </div>
                        }
                        {element.type=="boolean"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name"></TextField>
                                </div>
                            </div>
                        }
                        {element.type=="datetime"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name"></TextField>
                                </div>
                            </div>
                        }
                        {element.type=="host"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name"></TextField>
                                </div>
                            </div>
                        }
                        {element.type=="folder"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name"></TextField>
                                </div>
                            </div>
                        }
                        {element.type=="template"&&
                            <div className="form-line-item">
                                <div>
                                    <TextField value={element.varname} onChange={(e)=>{setElementVarName(index, e.target.value)}} label="Form Field Name"></TextField>
                                </div>
                            </div>
                        }
                        {element.type=="envvar"&&
                            <>
                                <EnvironFormElementBuilder
                                    source={source}
                                    sourceid={sourceid}
                                    element={element}
                                    setelement={setElement(index)}
                                >
                                </EnvironFormElementBuilder>
                            </>
                        }
                        {element.elemtype!="system"&&
                            <div className="form-line-item">
                                <IconButton onClick={()=>{deleteElement(index)}}>
                                    <span className="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                            </div>
                        }
                    </div>
                )
            })}
            <div>
                <Button style={{"color": "#3A07CD"}} onClick={()=>{addElement()}}>Add Field</Button>
            </div>
        </div>
    )
}

const SelectPipeline = ({
    source, 
    sourceid,
    manifestid
})=>{

    const dispatch = useDispatch();

    const {workspace} = useParams();

    const [newpipeline, setnewpipeline] = useState(false);

    const navigate = useNavigate();

    const addPipeline = ()=>{
        dispatch(resetactivepipelineid(""));
        dispatch(resetactivepipeline(""));
        setnewpipeline(true);
        dispatch(createPipeline({schemaid: source+"__"+sourceid, source: "installmanifest"}));
    }

    const manifestpipeline = useSelector(selectManifestpipeline);

    const openPipeline = ()=>{
        dispatch(setactivepipelineid(manifestpipeline._id));
        dispatch(getPipelinebyId({id: manifestpipeline._id}));
        setnewpipeline(true);
    }

    useEffect(()=>{
        if(source!=""&&sourceid!=""){
            dispatch(getManifestPipeline(source+"__"+sourceid));
        }
    },[source, sourceid])

    const activepipleineid = useSelector(selectActivepipelineid);

    useEffect(()=>{
        if(newpipeline&&activepipleineid!=""){
            navigate("/"+manifestid+"/schemas/"+workspace+"/details/"+source+"__"+sourceid+"/"+activepipleineid+"/installmanifest");
        }
    },[activepipleineid,newpipeline])

    return (
        <div>
            {manifestpipeline==null?
                <Button onClick={()=>{addPipeline()}} style={{"color": "#3A07CD"}}>
                    Create Pipeline
                </Button>:
                <>
                    <span>Install Pipeline: </span>
                    <a href={"/"+manifestid+"/schemas/"+workspace+"/details/"+source+"__"+sourceid+"/"+manifestpipeline._id+"/installmanifest"} onClick={()=>{}}>{manifestpipeline.name}</a>
                </>
                
            }
        </div>
    )
}

const AdvancedForm = ({
    source,
    sourceid,
    elements,
    setElements,
    manifestid
})=>{

    return (
        <div>
            <SimpleForm
                source={source}
                sourceid={sourceid}
                elements={elements}
                setElements={setElements}
            >
            </SimpleForm>
            <SelectPipeline
                source={source}
                sourceid={sourceid}
                manifestid={manifestid}
            >
            </SelectPipeline>
        </div>
    )

}

const ThirdPartyForm = ({
        thirdpartyurl,
        setThirdpartyurl,
        source,
        sourceid,
        elements,
        setElements,
        manifestid,
        installbuttontemplate,
        setInstallbuttontemplate
})=>{

    const {workspace} = useParams();

    const dispatch = useDispatch();

    useEffect(()=>{
        if(workspace!=undefined&&workspace!=""){
            dispatch(getInterfaceNonJsTemplates(workspace))
        }
    },[])

    const templates = useSelector(selectNonjstemplates);

    return (
        <div>
            <SimpleForm
                source={source}
                sourceid={sourceid}
                elements={elements}
                setElements={setElements}
            >
            </SimpleForm>
            <div>
                <h5>Installation Button Template</h5>
                <div>
                    Installation Button cannot contain any event handler or function or pipeline
                </div>
            </div>
            <div className="installation-template-block">
                <div className="installation-template-block-element">
                    <Select
                        value={installbuttontemplate}
                        onChange={(e)=>{setInstallbuttontemplate(e.target.value)}}
                    >
                        {templates.map((template)=>{
                            return (
                                <MenuItem value={template._id}>{template.name}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <div>
                    {installbuttontemplate!=""&&
                        <>
                            <AppContainer
                                templateid={installbuttontemplate}
                                indexarr={[]}
                                elmkey={""}
                                type={"html"}
                            >
                            </AppContainer>
                        </>
                    }
                </div>
            </div>
            <SelectPipeline
                source={source}
                sourceid={sourceid}
                manifestid={manifestid}
            >
            </SelectPipeline>
            <div style={{marginTop: "10px", padding: "10px"}}>
                <TextField 
                    fullWidth
                    value={thirdpartyurl} 
                    onChange={(e)=>{setThirdpartyurl(e.target.value)}}
                    label="Redirection Url"
                ></TextField>
            </div>
            <div style={{marginTop: "10px", padding: "10px"}}>
                We are going to append the form data as query in to the redirection url , with <pre>installationId</pre> as one of the parameters<br/>To complete the package installation , you will need to redirect to <pre>https://app.toiler.co/?installationId="installationId"&your_parameter1="paramater1"&your_parameter2="parameter2"</pre> <br/> The pipeline will run at the end of installation process, and we will map the parameters recieved to the same variables in the input of the pipeline.
            </div>
        </div>
    )

}

const ManifestPublishStage = ({
                                manifestid, 
                                source, 
                                sourceid,
                                handleClose,
                                setpublishstage
                             })=>{

    const [installtype, setinstalltype] = useState("form");

    const [formElements, setFormElements] = useState([]);

    const [thirdpartyurl, setThirdpartyurl] = useState("");

    const [installbuttontemplate, setInstallbuttontemplate] = useState("");

    const dispatch = useDispatch();

    const manifestpipeline = useSelector(selectManifestpipeline);

    const changeInstalltype = (e)=>{
        setinstalltype(e.target.value);
    }

    const manifest = useSelector(selectManifest);

    const manifestform = useSelector(selectManifestform);

    const thirdpartyurlremote = useSelector(selectManifestThirdPartyUrl);

    useEffect(()=>{
        if(thirdpartyurlremote!=""&&thirdpartyurlremote!=undefined){
            setThirdpartyurl(thirdpartyurlremote);
        }
    },[thirdpartyurl])

    const manifestinstalltype = useSelector(selectManifestInstalltype);

    useEffect(()=>{
        if(manifestinstalltype!="form"&&manifestinstalltype!=""&&manifestinstalltype!=undefined){
            setinstalltype(manifestinstalltype);
        }
    },[manifestinstalltype])

    const installbuttontemplateremote = useSelector(selectInstallButtonTemplate);

    useEffect(()=>{
        if(installbuttontemplateremote!=""&&installbuttontemplateremote!=undefined){
            setInstallbuttontemplate(installbuttontemplate);
        }
    },[installbuttontemplateremote])

    const gatherElements = (deps, gatheredelements)=>{
        for(let i=0; i< deps.length; i++){
            if(deps[i].type=="form"){
                if(deps[i].subtype=="envvar"){
                    let val = deps[i].name;
                    let valparts = val.split(".");
                    if(source=="workspace"&&valparts[2]!="workspace"){
                        if(valparts[2]=="app"){
                            let formelement = {
                                "type": "envvar",
                                "scope": "app",
                                "appid": deps[i].appid,
                                "elemtype": "system",
                                "varobj":{
                                    type: deps[i].envvartype,
                                    name: valparts[3]
                                }
                            }
                            gatheredelements.push(formelement);

                        }else if(valparts[2]=="website"){
                            let formelement = {
                                "type": "envvar",
                                "scope": "website",
                                "appid": deps[i].websiteid,
                                "elemtype": "system",
                                "varobj":{
                                    type: deps[i].envvartype,
                                    name: valparts[3]
                                }
                            }
                            gatheredelements.push(formelement);
                        }
                        
                    }else if(source=="workspace"&&valparts[2]=="workspace"){
                        let formelement = {
                            "type": "envvar",
                            "scope": "workspace",
                            "elemtype": "system",
                            "varobj":{
                                type: deps[i].envvartype,
                                name: valparts[3]
                            }
                        }
                        gatheredelements.push(formelement)
                    }else if(source=="app"){
                        let formelement = {
                            "type": "envvar",
                            "scope": valparts[2],
                            "elemtype": "system",
                            "varobj":{
                                type: deps[i].envvartype,
                                name: valparts[3]
                            }
                        }
                        gatheredelements.push(formelement)
                    }else if(source=="website"){
                        let formelement = {
                            "type": "envvar",
                            "scope": valparts[2],
                            "elemtype": "system",
                            "varobj":{
                                type: deps[i].envvartype,
                                name: valparts[3]
                            }
                        }
                        gatheredelements.push(formelement)
                    }
                }else{
                    if(deps[i].pipelineid!=undefined){
                        let formelement = {
                            "type": deps[i].subtype,
                            "varname": deps[i].name,
                            "extra":deps[i].pipelineid,
                            "elemtype": "system",
                            "source": "pipeline"
                        }
                        gatheredelements.push(formelement)
                    }else if(deps[i].websiteid!=undefined){
                        let formelement = {
                            "type": deps[i].subtype,
                            "varname": deps[i].name,
                            "extra":deps[i].websiteid,
                            "elemtype": "system",
                            "source": "website"
                        }
                        gatheredelements.push(formelement)
                    }
                }
            }
            if(deps[i].deps?.length>0){
                gatherElements(deps[i].deps, gatheredelements)
            }
        }
    }

    const compareFormElements = (element1, element2)=>{
        if(element1.type==element2.type){
            if(element1.type=="envvar"){
                if(element1.scope==element2.scope){
                    if(element1.varobj.type==element2.varobj.type&&element1.varobj.name==element2.varobj.name){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }

            }else{
                if(element1.varname==element2.varname){
                    return true
                }else{
                    return false;
                }
            }
        }else{
            return false;
        }
    }

    useEffect(()=>{
        let gatheredelements = [];
        if(manifest.deps!=undefined){
            if(manifest.deps?.length>0){
                gatheredelements = [];
                gatherElements(manifest.deps, gatheredelements);
            }
        }
        if(manifestform.length>0){
            for(let i=0; i<manifestform.length; i++){
                let elmindex = _.findIndex(gatheredelements, (element1)=>{return compareFormElements(element1, manifestform[i])});
                if(elmindex==-1){
                    gatheredelements.push(manifestform[i]);
                }    
            }
        }

        if(gatheredelements.length>0){
            setFormElements(gatheredelements)
        }

    },[manifest, manifestform])

    useEffect(()=>{
        dispatch(getManifestPublishVar({source: source, sourceid: sourceid})) 
    },[])

    // save the manifest
    const [saveclicked, setSaveClicked] = useState(false);

    const saveManifest = ()=>{
        setSaveClicked(true);
        dispatch(resetmanifestsaved());
        dispatch(savePublihsedManifest({
            id: manifestid,
            form: formElements,
            thirdpartyurl: thirdpartyurl,
            installbuttontemplate: installbuttontemplate,
            installtype: installtype
        }));
    }

    const manifestsaved = useSelector(selectManifestSaved);

    const navigate = useNavigate();

    const {workspace} = useParams();

    useEffect(()=>{
        if(saveclicked&&manifestsaved){
            navigate("/"+workspace+"/savedmanifest/"+source+"/"+sourceid+"/"+manifestid);
        }
    },[saveclicked, manifestsaved])

    return (
        <div>
            <div>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Options</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={installtype}
                        onChange={changeInstalltype}
                    >
                        <FormControlLabel value="form" control={<Radio />} label="Form" />
                        <FormControlLabel value="advanced-form" control={<Radio />} label="Advanced Form" />
                        <FormControlLabel value="third-party" control={<Radio />} label="Third Party" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                {installtype=="form"&&
                    <SimpleForm
                        source={source}
                        sourceid={sourceid}
                        elements={formElements}
                        setElements={setFormElements}
                    >
                    </SimpleForm>
                }
                {installtype=="advanced-form"&&
                    <>
                        <AdvancedForm
                            source={source}
                            sourceid={sourceid}
                            elements={formElements}
                            setElements={setFormElements}
                            manifestid={manifestid}
                        >
                        </AdvancedForm>
                    </>
                }
                {installtype=="third-party"&&
                    <>
                        <ThirdPartyForm
                            source={source}
                            sourceid={sourceid}
                            elements={formElements}
                            setElements={setFormElements}
                            manifestid={manifestid}
                            thirdpartyurl={thirdpartyurl}
                            setThirdpartyurl={setThirdpartyurl}
                            installbuttontemplate={installbuttontemplate}
                            setInstallbuttontemplate={setInstallbuttontemplate}                        
                        >
                        </ThirdPartyForm>
                    </>
                }
                <div>
                    <Button onClick={(e)=>{handleClose()}} style={{"color": "#3A07CD"}}>Cancel</Button>
                    <Button onClick={
                                        ()=>{
                                            setpublishstage(0)
                                        }
                                    }
                            style={{"color": "#3A07CD"}}
                    >Back</Button>
                    <Button style={{"color": "#3A07CD"}} onClick={()=>{saveManifest()}}>Save</Button>
                </div>
            </div>
        </div>
    )
}

// show warning on auto remove of the stages
const Manifest = ()=>{

    const {workspace, source, sourceid} = useParams();

    const [opencreate, setOpencreate] = useState(true);

    const [manifestid, setManifestid] = useState("");

    // const [version, setVersion] = useState("");

    const manifest = useSelector(selectManifest);

    const [publishstage, setpublishstage] = useState(0);

    const [installmanifestclicked, setInstallmanifestclicked] = useState(false);

    const dispatch = useDispatch();

    const version = useSelector(selectManifestVersion);

    useEffect(()=>{
        if(source!=""&&sourceid!=""&&opencreate==false){
            dispatch(getManifest({source: source, sourceid: sourceid}))
            dispatch(getManifestVersion({source: source, sourceid: sourceid}))
        }
    },[source, sourceid, opencreate])

    const navigate = useNavigate();

    const handleClose = ()=>{
        if(source=="app"){
            dispatch(resetmanifest());
            navigate("/"+workspace+"/schemas/"+sourceid);
        }else if(source=="workspace"){
            dispatch(resetmanifest());
            navigate("/workspaces/"+sourceid)
        }else if(source=="website"){
            dispatch(resetmanifest());
            navigate("/"+workspace+"/website/details/"+sourceid)
        }
    }

    const installmanifest = ()=>{
        dispatch(installPManifest({
            source: source,
            sourceid: sourceid
        }))
        dispatch(resetPmanifestinstalled())
        setInstallmanifestclicked(true);
    }

    const manifestinstalled = useSelector(selectManifestInstalled);

    useEffect(()=>{
        if(manifestinstalled&&installmanifestclicked&&publishstage!=1){
            setpublishstage(1);
            setInstallmanifestclicked(false);
        }
    },[manifestinstalled, installmanifestclicked])

    return (
        <Box
        >
            <div>
                Version: {version.version}
            </div>
            {publishstage==0&&
                <div>
                    <h3>
                        Manifest
                    </h3>
                    <div>
                        <h5>
                            Errors
                        </h5>
                        <div>
                            <pre style={{whiteSpace: "pre-wrap"}}>
                                {JSON.stringify(manifest?.errors, null, 2)}
                            </pre>
                        </div>
                        <div className="manifest-warn">
                            Please remove the stages in the pipelines that causing the errors.
                        </div>
                    </div>
                    <div>
                        <h5>
                            Dependencies
                        </h5>
                        <div>
                            <pre>
                                {JSON.stringify(manifest?.deps, null, 2)}
                            </pre>
                        </div>
                    </div>
                </div>
            }
            {publishstage==1&&
                <>
                    <ManifestPublishStage
                        manifestid={version.id}
                        source={source}
                        sourceid={sourceid}
                        handleClose={handleClose}
                        setpublishstage={setpublishstage}
                    >
                    </ManifestPublishStage>
                </>
            } 
            <div>
                {publishstage==0&&
                    <>
                        <Button onClick={(e)=>{handleClose()}} style={{"color": "#3A07CD"}}>Cancel</Button>
                        <Button onClick={()=>{installmanifest()}} style={{"color": "#3A07CD"}}>Next</Button>
                    </>
                    
                }
            </div>
            <ManifestStarter
                source={source}
                sourceid={sourceid}
                open={opencreate}
                handleclose={()=>{setOpencreate(false)}}
            >
            </ManifestStarter>                
        </Box>
    )
}

export default Manifest;