import "./TemplateDetailtile.css";

const TemplateDetailTile = (
    {
        element,
        addElementAction,
        cloneElementAction
    }
)=>{

    return (
        <>
            <div style={{padding: "20px"}}>
                <div className="container">
                    {element.workspacename!=undefined&&
                        <div className="property-level-1">
                            {element.workspacename}
                        </div>
                    }
                    {element.websitename!=undefined&&
                        <div className="property-level-2">
                            {element.websitename}
                        </div>
                    }
                    <div
                        className="property-level main-property"
                    >
                        <div>
                            {element.name}
                        </div>
                        <div className="template-action-buttons">
                            <div 
                                className="import-template-button"
                                onClick={
                                            () => {addElementAction(element, "templates")}
                                        }
                            >import</div>
                            <div 
                                className="clone-template-button"
                                onClick={
                                            ()=>{
                                                cloneElementAction(
                                                    element._id
                                                );
                                            }
                                        }
                            >clone</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}


export default TemplateDetailTile;