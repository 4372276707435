import { baseURL } from "../../env";
import { postjson, putjson, get, deleteM } from "../signin/axios";



export const createWorkspaceApi = async(payload)=>{
    let res = await postjson(baseURL+"workspaces/", payload);
    return res;
}


export const getWorkspacesApi = async()=>{
    let res = await get(baseURL+"workspaces/");
    return res;
}

export const deleteWorkspaceApi = async(id)=>{
    let res = await deleteM(baseURL+"workspaces/"+id);
    return res;
}


export const getWorkspaceApi = async(id)=>{
    let res = await get(baseURL+"workspaces/"+id);
    return res;
}

export const getEnvironVariableApi = async(payload)=>{
    
}


export const updateEnvironVariableApi = async()=>{


}


export const getWorkspaceVariableApi = async()=>{


}


export const updateWorkspaceVariableApi = async()=>{


    
}