
import "./CircularLoader.css";

const CircularLoader = ()=>{

    return (
        <div style={{width: "30px", height: "30px"}}>
            <svg class="circular-loader" viewBox="25 25 50 50" >
                <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#70c542" stroke-width="2" />
            </svg>
        </div>
        
    )

}


export default CircularLoader;