import { useParams } from "react-router-dom";
import AppContainer from "../AppContainer";
import HtmlNativeElements from "../elements/HtmlNativeElements";
import HtmlNativeProdElements from "../elements/HtmlNativeProdElements";
import AndroidNativeElements from "../elements/AndroidNativeElements";
import ToilerElement from "../elements/ToilerElement";

const SingleBlock = ({
                        element, 
                        elmkey, 
                        indexarr,
                        position,
                        type,
                        childs,
                        parentkey,
                        parentposition,
                        parentindexarr,
                        templateinteractions,
                        path,
                        preview,
                        indesign
                    })=>{

    const getClassName = ()=>{
        if(element.desktop&&element.mobile){
            return "";
        }else if(element.desktop&&element.mobile==false){
            return "only-desktop";
        }else if(element.mobile&&element.desktop==false){
            return "only-mobile";
        }else{
            return "display-none";
        }
    }
        
    return (
        <>
            {type=="android"&&
                <>
                    {element.elemtype=="native"&&
                        <AndroidNativeElements
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position = {position}
                            type={type}                    
                        >
                        </AndroidNativeElements>
                    }
                    {element.elemtype=="template"&&
                        <AppContainer
                            templateid = {element._id}
                            indexarr= {indexarr}
                            elmkey={elmkey}
                            position = {position}
                            type={type}
                            path={path}
                            preview={preview}
                            indesign={indesign}
                        >
                        </AppContainer>
                    }
                </>
            }
            {type=="html"&&
                <>
                    
                    {element.elemtype=="native"&&
                        <>
                            {preview!=undefined?
                                <>
                                    <HtmlNativeProdElements
                                        element={element}
                                        elmkey={elmkey}
                                        indexarr={indexarr}
                                        position={position}
                                        type={type}
                                        childs={childs}
                                        parentkey={parentkey}
                                        parentposition={parentposition}
                                        templateinteractions={templateinteractions}
                                        path={path}
                                        preview={preview}
                                        indesign={indesign}
                                    >
                                    </HtmlNativeProdElements>   
                                </>:
                                <>
                                    <HtmlNativeElements
                                        element={element}
                                        elmkey={elmkey}
                                        indexarr={indexarr}
                                        position={position}
                                        type={type}    
                                        childs={childs}
                                        parentkey={parentkey}  
                                        parentposition={parentposition}
                                        parentindexarr={parentindexarr}
                                        templateinteractions={templateinteractions}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </HtmlNativeElements>
                                </>
                            }    
                        </>
                        
                    }
                    {element.elemtype=="toiler"&&
                        <ToilerElement
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position={position}
                            type={type}
                            parentkey={parentkey}
                            path={path}
                        >
                        </ToilerElement>

                    }
                    {element.elemtype=="template"&&
                        <div className={getClassName()}>
                            <AppContainer
                                templateid={element._id}
                                indexarr={indexarr}
                                elmkey={elmkey}
                                position={position}
                                type={type}
                                childs={element.childs}
                                templateinteractions={element.interactions}
                                path={path}
                                preview={preview}
                                indesign={indesign}
                            >
                            </AppContainer>
                        </div>
                        
                    }
                </>
            }
        </>
    )
}

export default SingleBlock;