import { 
        Table, 
        TableCell, 
        TableRow, 
        Button, 
        Modal, 
        Box, 
        TextField, 
        Select, 
        MenuItem, 
        IconButton, 
        FormControl, 
        InputLabel 
    } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
        getDataData, 
        getDataHeaders, 
        resetrecordcreated, 
        selectDataData, 
        selectDataHeaders, 
        selectDataRecordCreated, 
        selectDataTotal,
        createDataObject,
        selectDataRecordDeleted,
        resetrecorddeleted,
        deleteDataObject,
        updateDataObject,
        resetrecordupdated,
        selectDataRecordUpdated
    } from "../../Services/data/data.slice";
import {DbConditionalOperators} from "../Settings";
import * as _ from "lodash";
import "./Datadisplay.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const DataCreateModal = ({open , handleclose, schema, schemaid})=>{


    const [values, setValues]= useState([]);

    const [createobj, setCreateObj] = useState({});

    const [saveclicked, setSaveclicked] = useState(false);


    useEffect(()=>{
        if(schemaid!=null&schemaid!=undefined&&open){
            let valuesCopy  =[];
            for(let i=0; i<schema.length; i++){
                valuesCopy.push("");
            }
            setValues(valuesCopy)
        }
    },[schemaid, open])

    const setValueAction = (val ,i)=>{
        let createObjCopy = {...createobj};
        createObjCopy[schema[i].inputkey] = val;
        setCreateObj(createObjCopy);
    }

    const dispatch = useDispatch();

    const recordcreated = useSelector(selectDataRecordCreated);

    const save = ()=>{
        setSaveclicked(true);
        dispatch(resetrecordcreated());
        dispatch(createDataObject({
            "schemaid": schemaid,
            "payload": createobj
        }));
    }

    useEffect(()=>{
        if(recordcreated&&saveclicked){
            handleclose("create")
        }
    },[recordcreated, saveclicked])


    return (
        <Modal
            open={open}
            handleclose={handleclose}
        
        >
            <Box sx={style}>
                <div>
                    <div>
                        <h3>Create Object</h3>
                    </div>
                    {values.length>0&&
                        <>
                            {schema.map((sc, index)=>{
                                return (
                                    <>
                                        {sc.type=="user"&&sc.inputtype=="string"&&
                                            <div style={{margin: "10px"}}>
                                                <TextField label={sc.inputlabel} value={createobj[sc.inputkey]} onChange={(e)=>{setValueAction(e.target.value, index)}}></TextField>
                                            </div>
                                            
                                        }
                                        {sc.type=="user"&&sc.inputtype=="text"&&
                                            <div style={{margin: "10px"}}>
                                                <TextField
                                                    label={sc.inputlabel}
                                                    multiline
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                ></TextField>
                                            </div>
                                            
                                        }
                                        {sc.type=="user"&&sc.inputtype=="integer"&&
                                            <div style={{margin: "10px"}}>
                                                <TextField 
                                                    label={sc.inputlabel} 
                                                    type="number" 
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                ></TextField>
                                            </div>
                                        }
                                        {sc.type=="user"&&sc.inputtype=="number"&&
                                            <div style={{margin: "10px"}}>
                                                <TextField 
                                                    label={sc.inputlabel} 
                                                    type="number" 
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                ></TextField>
                                            </div>
                                        }
                                        {sc.type=="user"&&sc.inputtype=="boolean"&&
                                            <div style={{margin: "10px"}}>
                                                <input 
                                                    label={sc.inputlabel} 
                                                    type="checkbox" 
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.checked, index)}}
                                                ></input>
                                            </div>
                                        }
                                        {sc.type=="user"&&sc.inputtype=="datetime"&&
                                            <div style={{margin: "10px"}}>
                                                <input 
                                                    label={sc.inputlabel} 
                                                    type="datetime" 
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                ></input>
                                            </div>
                                        }
                                        {sc.type=="user"&&sc.inputtype=="enum"&&
                                            <div style={{margin: "10px"}}>
                                                <FormControl sx={{width: 200}}>
                                                    <InputLabel id={"input-form-"+sc.inputkey}>{sc.inputlabel}</InputLabel>
                                                    <Select
                                                        labelId={"input-form-"+sc.inputkey}
                                                        value={createobj[sc.inputkey]} 
                                                        onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                    >
                                                        {sc.optionlist?.map((op)=>{
                                                            return (
                                                                <MenuItem value={op.value}>{op.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }
                                    </>
                                )
                            })}    
                        </>
                    }
                </div>
                <div >
                    <Button style={{color: "#3A07CD"}} onClick={()=>{handleclose("cancel")}}>Cancel</Button>
                    <Button style={{color: "#3A07CD"}} onClick={()=>{save()}}>Save</Button>
                </div>
            </Box>
        </Modal>
    )
}

const DataUpdateModal = ({open, handleclose, schema, schemaid, obj})=>{

    const [createobj, setCreateObj] = useState({});

    const [saveclicked, setSaveclicked] = useState(false);

    const setValueAction = (val ,i)=>{
        let createObjCopy = {...createobj};
        createObjCopy[schema[i].inputkey] = val;
        setCreateObj(createObjCopy);
    }

    const dispatch = useDispatch();

    const recordupdated = useSelector(selectDataRecordUpdated);

    const save = ()=>{
        setSaveclicked(true);
        dispatch(resetrecordupdated());
        dispatch(updateDataObject({
            "schemaid": schemaid,
            "payload": createobj
        }));
    }

    useEffect(()=>{
        if(recordupdated&&saveclicked){
            handleclose("update")
        }
    },[recordupdated, saveclicked])

    useEffect(()=>{
        if(obj!=null){
            setCreateObj(obj);
        }
    },[obj])


    return (
        <Modal
            open={open}
            handleclose={handleclose}
        
        >
            <Box sx={style}>
                <div>
                    <div>
                        <h3>Create Object</h3>
                    </div>
                    <>
                        {schema.map((sc, index)=>{
                            return (
                                <>
                                    {sc.type=="user"&&sc.inputtype=="string"&&
                                        <div style={{margin: "10px"}}>
                                            <TextField label={sc.inputlabel} value={createobj[sc.inputkey]} onChange={(e)=>{setValueAction(e.target.value, index)}}></TextField>
                                        </div>
                                        
                                    }
                                    {sc.type=="user"&&sc.inputtype=="text"&&
                                        <div style={{margin: "10px"}}>
                                            <TextField
                                                label={sc.inputlabel}
                                                multiline
                                                value={createobj[sc.inputkey]} 
                                                onChange={(e)=>{setValueAction(e.target.value, index)}}
                                            ></TextField>
                                        </div>
                                        
                                    }
                                    {sc.type=="user"&&sc.inputtype=="integer"&&
                                        <div style={{margin: "10px"}}>
                                            <TextField 
                                                label={sc.inputlabel} 
                                                type="number" 
                                                value={createobj[sc.inputkey]} 
                                                onChange={(e)=>{setValueAction(e.target.value, index)}}
                                            ></TextField>
                                        </div>
                                    }
                                    {sc.type=="user"&&sc.inputtype=="number"&&
                                        <div style={{margin: "10px"}}>
                                            <TextField 
                                                label={sc.inputlabel} 
                                                type="number" 
                                                value={createobj[sc.inputkey]} 
                                                onChange={(e)=>{setValueAction(e.target.value, index)}}
                                            ></TextField>
                                        </div>
                                    }
                                    {sc.type=="user"&&sc.inputtype=="boolean"&&
                                        <div style={{margin: "10px"}}>
                                            <input 
                                                label={sc.inputlabel} 
                                                type="checkbox" 
                                                value={createobj[sc.inputkey]} 
                                                onChange={(e)=>{setValueAction(e.target.checked, index)}}
                                            ></input>
                                        </div>
                                    }
                                    {sc.type=="user"&&sc.inputtype=="datetime"&&
                                        <div style={{margin: "10px"}}>
                                            <input 
                                                label={sc.inputlabel} 
                                                type="datetime" 
                                                value={createobj[sc.inputkey]} 
                                                onChange={(e)=>{setValueAction(e.target.value, index)}}
                                            ></input>
                                        </div>
                                    }
                                    {sc.type=="user"&&sc.inputtype=="enum"&&
                                        <div style={{margin: "10px"}}>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id={"input-form-"+sc.inputkey}>{sc.inputlabel}</InputLabel>
                                                <Select
                                                    labelId={"input-form-"+sc.inputkey}
                                                    value={createobj[sc.inputkey]} 
                                                    onChange={(e)=>{setValueAction(e.target.value, index)}}
                                                >
                                                    {sc.optionlist?.map((op)=>{
                                                        return (
                                                            <MenuItem value={op.value}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </>
                            )
                        })}    
                    </>
                </div>
                <div >
                    <Button style={{color: "#3A07CD"}} onClick={()=>{handleclose("cancel")}}>Cancel</Button>
                    <Button style={{color: "#3A07CD"}} onClick={()=>{save()}}>Save</Button>
                </div>
            </Box>
        </Modal>
    )

}


const Conditions = ({avlfields, filters, setFilters})=>{
    

    const deleteConditionBlock = (i)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy.splice(i,1);
        setFilters(filtersCopy)
    }

    const addCondition = (i)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy[i].push({
            "valuetocompare": "",
            "condition": "",
            "valuecompareto": "" 
        })
        setFilters(filtersCopy);
    }

    const addConBlock = ()=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy.push([]);
        setFilters(filtersCopy);
    }

    const setValuetoCompare = (i,j,val)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy[i][j] = {...filtersCopy[i][j],
                             valuetocompare: val
                            }
        setFilters(filtersCopy);
    }

    const selectCondition = (i,j,val)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy[i][j] = {...filtersCopy[i][j],
                             condition: val
                            }
        setFilters(filtersCopy)
    }

    const setValueCompareto = (i, j, val)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy[i][j] = {...filtersCopy[i][j],
                             valuecompareto: val
                            }
        setFilters(filtersCopy)
    }

    const deleteCondition = (i, j)=>{
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy[i].splice(j,1);
        setFilters(filtersCopy);
    }

    return (
        <div>
            {filters.map((conblock, i)=>{
                return (
                    <>
                        {i==0?
                            <>Where</>:
                            <div className="or-block-desc">
                                <div>Or</div>
                                <div>
                                    <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                <div className="condition-line">
                                    <div>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id="select-field-value-to-compare"> Select Field</InputLabel>
                                            <Select
                                                labelId="select-field-value-to-compare"
                                                id="select-field"
                                                value={con.valuetocompare}
                                                onChange={(e)=>{setValuetoCompare(i,j,e.target.value)}}
                                            >
                                                {avlfields.map((field)=>{
                                                    return (<MenuItem value={field.inputkey}>{field.inputlabel}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={con.condition}
                                                label="Condition"
                                                onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                            >
                                                {DbConditionalOperators.map((op)=>{
                                                    return(
                                                        <MenuItem value={op.val}>{op.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <TextField value={con.valuecompareto} onChange={(e)=>{setValueCompareto(i,j,e.target.value)}}></TextField>
                                    </div>
                                    <div>
                                        <IconButton onClick={()=>{deleteCondition(i,j)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })}
                        {i==(filters.length-1)?
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            Or
                                    </div>
                                </div>
                            </>:
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                </div>
                            </>
                        }
                        <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                </>

                )
            })}
        </div>
    )

}

const OrderBy = ({avlfields, sorts, setSorts})=>{
        
        const addSortField = ()=>{
            let sortsCopy = [...sorts];
            sortsCopy.push({
                "field": "",
                "direction": ""
            })
            setSorts(sortsCopy)
        }

        const deleteSortField = (i)=>{
            let sortsCopy = [...sorts];
            sortsCopy.splice(i,1);
            setSorts(sortsCopy);
        }

        const setSortField = (i, val)=>{
            let sortsCopy = [...sorts];
            sortsCopy[i] = {...sortsCopy[i],
                            field: val 
                        }   
            setSorts(sortsCopy);
        }

        const setSortDirection = (i, val)=>{
            let sortsCopy = [...sorts];
            sortsCopy[i] = {...sortsCopy[i],
                            direction: val
                            }
            setSorts(sortsCopy)
        }

        return (
            <>
                <div style={{marginTop: "10px"}}>
                    <div>
                        Order By
                    </div>
                    <div>
                        {sorts.map((sort, i)=>{
                            return (
                                <div className="sort-line">
                                    <div className="sort-item">
                                        <FormControl sx={{width: "200px"}}>
                                            <InputLabel id="select-sort-field"> Select Field</InputLabel>
                                            <Select
                                                labelId="select-sort-field"
                                                value={sort.field}
                                                onChange={(e)=>{setSortField(i, e.target.value)}}
                                            >
                                                {avlfields.map((field)=>{
                                                    return (<MenuItem value={field.inputkey}>{field.inputlabel}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="sort-item">
                                        <FormControl sx={{width: "200px"}}>
                                            <InputLabel id="select-sort-direction">Select Direction</InputLabel>
                                            <Select
                                                labelId="select-sort-direction"
                                                value={sort.direction}
                                                onChange={(e)=>{setSortDirection(i, e.target.value)}}
                                            >
                                                <MenuItem value="desc">Descending</MenuItem>
                                                <MenuItem value="asc">Ascending</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="sort-item">
                                        <IconButton onClick={()=>{deleteSortField(i)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>

                                </div>
                            )
                        })}
                        <div>
                            <Button onClick={()=>{addSortField()}}>Add Field</Button>
                        </div>
                    </div>
                </div>
            </>
        )
}



const DataDisplay = ({schemaid})=>{

    const [start, setStart] = useState(0);

    const [limit, setLimit] = useState(5);

    const [sorts, setSorts] = useState([]);

    const [opencreate, setOpencreate] = useState(false);

    const [openupdate, setOpenupdate] = useState(false);

    const [updaterecord, setupdaterecord] = useState(null);

    const [filters, setFilters] = useState([[]]);

    const [showfilters, setShowFilters] = useState(false);

    const dispatch = useDispatch();

    const dataheaders = useSelector(selectDataHeaders);
    
    const datadata = useSelector(selectDataData);

    const total = useSelector(selectDataTotal);

    const getDataDataAction = ()=>{
        setStart(0);
        dispatch(getDataData({
            "schemaid": schemaid,
            "payload": {
                "filters": filters,
                "page": 0,
                "limit": limit,
                "sorts": sorts
            }
        }))
    }

    useEffect(()=>{
        if(schemaid!=undefined&&schemaid!=""){
            dispatch(getDataHeaders(schemaid))
        } 
    },[schemaid])

    useEffect(()=>{
        if(dataheaders.length>0){
            dispatch(getDataData({
                "schemaid": schemaid,
                "payload": {
                    "filters": filters,
                    "page": start,
                    "limit": limit,
                    "sorts": sorts
                }
            }))
        }
    },[dataheaders])

    const closeOpenModal = (action)=>{
        if(action=="create"){
            dispatch(getDataData({
                "schemaid": schemaid,
                "payload":{
                    "filters": filters,
                    "page": start,
                    "limit": limit,
                    "sorts": sorts
                }
            }))
            setOpencreate(false);

        }else if(action=="cancel"){
            setOpencreate(false);
        }
    }
    
    const closeUpdateModal = (action)=>{
        if(action=="update"){
            dispatch(getDataData({
                "schemaid": schemaid,
                "payload":{
                    "filters": filters,
                    "page": start,
                    "limit": limit,
                    "sorts": sorts
                }
            }))
            setupdaterecord(null);
            setOpenupdate(false);
        }else if(action=="cancel"){
            setupdaterecord(null);
            setOpenupdate(false);
        }
    }

    const openUpdateModalAction = (record)=>{
        setupdaterecord(record);
        setOpenupdate(true);
    }

    const [deleteclicked, setDeleteclicked] = useState(false);

    const deleteDataObj = (id)=>{
        setDeleteclicked(true);
        dispatch(resetrecorddeleted());
        dispatch(deleteDataObject({
            "schemaid": schemaid,
            "payload":{
                "objectid": id
            }
        }));
    }

    const recorddeleted = useSelector(selectDataRecordDeleted);

    useEffect(()=>{
        if(deleteclicked, recorddeleted){
            dispatch(getDataData({
                "schemaid": schemaid,
                "payload": {
                    "filters": filters,
                    "page": start,
                    "limit": limit,
                    "sorts": sorts
                }
            }))
        }
    },[deleteclicked, recorddeleted])


    const getNextPage = ()=>{
        let nextpage = start+1;
        dispatch(getDataData({
            schemaid: schemaid,
            payload: {
                "filters": filters,
                "page": nextpage,
                "limit": limit,
                "sorts": sorts 
            }
        }))
        setStart(nextpage);
    }

    const getPreviousPage = ()=>{
        let previouspage = start-1;
        dispatch(getDataData({
            schemaid: schemaid,
            payload: {
                "filters": filters,
                "page": previouspage,
                "limit": limit,
                "sorts": sorts
            }
        }))
        setStart(previouspage);
    }

    const changePageLimit = (val)=>{
        let limit = val;
        dispatch(getDataData({
            schemaid: schemaid,
            payload:{
                "filters": filters,
                "page": start,
                "limit": limit,
                "sorts": sorts
            }
        }))
        setLimit(limit);   
    }

    return(
        <div style={{marginBottom: "30px"}}>
            <div>
                <h3>
                    Data Tables
                </h3>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {showfilters?
                    <>
                        <Button 
                            style={{color: "#3A07CD"}}
                            onClick={()=>{getDataDataAction()}}
                        >Apply Filters</Button>
                        <Button style={{color: "#3A07CD"}} onClick={()=>{setShowFilters(false)}}>Hide Filters</Button>
                    </>:
                    <>
                        <Button style={{color: "#3A07CD"}} onClick={()=>{setShowFilters(true)}}>Show Filters</Button>
                    </>
                }
                <Button onClick={()=>{setOpencreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
            </div>
            <div>
                {showfilters&&
                    <>
                        <Conditions
                            avlfields={dataheaders}
                            filters={filters}
                            setFilters={setFilters}
                        >
                        </Conditions>
                        <OrderBy
                            avlfields={dataheaders}
                            sorts={sorts}
                            setSorts={setSorts}
                        >
                        </OrderBy>
                    </>
                    
                }
            </div>
            <div style={{overflow: "auto"}}>
                <div>
                    <Table>
                        <TableRow>
                            {dataheaders.map((header)=>{
                                return (
                                    <TableCell>
                                        {header.inputlabel}         
                                    </TableCell>
                                )
                            })}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                        {datadata.map((data)=>{
                            return (
                                <TableRow>
                                    {Object.keys(data).map((key)=>{
                                        return (
                                            <TableCell>
                                                {data[key]}
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell>
                                        <IconButton onClick={()=>{openUpdateModalAction(data)}}>
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </IconButton>
                                        <IconButton onClick={()=>{deleteDataObj(data.id)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </Table>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div>
                        <select
                            value={limit}
                            onChange={(e)=>{
                                changePageLimit(e.target.value)
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                    {start!=0&&
                        <div>  
                            <IconButton onClick={()=>{getPreviousPage()}}>
                                <span className="material-symbols-outlined">
                                    chevron_left      
                                </span>
                            </IconButton>
                        </div>
                    }
                    <div>
                        {}
                    </div>
                    {(total-(start*limit+limit))>0&&
                        <div>
                            <IconButton onClick={()=>{getNextPage()}}>
                                <span className="material-symbols-outlined">
                                    chevron_right
                                </span>
                            </IconButton>
                        </div>    
                    }
                </div>
            </div>
            <DataCreateModal
                open={opencreate}
                handleclose={closeOpenModal}
                schema={dataheaders}
                schemaid={schemaid}
            >
            </DataCreateModal>
            <DataUpdateModal
                open={openupdate}
                handleclose={closeUpdateModal}
                schema={dataheaders}
                schemaid={schemaid}
                obj={updaterecord}
            >
            </DataUpdateModal>    
        </div>
    )
}

export default DataDisplay;