import AutomataLogo from "../Images/automata_logo.png";
import "./Applist.css";
import { Button, CircularProgress, IconButton, LinearProgress } from "@mui/material";
import {getApps, selectApplist, createApp, selectAppcreated, resetappcreated, deleteApp, resetappdeleted, selectAppdeleted, selectAppWorkspaceAcess, selectAppLoader} from "../Services/apps/app.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppCreateModal from "./AppCreateModal";
import { fileURL } from "../env";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BreadCrumbs from "./BreadCrumbs";
import { useNavigate, useParams, Link } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AppDeleteModal = ({
                            open, 
                            handleClose, 
                            app, 
                            deleteApp,
                            deleteclicked
                        })=>{
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Delete App {app?.name}
                    </h3>
                    {deleteclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Deleting app will result in deleting all the associated resources, including schemas, pipelines , events. Are you sure you want to delete app?
                            </Typography>
                            <div style={{display: "flex", "padding": 10}}>
                                <div>
                                    <Button onClick={()=>{handleClose()}}>Cancel</Button>
                                </div>
                                <div>
                                    <Button onClick={()=>{deleteApp(app._id)}}>Yes</Button>
                                </div>
                            </div>
                        </>
                    }
                    
                </div>
               
            </Box>
      </Modal>
    )
}



const Applist = ()=>{

    const apps = useSelector(selectApplist);

    const {workspace} = useParams();

    const [opencreate, setOpencreate] = useState(false);
    const [saveclicked, setSaveClicked] = useState(false);

    const [appdel, setAppdel] = useState(null);
    const [deleteclicked, setDeleteClicked] = useState(false);
    const [opendeleteModal, setOpendeletemodal] = useState(false);

    const navigate = useNavigate();

    const closeOpencreate = ()=>{
        setOpencreate(false);
        dispatch(getApps({
            workspace: workspace,
        }));
    }

    const saveApp = (payload)=>{
        setSaveClicked(true);
        dispatch(resetappcreated());
        dispatch(createApp(payload));
    }


    const delApp = (id)=>{
        setDeleteClicked(true);
        dispatch(resetappdeleted());
        dispatch(deleteApp(id))
    }

    const openDeleteModal = (app)=>{
        setAppdel(app);
        setOpendeletemodal(true);
    }

    const closeDeleteModal = ()=>{
        setAppdel(null);
        setOpendeletemodal(false);
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getApps({
            workspace: workspace,
        }));
    },[])

    const appcreated = useSelector(selectAppcreated);

    useEffect(()=>{
        if(saveclicked&&appcreated){
            dispatch(getApps({
                workspace: workspace,
            }));
            setSaveClicked(false);
            setOpencreate(false);
        }
    },[appcreated])


    const appdeleted = useSelector(selectAppdeleted);

    const appsworkspaceaccess = useSelector(selectAppWorkspaceAcess);

    const navigateToWorkspace = ()=>{
        navigate("/workspaces/"+workspace+"/");
    }

    useEffect(()=>{
        if(appdeleted&&deleteclicked){
            dispatch(getApps({
                workspace: workspace,
            }));
            setDeleteClicked(false);
            setOpendeletemodal(false);
            setAppdel(null);
        }
    },[appdeleted])

    const apploader = useSelector(selectAppLoader);

    return (
        <div className="applist-container">
            {apploader>0&&
                <>
                    <LinearProgress></LinearProgress>
                </>
            }
            <BreadCrumbs
                breadcrumbs={
                    workspace==""?
                    [
                        {
                            "name": "dashboard",
                            "url": "/"
                        }
                    ]:
                    [
                    {
                        "name": "dashboard",
                        "url": "/"
                    },
                    {
                        "name": "workspace",
                        "url": "/workspaces/"+workspace+"/"
                    }
            ]}
            
            >
            </BreadCrumbs>
            <div className="applist-header">
                {appsworkspaceaccess=="write"?
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <Button onClick={()=>{setOpencreate(true)}} style={{color: "#3A07CD"}}>Create App</Button>
                        </div>
                        <div>
                            <Button onClick={()=>{navigateToWorkspace()}} style={{color: "#3A07CD"}}>Close</Button>
                        </div>
                    </div>:
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <div>
                            <Button onClick={()=>{navigateToWorkspace()}} style={{color: "#3A07CD"}}>Close</Button>
                        </div>
                    </div>
                }
            </div>
            <div className="applist">
                {apps.map((app)=>{
                    return (
                        <div className="app-tile">
                                <Link to={"/"+workspace+"/schemas/"+app._id} style={{textDecoration:"none"}}>
                                    <img style={{height: 150, width:150}} src={app.logo!=null?fileURL+app.logo:AutomataLogo}></img>
                                </Link>
                                <div className="app-details">
                                    <Link to={"/"+workspace+"/schemas/"+app._id} style={{textDecoration:"none"}}>
                                        <div>
                                            {app.name}
                                        </div>
                                    </Link>
                                    <div>
                                        {app.access=="write"&&
                                            <IconButton onClick={()=>{openDeleteModal(app)}}>
                                                <span className="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                        </div>
                    )
                })}
            </div>
            <AppCreateModal 
                open={opencreate} 
                handleClose={closeOpencreate} 
                saveApp={saveApp}
                saveclicked={saveclicked}
            ></AppCreateModal>
            <AppDeleteModal 
                open={opendeleteModal} 
                handleClose={closeDeleteModal} 
                app={appdel} 
                deleteApp={delApp}
                deleteclicked={deleteclicked}
            ></AppDeleteModal>
        </div>
    )
}

export default Applist;