import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import "./WebsiteDetails.css";
import { 
        Button, 
        TextField, 
        FormControl, 
        InputLabel, 
        Select, 
        MenuItem,
        IconButton,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        TableBody,
        Badge,
        Modal,
        Box,
        CircularProgress,
        LinearProgress
    } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { 
        getAvailableHosts, 
        getWebsiteCreateOptions, 
        getWebsiteDetails, 
        getWebsiteTemplates, 
        resetwebisteupdated, 
        selectAvailableHosts, 
        selectWebsiteCreateOption, 
        selectWebsiteDetails, 
        selectWebsiteTemplates, 
        selectWebsiteUpdated, 
        updateWebsite, 
        updateWebsitedetails,
        createWebsiteTemplate,
        resetwebsitetemplatecreated,
        selectWebsitetemplateCreated,
        saveFavicon,
        resetwebsitecreateoption,
        resetwebsitecreatewebsiteoptions,
        resetwebsitecreateworkspaceoptions,
        getWebsiteCreateWebsiteOptions,
        getWebsiteCreateWorkspaceOptions,
        selectWebsitecreatewebsiteoptions,
        selectWebsitecreateworkspaceoptions,
        selectWebsiteDetailsLoader
    } from "../../Services/websites/website.slice";

import { Link } from "react-router-dom";
import { getManifestVersion, selectManifestVersion } from "../../Services/manifest/manifest.slice";
import {deleteTemplate, deployTemplate, resetTemplatedeleted, selectTemplateDeleted} from "../../Services/templates/template.slice";

import DeclareVariable from "../apps/Fields/DeclareVariable";
import * as _ from "lodash";
import { showError } from "../../Services/error.slice";
import { deployWebsite } from "../../Services/apptemplates/apptemplates.slice";
import EnvironVariables from "../environvariables/Environvariables";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const WebsiteCreateModal = ({open, handleClose})=>{
    
    const dispatch = useDispatch();

    const {workspace} = useParams();

    const [loading, setLoading] = useState(false);

    const [createmode, setCreateMode] = useState("all");

    const [templatename, setTemplateName] = useState("");

    const [tempateid, setTemplateid] = useState(null);

    const [websiteid, setWebsiteid] = useState(null);

    const [workspaceid, setWorkspaceid] = useState(null);

    const [templates, setTemplates] = useState([]);

    const {id} = useParams();

    const [saveclicked, setSaveClicked] = useState(false);

    const websitecreateoption = useSelector(selectWebsiteCreateOption);

    useEffect(()=>{
        if(open){
            dispatch(getWebsiteCreateOptions(workspace))
        }
        
    },[open])
    

    const moveback = ()=>{
        if(createmode=="template"){
            setTemplateid(null);
            setTemplateName("")
            setCreateMode("all")
        }else if(createmode=="website"){
            setWebsiteid(null);
            setTemplates([]);
            setCreateMode("all");
        }else if(createmode=="new"){
            setTemplateName("");
            setCreateMode("all")
        }else if(createmode=="websitetemplate"){
            setTemplateid(null);
            setTemplateName("");
            setCreateMode("website");
        }else if(createmode=="workspace"){
            setWorkspaceid(null);
            setCreateMode("all");
        }else if(createmode=="workspacewebsite"){
            setWebsiteid(null);
            setCreateMode("workspace");
        }else if(createmode=="workspacewebsitetemplate"){
            setTemplateid(null);
            setTemplateName("");
            setCreateMode("workspacewebsite");
        }
    }

    const moveMode = (mode, data)=>{
        if(mode=="template"){
            setTemplateid(data);
            setCreateMode("template");
        }else if(mode=="website"){
            setWebsiteid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(getWebsiteCreateWebsiteOptions(data));
            setCreateMode("website");
        }else if(mode=="new"){
            setCreateMode("new")
        }else if(mode=="websitetemplate"){
            setTemplateid(data);
            setCreateMode("websitetemplate");
        }else if(mode=="workspace"){
            setWorkspaceid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(resetwebsitecreateworkspaceoptions());
            dispatch(getWebsiteCreateWorkspaceOptions(data));
            setCreateMode("workspace")
        }else if(mode=="workspacewebsite"){
            setWebsiteid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(getWebsiteCreateWebsiteOptions(data));
            setCreateMode("workspacewebsite");
        }else if(mode=="workspacetemplate"){
            setTemplateid(data);
            setCreateMode("workspacetemplate")
        }else if(mode=="workspacewebsitetemplate"){
            setTemplateid(data);
            setCreateMode("workspacewebsitetemplate");
        }
    }

    const websitecreatewebsiteoptions = useSelector(selectWebsitecreatewebsiteoptions);

    const websitecreateworkspaceoptions = useSelector(selectWebsitecreateworkspaceoptions);

    const save = ()=>{
        if(createmode=="new"){
                let payload = {
                    "type": "new",
                    "source": id,
                    "name": templatename
                }
                setSaveClicked(true);
                dispatch(resetwebsitetemplatecreated());
                dispatch(createWebsiteTemplate(payload));
        }else if(
                createmode=="template"||
                createmode=="websitetemplate"||
                createmode=="workspacetemplate"||
                createmode=="workspacewebsitetemplate"
            ){
                let payload = {
                    "type": "template",
                    "source": id,
                    "name": templatename,
                    "templateid": tempateid
                }
                setSaveClicked(true);
                dispatch(resetwebsitetemplatecreated());
                dispatch(createWebsiteTemplate(payload));
        }
    }

    const templatecreated = useSelector(selectWebsitetemplateCreated);

    useEffect(()=>{
        if(templatecreated&&saveclicked){
            setSaveClicked(false);
            setCreateMode("all");
            setTemplateName("");
            setTemplateid(null);
            setWebsiteid(null);
            setWorkspaceid(null);
            handleClose()
        }
    },[saveclicked, templatecreated])


    const handleCloseAction = ()=>{
        dispatch(resetwebsitecreateoption());
        dispatch(resetwebsitecreatewebsiteoptions());
        dispatch(resetwebsitecreateworkspaceoptions());
        setTemplateName("");
        setTemplateid(null);
        setWorkspaceid(null);
        setWebsiteid(null);
        handleClose();
    }
    
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <h4>Create Template</h4>
                            {createmode!="all"&&
                                <IconButton onClick={()=>{moveback()}}>
                                    <span className="material-symbols-outlined">
                                        arrow_back
                                    </span>
                                </IconButton>
                            }
                        </div>
                        <div>
                            <IconButton onClick={()=>{handleCloseAction()}}>
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </IconButton>
                        </div>
                    </div>
                    {saveclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <>
                                {createmode=="all"&&
                                    <div className="website-create-conatiner">
                                        <div style={{textAlign: "center" , cursor: "pointer"}} onClick={()=>{moveMode("new")}}>
                                            <div>
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    create_new_folder
                                                </span>
                                            </div>
                                            <div>
                                                New
                                            </div>
                                        </div>
                                        {websitecreateoption?.map((wco)=>{
                                            return (
                                                <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode(wco.type, wco._id)}}>
                                                    <div>
                                                        {wco.type=="template"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder
                                                            </span>
                                                        }
                                                        {wco.type=="website"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                language
                                                            </span>
                                                        }
                                                        {wco.type=="workspace"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder_open
                                                            </span>
                                                        }
                                                    </div>
                                                    <div>
                                                        {wco.name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {createmode=="template"&&
                                    <>
                                        <div>
                                            <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                                        </div>
                                        <div style={{"display": "flex", "justifyContent": "center"}}>
                                            <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                }
                                {createmode=="new"&&
                                    <>
                                        <div>
                                            <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                                        </div>
                                        <div style={{"display": "flex", "justifyContent": "center"}}>
                                            <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                }
                                {createmode=="website"&&
                                    <div className="website-create-conatiner">
                                        {websitecreatewebsiteoptions?.map((t)=>{
                                            return(
                                                <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("website"+t.type, t._id)}}>
                                                    <div>
                                                        {t.type=="template"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder
                                                            </span>
                                                        }
                                                        {t.type=="website"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder_open
                                                            </span>

                                                        }
                                                    </div>
                                                    <div>
                                                        {t.name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {createmode=="websitetemplate"&&
                                    <>
                                        <div>
                                            <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                                        </div>
                                        <div style={{"display": "flex", "justifyContent": "center"}}>
                                            <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                }
                                {createmode=="workspace"&&
                                    <div className="website-create-conatiner">
                                        {websitecreateworkspaceoptions?.map((wco)=>{
                                            return (
                                                <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("workspace"+wco.type, wco._id)}}>
                                                    <div>
                                                        {wco.type=="template"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder
                                                            </span>
                                                        }
                                                        {wco.type=="website"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                language
                                                            </span>
                                                        }
                                                        {wco.type=="workspace"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder_open
                                                            </span>
                                                        }
                                                    </div>
                                                    <div>
                                                        {wco.name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {createmode=="workspacetemplate"&&
                                    <>
                                        <>
                                            <div>
                                                <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                                            </div>
                                            <div style={{"display": "flex", "justifyContent": "center"}}>
                                                <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                                    Save
                                                </Button>
                                            </div>
                                        </>
                                    </>
                                }
                                {createmode=="workspacewebsite"&&
                                    <div className="website-create-conatiner">
                                    {websitecreatewebsiteoptions?.map((t)=>{
                                            return(
                                                <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("workspacewebsite"+t.type, t._id)}}>
                                                    <div>
                                                        {t.type=="template"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder
                                                            </span>
                                                        }
                                                        {t.type=="website"&&
                                                            <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                                folder_open
                                                            </span>

                                                        }
                                                    </div>
                                                    <div>
                                                        {t.name}
                                                    </div>
                                                </div>
                                            )
                                        })}  
                                    </div>
                                }
                                {createmode=="workspacewebsitetemplate"&&
                                    <>
                                        <div>
                                            <TextField value={templatename} onChange={(e)=>{setTemplateName(e.target.value)}} fullWidth label="Template name"></TextField>
                                        </div>
                                        <div style={{"display": "flex", "justifyContent": "center"}}>
                                            <Button sx={{color: "#3A07CD"}} onClick={()=>{save()}}>
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                }
                            </>    
                        </>
                    }
                    
                </Box>
            </Modal>
        </>
    )
}


const WebsiteDetails = ()=>{

    const {id, workspace} = useParams();

    const dispatch = useDispatch();

    const [websitedetail, setWesbitedetail] = useState(null);

    const [openCreate, setOpenCreate] = useState(false);

    const [titleEdit, setTitleEdit] = useState(false);

    const [faviconEdit, setFaviconEdit] = useState(false);

    const [scriptsEdit, setScriptsEdit] = useState(false);

    const websitedetailremote = useSelector(selectWebsiteDetails);

    const [edit, setEdit] = useState(false);

    const [saveclicked, setSaveClicked] = useState(false);

    const availablehosts = useSelector(selectAvailableHosts);

    const navigate = useNavigate();


    useEffect(()=>{
        if(websitedetailremote==null||websitedetailremote==undefined){
            return
        }
        let websitedetailsremotecopy = {...websitedetailremote};
        setWesbitedetail(websitedetailsremotecopy);

    },[websitedetailremote])

    useEffect(()=>{
        if(id!=null&&id!=undefined){
            dispatch(getWebsiteDetails(id))
            dispatch(getWebsiteTemplates(id));
        }
        
    },[id])

    useEffect(()=>{
        dispatch(getAvailableHosts());
    },[edit])


    const modifySchema = (sc, scm)=>{
        let settingsCopy = {...websitedetail.settings};
        settingsCopy = {...settingsCopy,
                        schema: sc,
                        schemamapping: scm
                        }
        let websitedetailscopy = {...websitedetail,
                                  settings: settingsCopy
                                 }
        dispatch(updateWebsitedetails(websitedetailscopy));
        setWesbitedetail(websitedetailscopy);
        
    }

    const setWebsitename = (val)=>{
        let websitedetailscopy = {...websitedetail,
                                  name: val
                                 }
        setWesbitedetail(websitedetailscopy)
    }

    const setwebsitehost = (val)=>{
        let websitedetailscopy = {...websitedetail,
                                  host: val
                                 }
        setWesbitedetail(websitedetailscopy);
    }   

    const save = ()=>{
        let websitedetailscopy = {...websitedetail};
        dispatch(resetwebisteupdated());
        setSaveClicked(true);
        dispatch(updateWebsitedetails(websitedetailscopy))
    }

    const websiteupdated = useSelector(selectWebsiteUpdated);


    const templatelist = useSelector(selectWebsiteTemplates);

    useEffect(()=>{
        if(saveclicked&&websiteupdated){
            setEdit(false);
            setSaveClicked(false);
        }
    },[websiteupdated, saveclicked])

    const openTemplateEditor = (templateid)=>{
        navigate("/"+workspace+"/appdesigner/html/"+id+"/"+templateid);
    }   

    const closeDetails = ()=>{
        navigate("/"+workspace+"/websites");
    }

    const closeOpenCreate = ()=>{
        setOpenCreate(false);
    }

    const [deleteClicked, setDeleteClicked] = useState(false);

    const deleteTemplateAction = (templateid)=>{
        dispatch(resetTemplatedeleted());
        setDeleteClicked(true);
        dispatch(deleteTemplate(templateid));
    }

    const templateDeleted = useSelector(selectTemplateDeleted);

    useEffect(()=>{
        if(deleteClicked&&templateDeleted){
            dispatch(getWebsiteTemplates(id));
            setDeleteClicked(false);
        }
    },[templateDeleted,deleteClicked]);


    //website srcipts
    const addScript = ()=>{
        let scriptscopy = [...websitedetail.scripts];
        scriptscopy.push("");
        let websitedetailscopy = {...websitedetail,
                                  scripts: scriptscopy
                                 };
        setWesbitedetail(websitedetailscopy);

    }

    const deleteScript = (index)=>{
        let scriptscopy = [...websitedetail.scripts];
        scriptscopy.splice(index, 1);
        let websitedetailscopy = {...websitedetail,
                                  scripts: scriptscopy
                                }
        setWesbitedetail(websitedetailscopy);

    }

    const changeScript = (val, index)=>{
        let scriptscopy = [...websitedetail.scripts];
        scriptscopy[index] = val;
        let websitedetailscopy = {...websitedetail,
                                  scripts: scriptscopy
                                  }
        setWesbitedetail(websitedetailscopy)

    }

    const [savescriptclicked, setsavescriptclicked] = useState(false);

    const saveScripts = ()=>{
        let websitedetailscopy = {...websitedetail};
        dispatch(resetwebisteupdated());
        setsavescriptclicked(true);
        dispatch(updateWebsitedetails(websitedetailscopy))
    }

    useEffect(()=>{
        if(savescriptclicked&&websiteupdated){
            setsavescriptclicked(false);
            setScriptsEdit(false);
        }
    },[savescriptclicked, websiteupdated]);
    
    const [savetitleclicked, setSavetitleclicked] = useState(false);

    const changeTitle = (val)=>{
        let websitedetailscopy = {...websitedetail,
                                  title: val
                                 }
        setWesbitedetail(websitedetailscopy);
    }

    const saveTitle = ()=>{
        let websitedetailscopy = {...websitedetail};
        dispatch(resetwebisteupdated());
        setSavetitleclicked(true);
        dispatch(updateWebsitedetails(websitedetailscopy));
    }

    useEffect(()=>{
        if(savetitleclicked&&websiteupdated){
            setSavetitleclicked(false);
            setTitleEdit(false);
        }
    },[savetitleclicked, websiteupdated])

    const [faviconname, setFaviconname] = useState("");

    const [savefaviconclicked, setSavefaviconclicked] = useState(false);

    const changeFavicon = (e)=>{
        let file = e.target.files[0];
        if(file!=undefined){
            let filedetails = file.name;
            let fileurl = URL.createObjectURL(file);
            let image = new Image();
            image.src = fileurl;
            image.onload = ()=>{
                if((image.width==32&&image.height==32)||(image.width==16&&image.height==16)){
                    setFaviconname(filedetails);
                    let websitedetailscopy = {...websitedetail,
                                            favicon: fileurl
                                            }
                    setWesbitedetail(websitedetailscopy);
                }else{
                    dispatch(showError("Favicon is not valid"));
                }
            }

        }
    }

    const saveFaviconAction = ()=>{
        let payload = {
            websitedetail: websitedetail,
            faviconname: faviconname
        }
        dispatch(resetwebisteupdated());
        setSavefaviconclicked(true);
        dispatch(saveFavicon(payload))
    }

    const deployWebsiteAction = ()=>{
        dispatch(deployWebsite(id));
    }

    useEffect(()=>{
        if(savefaviconclicked&&websiteupdated){
            setSavefaviconclicked(false);
            setFaviconEdit(false);
        }
    },[savefaviconclicked, websiteupdated])

    useEffect(()=>{
        dispatch(getManifestVersion({
            source: "website",
            sourceid: id
       }))
    },[])

    const publishedversion = useSelector(selectManifestVersion);

    const openPublish = ()=>{
        navigate("/"+workspace+"/manifest/website/"+id)
    }

    const websitedetailsloader = useSelector(selectWebsiteDetailsLoader);

    return (
        <div>
            {(
                websitedetailsloader>0||
                savefaviconclicked||
                savetitleclicked||
                savescriptclicked||
                deleteClicked||
                saveclicked
            )&&
                <>
                    <LinearProgress></LinearProgress>
                </>

            }
            <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "interfaces",
                                "url": "/"+workspace+"/interfaces"
                            },
                            {
                                "name": "websites",
                                "url": "/"+workspace+"/websites"
                            }
                        ]:
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "workspace",
                                "url": "/workspaces/"+workspace
                            },
                            {
                                "name": "interfaces",
                                "url": "/"+workspace+"/interfaces"
                            },
                            {
                                "name": "websites",
                                "url": "/"+workspace+"/websites"
                            }
                        ]
                }
            ></BreadCrumbs>
                <div style={{display: "flex", justifyContent: "flex-end", marginRight: "10px"}}>
                    <Button sx={{color: "#3A07CD"}} onClick={()=>{closeDetails()}}>
                        Close
                    </Button>
                </div>
                <div className="website-detail-box">
                    <div className="website-detail-section">
                        <h4>Templates</h4>
                        <div className="website-action-headers">
                            <Button sx={{color: "#3A07CD"}} onClick={()=>{deployWebsiteAction()}}>Deploy</Button>
                            <Button sx={{color: "#3A07CD"}} onClick={()=>{setOpenCreate(true)}}>Create</Button>
                        </div>
                        <div>
                        <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Updated At</TableCell>
                                <TableCell>DeployedAt</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templatelist.map((template)=>{
                                return (
                                    <TableRow>
                                        <TableCell>
                                            {template._id==websitedetail?.maintemplate?
                                                <Badge badgeContent="main">
                                                    <a style={{"cursor": "pointer"}} onClick={()=>{openTemplateEditor(template._id)}}>{template.name}</a>    
                                                </Badge>:
                                                <a style={{"cursor": "pointer"}} onClick={()=>{openTemplateEditor(template._id)}}>{template.name}</a>
                                            }
                                        </TableCell>
                                        <TableCell>{template.type}</TableCell>
                                        <TableCell>{template.createdAt}</TableCell>
                                        <TableCell>{template.updatedAt}</TableCell>
                                        <TableCell>{template.deployedAt}</TableCell>
                                        <TableCell>
                                            {template._id!=websitedetail?.maintemplate&&
                                                <IconButton onClick={()=>{deleteTemplateAction(template._id)}}>
                                                    <span className="material-symbols-outlined">
                                                        delete
                                                    </span>
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </TableContainer>
                        </div>
                    </div>
                    <div className="website-detail-section">
                        <h4>Settings</h4>
                        <div className="website-action-headers">
                            {edit?
                                <Button onClick={()=>{save()}} sx={{color: "#3A07CD"}}>Save</Button>:
                                <Button onClick={()=>{setEdit(true)}} sx={{color: "#3A07CD"}}>Edit</Button>
                            }            
                            
                        </div>
                        {websitedetail!=null&&websitedetail!=undefined&&
                            <div>
                                {edit?
                                    <div className="website-details-row">
                                        <TextField
                                            fullWidth 
                                            value={websitedetail?.name} 
                                            onChange={(e)=>{setWebsitename(e.target.value)}}
                                            label="Website name"    
                                        ></TextField>
                                    </div>:
                                    <div className="website-details-row">
                                        <div className="website-details-row-item">
                                            Name: 
                                        </div>
                                        <div className="website-details-row-item">
                                            {websitedetail?.name}
                                        </div>
                                    </div>
                                }
                                {edit?
                                    <div className="website-details-row">
                                        <FormControl
                                            fullWidth
                                        >
                                            <InputLabel
                                                id="website-host"
                                                
                                            >
                                                Website host
                                            </InputLabel>
                                                <Select
                                                    labelId="website-host"
                                                    value={websitedetail?.host}
                                                    onChange={(e)=>{setwebsitehost(e.target.value)}}
                                                    label="Website host"
                                                >
                                                    {availablehosts.map((host)=>{
                                                        return(
                                                            <MenuItem value={host.value}>{host.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                        </FormControl>
                                    </div>:
                                    <div className="website-details-row">
                                        <div className="website-details-row-item">
                                            Host:
                                        </div>
                                        <div className="website-details-row-item">
                                            <a href={"https://"+websitedetail?.host} target="_blank">
                                                {websitedetail?.host}
                                            </a>
                                        </div>
                                    </div>
                                }
                                <div>
                                    <h5>
                                        Settings/env
                                    </h5>
                                    <div className="website-settings">
                                        <div className="website-settings-item">
                                            <h6>
                                                Title
                                            </h6>
                                        </div>
                                        <div className="website-settings-item">
                                            {titleEdit?
                                                <div>
                                                    <TextField fullWidth variant="standard" value={websitedetail.title} onChange={(e)=>{changeTitle(e.target.value)}}></TextField>
                                                </div>:
                                                <div>
                                                    {websitedetail.title}
                                                </div>
                                            }
                                        </div>
                                        <div className="website-settings-item">
                                            {titleEdit?
                                                <div>
                                                    <IconButton onClick={()=>{saveTitle()}}>
                                                        <span className="material-symbols-outlined">
                                                            done
                                                        </span>
                                                    </IconButton>
                                                    <IconButton onClick={()=>{setTitleEdit(false)}}>
                                                        <span className="material-symbols-outlined">
                                                            close
                                                        </span>
                                                    </IconButton>
                                                </div>:
                                                <>
                                                    <IconButton onClick={()=>{setTitleEdit(true)}}>
                                                        <span className="material-symbols-outlined">
                                                            edit
                                                        </span>
                                                    </IconButton>
                                                </>
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className="website-settings">
                                        <div className="website-settings-item">
                                            <div>
                                                <h6>Favicon</h6>
                                            </div>
                                            <div style={{fontSize:"8px"}}>
                                                32X32 or 16X16
                                            </div>
                                        </div>
                                        <div style={{display: "flex", "alignItems": "center"}}>
                                            {websitedetail.favicon!=""&&
                                                <div style={{textAlign: "center", padding: "10px"}}>
                                                    <img src={websitedetail.favicon}></img>
                                                </div>
                                                
                                            }
                                            {faviconEdit&&
                                                <>
                                                    <input type="file" onChange={(e)=>{changeFavicon(e)}}></input>
                                                </>
                                            }
                                        </div>
                                        <div>
                                            {faviconEdit?
                                                <>
                                                    <IconButton onClick={()=>{saveFaviconAction()}}>
                                                        <span className="material-symbols-outlined">
                                                            done
                                                        </span>
                                                    </IconButton>
                                                    <IconButton onClick={()=>{setFaviconEdit(false)}}>
                                                        <span className="material-symbols-outlined">
                                                            close
                                                        </span>
                                                    </IconButton>
                                                </>:
                                                <>
                                                    <IconButton onClick={()=>{setFaviconEdit(true)}}>
                                                        <span className="material-symbols-outlined">
                                                            edit
                                                        </span>
                                                    </IconButton>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{"display": "flex", "justifyContent": "space-between"}}>
                                            <div>
                                                <h6>
                                                    Scripts
                                                </h6>
                                            </div>
                                            <div>
                                                {scriptsEdit?
                                                    <>
                                                        <IconButton onClick={()=>{saveScripts()}}>
                                                            <span className="material-symbols-outlined">
                                                                done
                                                            </span>
                                                        </IconButton>
                                                        <IconButton onClick={()=>{setScriptsEdit(false)}}>
                                                            <span className="material-symbols-outlined">
                                                                close
                                                            </span>
                                                        </IconButton>   
                                                    </>:
                                                    <>
                                                        <IconButton onClick={()=>{setScriptsEdit(true)}}>
                                                            <span className="material-symbols-outlined">
                                                                edit
                                                            </span>
                                                        </IconButton>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {scriptsEdit?
                                            <div>
                                                {websitedetail.scripts?.map((script, index)=>{
                                                    return (
                                                        <div style={{"display": "flex", marginBottom: "10px"}}>
                                                            <TextField value={script} onChange={(e)=>{changeScript(e.target.value, index)}}></TextField>
                                                            <IconButton onClick={()=>{deleteScript(index)}}>
                                                                <span className="material-symbols-outlined">
                                                                    delete
                                                                </span>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                })}
                                                <div>
                                                    <Button sx={{color: "#3A07CD"}} onClick={()=>{addScript()}}>Add</Button>
                                                </div>
                                            </div>:
                                            <ol>
                                                {websitedetail.scripts?.map((script)=>{
                                                    return (
                                                        <li>
                                                            {script}
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        }
                                    </div>
                                    <div>
                                        <h3>
                                            Environment Variable
                                        </h3>
                                        <EnvironVariables
                                            resource={"website"}
                                            resourceId={id}
                                        >
                                        </EnvironVariables>
                                    </div>
                                    <div>
                                        <div className="publish-block">
                                            <div>
                                                {publishedversion.version>1?
                                                    <div>
                                                        Status: published , version: <Link to={"/"+workspace+"/savedmanifest/website/"+id+"/"+publishedversion.id}>{publishedversion.version-1}</Link>
                                                    </div>:
                                                    <div>
                                                        Status: not published
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <Button onClick={()=>{openPublish()}} style={{"color": "#3A07CD"}}>Publish</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <WebsiteCreateModal
                    open={openCreate}
                    handleClose={closeOpenCreate}
                >
                </WebsiteCreateModal>
        </div>
    )

}


export default WebsiteDetails