import { Button, Modal, Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetfileuploadres, selectFileuploadres, uploadFile } from "../../Services/pipelines/pipeline.slice";
import { showError } from "../../Services/error.slice";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import { getManifestSavedDocumentation, getPublishedManifestSource, getSavedManifest, getSavedManifestDeps, publishSavedManifest, resetmanifestfaviconsaved, resetmanifestsaveddeps, selectManifestDocumentation, selectManifestFaviconSaved, selectSavedManifest, selectSavedManifestDeps, updateManifestBanners, updateManifestFavicon } from "../../Services/manifest/manifest.slice";
import * as _ from "lodash";
import "./ManifestDetails.css";
import Readme from "../readme/Readme";
import { updateDocument } from "../../Services/documentations/documentations.slice";
import MarketManifestModal from "./MarketManifestModal";
import PlatformManifestModal from "./PlatformManifestModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const depStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "auto"
}

const DependencyModal = ({
    open,
    handleClose,
    id
})=>{

    const dispatch = useDispatch();

    const deps = useSelector(selectSavedManifestDeps)

    useEffect(()=>{
        dispatch(resetmanifestsaveddeps());
        dispatch(getSavedManifestDeps(id));
    },[])

    return (
        <Modal
            open={open}
            handleClose={handleClose}
        >
            <Box sx={depStyle}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <IconButton onClick={()=>{handleClose()}}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </IconButton>
                </div>
                <div>
                    <pre>
                        {JSON.stringify(deps, null, 2)}
                    </pre>
                </div>
            </Box>
        </Modal>
    )
}

const BannerUploadModal = ({
                             open,
                             handleClose,
                             addtobanners
                            })=>{

            const dispatch = useDispatch();
            
            const [bannersent, setBannersent] = useState(false);
            
            const [bannerurl, setBannerurl] = useState("");


            const uploadBanner = (e)=>{
                let file = e.target.files[0];
                if(file!=undefined){
                    dispatch(resetfileuploadres());
                    setBannersent(true);
                    dispatch(uploadFile({
                        "file": file
                    }))
                }
            }

            const fileuploadres = useSelector(selectFileuploadres);

            useEffect(()=>{
                if(fileuploadres!=null&&bannersent){
                    let bannerurl = fileuploadres.url;
                    setBannerurl(bannerurl);
                    dispatch(resetfileuploadres());
                }

            },[fileuploadres])

            const addbannersAction = ()=>{
                addtobanners(bannerurl);
                handleClose();
            }

            
            return (
                <Modal
                    open={open}
                    handleClose={handleClose}
                >
                    <Box
                        sx={style}
                    >
                        <div>
                            <input type="file" onChange={(e)=>{uploadBanner(e)}}></input>
                        </div>
                        <div>
                            <Button onClick={()=>{handleClose()}}>Cancel</Button>
                            <Button onClick={()=>{addbannersAction()}}>Save</Button>
                        </div>
                    </Box>
                </Modal>
            )
}

const ManifestDetails = ()=>{

    const [manifest, setManifest] = useState(null)

    const [dependencyModal, setDependencyModal] = useState(false);    
    
    const {
        source,
        sourceid,
        id
    } = useParams();

    const [openbannerupload, setOpenbannerupload] = useState(false);
    
    const [faviconEdit, setFaviconEdit] = useState(false);

    const [faviconname, setFaviconname] = useState("");

    const [showpreview, setShowpreview] = useState(false);

    const [showPlatformPreview, setShowPlatfromPreview] = useState();

    const { workspace } = useParams();

    const dispatch = useDispatch();

    const addtobanners = (url)=>{
        let manifestCopy = {...manifest};
        let manifestbannerscopy = [...manifestCopy.banners];
        manifestbannerscopy.push(url);
        manifestCopy = {...manifestCopy,
                        banners: manifestbannerscopy
                       }
        let requestPayload = {
                                _id: manifestCopy._id,
                                banners: manifestbannerscopy,
                                manifestscope: manifestCopy.manifestscope
                             }
        dispatch(updateManifestBanners(requestPayload))
        setManifest(manifestCopy);
    }

    const removebanners = (index)=>{
        let manifestCopy = {...manifest};
        let manifestbannerscopy = [...manifestCopy.banners];
        manifestbannerscopy.splice(index,1);
        manifestCopy = {...manifestCopy,
                        banners: manifestbannerscopy
                       }
        let requestPayload = {
                                _id: manifestCopy._id,
                                banners: manifestbannerscopy,
                                manifestscope: manifestCopy.manifestscope
                             }
        dispatch(updateManifestBanners(requestPayload))
        setManifest(manifestCopy);
    }

    const [savefaviconclicked, setSavefaviconclicked] = useState(false);

    const saveFaviconAction = ()=>{
        let manifestCopy = {...manifest};
        let requestPayload = {
                                url: manifestCopy.favicon,
                                faviconname: faviconname,
                                id: manifestCopy._id,
                                manifestscope: manifestCopy.manifestscope
                             }
        setSavefaviconclicked(true);
        dispatch(resetmanifestfaviconsaved());
        dispatch(updateManifestFavicon(requestPayload));
    }

    const faviconsaved = useSelector(selectManifestFaviconSaved);

    useEffect(()=>{
        if(faviconsaved&&savefaviconclicked){
            setFaviconEdit(false);
            setSavefaviconclicked(false);
        }
    },[savefaviconclicked, faviconsaved])

    const changeFavicon = (e)=>{
        let file = e.target.files[0];
        if(file!=undefined){
            let filedetails = file.name;
            let fileurl = URL.createObjectURL(file);
            let image = new Image();
            image.src = fileurl;
            image.onload = ()=>{
                if((image.width==512&&image.height==512)||(image.width==16&&image.height==16)){
                    setFaviconname(filedetails);
                    let manifestCopy = {...manifest,
                                        favicon: fileurl
                                       }
                    setManifest(manifestCopy);
                }else{
                    dispatch(showError("Favicon is not valid"));
                }
            }
        }
    }

    const getBreadCrumbsArray = ()=>{
        if(source=="app"){
            let breadcrumbs = workspace=="main"?[
                                                    {
                                                        "name": "dashboard",
                                                        "url": "/"
                                                    },
                                                    {
                                                        "name": "apps",
                                                        "url": "/"+workspace+"/apps"
                                                    },
                                                    {
                                                        "name": "appdetails",
                                                        "url": "/"+workspace+"/schemas/"+sourceid
                                                    }
                                                ]:
                                                [
                                                    {
                                                        "name": "dashboard",
                                                        "url": "/"
                                                    },
                                                    {
                                                        "name": "workspace",
                                                        "url": "/workspaces/"+workspace+"/"
                                                    },
                                                    {
                                                        "name": "apps",
                                                        "url": "/"+workspace+"/apps"
                                                    },
                                                    {
                                                        "name": "appdetails",
                                                        "url": "/"+workspace+"/schemas/"+sourceid
                                                    }
                                                ]
            return breadcrumbs;
        }
    }

    useEffect(()=>{
        dispatch(getSavedManifest(id))
    },[])

    const manifestdetails = useSelector(selectSavedManifest);

    useEffect(()=>{
        let notempty = _.isEmpty(manifestdetails);
        if(manifestdetails!=null&&notempty==false){
            let manifestdetailsCopy = {...manifestdetails};
            setManifest(manifestdetailsCopy);
            dispatch(getManifestSavedDocumentation({
                                                    "manifestscope": manifestdetailsCopy.manifestscope,
                                                    "id": manifestdetailsCopy._id
                                                    }))
        }

    },[manifestdetails]);

    const [introduction, setIntroductionAction] = useState("");

    const [initintroduction, setInitIntroduction] = useState(false);

    const manifestreadme = useSelector(selectManifestDocumentation);

    const setIntroduction = (doc)=>{
        let source = manifestreadme.introduction.source;
        let sourceid = manifestreadme.introduction.sourceid;
        dispatch(updateDocument({
            source: source,
            sourceid: sourceid,
            document: doc
        }));
    }

    useEffect(()=>{
        if(manifestreadme?.introduction?.document!=null&&manifestreadme?.introduction?.document!=""&&initintroduction==false){
            setIntroductionAction(manifestreadme.introduction.document);
            setInitIntroduction(true);
        }
    },[manifestreadme])

    const publishManifest = ()=>{
        dispatch(publishSavedManifest(
            {
                id: manifestdetails._id,
                manifestscope: manifestdetails.manifestscope,
                manifestid: id
            }
        ))
    }

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={getBreadCrumbsArray()}
            >
            </BreadCrumbs>
            {manifest!=null&&
                <div>
                    <div className="manifest-details">
                        <div className="manifest-details-line">
                            <div className="manifest-details-item">
                                Manifest Name
                            </div>
                            <div className="manifest-details-item">
                                {manifest?.manifestname}
                            </div>
                        </div>
                        <div className="manifest-details-line">
                            <div className="manifest-details-item">
                                Published Version
                            </div>
                            <div className="manifest-details-item">
                                {manifest?.publishedversion}
                            </div>
                        </div>
                        <div className="manifest-details-line">
                            <div className="manifest-details-item">
                                Available Version
                            </div>
                            <div className="manifest-details-item">
                                {manifest?.availableversion}
                            </div>
                        </div>
                        <div className="manifest-details-line">
                            <div className="manifest-details-item">
                                Manifest Starter
                            </div>
                            <div className="manifest-details-item">
                                {manifest?.manifeststarter}
                            </div>
                        </div>
                        <div className="manifest-details-line">
                            <div className="manifest-details-item">
                                Manifest Scope
                            </div>
                            <div className="manifest-details-item">
                                {manifest?.manifestscope}
                            </div>
                        </div>
                    </div>
                    <div className="manifest-action-buttons">
                        <Button 
                            onClick={()=>{publishManifest()}} 
                            disabled={manifestdetails.publishedversion==manifestdetails.availableversion}
                        >Publish</Button>
                        <Button onClick={()=>{setShowpreview(true)}}>Marketplace Preview</Button>
                        <Button onClick={()=>{setShowPlatfromPreview(true)}}>Platform Preview</Button>
                        <Button onClick={()=>{setDependencyModal(true)}}>Dependencies</Button>
                    </div>
                    <div className="manifest-media">
                        <div className="manifest-favicon-details-line">
                            <div className="manifest-favicon-item">
                                <div>
                                    Package Icon
                                </div>
                                <div style={{fontSize:"8px"}}>
                                    512*512
                                </div>
                            </div>
                            <div className="manifest-favicon-item">
                                <div style={{
                                                display: "flex", 
                                                "alignItems": "center"
                                            }}>
                                    {manifest?.favicon!=""&&
                                        <div className="favicon-container">
                                            <img style={{maxWidth: "100%", maxHeight: "100%"}} src={manifest.favicon}></img>
                                        </div>  
                                    }
                                    {faviconEdit&&
                                        <>
                                            <input type="file" onChange={(e)=>{changeFavicon(e)}}></input>
                                        </>
                                    }
                                </div>
                            </div>
                            <div>
                                {faviconEdit?
                                    <>
                                        <IconButton onClick={()=>{saveFaviconAction()}}>
                                            <span className="material-symbols-outlined">
                                                done
                                            </span>
                                        </IconButton>
                                        <IconButton onClick={()=>{setFaviconEdit(false)}}>
                                            <span className="material-symbols-outlined">
                                                close
                                            </span>
                                        </IconButton>
                                    </>:
                                    <>
                                        <IconButton onClick={()=>{setFaviconEdit(true)}}>
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </IconButton>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="manifest-banners">
                            <div>
                                <h3 style={{marginLeft: "10px"}}>
                                    Banners
                                </h3>
                            </div>
                            <div className="manifest-banners-outer-box">
                                <div className="manifest-banners-box">
                                    <div style={{margin: "20px", marginTop: "60px"}}>
                                        <div className="banner-tile" style={{cursor: "pointer"}} onClick={()=>{setOpenbannerupload(true)}}>
                                            <div>
                                                <span className="material-symbols-outlined">
                                                    add
                                                </span>
                                            </div>
                                            <div>
                                                Upload in aspect ratio of 4:3
                                            </div>
                                        </div>
                                    </div>
                                    {manifest.banners.map((banner, index)=>{
                                        return (
                                            <div style={{margin: "20px"}}>
                                                <div className="banner-delete-div">
                                                    <IconButton onClick={()=>{removebanners(index)}}>
                                                        <span className="material-symbols-outlined">
                                                            close
                                                        </span>
                                                    </IconButton>
                                                </div>
                                                <div className="banner-tile">
                                                    <img style={{maxWidth: "100%", maxHeight: "100%"}} src={banner}></img>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
            <div>
                {manifestreadme!=null&&
                    <div>
                        <Readme
                            readme={introduction}
                            setreadme={setIntroduction}
                            write="write"
                            source={manifestreadme?.introduction?.source}
                            sourceid={manifestreadme?.introduction?.sourceid}
                            mode="component"
                            isManifest={true}
                            manifestid={id}
                            msource={source}
                            msourceid={sourceid}
                        >
                        </Readme>
                        <div className="documentation-schemas">
                            <h3>Schemas</h3>
                            {manifestreadme.schemas.map((schema)=>{
                                return (
                                    <div>
                                        <Link to={"/"+workspace+"/manifest/"+source+"/"+sourceid+"/"+id+"/schema"+"/"+schema._id}>{schema.schemaname}</Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            <BannerUploadModal
                open={openbannerupload}
                handleClose={()=>{setOpenbannerupload(false)}}
                addtobanners={addtobanners}
            >
            </BannerUploadModal>
            {manifest?._id!=undefined&&
                <DependencyModal
                    open={dependencyModal}
                    handleClose={()=>{setDependencyModal(false)}}
                    id={id}
                >
                </DependencyModal>
            }
            {manifest?._id!=undefined&&
                <MarketManifestModal
                    open={showpreview}
                    handleClose={()=>{setShowpreview(false)}}
                    manifestid={manifestdetails._id}
                    preview={true}
                    manifestscope={manifestdetails.manifestscope}
                >
                 </MarketManifestModal>
            }
            {manifestdetails?._id!=undefined&&
                <PlatformManifestModal
                    open={showPlatformPreview}
                    handleClose={()=>{setShowPlatfromPreview(false)}}
                    appName={manifestdetails.manifestname}
                    iconSrc={manifestdetails.favicon}
                    onInstall={()=>{}}
                    onPreview={()=>{}}
                >
                </PlatformManifestModal>
            }
        </div>
    )

}

export default ManifestDetails;