import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLoaderData, useLocation, useParams } from "react-router-dom";
import AppContainer from "../AppContainer";
import { 
            jsoncompare, 
            selectActiveRoute, 
            selectFocusPath,
            selectSeoProps
        } from "../../../Services/apptemplates/apptemplates.slice";
import { useEffect, useState } from "react";
import {findIndex} from "lodash";


const handeSlashesInPath = (path)=>{
    if(!path.startsWith("/")){
        path = "/"+path;
    }
    if(!path.endsWith("/")){
        path = path+"/"
    }

    return path;

}

const checkleadingslash = (path)=>{
    if(path.startsWith("/")){
        return true;
    }else{
        return false;
    }
}

const checktrailingslash = (currentpath)=>{
    if(currentpath.endsWith("/")){
        return true;
    }else{
        return false;
    }
}

function useMetaTags(
                        metaTags, 
                        absolutepath, 
                        locationpath,
                        indesign,
                        workspace,
                        type,
                        __source,
                        __id
                    ) {
    useEffect(() => {
      if (!metaTags) return;
      let elmpath = absolutepath;
      if(indesign){
        if(checkleadingslash(absolutepath)){
            elmpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id+absolutepath;
        }else{
            elmpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id+"/"+absolutepath;
        }
      } 
      elmpath = handeSlashesInPath(elmpath);
      locationpath = handeSlashesInPath(locationpath);
      if(elmpath!=locationpath){
            return;
      }
      const defaultMetaTags = [
        {
            "name": "viewport",
            "content": "width=device-width, initial-scale=1"
        },
        {
            "name": "charset",
            "content": "utf-8"
        },
        {
            "name": "theme-color",
            "content": "#000000"
        }
      ]
      for(let i=0; i<metaTags.length; i++){
        let metaTag = document.querySelector(`meta[name="${metaTags[i].name}"]`);
        let isDefault = findIndex(defaultMetaTags, (t)=>{return t.name==metaTags[i].name});
        if(isDefault==-1){
            if(!metaTag){
                metaTag = document.createElement("meta");
                metaTag.name = metaTags[i].name;
                document.head.appendChild(metaTag);
            }
            metaTag.content = metaTags[i].content;
        }
      }

      // Cleanup: Remove tags that are no longer present in the metaTags prop
      
      const existingTags = document.querySelectorAll("meta[name]");
      existingTags.forEach((existingTag) => {
        const tagName = existingTag.getAttribute("name");
        let tagindex = findIndex(metaTags, (t)=>{return t.name==tagName});
        if(tagindex==-1){
            let isdefaultTag = findIndex(defaultMetaTags, (t)=>{return t.name==tagName});
            if(isdefaultTag==-1){
                existingTag.remove();
            }
        }
      });
    }, [metaTags]);
  }

const RouteElement = ({
    elm,
    rendertype,
    preview,
    elmkey,
    position,
    absolutepath,
    indesign
})=>{

    const routeparams = useParams();

    const {
            workspace,
            type,
            __source,
            __id
          } = useParams();

    const seoprops = useSelector((state)=>{
                                            return selectSeoProps(
                                                                    state,
                                                                    {schema: elm.seoprops, schemamapping: elm.seopropsmapping, key:elmkey},
                                                                    routeparams,
                                                                    elmkey,
                                                                    position
                                                                )
                                          })
    const location = useLocation();
    
    useEffect(()=>{
        if(seoprops!=undefined&&seoprops.input!=undefined&&seoprops.input.title!=undefined&&seoprops.input.title!=""){
            
            let pathname = location.pathname;
            let elmpath = absolutepath;
            if(indesign){
                if(checkleadingslash(absolutepath)){
                    elmpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id+absolutepath;
                }else{
                    elmpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id+"/"+absolutepath;
                }
            }
            elmpath = handeSlashesInPath(elmpath);
            pathname = handeSlashesInPath(pathname);
            if(elmpath==pathname){
                document.title = seoprops.input.title;
            }
        }
    },[seoprops])

    useMetaTags(
                seoprops?.input.metatags, 
                absolutepath, 
                location.pathname,
                indesign,
                workspace,
                type,
                __source,
                __id
              );

    return (
        <AppContainer
            templateid={elm.templateid}
            indexarr={[]}
            elmkey={""}
            type={rendertype}
            isroute={true}
            preview={preview}
            absolutepath={absolutepath}
        >
        </AppContainer>
    )
}

const RouterContainer = ({
    element,
    type,
    elmFocus,
    elmActive,
    preview,
    elmkey,
    position,
    absolutepath,
    indesign
})=>{

    // const [elmFocus, setElmFocus] = useState(false);

    // const [elmActive, setElmActive] = useState(false);

    // test -> /test/
    //
    const getRoutes = ()=>{
        if(element!=undefined&&element!=null){
            let routes = [];
            for(let i=0; i< element.routes.length; i++){
                let path = element.routes[i].path;
                for(let j=0; j< element.routes[i].params.length; j++){
                    if(path.endsWith("/")){
                        path = path+ ":"+element.routes[i].params[j]+"/"
                    }else{
                        path = path+ "/:"+element.routes[i].params[j]+"/"
                    }
                }

                // path validation 
                // if(){

                // }

                if(element.routes[i].template!=""){
                    routes.push({
                        "path": path,
                        "templateid": element.routes[i].template,
                        "seoprops": element.routes[i].seoprops,
                        "seopropsmapping": element.routes[i].seopropsmapping,
                        title: element.routes[i].title
                    })
                }
            }
            return routes;
        }else{
            return [];
        }
    }

    // const dispatch = useDispatch();

    // const [activeRoute, setActiveRoute] = useState(null);

    let location = useLocation();

    const {__source, __id, workspace} = useParams();

    useEffect(()=>{
        if(location.pathname!=""){
            let designerpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id;
            let path = location.pathname;
            let dpath = path.slice(designerpath.length);
            let routerpaths = getRoutes();
            let pathfound = false;
            let pathindex = null;
            for(let i=0; i< routerpaths.length; i++){
                if(dpath==routerpaths[i].path){
                    pathfound = true;
                    pathindex = i;
                    break;
                }
            }
            if(pathfound){
                // if(activeRoute!=pathindex){
                //     setActiveRoute(pathindex);
                // }
            }else{
                // if(activeRoute!=null){
                //     setActiveRoute(null);
                // }
            }
        }
    },[location])

    // const routeactive = useSelector(selectActiveRoute);

    const checkleadingslash = (path)=>{
        if(path.startsWith("/")){
            return true;
        }else{
            return false;
        }
    }

    const checktrailingslash = (currentpath)=>{
        if(currentpath.endsWith("/")){
            return true;
        }else{
            return false;
        }
    }
    
    return (
            <div
                id="router-container"
            >
                <Routes>
                    {getRoutes().map((route, index)=>{
                        return (
                                <Route 
                                    path={route.path}
                                    element={<RouteElement
                                                    indesign={indesign}
                                                    preview={preview}
                                                    elm={route}
                                                    rendertype={type}
                                                    elmkey={elmkey}
                                                    position={position}
                                                    absolutepath={(checkleadingslash(route.path)||checktrailingslash(absolutepath))?absolutepath+route.path:absolutepath+"/"+route.path}
                                            ></RouteElement>}>
                                </Route>                            
                        )
                    })}
                </Routes>
            </div>
            
    )

}


export default RouterContainer;