
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const SingleFieldPreview = ({inputtype,inputlabel,optionlist,relationdata})=>{


    return (
        <div style={{width: "100%"}}>
                    {inputtype=="string"&&
                        <div>
                            <TextField fullWidth id="outlined-basic" label={inputlabel} variant="outlined" />
                        </div>
                    }
                    {inputtype=="text"&&
                        <div>
                            <TextField
                                id="standard-multiline-static"
                                label={inputlabel}
                                multiline
                                fullWidth
                                rows={4}
                                defaultValue="Default Value"
                                variant="outlined"
                            />
                        </div>
                    }
                    {inputtype=="integer"&&
                        <div>
                            <TextField fullWidth id="outlined-basic" label={inputlabel} variant="outlined" type="number"/>
                        </div>
                    }
                    {inputtype=="number"&&
                        <div>
                            <TextField fullWidth id="outlined-basic" label={inputlabel} variant="outlined" type="number"/>
                        </div>
                    }
                    {inputtype=="enum"&&
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="input-type-select-label">{inputlabel}</InputLabel>
                                    <Select
                                        labelId="input-type-select-label"
                                        id="input-type-select-label"
                                    >
                                    {optionlist.map((it)=>{
                                        return (
                                            <MenuItem value={it.value}>{it.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    {inputtype=="datetime"&&
                        <div>
                            <input type="datetime"></input>
                        </div>
                    }
                    {inputtype=="one_to_one_relation"&&
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="input-type-onetoonerelation">{inputlabel}</InputLabel>
                                <Select
                                    labelId="input-type-onetoonerelation"
                                    id="input-type-onetoonerelation"
                                >
                                    <MenuItem value="choose_field">Choose Field</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }
        </div>
    )
}


export default SingleFieldPreview;