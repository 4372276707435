import { useEffect } from "react";
import { useParams } from "react-router-dom";



const TestComponent = ()=>{

    const {id} = useParams();

    // useEffect(()=>{
    //     console.log("mount");
    //     return ()=>{
    //         console.log("unmount");
    //     }
    // },[id])

    useEffect(()=>{
        return ()=>{
            console.log("unmount");
        }
    },[])

    useEffect(()=>{
        console.log("mount");
    },[])

    return (
        <>
            <div>
                <a href="/">goooooooo</a>
            </div>
        </>
    )

}


export default TestComponent;