
import { useState , useEffect} from "react"
import "./ConditionalStageBlock.css";
import { FormControl, IconButton, TextField } from "@mui/material";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Output from "../../apps/Output/Output";
import {conditionalActions} from "../../Settings";
import { useDispatch, useSelector } from "react-redux";
import {
    selectStage,
    selectTestActionOutput,
    setStage,
    getVariablePipeline,
    testAction,
    createNextStage,
    setmappingkey
} from "../../../Services/pipelines/pipeline.slice";
import * as _ from "lodash";
import Button from '@mui/material/Button';
import {getOutput} from "../../utils";


export const ValuetoCompareField = ({
                                i,
                                j,
                                setConstValuetocompare,
                                setGetValuetocompare,
                                pipelinestage,
                                pipelinetype,
                                getValueToCompare,
                                source,
                                templateid
                            })=>{

    const [openFieldSelect, setOpenfieldSelect] = useState(false);

    const closeFieldSelector = ()=>{
        setOpenfieldSelect(false)
    }

    return (
        <>
            <div>
                <div>
                    <div>
                        <TextField
                            sx={{minWidth: 100}} 
                            value={getValueToCompare(i,j)} 
                            onClick={()=>{setOpenfieldSelect(true)}}
                            onChange={(e)=>{setConstValuetocompare(i,j,e.target.value)}}    
                        ></TextField>
                    </div>
                    <div style={{position: "relative"}}>
                        {openFieldSelect&&
                            <div style={{
                                            position: "absolute", 
                                            background: "#fff", 
                                            width: "100%",
                                            zIndex: 1
                                        }}>
                                <Output
                                    type="any"
                                    setschemaMapping={setGetValuetocompare(i,j)}
                                    closeFieldSelector={closeFieldSelector}
                                    mapping=""
                                    pipelinestage={pipelinestage}
                                    pipelinetype={pipelinetype}
                                    source={source}
                                    templateid={templateid}
                                ></Output>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export const ValueCompareToField = ({
                                        i, 
                                        j, 
                                        getValueCompareTo, 
                                        setConstValueCompareTo, 
                                        setGetValueCompareTo, 
                                        pipelinestage, 
                                        pipelinetype,
                                        source,
                                        templateid
                                    })=>{

    const [openFieldSelect, setOpenfieldSelect] = useState(false);

    const closeFieldSelector = ()=>{
        setOpenfieldSelect(false)
    }

    return (
        <>
            <div>
                <div>
                    <TextField
                        sx={{minWidth: 100}}
                        value={getValueCompareTo(i,j)} 
                        onClick={()=>{setOpenfieldSelect(true)}}
                        onChange={(e)=>{setConstValueCompareTo(i,j,e.target.value)}}
                    ></TextField>
                </div>
                <div style={{position: "relative"}}>
                    {openFieldSelect&&
                        <div style={{
                                        position: "absolute", 
                                        background: "#fff", 
                                        width: "100%",
                                        zIndex: 1
                                    }}>
                            <Output
                                type="any"
                                setschemaMapping={setGetValueCompareTo(i,j)}
                                closeFieldSelector={closeFieldSelector}
                                mapping=""
                                pipelinetype={pipelinetype}
                                pipelinestage={pipelinestage}
                                source={source}
                                templateid={templateid}
                            >
                            </Output>
                        </div>   
                    }
                </div>
                
            </div>
        </>
    )
}

const ConditionalStageBlock = ({
                                    pipelinetype,
                                    pipelinestage,
                                    index,
                                    stage,
                                    isLog,
                                    source,
                                    templateid
                                })=>{

    const dispatch = useDispatch();
    

    const actionOutput = useSelector(selectTestActionOutput);

    useEffect(()=>{
        if(actionOutput==null){
            return
        }
        if(stage.stageindex==actionOutput.stageindex){
            setDisplayoutput(true);
        }        
    },[actionOutput])

    const setDisplayoutput = (val)=>{
        if(val){
            let stageCopy = {...stage,
                             status: true
                            }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
        }else{
            let stageCopy = {...stage,
                status: undefined
               }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }


    const test = ()=>{
        dispatch(testAction({
            stageindex: pipelinestage.stageindex,
            source: source
        }));
    }

    const setConditions = (conditions)=>{
        let stageCopy = JSON.parse(JSON.stringify(stage));
        stageCopy = {...stage,
                     conditions: conditions
                    }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    // think about how to handle other types
    const getSelectedObjectTypeC = (val)=>{
        return "string";
    }

    const getSelectedObjectTypeP = ()=>{

    }

    const selectCondition = (blockindex, conditionindex, condition)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      condition: condition
                                                     }
        setConditions(conditionsCopy);
    }

    const setGetValuetocompare = (blockindex, conditionindex)=>{
        return (val, type)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
            let allconditions = [...conditionalActions];
            let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type==type})
            let options = [...allconditions[selectedtypeindex].operators];
            if(conditionsCopy[blockindex][conditionindex].valuecompareto?.action!="get"){
                dispatch(setmappingkey({
                    oldkey: "",
                    key: val.schemaval,
                    value: val.value,
                    stageindex:pipelinestage.stageindex, 
                    stagetype: "single"
                }))
            }else{
                dispatch(setmappingkey({
                    oldkey: conditionsCopy[blockindex][conditionindex].valuecompareto.val,
                    key: val.schemaval,
                    value: val.value,
                    stageindex:pipelinestage.stageindex, 
                    stagetype: "single"
                }))
            }
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                          valuetocompare:{"action": "get", "val": val.schemaval},
                                                          options: options,
                                                          "comparetotype": type
                                                         }
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValuetocompare = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
        let allconditions = [...conditionalActions];
        let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type=="string"})
        let options = [...allconditions[selectedtypeindex].operators];
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      valuetocompare:{"action": "const", "val": val},
                                                      options: options,
                                                      "comparetotype": "string"
                                                    }
        setConditions(conditionsCopy);
    }

    const getValueToCompare = (blockindex, conditionindex)=>{
        if(stage.conditions[blockindex][conditionindex].valuetocompare.val){
            return stage.conditions[blockindex][conditionindex].valuetocompare.val;
        }else{
            return ""
        }
        
    }

    const setGetValueCompareTo = (blockindex, conditionindex)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
            if(conditionsCopy[blockindex][conditionindex].valuecompareto?.action!="get"){
                dispatch(setmappingkey({
                    oldkey: "",
                    key: val.schemaval,
                    value: val.value,
                    stageindex:pipelinestage.stageindex, 
                    stagetype: "single"
                }))
            }else{
                dispatch(setmappingkey({
                    oldkey: conditionsCopy[blockindex][conditionindex].valuecompareto.val,
                    key: val.schemaval,
                    value: val.value,
                    stageindex:pipelinestage.stageindex, 
                    stagetype: "single"
                }))
            }
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                            valuecompareto:{"action": "get", "val": val.schemaval}
                                                         }       
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValueCompareTo = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                        valuecompareto:{"action": "const", "val": val}
                                                     }
        setConditions(conditionsCopy);
    }

    const getValueCompareTo = (blockindex, conditionindex)=>{
        if(stage.conditions[blockindex][conditionindex].valuecompareto.val){
            return stage.conditions[blockindex][conditionindex].valuecompareto.val
        }else{
            return "";
        }
    }

    const createnextstage = ()=>{
        dispatch(createNextStage({position: pipelinestage.position}))
    }

    const deleteConditionBlock = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
        conditionsCopy.splice(i,1);
        setConditions(conditionsCopy);
    }

    const deleteCondition = (blockindex, conditionindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
        let conditionBlockCopy= [...conditionsCopy[blockindex]];
        conditionBlockCopy.splice(conditionindex, 1);
        conditionsCopy[blockindex] = conditionBlockCopy;
        setConditions(conditionsCopy);    
    }

    const addConBlock = ()=>{
        if(isLog==undefined){
            let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
            conditionsCopy.push([]);
            setConditions(conditionsCopy);
        }
    }

    const addCondition = (blockindex)=>{
        if(isLog==undefined){
            let conditionsCopy = JSON.parse(JSON.stringify(stage.conditions));
            conditionsCopy[blockindex].push(
                {
                    "valuetocompare":"",
                    "tocompareopen":false,
                    "comparetotype": "any",
                    "options":[],
                    "condition": "",
                    "valuecompareto":"",
                    "comparetoopen":false
                }   
            )
            setConditions(conditionsCopy);
        } 
    }

    return (
        <>
            {stage.conditions.map((conblock, i)=>{
                return(
                    <>
                        {i==0?
                            <div>Only continue if</div>:
                            <div className="or-block-desc">
                                <div>Or continue if</div>
                                {isLog==undefined&&
                                    <div>
                                        <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                            <span class="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>
                                }
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                    <div className="condition-line">

                                        <div>
                                            <ValuetoCompareField
                                                i={i}
                                                j={j}
                                                setConstValuetocompare={setConstValuetocompare}
                                                setGetValuetocompare={setGetValuetocompare}
                                                pipelinestage={pipelinestage}
                                                pipelinetype={pipelinetype}
                                                getValueToCompare={getValueToCompare}
                                                source={source} 
                                                templateid={templateid}                                           
                                            >
                                            </ValuetoCompareField>
                                        </div>
                                        <div>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={con.condition}
                                                    label="Condition"
                                                    onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                                >
                                                    {con.options.map((op)=>{
                                                        return(
                                                            <MenuItem value={op.value}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <ValueCompareToField
                                                i={i}
                                                j={j}
                                                getValueCompareTo={getValueCompareTo}
                                                setConstValueCompareTo={setConstValueCompareTo}
                                                setGetValueCompareTo={setGetValueCompareTo}
                                                pipelinestage={pipelinestage}
                                                pipelinetype={pipelinetype}
                                                templateid={templateid}
                                                source={source}
                                            >
                                            </ValueCompareToField>
                                        </div>
                                        <div className="close-button" onClick={()=>{deleteCondition(i,j)}}>
                                            <span class="material-symbols-outlined">
                                                close
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {i==(stage.conditions.length-1)?
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            Or
                                    </div>
                                </div>
                            </>:
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                </div>
                            </>
                        }
                        <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                    </>
                )
            })}
            {isLog==undefined&&
                <div style={{display: "flex", justifyContent: "center"}}>
                    {stage.status!=undefined&&stage.status!=false?
                        <>
                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                        </>:
                        <>
                            <Button onClick={()=>{test()}} style={{color: "#3A07CD"}}>Test</Button>
                        </>
                    }
                </div>
            }
            
            {getOutput(stage.stageindex)?.result!=null&&getOutput(stage.stageindex)?.result!=undefined&&stage.status!=undefined&&stage.status!=false&&
                <>
                    {getOutput(stage.stageindex).result?
                        <>
                            <div className="condition-result-block">
                                Pipeline will run
                            </div>
                        </>:
                        <>
                            <div className="condition-result-block">
                                Pipeline will not run
                            </div>
                        </>
                    }
                </>
            }
            <div>
            </div>
            {isLog==undefined&&
                <div style={{display: "flex", justifyContent: "center", margin: 10}}>
                    {(getOutput(stage.stageindex)?.result!=undefined)?
                        <>
                            <Button onClick={()=>{createnextstage()}} style={{color: "#3A07CD"}}>Continue</Button>
                        </>:
                        <>
                        </>
                    }
                </div>
            }
        </>
    )
}


export default ConditionalStageBlock;