import {postjson, get, putjson, deleteM} from "../signin/axios";
import { baseURL } from "../../env";

export const registerDomainApi = async(payload)=>{
    let res = await postjson(baseURL+"client/settings/", payload);
    return res;
}

export const getDomainsApi = async(payload)=>{
    let res = await get(baseURL+"client/settings/");
    return res;
}

export const updateDomainApi = async(payload)=>{
    let res = await putjson(baseURL+"client/settings/"+payload.id, payload.body);
    return res;
}

export const deleteDomainApi = async(payload)=>{
    let res = await deleteM(baseURL+"client/settings/"+payload);
    return res;
}
