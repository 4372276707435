import { useEffect, useRef, useState } from "react";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { IconButton, TextField, Tooltip} from "@mui/material";
import Output from "../Output/Output";
import Button from "@mui/material/Button";
import CustomInput from "../CustomInput/CustomInput";
import "./DeclareVariable.css";
import * as _ from "lodash";
import {getSchemaMappingValue,setmappingkey} from "../../../Services/pipelines/pipeline.slice";
import {
            getValfromPipe, 
            getObjectVal, 
            getSchemFromPipe
        } from "../../utils";
import FileField from "./Files";
import { useDispatch, useSelector } from "react-redux";
import {getRandomId} from "../../utils";
import {
        getEnumOptions, 
        selectEnumResults
    } from "../../../Services/utils/utils.slice";
import * as moment from "moment";
import {validatePrimaryTypes, getsubschemamapping} from "./Utils";
import { useParams } from "react-router-dom";
import { setloading } from "../../../Services/environvariables/environvariables.slice";

const SelectTypes = ({val,subtype ,onChange, editallowed})=>{

    const inputtypes = [
        {"name": "object", "value": "object"},
        {"name": "string", "value": "string"},
        {"name": "text", "value": "text"},
        {"name": "integer", "value": "integer"},
        {"name": "number", "value": "number"},
        {"name": "datetime", "value": "datetime"},
        {"name": "boolean", "value": "boolean"},
        {"name": "array[any]", "value": "array"},
        {"name": "any", "value": "any"}
    ]

    const [selectedVal, setSelectedVal] = useState("");


    useEffect(()=>{
        if(val!=""&&val!=undefined&&val!=null){
            if(subtype!=undefined){
                setSelectedVal(val+"["+subtype+"]");
            }else{
                setSelectedVal(val);
            }
            
        }
    },[val, subtype])

    const onSelect = (value)=>{
        onChange(value);
        setSelectedVal(value);
        setOpenOptions(false);
    }

    const [openOptions, setOpenOptions] = useState(false);

    return (
        <div>
            {/* TODO: write a tooltip for change type */}
            <div style={editallowed?{padding: 0, margin: 0, position: "relative", "cursor": "pointer"}:{padding: 0, margin: 0, position: "relative"}}>
                <span style={{padding: 5}} onClick={()=>{
                                                                                    if(editallowed){
                                                                                        setOpenOptions(!openOptions)
                                                                                    }
                                                                                  }}>
                    type: {selectedVal}
                </span>
            </div>
            {openOptions&&
                <div style={{position: "absolute", marginTop: 10, zIndex: 101, "backgroundColor": "#fff", "cursor": "pointer"}}>
                    {inputtypes.map((t)=>{
                        return (
                            <div style={{padding: 5, margin: 0,  border: "1px solid #eee"}}>
                                <span  onClick={()=>{onSelect(t.value)}}>
                                    {t.name}
                                </span>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

const AutomataText = ({
                        error,
                        schemaval,
                        setConstSchemaMapping,
                        openFieldDec,
                        setOpenFieldDec,
                        getInputLabel,
                        editable,
                        teststage
                    })=>{
    const [textFrom, setTextFrom] = useState(0);
    
    
    return(
        <div>
            <div className="input-type-switch">
                <div onClick={()=>{setTextFrom(0)}} className={textFrom==0?"input-type active":"input-type"}>
                    Input
                </div>
                {(teststage==undefined||teststage==false)&&
                    <div onClick={()=>{setTextFrom(1)}} className={textFrom==1?"input-type active":"input-type"}>
                        From Interfaces
                    </div>
                }                                            
            </div>
            <div>
                {textFrom==0&&
                    <>
                        <TextField
                            multiline
                            rows={4}
                            error={error!=""} 
                            variant="outlined" 
                            fullWidth 
                            value={schemaval} 
                            onChange={(e)=>{setConstSchemaMapping(e.target.value)}} 
                            onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                            label={getInputLabel()}
                        ></TextField>
                    </>
                }
            </div>
        </div>
    )
}

// open create schema -> open schema mapping -> modify schema mapping

export const DataValueContainer = ({
                                sc, 
                                schemaMapping,
                                openaddschema,
                                toggleSubSchema,
                                toggleAddSChema, 
                                subschemaopen,
                                osmallowed,
                                pipelinetype,
                                stageindex,
                                stagetype,
                                layout,
                                teststage,
                                modifySchema,
                                editable,
                                addtoschema,
                                closeAddSchema,
                                pipelinestage,
                                source,
                                templateid,
                                indexarr,
                                sourceid,
                                parentschema,
                                parentschemamapping
                            })=>{
                    

    const {workspace, appid} = useParams();

    const [openFieldDec, setOpenFieldDec] = useState(false);

    const [actionsToggle, setActionsToggle] = useState(false);
    
    const [schemaval, setschemaval] = useState("");

    const [schemavalget, setschemavalget] = useState("");

    const dispatch = useDispatch();

    const [inputval ,setInputval] = useState("");

    const [error, setError] = useState("");

    const [textboxexpanded, settextboxexpanded] = useState(false);

    // const inputvalue = useSelector(getSchemaMappingValue(schemavalget));

    // 0 -> Const value , 1-> value from pipelinee
    const [objectInputMethod, setObjectInputMethod] = useState(0);

    const [actionActive, setActionActive] = useState(false);

    const setSchemaMappingData = ()=>{
        return (val)=>{
            if(val.type=="pipeline"){
                
            }else{
                let mappingCopy = {};

                if(schemaMapping.mapping?.action!="get"){

                    dispatch(setmappingkey({
                                             oldkey: "",
                                             key: val.schemaval,
                                             stageindex: stageindex,
                                             stagetype: stagetype
                                            }
                                           ))

                }else{
                    dispatch(setmappingkey({
                                            oldkey: schemaMapping.mapping.val, 
                                            key:val.schemaval,
                                            stageindex:stageindex, 
                                            stagetype: stagetype
                                          }))

                }

                if(sc.required){
                    mappingCopy = {...mappingCopy,
                                    "action":"get",
                                    "val": val.schemaval
                                  }
                }else{
                    mappingCopy = {...mappingCopy,
                                    "action": "get",
                                    "val": val.schemaval
                                  }
                }
                let schemaMappingCopy = {...schemaMapping,
                                        mapping: mappingCopy
                                        };
                let scCopy = {...sc};
                if(sc.dispatchaction&&sc.dispatchactioncompleted){
                    scCopy = {...scCopy,
                              dispatchactioncompleted: false
                             }
                }
                modifySchema({...scCopy},schemaMappingCopy);
                setschemavalget(val.schemaval);
                setInputval(val.value);
            }
            
        }
    }

    const setConstSchemaMapping = (value)=>{
        let mappingCopy = {};
        if(schemaMapping?.mapping?.action=="get"){
            value = "";
            mappingCopy = {
                            ...mappingCopy,
                            "action": "const",
                            "val": ""
                          }
            let schemaMappingCopy = {
                                        ...schemaMapping,
                                        mapping: mappingCopy
                                    };
            modifySchema({...sc},schemaMappingCopy);
        }
        if(value==""){
            setschemaval(value);
            setInputval(null);
            mappingCopy = {...mappingCopy,
                "action": "const",
                "val": ""
                }
            let schemaMappingCopy = {...schemaMapping,
                mapping: mappingCopy
                };
            modifySchema({...sc},schemaMappingCopy);
        }else{
            setschemaval(value);
            setOpenFieldDec(false);
            let val;
            if(sc.type=="string"||sc.type=="enum"){
                mappingCopy = {...mappingCopy,
                                "action": "const",
                                "val": value
                              }
                let schemaMappingCopy = {
                                         ...schemaMapping,
                                         mapping: mappingCopy
                                        };
                let scCopy = {...sc};
                if(sc.transformaction&&sc.transformactioncompleted){
                    scCopy = {
                              ...scCopy,
                              transformactioncompleted: false
                             }
                }
                modifySchema(
                                {...scCopy},
                                schemaMappingCopy
                            );
                setInputval(value);
                setError("");
            }
            else if(sc.type=="text"){
                mappingCopy = {...mappingCopy,
                    "action": "const",
                    "val": value
                    }
                let schemaMappingCopy = {...schemaMapping,
                    mapping: mappingCopy
                    };
                modifySchema({...sc},schemaMappingCopy);
                setInputval(value);
                setError("");
            }
            else if(sc.type=="number"){
                try{
                    let val = parseFloat(value);
                    if(val==null||isNaN(val)){
                        setError("TypeError");
                        setInputval(value);
                    }else{
                        mappingCopy = {...mappingCopy,
                            "action": "const",
                            "val": value
                            }
                        let schemaMappingCopy = {...schemaMapping,
                            mapping: mappingCopy
                            };
                        modifySchema({...sc},schemaMappingCopy);
                        setInputval(val);
                        setError("");
                    }
                }catch(error){
                    setError("TypeError");
                    setInputval(value);
                }
            }
            else if(sc.type=="integer"){
                let val = parseInt(value);
                if(val==null||isNaN(val)){
                    setError("TypeError");
                    setInputval(value);
                }else{
                    mappingCopy = {...mappingCopy,
                        "action": "const",
                        "val": value
                        }
                    let schemaMappingCopy = {...schemaMapping,
                        mapping: mappingCopy
                        };
                    modifySchema({...sc},schemaMappingCopy);
                    setInputval(val);
                    setError("");
                }
            }
            else if(sc.type=="boolean"){
                if(value=="false"||value=="true"){
                    mappingCopy = {...mappingCopy,
                        "action": "const",
                        "val": value
                        }
                    let schemaMappingCopy = {...schemaMapping,
                        mapping: mappingCopy
                        };
                    modifySchema({...sc},schemaMappingCopy);
                    setInputval(value);
                    setError("");
                }else{
                    setError("TypeError");
                    setInputval(value);
                }

            }
            else if(sc.type=="datetime"){
                try{
                    if(value!=""){
                        let valid = moment(value).isValid();
                        if(valid){
                            // let val = moment(value).format();
                            mappingCopy = {...mappingCopy,
                                "action": "const",
                                "val": value
                                }
                            let schemaMappingCopy = {...schemaMapping,
                                mapping: mappingCopy
                                };
                            modifySchema({...sc},schemaMappingCopy);
                            setInputval(value);
                            setError("");
                        }else{
                            setError("TypeError");
                            setInputval(value);
                        }
                    }else{
                        setInputval(value);
                        mappingCopy = {...mappingCopy,
                            "action": "const",
                            "val": ""
                            }
                        let schemaMappingCopy = {...schemaMapping,
                            mapping: mappingCopy
                            };
                        modifySchema({...sc},schemaMappingCopy);          
                    }
                }catch(error){
                    
                }
            }else if(sc.type=="any"){
                mappingCopy = {...mappingCopy,
                    "action": "const",
                    "val": value
                    }
                let schemaMappingCopy = {...schemaMapping,
                    mapping: mappingCopy
                    };
                modifySchema({...sc},schemaMappingCopy);
                setInputval(value);
                setError("");                
            }
        }
    }

    //change schema type
    const changeSchemaType = (schematype)=>{
        if(schematype=="array"){
            let schemaCopy = {...sc,
                              type: "array",
                              subschema:[
                                {
                                    "type": "any",
                                    "subschema":[]
                                }
                               
                              ]
                             };
            let schemaMappingCopy = {...schemaMapping,
                                     mapping: ""
                                    };
            modifySchema(schemaCopy, schemaMappingCopy);
                        
        }else{
            let schemaCopy = {...sc,
                              "type": schematype,
                              subschema:[]
                             };
            let schemaMappingCopy = {...schemaMapping,
                                     mapping: ""
                                    }
            modifySchema(schemaCopy, schemaMappingCopy);
        }
    }

    const modifyDataValueSchema = (sch, schm)=>{
        let schemaCopy = {...sc,
                          subschema: sch
                         };
        let schemamappingCopy = {...schemaMapping,
                                 mapping: schm
                                };

        modifySchema(schemaCopy, schemamappingCopy);
    }

    useEffect(()=>{
        let sm = schemaMapping;
        if(sm?.mapping?.val!==undefined){
            setschemaval(sm.mapping.val);
            if(sm.mapping.action=="const"){
                setInputval(sm.mapping.val);
                if(objectInputMethod!==0){
                    setObjectInputMethod(0);
                }
            }
            if(sm.mapping.action=="get"){      
                setschemavalget(sm.mapping.val);
                if(objectInputMethod!==1){
                    setObjectInputMethod(1);
                }
                
            }
        }else{
            setInputval("");
            setschemavalget("");
            setschemaval("");
        }
        setError("");
    },[openFieldDec,sc, schemaMapping])

    useEffect(()=>{
        if(openaddschema){
            setActionActive("newschema")
        }else{
            setActionActive("")
        }
    },[openaddschema])

    useEffect(()=>{
        if(subschemaopen){
            setActionActive("expand")
        }else{
            setActionActive("")
        }
    },[subschemaopen])

    const toggleSubSchemaInternal = ()=>{
        toggleSubSchema();
        setActionsToggle(!actionsToggle);
    }

    const toggleAddSChemaInternal = ()=>{
        toggleAddSChema();
        setActionsToggle(!actionsToggle);
    }

    const getInputLabel = ()=>{
        if(sc.type=="datetime"){
            if(
                (sc.default===""&&sc.help=="")||
                (sc.default==undefined&&sc.help==undefined)||(sc.default===""&&sc.help==undefined)||(sc.default==undefined&&sc.help=="")){
                return "input format 'YYYY-MM-DD hh:mm:ss.z'"
            }else if(sc.default===""||sc.default==undefined){
                return sc.help+ " input format 'YYYY-MM-DD hh:mm:ss.z'"
            }else if(sc.help===""||sc.help==undefined){
                return sc.default+ " input format 'YYYY-MM-DD hh:mm:ss.z'"
            }else{
                return sc.help+" defaults to "+sc.default+" input format 'YYYY-MM-DD hh:mm:ss.z'";
            }
        }else{
            if(
                (sc.default===""&&sc.help=="")||
                (sc.default==undefined&&sc.help==undefined)||(sc.default===""&&sc.help==undefined)||(sc.default==undefined&&sc.help=="")){
                return ""
            }else if(sc.default===""||sc.default==undefined){
                return sc.help
            }else if(sc.help===""||sc.help==undefined){
                return sc.default
            }else{
                return sc.help+" defaults to "+sc.default;
            }
        }   
    }

    useEffect(()=>{
        if(teststage==true){
            setActionsToggle(true);
        }
    },[teststage])

    // handle enums with http options
    const [enumOptions, setEnumOptions] = useState([]);

    const [enumId, setEnumId] = useState("");

    const enumoptionresult = useSelector(selectEnumResults(enumId));

    useEffect(()=>{
        if(enumId!=""&&enumoptionresult!=undefined){
            setEnumOptions([...enumoptionresult]);
        }
    },[enumoptionresult])

    useEffect(()=>{
        if(sc.type=="enum"){
            if(sc.optiontype=="http"&&sc.optionsurl!=""){
                let enumId = getRandomId();
                setEnumId(enumId);
                dispatch(getEnumOptions(
                                            {
                                                id: enumId, 
                                                url: sc.optionsurl, 
                                                data: {workspace: workspace, appid: appid},
                                                type: "http"
                                            }
                                        ))
            }else{
                setEnumOptions(sc.options);
            }
        }
    },[sc])

    return (
        <>
            <div>
                <div className="insert-data-tile">
                    <div className={subschemaopen?"data-label-dec":"data-label-dec"}>
                        {sc.label!=undefined?sc.label:sc.key}
                        {sc.required&&
                            <>
                                *
                            </>
                        }
                        {sc.type=="object"&&(teststage==undefined||teststage==false)&&sc.editallowed&&
                            <div className={actionActive=="newschema"?"data-action-toggles-button":"data-action-toggles-button"}>
                                <Tooltip title="modify schema">
                                    <IconButton onClick={()=>{toggleAddSChemaInternal()}}>
                                        <span className="material-symbols-outlined">
                                            add
                                        </span>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        {(teststage==undefined||teststage==false)&&
                            <div className={(actionActive=="expand")?"data-action-toggles-button":"data-action-toggles-button"}>
                                {actionActive=="expand"?
                                    <Tooltip title="expand schema">
                                        <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                            <span className="material-symbols-outlined">
                                                unfold_more_double
                                            </span>
                                        </IconButton>
                                    </Tooltip>:
                                    <Tooltip title="expand schema">
                                    <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                        <span className="material-symbols-outlined">
                                            unfold_less_double
                                        </span>
                                    </IconButton>
                                </Tooltip>
                                }
                            </div>
                        }
                    </div>
                    <div>
                        <div className="decalare-variable-data-type">
                            {
                                <SelectTypes
                                    val={sc.type}
                                    editallowed={sc.typechange}
                                    onChange={changeSchemaType}
                                >
                                </SelectTypes>
                            }
                            {(source=="apps"||source=="apptemplates"||source=="installmanifest")&&
                                <>
                                    {sc.type=="object"?
                                        <span style={{display: "flex", alignItems: "center"}}>
                                            {schemaMapping!=undefined&&
                                                <>
                                                    input: <pre
                                                                className="files-input-display"
                                                            >
                                                                {JSON.stringify(getObjectVal(sc.subschema,schemaMapping,{}), null, " ")}
                                                            </pre>
                                                </>
                                            }
                                            
                                        </span>:
                                        <span style={{display: "flex", alignItems: "center"}}>
                                            input: <pre 
                                                        className="files-input-display"
                                                    >
                                                        {JSON.stringify(getObjectVal(sc, schemaMapping, {}), null, " ")}
                                                    </pre>
                                        </span>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    {openaddschema&&(source=="apps"||source=="apptemplates"||source=="installmanifest"||source=="texttemplates")&&
                        <div className="add-subschema">
                            <CustomInput 
                                close={closeAddSchema} 
                                addtoschema={addtoschema} 
                                initialSchema={sc.subschema}
                                source={source}
                            >
                            </CustomInput>
                        </div>
                    }
                    {openaddschema&&(source=="designerprops"||source=="appvariables")&&
                        <div className="designer-add-subschema">
                            <CustomInput
                                close={closeAddSchema}
                                addtoschema={addtoschema}
                                initialSchema={sc.subschema}
                                source={source}
                            >
                            </CustomInput>
                        </div>

                    }
                    <div className="data-example">
                        {subschemaopen?
                            <>
                                {(sc.subschema.length>0&&subschemaopen)?
                                    <>
                                        <div className="input-type-switch">
                                            <div onClick={()=>{setObjectInputMethod(0)}} className={objectInputMethod==0?"input-type active":"input-type"}>
                                                Input
                                            </div>
                                            {(teststage==undefined||teststage==false)&&
                                                <div onClick={()=>{setObjectInputMethod(1)}} className={objectInputMethod==1?"input-type active":"input-type"}>
                                                    From Pipeline
                                                </div>
                                            }
                                            
                                        </div>
                                        {objectInputMethod==0&&
                                            <InsertSchemaContainer 
                                                schema={sc.subschema} 
                                                schemaMapping={schemaMapping.mapping}
                                                pipelinetype={pipelinetype}
                                                stagetype={stagetype}
                                                layout={layout}
                                                modifySchema={modifyDataValueSchema}
                                                editable={editable}
                                                pipelinestage={pipelinestage}
                                                teststage={teststage}
                                                source={source}
                                                templateid={templateid}
                                                indexarr={indexarr}
                                                sourceid={sourceid}
                                            ></InsertSchemaContainer>
                                        }
                                        {objectInputMethod==1&&
                                            <TextField
                                                error={error!=""}
                                                variant="outlined"
                                                fullWidth
                                                value={schemaval}
                                                onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                                label={getInputLabel()}
                                            >
                                            </TextField>
                                        }
                                        {(openFieldDec&&(teststage==false||teststage==undefined))&&(source=="apps"||source=="apptemplates"||source=="appprops"||source=="installmanifest")&&
                                            <Output 
                                                closeFieldSelector={setOpenFieldDec} 
                                                type={sc.type} 
                                                subschematobefilled={sc.subschema}
                                                setschemaMapping={setSchemaMappingData()}  
                                                pipelinetype={pipelinetype}
                                                pipelinestage={pipelinestage}  
                                                source={source}
                                                templateid={templateid}
                                                indexarr = {indexarr}
                                            ></Output>
                                        }
                                    </>:
                                    <>
                                        {osmallowed&&sc.type!="enum"&&sc.type!="file"&&sc.type!="text"&&sc.type!="object"&&sc.type!="string"&&
                                            <TextField
                                                error={error!=""} 
                                                variant="outlined" 
                                                fullWidth 
                                                value={schemaval} 
                                                onChange={(e)=>{setConstSchemaMapping(e.target.value)}} 
                                                onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                                label={getInputLabel()}
                                            ></TextField>
                                        }
                                        {osmallowed&&sc.type=="string"&&
                                            <>
                                                {textboxexpanded?
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <TextField
                                                            error={error!=""} 
                                                            variant="outlined" 
                                                            fullWidth
                                                            multiline
                                                            rows={6} 
                                                            value={schemaval} 
                                                            onChange={(e)=>{setConstSchemaMapping(e.target.value)}} 
                                                            onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                                            label={getInputLabel()}
                                                        ></TextField>
                                                        <IconButton onClick={()=>{settextboxexpanded(false)}}>
                                                            <span className="material-symbols-outlined">
                                                                unfold_less
                                                            </span>
                                                        </IconButton>
                                                    
                                                    </div>:
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <TextField
                                                            error={error!=""} 
                                                            variant="outlined" 
                                                            fullWidth 
                                                            value={schemaval} 
                                                            onChange={(e)=>{setConstSchemaMapping(e.target.value)}} 
                                                            onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                                            label={getInputLabel()}
                                                        ></TextField>
                                                        <IconButton onClick={()=>{settextboxexpanded(true)}}>
                                                            <span className="material-symbols-outlined">
                                                                unfold_more
                                                            </span>
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>

                                        }
                                        {osmallowed&&sc.forcevisible&&
                                            <TextField
                                                error={error!=""} 
                                                variant="outlined" 
                                                fullWidth 
                                                value={schemaval} 
                                                onChange={(e)=>{setConstSchemaMapping(e.target.value)}} 
                                                onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                                label={getInputLabel()}
                                            ></TextField>
                                        }
                                        {osmallowed&&sc.type=="text"&&
                                            <>
                                                <div>
                                                    <AutomataText
                                                        error={error}
                                                        schemaval={schemaval}
                                                        setConstSchemaMapping={setConstSchemaMapping}
                                                        openFieldDec={openFieldDec}
                                                        setOpenFieldDec={setOpenFieldDec}
                                                        getInputLabel={getInputLabel}
                                                        editable={editable}
                                                        teststage={teststage}
                                                        pipelinestage={pipelinestage}
                                                    >
                                                    </AutomataText>
                                                </div>
                                            </>
                                        }
                                        {osmallowed&&sc.type=="enum"&&
                                            <FormControl fullWidth>
                                                <Select
                                                    value={schemaval}
                                                    onChange={(e)=>{setConstSchemaMapping(e.target.value)}}
                                                >
                                                    {enumOptions.map((option)=>{
                                                        return(
                                                            <MenuItem value={option.value}>{option.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        }
                                        {(openFieldDec&&
                                         (teststage==false||teststage==undefined))&&
                                         (source=="apps"||source=="apptemplates"||source=="appprops"||source=="installmanifest")&&
                                            <Output 
                                                closeFieldSelector={setOpenFieldDec} 
                                                type={sc.type} 
                                                setschemaMapping={setSchemaMappingData()}  
                                                pipelinetype={pipelinetype}
                                                pipelinestage={pipelinestage}  
                                                source={source}
                                                templateid={templateid}
                                                indexarr = {indexarr}
                                            ></Output>
                                        }
                                    </>
                                }
                            </>:
                            <>
                            </> 
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const   ArrayElementInput = ({
                                i,  
                                getArrayElementVal,
                                val, 
                                setArrayElementVal, 
                                setArrayElementValGet, 
                                type, 
                                pipelinetype, 
                                editable, 
                                accessPipeline,
                                pipelinestage,
                                teststage,
                                source,
                                templateid,
                                indexarr,
                                sourceid
                            })=>{

    const [openFieldDec, setOpenFieldDec] = useState(false);

    const [error, setError] = useState(false);
    
    const setArrayElementValAction = (val)=>{
        if(openFieldDec){
            setOpenFieldDec(false)
        }
        let valid = validatePrimaryTypes(val, type);
        if(valid.valid){
            setError(false)
        }else{
            setError(true)
        }
        setArrayElementVal(i, val);
    }

    const setSchemaMappingInternal = ()=>{
        return (val)=>{
            setError(false)
            setArrayElementValGet(i, val)
        }
    }

    const getInputLabel = ()=>{
        if(type=="datetime"){
            return "input format 'YYYY-MM-DD hh:mm:ss'"
        }else{
            return "";
        }
    }

    return (
        <div>
            <TextField
                error={error}
                onClick={()=>{setOpenFieldDec(true)}}
                variant="outlined"
                fullWidth
                value={val}
                onChange={(e)=>{setArrayElementValAction(e.target.value)}}
                editable={editable}
                label={getInputLabel()}
            ></TextField>
            {openFieldDec&&teststage!=true&&(source=="apps"||source=="apptemplates"||source=="appprops"||source=="installmanifest")&&
                <Output
                    closeFieldSelector={setOpenFieldDec} 
                    type={type} 
                    pipeline={accessPipeline}
                    setschemaMapping={setSchemaMappingInternal()}
                    pipelinetype={pipelinetype}
                    pipelinestage={pipelinestage}
                    source={source}
                    templateid={templateid}
                    indexarr = {indexarr}
                >
                </Output>
            }
        </div>
    )
}

const DataArrayContainer = ({
    pipelinetype,
    stageindex,
    stagetype,
    layout,
    teststage,
    editable,
    pipelinestage,
    source,
    templateid,
    indexarr,
    sc, 
    schemaMapping,
    modifySchema,
    sourceid
})=>{

    const [openFieldDec, setOpenFieldDec] = useState(false);

    const [schemaval, setschemaval] = useState("");
    
    const [schemavalget, setschemavalget] = useState("");
    
    const dispatch = useDispatch();

    const [inputval ,setInputval] = useState([]);

    const [submappings, setSubmappings] = useState([]);

    // 0 -> Const value , 1-> value from pipeline
    const [arrayInputMethod, setArrayInputMethod] = useState(0);

    const [initialized, setInitialized] = useState(false);

    useEffect(()=>{
        if(!initialized){
            let sm = {...schemaMapping};
            if(sm.mapping?.val!=undefined){
                setschemaval(sm.mapping.val);
                if(sm.mapping.action=="const"){
                    setInputval([...sm.mapping.val]);
                    setSubmappings([...sm.mapping.mapping]);
                    if(arrayInputMethod!==0){
                        setArrayInputMethod(0);
                    }
                }
                if(sm.mapping.action=="get"){      
                    setschemavalget(sm.mapping.val);
                    if(arrayInputMethod!==1){
                        setArrayInputMethod(1);
                    }
                }
            }else{
                setInputval([])
                setSubmappings([])
            }
            setInitialized(true)
        }
    },[openFieldDec,sc, schemaMapping])
    
    const setSchemaMappingData = ()=>{
        return (val)=>{
            let mappingCopy = {};
            if(schemaMapping.mapping?.action!="get"){
                dispatch(setmappingkey({
                                            oldkey: "",
                                            key: val.schemaval,
                                            stageindex: stageindex,
                                            stagetype: stagetype
                                        }
                                        ))

            }else{
                dispatch(setmappingkey({
                                            oldkey: schemaMapping.mapping.val, 
                                            key:val.schemaval,
                                            stageindex:stageindex, 
                                            stagetype: stagetype
                                        }))

            }
            if(sc.required){
                mappingCopy = {
                                "action": "get",
                                "val": val.schemaval
                              }
            }else{
                mappingCopy = {"action": "get",
                                "val": val.schemaval
                                }
            }
            let schemaMappingCopy = {...schemaMapping,
                                        mapping: mappingCopy
                                    }
            modifySchema({...sc},schemaMappingCopy);
            setschemavalget(val.schemaval);
            setInputval(val.value);
        }
    }

    const addArrayElement = ()=>{
        // let inputvalCopy = [...inputval];
        // let mappingCopy = [...submappings];
        let inputvalCopy = [];
        let mappingCopy = [];
        if(schemaMapping?.mapping?.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping?.mapping?.val)){
            inputvalCopy = [...schemaMapping.mapping.val];
        }
        if(schemaMapping?.mapping?.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping?.mapping?.mapping)){
            mappingCopy = [...schemaMapping.mapping.mapping];
        }
        let subschema = sc.subschema[0];
        let schemamapping = "";
        if(subschema.type=="object"){
            schemamapping = [];
            getsubschemamapping(subschema.subschema,schemamapping);
            let objVal = getObjectVal(subschema.subschema,schemamapping, {});
            inputvalCopy.push(objVal);
            mappingCopy.push(schemamapping);
        }else if(subschema.type=="array"){
            inputvalCopy.push([]);
            mappingCopy.push("");
        }
        else{
            inputvalCopy.push("");
            mappingCopy.push("");
        }
        let mmCopy = {
                        "action": "const",
                        "val": inputvalCopy,
                        "mapping": mappingCopy

                     }
        let schemaMappingCopy = {...schemaMapping,
                                 mapping: mmCopy
                                }
        modifySchema({...sc},schemaMappingCopy);
    }
    
    const setArrayElementVal = (i, val)=>{
        if(sc.subschema[0].type!="object"){
            let inputvalCopy = [];
            let submappingsCopy = [];
            if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
                inputvalCopy = [...schemaMapping.mapping.val];
            }
            if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
                submappingsCopy = [...schemaMapping.mapping.mapping];
            }
            submappingsCopy[i] = {"action":"const", "val":val}
            inputvalCopy[i] = val;
// /            setSubmappings(submappingsCopy);
            let mmCopy = {
                            "action": "const",
                            "val": inputvalCopy,
                            "mapping": submappingsCopy
                        }
            let schemaMappingCopy = {...schemaMapping,
                                        mapping: mmCopy
                                    }
            modifySchema({...sc},schemaMappingCopy);
            // setInputval(inputvalCopy);
        }
    }

    const setArrayElementValGet = (i, val)=>{
        if(sc.subschema[0].type!="object"){
            let inputvalCopy = [];
            let submappingsCopy = [];
            if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
                inputvalCopy = [...schemaMapping.mapping.val];
            }
            if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
                submappingsCopy = [...schemaMapping.mapping.mapping];
            }
            if(submappingsCopy[i]?.action!="get"){
                dispatch(setmappingkey({
                    oldkey: "",
                    key:val.schemaval,
                    stageindex:stageindex, 
                    stagetype: stagetype
                    }))
            }else{
                dispatch(setmappingkey({
                    oldkey: submappingsCopy[i].val,
                    key:val.schemaval,
                    stageindex:stageindex, 
                    stagetype: stagetype
                    }))
            }
            submappingsCopy[i] = {"action":"get", "val": val.schemaval};
            inputvalCopy[i] = val.value;
            setSubmappings(submappingsCopy);
            let mmCopy = {
                            "action": "const",
                            "val": inputvalCopy,
                            "mapping": submappingsCopy
                            }
            let schemaMappingCopy = {...schemaMapping,
                                        mapping: mmCopy
                                    }
            modifySchema({...sc},schemaMappingCopy);
            setInputval(inputvalCopy);
        }
    }

    const getArrayElementVal = (i)=>{
        let val;
        try{
            val = submappings[i].val;
        }catch(error){
            val=""
        }
        return val;
    }

    const setSchemaMappingIndex = (index)=>{
        return (val)=>{
            if(sc.subschema[0].type=="object"){
                let inputvalCopy = [];
                let submappingsCopy = [];
                if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
                    inputvalCopy = [...schemaMapping.mapping.val];
                }
                if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
                    submappingsCopy = [...schemaMapping.mapping.mapping];
                }
                submappingsCopy[index] = val;
                let objectval = getObjectVal(sc.subschema[0].subschema,[...submappingsCopy[index]],{});
                inputvalCopy[index] = objectval;
                setSubmappings(submappingsCopy);
                let mmCopy = {
                    "action": "const",
                    "val": inputvalCopy,
                    "mapping": [...submappingsCopy]
                }

                let schemaMappingCopy = {...schemaMapping,
                                         mapping: mmCopy
                                        }
                modifySchema({...sc},schemaMappingCopy);
            }
        }
    }

    const modifySchemaIndex = (index)=>{
        return (scc, sm)=>{
            if(sc.subschema[0].type=="object"){
                let inputvalCopy = [];
                let submappingsCopy = [];
                if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
                    inputvalCopy = [...schemaMapping.mapping.val];
                }
                if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
                    submappingsCopy = [...schemaMapping.mapping.mapping];
                }
                submappingsCopy[index] = sm;
                let objectval = getObjectVal(sc.subschema[0].subschema,[...submappingsCopy[index]],{});
                inputvalCopy[index] = objectval;
                setSubmappings(submappingsCopy);
                let mmCopy = {
                    "action": "const",
                    "val": inputvalCopy,
                    "mapping": [...submappingsCopy]
                }
                let schemaMappingCopy = {...schemaMapping,
                                         mapping: mmCopy
                                        }
                modifySchema({...sc},schemaMappingCopy);
            }else if(sc.subschema[0].type=="array"){
                let inputvalCopy = [];
                let submappingsCopy = [];
                if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
                    inputvalCopy = [...schemaMapping.mapping.val];
                }
                if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
                    submappingsCopy = [...schemaMapping.mapping.mapping];
                }
                submappingsCopy[index] = sm;
                let objectval = getObjectVal(sc.subschema[0].subschema,submappingsCopy[index],{});
                inputvalCopy[index] = objectval;
                setSubmappings(submappingsCopy);
                let mmCopy = {
                    "action": "const",
                    "val": inputvalCopy,
                    "mapping": [...submappingsCopy]
                }
                let schemaMappingCopy = {...schemaMapping,
                                         mapping: mmCopy
                                        }
                modifySchema({...sc},schemaMappingCopy);
            }
        }
    }

    const deleteArrayElement = (i)=>{
        let inputvalCopy = [];
        let submappingsCopy = [];
        if(schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)){
            inputvalCopy = [...schemaMapping.mapping.val];
        }
        if(schemaMapping.mapping.mapping!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.mapping)){
            submappingsCopy = [...schemaMapping.mapping.mapping];
        }
        submappingsCopy.splice(i,1);
        inputvalCopy.splice(i,1);
        setInputval(inputvalCopy);
        setSubmappings(submappingsCopy);
        let mmCopy = {
                        "action": "const",
                        "val": inputvalCopy,
                        "mapping": submappingsCopy

                     }
        let schemaMappingCopy = {...schemaMapping,
                                 mapping: mmCopy
                                }
        modifySchema({...sc},schemaMappingCopy);
    }

    const getInputLabel = ()=>{
        if((sc.default==""&&sc.help=="")||(sc.default==undefined&&sc.help==undefined)||(sc.default==""&&sc.help==undefined)||(sc.default==undefined&&sc.help=="")){
            return ""
        }else if(sc.default==""||sc.default==undefined){
            return sc.help
        }else if(sc.help==""||sc.help==undefined){
            return sc.default
        }else{
            return sc.help+" defaults to "+sc.default;
        }
    }

    return (
        <div className="data-example">
            <div className="input-type-switch">
                <div onClick={()=>{setArrayInputMethod(0)}} className={arrayInputMethod==0?"input-type active":"input-type"}>
                    Input
                </div>
                {(teststage==undefined||teststage==false)&&
                    <div onClick={()=>{setArrayInputMethod(1)}} className={arrayInputMethod==1?"input-type active":"input-type"}>
                        From Pipeline
                    </div>
                }
            </div>
            {arrayInputMethod==0&&
                <>
                    {sc.subschema[0].type=="object"?
                        <div style={{marginLeft: 10}}>
                            {schemaMapping!=undefined&&schemaMapping.mapping!=undefined&&schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)&&
                                <>
                                    {schemaMapping.mapping.val.map((val, i)=>{
                                        return (
                                            <div style={{display: "flex"}}>
                                                <div>
                                                    {i}
                                                </div>
                                                <div>
                                                    <InsertSchemaContainer
                                                        schema={sc.subschema[0].subschema} 
                                                        schemaMapping={schemaMapping.mapping.mapping[i]}                                                            
                                                        setSchemaMapping={setSchemaMappingIndex(i)}
                                                        deleteField={()=>{}}
                                                        insertschema={()=>{}}
                                                        pipelinetype={pipelinetype}
                                                        stagetype={stagetype}
                                                        layout={layout}
                                                        teststage={teststage}
                                                        setSchema={()=>{}}
                                                        modifySchema={modifySchemaIndex(i)}
                                                        editable={editable}
                                                        pipelinestage={pipelinestage}
                                                        source={source}
                                                        templateid={templateid}
                                                        indexarr={indexarr}
                                                        sourceid={sourceid}
                                                    >
                                                    </InsertSchemaContainer>
                                                </div>
                                                <div>
                                                    <Tooltip>
                                                        <IconButton onClick={()=>{deleteArrayElement(i)}}>
                                                            <span className="material-symbols-outlined">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                            <Button onClick={()=>{addArrayElement()}} style={{color: "#3A07CD"}}>Add Element</Button>
                        </div>:
                        <>
                            {sc.subschema[0].type=="array"?
                                <div style={{marginLeft: 10}}>
                                    {schemaMapping!=undefined&&schemaMapping.mapping!=undefined&&schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)&&
                                        <>
                                            {schemaMapping.mapping.val.map((val, i)=>{
                                                return (
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <div style={{padding: 10}}>
                                                            {i}
                                                        </div>
                                                        <div style={{padding: 10}}>
                                                            <DataArrayContainer
                                                                pipelinetype={pipelinetype}
                                                                stageindex={stageindex}
                                                                stagetype={stagetype}
                                                                layout={layout}
                                                                teststage={teststage}
                                                                editable={editable}
                                                                pipelinestage={pipelinestage}
                                                                source={source}
                                                                templateid={templateid}
                                                                indexarr={indexarr}
                                                                sc={sc.subschema[0]}
                                                                schemaMapping={submappings[i]}
                                                                modifySchema={modifySchemaIndex(i)}
                                                                sourceid={sourceid}
                                                            >

                                                            </DataArrayContainer>
                                                        </div>
                                                        <div>
                                                            <Tooltip>
                                                                <IconButton onClick={()=>{deleteArrayElement(i)}}>
                                                                    <span className="material-symbols-outlined">
                                                                        delete
                                                                    </span>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                    <Button onClick={()=>{addArrayElement()}} style={{color: "#3A07CD"}}>Add Element</Button>
                                </div>:
                                <div style={{marginLeft: 10}}>
                                    {schemaMapping!=undefined&&schemaMapping.mapping!=undefined&&schemaMapping.mapping.val!=undefined&&Array.prototype.isPrototypeOf(schemaMapping.mapping.val)&&
                                        <>
                                            {schemaMapping.mapping.mapping.map((val, i)=>{
                                                return (
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <div style={{padding: 10}}>
                                                            {i}
                                                        </div>
                                                        <div style={{padding: 10}}>
                                                            <ArrayElementInput
                                                                i={i}
                                                                getArrayElementVal={getArrayElementVal}
                                                                val={val.val}
                                                                setArrayElementVal={setArrayElementVal}
                                                                setArrayElementValGet={setArrayElementValGet}
                                                                type={sc.subschema[0].type}
                                                                pipelinetype={pipelinetype}
                                                                editable={editable}
                                                                pipelinestage={pipelinestage}
                                                                teststage={teststage}
                                                                source={source}
                                                                templateid={templateid}
                                                                indexarr = {indexarr}
                                                                sourceid={sourceid}
                                                            >
                                                            </ArrayElementInput>
                                                        </div>
                                                        <div>
                                                            <Tooltip>
                                                                <IconButton onClick={()=>{deleteArrayElement(i)}}>
                                                                    <span className="material-symbols-outlined">
                                                                        delete
                                                                    </span>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                    <div>
                                        <Button onClick={()=>{addArrayElement()}} style={{color: "#3A07CD"}}>Add Element</Button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            }
            {arrayInputMethod==1&&
                <>
                    <TextField 
                            variant="outlined" 
                            fullWidth 
                            value={schemaMapping.mapping.val} 
                            onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                            help={getInputLabel()}
                    ></TextField>
                </>
            }
            {openFieldDec&&teststage!=true&&(source=="apps"||source=="apptemplates"||source=="appprops"||source=="installmanifest")&&
                <Output 
                    closeFieldSelector={setOpenFieldDec} 
                    type={sc.type}
                    setschemaMapping={setSchemaMappingData()}
                    pipelinetype={pipelinetype}
                    pipelinestage={pipelinestage}
                    source={source}
                    templateid={templateid}
                    indexarr={indexarr}
                ></Output>
            }
        </div>
    )
}

export const InsertArraySchemaContainer = ({
                                        sc, 
                                        schemaMapping,
                                        modifySchema,
                                        openaddschema,   
                                        toggleSubSchema,
                                        toggleAddSChema, 
                                        subschemaopen,
                                        pipelinetype,
                                        stageindex,
                                        stagetype,
                                        layout,
                                        teststage,
                                        editable,
                                        closeAddSchema,
                                        pipelinestage,
                                        source,
                                        templateid,
                                        indexarr,
                                        sendNext,
                                        sourceid
                                    })=>{


        const [actionsToggle, setActionsToggle] = useState(false);
            
        const [actionActive, setActionActive] = useState(false);
    
        useEffect(()=>{
            if(openaddschema){
                setActionActive("newschema")
            }else{
                setActionActive("")
            }
        },[openaddschema])
    
        useEffect(()=>{
            if(subschemaopen){
                setActionActive("expand")
            }else{
                setActionActive("")
            }
        },[subschemaopen])

        const addtoschema = (val)=>{
            let schemaCopy = val[0];
            let submapping = [];
            getsubschemamapping(val, submapping);
            modifySchema(schemaCopy, submapping[0]);
        }

        const changeSchemaType = (schematype)=>{
            if(schematype=="array"){
                let schemaCopy = {...sc,
                                  type: "array",
                                  subschema:[
                                    {
                                        "type": "any"
                                    }
                                  ]
                                 };
                let schemaMappingCopy = {...schemaMapping,
                                         mapping: ""
                                        };
                modifySchema(schemaCopy, schemaMappingCopy);
                            
            }else{
                let schemaCopy = {...sc,
                                  "type": schematype,
                                  subschema:[]
                                 };
                let schemaMappingCopy = {...schemaMapping,
                                         mapping: ""
                                        }
                modifySchema(schemaCopy, schemaMappingCopy);
            }
        }

        const toggleSubSchemaInternal = ()=>{
            toggleSubSchema();
            setActionsToggle(!actionsToggle);
        }
    
        const toggleAddSChemaInternal = ()=>{
            toggleAddSChema();
            setActionsToggle(!actionsToggle);
        }
        
        
    return (
        <>
            <div className="insert-data-tile">
                <div className="data-label-dec">
                {sc.label!=undefined?sc.label:sc.key}
                    {sc.required&&
                        <>
                            *
                        </>
                    }
                    {(teststage==undefined||teststage==false)&&sc.editallowed&&
                            <div className={actionActive=="newschema"?"data-action-toggles-button active":"data-action-toggles-button"}>
                                <Tooltip title="modify schema">
                                    <IconButton onClick={()=>{toggleAddSChemaInternal()}}>
                                        <span className="material-symbols-outlined">
                                            add
                                        </span>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    {(teststage==undefined||teststage==false)&&
                        <>
                            <div className={(actionActive=="expand")?"data-action-toggles-button":"data-action-toggles-button"}>
                                {actionActive=="expand"?
                                    <Tooltip title="expand schema">
                                        <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                            <span className="material-symbols-outlined">
                                                unfold_more_double
                                            </span>
                                        </IconButton>
                                    </Tooltip>:
                                    <Tooltip title="expand schema">
                                        <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                            <span className="material-symbols-outlined">
                                                unfold_less_double
                                            </span>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </>
                    }
                </div>
                <div>
                    {sc?.subschema?.length>0&&
                        <div className="decalare-variable-data-type">
                            <span style={{padding: 10}}>
                                <SelectTypes
                                   variablePipeline val={sc.type}
                                    subtype={sc.subschema[0].type}
                                    onChange={changeSchemaType}
                                    editallowed={sc.typechange}
                                >
                                </SelectTypes>
                            </span>
                            {(source=="apps"||source=="apptemplates"||source=="installmanifest")&&
                                <span style={{display: "flex", alignItems: "center"}}>
                                input: 
                                        <pre                                         
                                            className="files-input-display"
                                        >
                                            {JSON.stringify(getObjectVal(sc,schemaMapping,{}), null, " ")}
                                        </pre>
                                </span>
                            }
                        </div>
                    }
                    {openaddschema&&
                        <div className="add-subschema">
                            <CustomInput 
                                close={closeAddSchema} 
                                addtoschema={addtoschema} 
                                initialSchema={[sc]}
                                isArray={true}
                                source={source}
                            >
                            </CustomInput>
                        </div>
                    }
                </div>
                {subschemaopen&&
                    <DataArrayContainer
                        pipelinetype={pipelinetype}
                        stageindex={stageindex}
                        stagetype={stagetype}
                        layout={layout}
                        teststage={teststage}
                        editable={editable}
                        pipelinestage={pipelinestage}
                        source={source}
                        templateid={templateid}
                        indexarr={indexarr}
                        sc={sc}
                        schemaMapping={schemaMapping}
                        modifySchema={modifySchema}
                        sourceid={sourceid}
                    >

                    </DataArrayContainer>
                }
            </div>
        </>
    )
}

export const ConditionalSchemaContainer = ({
                                            sc, 
                                            schemaMapping,
                                            sendNext,
                                            pipelinetype,
                                            stageindex,
                                            stagetype,
                                            layout,
                                            teststage,
                                            modifySchema,
                                            editable,
                                            pipelinestage,
                                            source,
                                            templateid,
                                            indexarr,
                                            sourceid
                                        })=>{



    const modifySchemaConditional = (sch, schm)=>{
        if(sc.conditionres){
            let schemaCopy = {...sc,
                               success: sch
                             };
            modifySchema( schemaCopy, schm );
        }else{
            let schemaCopy = {...sc,
                              error: sch
                             };
            modifySchema( schemaCopy, schm )
        }
    }
    
    return(
        <div className="conditional-stage">
            {sc.conditionres?
                <>
                    <SchemaContainer
                        sc = {sc.success} 
                        schemaMapping = {schemaMapping}
                        sendNext = {sendNext}
                        pipelinetype={pipelinetype}
                        stageindex= {stageindex}
                        stagetype = {stagetype}
                        layout={layout}
                        teststage={teststage}
                        modifySchema={modifySchemaConditional}
                        editable={editable}
                        pipelinestage={pipelinestage}
                        source={source}
                        templateid={templateid}
                        indexarr={indexarr}
                        sourceid={sourceid}
                    >
                    </SchemaContainer>
                </>:
                <>
                    <SchemaContainer
                        sc = {sc.error} 
                        schemaMapping = {schemaMapping}
                        sendNext = {sendNext}
                        pipelinetype={pipelinetype}
                        stageindex= {stageindex}
                        stagetype = {stagetype}
                        layout={layout}
                        teststage={teststage}
                        modifySchema={modifySchemaConditional}
                        editable={editable}
                        pipelinestage={pipelinestage}
                        source={source}
                        templateid={templateid}
                        indexarr={indexarr}
                        sourceid={sourceid}
                    >
                    </SchemaContainer>
                </>
            }
        </div>
    )
}

const InsertSchemaContainer = ({
                        schema,
                        layout,
                        schemaMapping, 
                        pipelinetype,
                        stageindex,
                        stagetype,
                        teststage,
                        modifySchema,
                        editable,
                        pipelinestage,
                        source,
                        templateid,
                        indexarr,
                        sourceid
    })=>{

    const [activeIndex, setactiveIndex] = useState(0);

    const [schemaloading, setschemaloading] = useState(false);

    const modifySchemaInternal = (index)=>{
        return (sch, schm)=>{
            let schemaCopy = [...schema];
            schemaCopy[index] = sch;
            let schemamappingCopy = [...schemaMapping];
            schemamappingCopy[index] = schm;
            modifySchema(schemaCopy, schemamappingCopy);
        }
    }

    const sendNext = (index)=>{
        return ()=>{
            let schemaCopy = [...schema];
            let schemaMappingCopy = [...schemaMapping];
            if(index<(schemaCopy.length-1)){
                if(schemaCopy[index+1]?.type=="conditional"){
                    let condition = schemaCopy[index+1].condition;
                    let cpv = condition.valindex;
                    let keymapping = schemaMapping[cpv];
                    let keyval;
                    if(keymapping.mapping.action=="const"){
                        keyval = keymapping.mapping.val;
                    }
                    if(keymapping.mapping.action=="get"){
                        keyval = getValfromPipe(keymapping.mapping.val);
                    }
                    let res;
                    if(condition.condition=="="){
                        res = keyval==condition.val;
                    }else if(condition.condition==">"){
                        res = keyval>condition.val;
                    }else if(condition.condition=="<"){
                        res = keyval<condition.val;
                    }
    
                    schemaCopy[index+1] = {...schemaCopy[index+1],
                                            conditionres: res
                                            };
                    
                    if(res){
                        let subschemamapping = getsubschemamapping(schemaCopy[index+1].success,[]);
                        schemaMappingCopy[index+1] = {...schemaMappingCopy[index+1],
                                                mapping: subschemamapping
                                                }
                        
                    }else{
                        let subschemamapping = getsubschemamapping(schemaCopy[index+1].error,[]) ;
                        schemaMappingCopy[index+1] = {...schemaMappingCopy[index+1],
                                                mapping: subschemamapping
                                                }
                    }
                    schemaCopy[index+1]={...schemaCopy[index+1],
                                        fillallowed: true
                                        };
    
                }else{
                    schemaCopy[index+1]={...schemaCopy[index+1],
                        fillallowed: true
                    };
                }
                schemaCopy[index] = {...schemaCopy[index],
                                    guidefinished: true
                                    }
                modifySchema(schemaCopy, schemaMappingCopy)
            }
        }
    }

    useEffect(()=>{

    },[schemaMapping])

    // handle key enum for transforms 
    const [enumOptions, setEnumOptions] = useState([]);

    const [enumId, setEnumId] = useState("");

    const enumoptionresult = useSelector(selectEnumResults(enumId));

    useEffect(()=>{
        if(enumId!=""&&enumoptionresult!=undefined){
            setEnumOptions([...enumoptionresult]);
            let scCopy = [...schema];
            if(scCopy[1].optionfilled!=false){
                scCopy[1] = {
                             ...scCopy[1],
                             options: [...enumoptionresult],
                             optionfilled: true
                            };
                modifySchema(scCopy, [...schemaMapping]);
            }
        }
    },[enumoptionresult])

    let dispatch = useDispatch();

    useEffect(()=>{
        let schemaCopy = [...schema];
        let conditionChanged = false;
        let schemaMappingCopy = [...schemaMapping];
        if(Array.prototype.isPrototypeOf(schemaMapping)){
            for(let i=0; i< schemaCopy.length; i++){
                if(schemaCopy[i].type=="conditional"){
                    let condition = schemaCopy[i].condition;
                    let cpv = condition.valindex;
                    let keymapping = schemaMapping[cpv];
                    let keyval;
                    if(keymapping?.mapping?.action=="const"){
                        keyval = keymapping.mapping.val;
                    }
                    if(keymapping?.mapping?.action=="get"){
                        keyval = getValfromPipe(keymapping.mapping.val);
                    }
                    if(keyval!=undefined){
                        let res;
                        if(condition.condition=="="){
                            res = keyval==condition.val;
                        }else if(condition.condition==">"){
                            res = keyval>condition.val;
                        }else if(condition.condition=="<"){
                            res = keyval<condition.val;
                        }
                        if(res!=schemaCopy[i].conditionres){
                            schemaCopy[i] = {...schemaCopy[i],
                                            conditionres: res
                                            };
                            if(res){
                                let subschemamapping = getsubschemamapping(schemaCopy[i].success,[]);
                                schemaMappingCopy[i] = {...schemaMappingCopy[i],
                                                        mapping: subschemamapping
                                                    }
                
                            }else{
                                let subschemamapping = getsubschemamapping(schemaCopy[i].error,[]) ;
                                schemaMappingCopy[i] = {...schemaMappingCopy[i],
                                                        mapping: subschemamapping
                                                    }          
                            }
                            conditionChanged = true;
                        }
                    }   
                }
            }
        }

        let updated = false;
        for(let i=0; i< schema.length; i++){
            if(
                schema[i].transformaction&&
                schema[i].transformactioncompleted==false
            ){
                updated = true;
                let objectindex = schema[i].objectindex;
                let fieldindextochange = schema[i].fieldindex;
                let transformsm = schemaMapping[i];
                let schematoinsert = [];
                if(transformsm?.mapping?.action=="const"){
                    if(schemaMapping[objectindex]?.mapping?.action=="get"){
                        let smtoinsert = schemaMapping[objectindex].mapping.val+"."+transformsm.mapping.val;
                        schematoinsert = getSchemFromPipe(smtoinsert);
                    }
                    if(schematoinsert!=undefined){
                        if(schematoinsert?.type=="object"||schematoinsert?.type=="array"){
                            schemaCopy[fieldindextochange] = {
                                                            ...schemaCopy[fieldindextochange],
                                                            type: schematoinsert.type,
                                                            subschema: schematoinsert.subschema,
                                                         }
                            let submapping = [];
                            getsubschemamapping(schematoinsert.subschema, submapping, [],[]);
                            schemaMappingCopy[fieldindextochange] = {...schemaMappingCopy[fieldindextochange],
                                                                     mapping: submapping 
                                                                    }
                        }else{
                            schemaCopy[fieldindextochange] = {
                                                            ...schemaCopy[fieldindextochange],
                                                            type: schematoinsert.type,
                                                            subschema: [],
                                                         }
                        }
                        schemaCopy[i] = {...schemaCopy[i],
                                      transformactioncompleted: true
                                    }
                    }
                    
                }
            }else if(
                schema[i].dispatchaction&&
                schema[i].dispatchactioncompleted==false
            ){
                updated = true;
                let sc = schemaCopy[i];
                let sm = schemaMappingCopy[i];
                const id = getRandomId();
                dispatch(getEnumOptions({
                                            sc: sc,
                                            sm: sm,
                                            id: id,
                                            type: "keys"
                                        }))
                schemaCopy[i] = {...schemaCopy[i],
                             dispatchactioncompleted: true,
                             optionfilled: false
                            }
                setEnumId(id);
            }
        }
        // check if transform updated
        if(updated||conditionChanged){
            modifySchema(schemaCopy, schemaMappingCopy);
        }
    },[schema, schemaMapping])

    return (
        <div className={layout=="vertical"?"insertschema-variable-container vertical":"insertschema-variable-container horizontal"}>
            {schema?.map((sc,i)=>{
                return(
                    <>
                        {sc.fillallowed&&sc.type!=="conditional"&&   
                            <>
                                {schemaMapping!=undefined&&
                                    <SchemaContainer
                                        sc = {sc} 
                                        schemaMapping = {schemaMapping[i]}
                                        sendNext = {sendNext(i)}
                                        pipelinetype={pipelinetype}
                                        stageindex= {stageindex}
                                        stagetype = {stagetype}
                                        layout={layout}
                                        teststage={teststage}
                                        modifySchema={modifySchemaInternal(i)}
                                        editable={editable}
                                        pipelinestage={pipelinestage}
                                        source={source}
                                        templateid={templateid}
                                        indexarr={indexarr}
                                        sourceid={sourceid}
                                        parentschema={schema}
                                        parentschemamapping={schemaMapping}

                                    >
                                    </SchemaContainer>
                                }
                            </>
                        }
                        {sc.fillallowed&&sc.type=="conditional"&&
                            <>
                                <ConditionalSchemaContainer
                                    sc = {sc} 
                                    schemaMapping = {schemaMapping[i]}
                                    sendNext = {sendNext}
                                    pipelinetype = {pipelinetype}
                                    stageindex = {stageindex}
                                    stagetype = {stagetype}
                                    layout = {layout}
                                    teststage = {teststage}
                                    modifySchema = {modifySchemaInternal(i)}
                                    editable = {editable}
                                    pipelinestage={pipelinestage}
                                    source={source}
                                    templateid={templateid}
                                    indexarr={indexarr}
                                    sourceid={sourceid}
                                >
                                </ConditionalSchemaContainer>
                            </>
                        }
                    </> 
                )
            })}
        </div>
    )
}

const SchemaContainer = ({  
                            sc, 
                            schemaMapping,
                            sendNext,
                            pipelinetype,
                            stageindex,
                            stagetype,
                            layout,
                            teststage,
                            modifySchema,
                            editable,
                            pipelinestage,
                            source,
                            templateid,
                            indexarr,
                            sourceid,
                            parentschema,
                            parentschemamapping
                        })=>{
        
    
    const [osmallowed, setosmallowed] = useState(false);

    const [guidestarted,setguidestarted] = useState(false);

    const [nextallowed, setnextallowed] = useState(false);

    const [scmverfied, setScmverfied] = useState(false);
    
    const [nextclicked, setNextclicked] = useState(false);
    
    const [subschemaopen, setSubschemaopen] = useState(true);

    const [openaddschema, setopenaddschema] = useState(false);

    const [init, setInit] = useState(false);

    const activateNext = ()=>{
        if(nextallowed){
            setNextclicked(true);
            let scCopy = {...sc,
                          guidefinished: true
                        }
            modifySchema(scCopy,{...schemaMapping}); 
            sendNext();
        }
    }
    
    const toggleSubSchema = ()=>{
        setSubschemaopen(!subschemaopen)
    }

    const toggleAddSChema = ()=>{
        setopenaddschema(!openaddschema);
    }

    // TODO: add nested validation
    const validateSchema = (schemamapping)=>{
        let valid = true;
        let schemaTobeValidated = sc.subschema;
        for(let i = 0; i < schemaTobeValidated.length; i++){
            if(schemaTobeValidated[i].required&&schemaTobeValidated[i].type!="object"){
                let schemamappingindex = _.findIndex(schemamapping.mapping, (sc)=>{return (sc.key==schemaTobeValidated[i].key)});
                if(schemamapping.mapping[schemamappingindex].mapping?.val==""||schemamapping.mapping[schemamappingindex].mapping?.val==undefined){
                    valid = false;
                }
            }
        }
        return valid;   
    }

    // TODO: add option to verify required schema before allowing osm
    const closeAddSchema = ()=>{
        setopenaddschema(false);
        setosmallowed(true);
        // handle schema verfication for null
        let schemamappingobject = schemaMapping;
        let valid = validateSchema(schemamappingobject);
        if(valid){
            setScmverfied(true);
        }
    }

    const addtoschema = (val)=>{
        // console.log(val);
        if(sc.guided&&osmallowed==false){
            let oldsubschema = JSON.parse(JSON.stringify(sc.subschema));
            let sccopy = {...sc,
                          subschema: val
                         };            
            let submapping = [];
            getsubschemamapping(val, submapping, schemaMapping.mapping, oldsubschema);
            let smCopy = {...schemaMapping,
                          mapping: submapping
                         }
            modifySchema(sccopy, smCopy);
            setosmallowed(true);
            setSubschemaopen(true);
        }else{
            let oldsubschema = JSON.parse(JSON.stringify(sc.subschema));
            let sccopy = {...sc,
                subschema: val
               };            
            let submapping = [];
            getsubschemamapping(val, submapping, schemaMapping.mapping, oldsubschema);
            let smCopy = {...schemaMapping,
                mapping: submapping
               }
            modifySchema(sccopy, smCopy);
        }
    }

    useEffect(()=>{
        if(sc.type=="object"&&osmallowed){
            let schemamappingobject = schemaMapping;
            let valid = validateSchema(schemamappingobject);
            if(valid){
                setScmverfied(true);
            }
        }else if(sc.type=="array"&&osmallowed){
            setScmverfied(true);
        }else{
            // TODO: add primitive type validation;
            setScmverfied(true);
        }
    },[schemaMapping])

    useEffect(()=>{
        if(sc.guided&&sc.guidefinished==false){
            setInit(true);
            if(sc.type=="object"){
                if(guidestarted==false){
                    setopenaddschema(true);
                    setosmallowed(false);
                    if(sc.required){
                        setScmverfied(false);
                    }else{
                        setScmverfied(true)
                    }
                    
                }
                setguidestarted(true);
            }else{
                if(guidestarted==false){
                    setosmallowed(true);
                    if(sc.required){
                        setScmverfied(false);
                    }else{
                        setScmverfied(true)
                    }
                    
                }
                setguidestarted(true);
            }
            
        }else if (sc.guided&&sc.guidefinished){
            if(init){
                setosmallowed(true);
                setnextallowed(true);
                setInit(false);
            }else{
                setosmallowed(true);
                setnextallowed(true);
                setNextclicked(true);
            }
        }
        else {
            setosmallowed(true);
            setnextallowed(true);
        }
    },[sc])

    useEffect(()=>{
        if(sc.guided&&osmallowed&&scmverfied==true){
            setnextallowed(true); 
        }
    },[openaddschema, osmallowed, scmverfied])

    useEffect(()=>{
        if(teststage==true){
            setSubschemaopen(true);
        }
    },[teststage])

    return (
        <>
            <div style={{"marginLeft": "10px", "marginTop": "10px"}}>
                {sc.inputtype!="system"&&
                    <>
                        {sc.type!="file"?
                            <>
                                {sc.type=="array"?
                                    <div>
                                        <div className="insert-data-tile">
                                            <InsertArraySchemaContainer
                                                sc={sc}
                                                schemaMapping={schemaMapping}
                                                openaddschema={openaddschema}
                                                toggleSubSchema={toggleSubSchema}
                                                toggleAddSChema={toggleAddSChema}
                                                subschemaopen={subschemaopen}
                                                pipelinetype={pipelinetype}
                                                stageindex={stageindex}
                                                stagetype={stagetype}
                                                layout={layout}
                                                teststage={teststage}
                                                editable={editable}
                                                closeAddSchema={closeAddSchema}
                                                modifySchema={modifySchema}
                                                pipelinestage={pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                indexarr={indexarr}
                                                sendNext={sendNext}
                                                sourceid={sourceid}
                                            >
                                            </InsertArraySchemaContainer>
                                        </div>
                                        <div className="next-action-tile">
                                            {sc.guided&&(nextclicked==false)&&
                                                <div>
                                                    <Button 
                                                        className={nextallowed?"next-schema active":"next-schema"} 
                                                        style={{color: "#3A07CD"}}
                                                        onClick={()=>{activateNext()}}>Next</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>:
                                    <div>
                                        <div className="insert-data-tile">
                                            <DataValueContainer 
                                                sc={sc}
                                                schemaMapping={schemaMapping}
                                                toggleSubSchema = {toggleSubSchema}
                                                subschemaopen={subschemaopen}
                                                toggleAddSChema={toggleAddSChema}
                                                openaddschema={openaddschema}
                                                osmallowed={osmallowed}
                                                scmverfied={scmverfied}
                                                setScmverfied={setScmverfied}
                                                pipelinetype={pipelinetype}
                                                stageindex= {stageindex}
                                                stagetype = {stagetype}
                                                layout={layout}
                                                teststage={teststage}
                                                modifySchema={modifySchema}
                                                editable={editable}
                                                addtoschema={addtoschema}
                                                closeAddSchema={closeAddSchema}
                                                pipelinestage={pipelinestage}
                                                source = {source}
                                                templateid = {templateid}
                                                indexarr = {indexarr}
                                                sourceid = {sourceid}
                                                parentschema = {parentschema}
                                                parentschemamapping = {parentschemamapping}
                                            >
                                            </DataValueContainer>
                                        </div>
                                        <div className="next-action-tile">
                                            {sc.guided&&(nextclicked==false)&&
                                                <div>
                                                    <Button 
                                                        style={{color: "#3A07CD"}}
                                                        className={nextallowed?"next-schema active":"next-schema"} 
                                                        onClick={()=>{activateNext()}}>Next</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>:
                            <>
                                <div>
                                    <div className="insert-data-tile">
                                        <FileField
                                            sc={sc}
                                            schemaMapping={schemaMapping}
                                            openaddschema={openaddschema}
                                            toggleSubSchema={toggleSubSchema}
                                            toggleAddSChema={toggleAddSChema}
                                            subschemaopen={subschemaopen}
                                            pipelinetype={pipelinetype}
                                            stageindex={stageindex}
                                            stagetype={stagetype}
                                            editable={editable}
                                            pipelinestage={pipelinestage}
                                            modifySchema={modifySchema}
                                            source={source}
                                            templateid={templateid}
                                            indexarr={indexarr}
                                            sourceid={sourceid}
                                        >
                                        </FileField>
                                    </div>
                                    <div className="next-action-tile">
                                        {sc.guided&&(nextclicked==false)&&
                                            <div>
                                                <Button 
                                                    style={{color: "#3A07CD"}}
                                                    className={nextallowed?"next-schema active":"next-schema"} 
                                                    onClick={()=>{activateNext()}}>Next</Button>
                                            </div>
                                        }
                                    </div>
                                </div>                    
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}

// declare variable to of two type , sequential or block
// possible sequential steps in sequential -> open create schema, open schema mapping <-> modify schema mapping,
// sequential to have allowed step configuration 
const DeclareVariable = ({
                            schema, 
                            modifySchema,
                            schemamapping, 
                            pipelinetype,
                            stageindex,
                            stagetype,
                            layout,
                            teststage,
                            editable,
                            pipelinestage,
                            source,
                            sourceid,
                            templateid,
                            indexarr
                        })=>{
    
    const [schematype, setschematype] = useState("object");

    const modifyInternalSchema = ( sch, schm)=>{
        modifySchema(sch, schm);
    }

    const add_key_at_all_index = (schema, keyname, keyvalue)=>{
        let schemaCopy = [...schema];
        let schemaCopyDuplicate = schemaCopy;
        for(let i = 0; i < schemaCopyDuplicate.length; i++){
            let schemaobj = {...schemaCopyDuplicate[i]};
            schemaobj[keyname] = keyvalue;
            schemaCopyDuplicate[i] = schemaobj;
            if(schemaCopyDuplicate[i].subschema.length>0){
               let subschema =  add_key_at_all_index(schemaCopyDuplicate[i].subschema,keyname, keyvalue);
               schemaCopyDuplicate[i].subschema = subschema;
            }
        }
        return schemaCopy;
    }

    useEffect(()=>{
        if(Array.prototype.isPrototypeOf(schema)){
            setschematype("object");
        }else{
            setschematype("primitive");
        }
    },[schema])

    return (
        <div className="declare-variable-container">
            {schematype=="primitive"&&
                <SchemaContainer
                    sc = {schema}
                    schemaMapping = {schemamapping}
                    sendNext = {()=>{}}
                    pipelinetype = {pipelinetype}
                    stageindex = {stageindex}
                    stagetype = {stagetype}
                    layout = {layout}
                    teststage = {teststage}
                    modifySchema = {modifySchema}
                    editable = {editable}
                    pipelinestage={pipelinestage}
                    source={source}
                    templateid={templateid}
                    indexarr={indexarr}
                    sourceid={sourceid}
                >
                </SchemaContainer>
            }
            {schematype=="object"&&
                <InsertSchemaContainer 
                    schema={schema} 
                    schemaMapping={schemamapping}
                    pipelinetype={pipelinetype}
                    stageindex= {stageindex}
                    stagetype={stagetype}
                    layout={layout}
                    teststage={teststage}
                    modifySchema={modifyInternalSchema}
                    editable={editable}
                    pipelinestage={pipelinestage}
                    source={source}
                    templateid={templateid}
                    indexarr={indexarr}
                    sourceid={sourceid}
                ></InsertSchemaContainer>
            }
        </div>
    )
}

export default DeclareVariable;