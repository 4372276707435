import { Button, TextField, Box, IconButton, Tooltip, CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from "react-redux";
import { createWorkspace, deleteWorkspace, getWorkspaces, resetworkspacecreated, resetworkspacedeleted, selectWorkspacecreated, selectWorkspacedeleted, selectWorkspaceLoader, selectWorkspaces } from "../../Services/workspaces/workspace.slice";
import { showError } from "../../Services/error.slice";
import "./Workspaces.css";
import { useNavigate,Link } from "react-router-dom";
import { WorkTwoTone } from "@mui/icons-material";
import BreadCrumbs from "../BreadCrumbs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const CreateWorkspace = ({open, handleClose})=>{
    
    const [name, setName] = useState("");

    const [saveclicked, setsaveclicked] = useState(false);

    const dispatch = useDispatch();

    const createworkspaceaction = ()=>{
        if(name!=""){
            dispatch(resetworkspacecreated());
            setsaveclicked(true);
            dispatch(createWorkspace({name: name}));
        }else{
            dispatch(showError("Workspace name cannot be empty"))
        }
    }

    const workspacecreated = useSelector(selectWorkspacecreated);

    useEffect(()=>{
        if(saveclicked&&workspacecreated){
            setsaveclicked(false);
            handleClose()
        }
    },[saveclicked, workspacecreated])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                sx={style}
            >
                <div>
                    <div style={{margin:"10px"}}>
                        Create Workspace
                    </div>
                    {saveclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <div>
                                <TextField value={name} onChange={(e)=>{setName(e.target.value)}} label="Workspace name">
                                </TextField>
                            </div>
                            <div>
                                <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                                <Button style={{color: "#3A07CD"}} onClick={()=>{createworkspaceaction()}}>Save</Button>
                            </div>
                        </>
                    }
                </div>
            </Box>
        </Modal>   
    )
}

const DeleteWorkspace = ({
                            open, 
                            handleClose,
                            workspaceId
                        })=>{

    
    const dispatch = useDispatch();

    const [deleteclicked, setDeleteclicked] = useState(false);
    
    const deleteWorkspaceAction = ()=>{
        if(workspaceId!=""){
            dispatch(resetworkspacedeleted());
            setDeleteclicked(true);
            dispatch(deleteWorkspace(workspaceId))
        }else{
            dispatch(showError("No workspace selected to delete?"))
        }
    }

    const workspacedeleted = useSelector(selectWorkspacedeleted);

    useEffect(()=>{
        if(workspacedeleted&&deleteclicked){
            setDeleteclicked(false);
            handleClose();
        }
    },[workspacedeleted, deleteclicked])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
            >
                <div>
                    <div style={{margin: "10px"}}>
                        Delete Workspace
                    </div>
                    {deleteclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <div>
                                Are you sure, you want to delete workspace?
                            </div>
                            <div>
                                <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                                <Button style={{color: "#3A07CD"}} onClick={()=>{deleteWorkspaceAction()}}>Delete</Button>
                            </div>    
                        </>
                    }
                </div>
            </Box>
        </Modal>
    )
}

const Workspaces = ()=>{

    const [opencreate, setOpenCreate] = useState(false);

    const workspaces = useSelector(selectWorkspaces);

    const [opendelete, setOpendelete] = useState(false);

    const [workspacetodelete, setWorkspacetodelete] = useState("");

    const handleClose = ()=>{
        setOpenCreate(false);
    } 

    const openDeleteAction = (id)=>{
        setWorkspacetodelete(id);
        setOpendelete(true);
    }


    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getWorkspaces());
    },[])

    const navigate = useNavigate();

    const navigateToDashboard = ()=>{
        navigate("/");
    }

    const loader = useSelector(selectWorkspaceLoader);

    return (
        <div>
            <div>
                {loader>0&&
                    <LinearProgress></LinearProgress>
                }
            </div>
            <BreadCrumbs
                    breadcrumbs={
                        [
                        {
                            "name": "dashboard",
                            "url": "/"
                        }
                ]}
                
                >
            </BreadCrumbs>
            <div>
                <div>
                    <h3>Workspaces</h3>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
                    <div>
                        <Button onClick={()=>{setOpenCreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{navigateToDashboard()}} style={{color: "#3A07CD"}}>Close</Button>
                    </div>
                </div>
            </div>  
            <div className="workspaces-container">
                {workspaces.map((workspace, index)=>{
                    return(
                        <div className="workspaces-items">
                            <div style={{width: "50%",display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <Link to={"/workspaces/"+workspace._id}>{workspace.name}</Link>
                                </div>
                                <div>
                                    {workspace.shared?
                                        <>
                                            <div className="workspace-owner-detail">
                                                <Tooltip title={workspace.access}>
                                                    <IconButton>
                                                        <span className={workspace.access=="write"?"material-symbols-outlined write":"material-symbols-outlined"}>
                                                            group
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                                <div>
                                                    {workspace.email}
                                                </div>
                                            </div>
                                        </>:
                                        <>
                                            <div className="workspace-owner-detail">
                                                <Tooltip title={"write"}>
                                                    <IconButton>
                                                        <span className="material-symbols-outlined write">
                                                            person
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                                <div>
                                                    you
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div>
                                {workspace.shared?
                                <>
                                </>:
                                <IconButton onClick={()=>{openDeleteAction(workspace._id)}}>
                                    <span className="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                                }
                                
                            </div>
                        </div>
                    )
                    })
                }
            </div>
            <CreateWorkspace
                open={opencreate}
                handleClose={handleClose}
            >
            </CreateWorkspace>
            <DeleteWorkspace
                open={opendelete}
                workspaceId = {workspacetodelete}
                handleClose={()=>{
                                    setWorkspacetodelete("")
                                    setOpendelete(false)
                                }}
            >
            </DeleteWorkspace>
        </div>
    )
}

export default Workspaces;