import {get, postjson} from "../signin/axios";
import { baseURL } from "../../env";


export const getDataHeadersApi = async(payload)=>{
    let res = await get(baseURL+"datatables/schema/header/"+payload);
    return res;
}

export const getDataDataApi = async(payload)=>{
    let res = await postjson(baseURL+"datatables/schema/query/"+payload.schemaid, payload.payload);
    return res;
}

export const createDataObjectApi = async(payload)=>{
    let res = await postjson(baseURL+"datatables/schema/create/"+payload.schemaid, payload.payload);
    return res;
}

export const updateDataObjectApi = async(payload)=>{
    let res = await postjson(baseURL+"datatables/schema/update/"+payload.schemaid, payload.payload);
    return res;
}

export const deleteDataObjectApi = async(payload)=>{
    let res = await postjson(baseURL+"/datatables/schema/delete/"+payload.schemaid, payload.payload);
    return res;
}