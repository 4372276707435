import {get, postjson, putjson} from "../signin/axios";
import { baseURL } from "../../env";

export const getOptionsApi = async (payload)=>{
    let res = get(baseURL+"environvars/options/"+payload.type+"/"+payload.workspace);
    return res;
}


export const getEnvironVarApi = async(payload)=>{
    let res = get(baseURL+"environvars/getone/"+payload.source+"/"+payload.sourceid);
    return res;
}

export const updateEnvironVarApi = async(payload)=>{
    let res = putjson(baseURL+"environvars/getone/"+payload.source+"/"+payload.sourceid, payload.fields);
    return res;
}


export const commitEnvironVarApi = async(payload)=>{
    let res = putjson(baseURL+"environvars/commit/")
}