import {get, postjson, putjson, deleteM} from "../signin/axios";
import { baseURL } from "../../env";

export const getTemplatesApi = async (payload)=>{
    let res = await get(baseURL+"templates/getlist/"+payload);
    return res;
}

export const getTemplateDetailsApi = async (payload)=>{
    let res = await get(baseURL+"templates/"+payload);
    return res;
}

export const createTemplateApi = async(payload)=>{
    let res = await postjson(baseURL+ "templates/",payload);
    return res;
}

export const updateTextTemplateApi = async(payload)=>{
    let res = await putjson(baseURL+"templates/text/"+payload.id, payload.template);
    return res;
}

export const deleteTemplateApi = async(payload)=>{
    let res = await deleteM(baseURL+"templates/"+payload);
    return res;
}

export const getInterfaceTemplatesApi = async(payload)=>{
    let res =  await get(baseURL+"templates/interfaces/"+payload);
    return  res;
}

export const getInterfaceNonJsTemplatesApi = async(payload)=>{
    let res = await get(baseURL+"templates/nonjsinterfaces/"+payload);
    return res;
}

export const getTemplateSchemaApi = async (payload)=>{
    let res =  await get(baseURL+ "/templates/interfaces/schema/"+payload);
    return res;
}

export const deployTemplateApi = async(payload)=>{
    let res = await get(baseURL+"templates/deploy"+"/"+payload);
    return res;
}