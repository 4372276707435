import  Markdown  from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectInnerHtml, jsoncompare } from "../../../Services/apptemplates/apptemplates.slice";

const MarkdownComponent = ({
                              element,
                              elmkey,
                              indexarr,
                              position
                            })=>{

    const routeParams = useParams();

    const innerhtml = useSelector((state)=>
                                    selectInnerHtml(
                                            state,
                                            {schema: element.innerhtml, schemamapping: element.innerhtmlmapping, key:elmkey},
                                            routeParams,
                                            elmkey,
                                            position
                                        ), jsoncompare);


    return (
        <Markdown remarkPlugins={[remarkGfm]}>{innerhtml}</Markdown>
    )

}


export default MarkdownComponent;