import { configureStore } from "@reduxjs/toolkit";
import schemaReducer from "./schemas/schema.slice";
import appSlice from "./apps/app.slice";
import pipelineSlice from "./pipelines/pipeline.slice";
import errorSlice from "./error.slice";
import clientSlice from "./clients/client.slice";
import utilsSlice from "./utils/utils.slice";
import httpstageSlice from "./httpstagetest/httpstage.slice";
import pipelinelogsSlice from "./pipelinelogs/pipelinelogs.slice";
import authSlice from "./signin/signin.slice";
import eventSlice from "./events/event.slice";
import dashboardSlice from "./dashboard/dashboard.slice";
import loadingReducers from './loader/loader.slice';
import filesystemSlice from "./filesystem/filesystem.slice";
import templateSlice from "./templates/template.slice";
import interfaceSlice from "./interfaces/interface.slice";
import apptemplatesSlice from "./apptemplates/apptemplates.slice";
import websiteSlice from "./websites/website.slice";
import androidSlice from "./androidapps/android.slice";
import iconslice from "./toilericons/toilericon.slice";
import workspaceSlice from "./workspaces/workspace.slice";
import collaboratorsSlice from "./collaborators/collaborators.slice";
import dataSlice from "./data/data.slice";
import environvariablesSlice from "./environvariables/environvariables.slice";
import manifestSlice from "./manifest/manifest.slice";
import documentationsSlice from "./documentations/documentations.slice";
import documentSlice from "./marketplace/document.slice";
import marketplaceSlice from "./marketplace/marketplace.slice";
import usageSlice from "./accounts/usage.slice";

export const store = configureStore({
    reducer:{
        schemas: schemaReducer,
        apps: appSlice,
        pipeline: pipelineSlice,
        error: errorSlice,
        clientslice: clientSlice,
        utils: utilsSlice,
        httpclientslice: httpstageSlice,
        pipelinelogs: pipelinelogsSlice,
        auth: authSlice,
        event: eventSlice,
        dashboard: dashboardSlice,
        loadingbar: loadingReducers,
        filesystem: filesystemSlice,
        templates: templateSlice,
        interfaces: interfaceSlice,
        apptemplates: apptemplatesSlice,
        website: websiteSlice,
        androidapps: androidSlice,
        iconslice: iconslice,
        workspaces: workspaceSlice,
        collaborators: collaboratorsSlice,
        data: dataSlice,
        environvariables: environvariablesSlice,
        manifest: manifestSlice,
        documentations: documentationsSlice,
        document: documentSlice,
        marketplace: marketplaceSlice,
        usage: usageSlice
    }
})