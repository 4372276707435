import "./Accounts.css";
import { Link } from "react-router-dom";

const Account = ()=>{
    
    return (
        <div className="client-settings-box">
            <div className="client-settings-item">
                <Link to="/accounts/usage">Usage</Link>
            </div>
            <div className="client-settings-item">
                <Link to="/accounts">Invoices</Link>
            </div>
            <div className="client-settings-item">
                <Link to="/accounts">Profile</Link>
            </div>
        </div>
    )

}

export default Account;