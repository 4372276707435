
import "./DisplayJson.css";

const DisplayJson = ({
    val
})=>{

    return (
        <div className="json-main-container">
            <pre className="json-value-container">
                {JSON.stringify(val, null, 2)}
            </pre>
        </div>
    )


}

export default DisplayJson;