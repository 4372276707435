import { useEffect } from "react";
import { 
            FormControl, 
            FormControlLabel, 
            InputLabel, 
            MenuItem, 
            Select, 
            TextField, 
            Button 
        } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    selectDomains, 
    selectFolders, 
    selectTemplates,
    getOptions
} from "../../Services/environvariables/environvariables.slice";
import AppContainer from "../appdesigner/AppContainer";



const EnvironVariablesContainer = ({
                                    formvalue,
                                    field,
                                    setFieldValue,
                                    index
                                })=>{
    
    const domains = useSelector(selectDomains);

    const folders = useSelector(selectFolders);

    const templates = useSelector(selectTemplates);

    
    return (
        <>
            {field.varobj.type=="host"&&
                <>
                    <FormControl sx={{width: 200}}>
                        <InputLabel id={"host-options-"+index}>Variable value</InputLabel>
                        <Select
                            label="Variable Value"
                            labelId={"host-options"+index}
                            value={formvalue}
                            onChange={(e)=>{setFieldValue(e.target.value, index)}}
                        >
                            {
                                domains.map((domain)=>{
                                    return (
                                        <MenuItem value={domain.name}>{domain.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </>
            }
            {field.varobj.type=="folder"&&
                <>
                    <FormControl sx={{width: 200}}>
                        <InputLabel id={"folder-options-"+index}>Variable value</InputLabel>
                        <Select
                            label="Variable Value"
                            labelId={"folder-options-"+index}
                            value={formvalue}
                            onChange={(e)=>{setFieldValue(e.target.value, index)}}
                        >
                            {folders.map((folder)=>{
                                return (
                                    <MenuItem value={folder._id}>{folder.path}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </>
            }
            {field.varobj.type=="template"&&
                <>
                    <FormControl sx={{width: 200}}>
                        <InputLabel id={"template-options-"+index}>Variable value</InputLabel>
                        <Select
                            labelId={"template-options-"+index}
                            value={formvalue}
                            onChange={(e)=>{setFieldValue(e.target.value, index)}}
                            label="Variable Value"
                        >
                            {templates.map((template)=>{
                                return (
                                    <MenuItem value={template._id}>{template.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </>
            }
            {field.varobj.type=="string"&&
                <>
                    <TextField 
                            value={formvalue}
                            onChange={(e)=>{setFieldValue(e.target.value, index)}}
                            label={"Variable Value"}
                    ></TextField>
                </>
            }
            {field.varobj.type=="number"&&
                <>
                    <TextField 
                        value={formvalue}
                        onChange={(e)=>{setFieldValue(e.target.value, index)}}
                        type="number"
                        label={"Variable Value"}
                    ></TextField>
                </>
            }
            {field.varobj.type=="boolean"&&
                <div>
                    <div>
                        Variable Value:
                    </div>
                    <div>
                        <FormControlLabel control={<Checkbox value={formvalue} checked={formvalue} onChange={(e)=>{setFieldValue(e.target.checked, index)}}></Checkbox>} label={field.varname}></FormControlLabel>
                    </div>
                        
                </div>
            }
            {field.varobj.type=="datetime"&&
                <div>
                    <div>
                        Variable Value
                    </div>
                    <div>
                        <input type="datetime" value={formvalue} onChange={(e)=>{setFieldValue(e.target.value, index)}}></input>
                    </div>
                </div>
            }
        </>
    )
}


const InstallManifest = ({
    demo,
    manifestid,
    form,
    installtype,
    formvalues,
    buttontemplate,
    setformvalues,
    oninstall,
    oncancel
})=>{

    const setFieldValue = (val, index)=>{
        let formvaluescopy = [...formvalues];
        formvaluescopy[index] = val;
        setformvalues(formvaluescopy);
    }

    const {workspace} = useParams();

    const dispatch = useDispatch();

    const domains = useSelector(selectDomains);

    const folders = useSelector(selectFolders);

    const templates = useSelector(selectTemplates);

    useEffect(()=>{
        dispatch(getOptions({type: "domain", workspace: workspace}));
        dispatch(getOptions({type: "folder", workspace: workspace}));
        dispatch(getOptions({type: "template", workspace: workspace}));
    },[])



    return (
        <div>
            <div>
                {(installtype=="form"||installtype=="advanced-form"||installtype=="third-party")&&
                    <div>
                        {form.map((field, i)=>{
                            return(
                                <>
                                    {field.type=="string"&&
                                        <TextField 
                                            type="text" 
                                            label={field.varname}
                                            value={formvalues[i]}
                                            onChange={(e)=>{setFieldValue(e.target.value, i)}}
                                        ></TextField>
                                    }
                                    {field.type=="number"&&
                                        <TextField 
                                            type="number" 
                                            label={field.varname}
                                            value={formvalues[i]}
                                            onChange={(e)=>{setFieldValue(e.target.value, i)}}
                                        ></TextField>
                                    }
                                    {field.type=="boolean"&&
                                        <FormControlLabel control={<Checkbox value={formvalues[i]} onChange={(e)=>{setFieldValue(e.target.checked, i)}}></Checkbox>} label={field.varname}></FormControlLabel>
                                    }
                                    {field.type=="datetime"&&
                                        <input type="datetime" value={formvalues[i]} onChange={(e)=>{setFieldValue(e.target.value, i)}}></input>
                                    }
                                    {field.type=="host"&&
                                        <FormControl sx={{width:"200px"}}>
                                            <InputLabel id={field.varname}>{field.varname}</InputLabel>
                                            <Select
                                                labelId={field.varname}
                                                value={formvalues[i]}
                                                onChange={(e)=>{setFieldValue(e.target.value, i)}}
                                                label={field.varname}
                                            >
                                                {domains.map((domain)=>{
                                                    return <MenuItem value={domain.name}>{domain.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    {field.type=="folder"&&
                                        <FormControl>
                                            <InputLabel id={field.name}>{field.varname}</InputLabel>
                                            <Select
                                                labelId={field.varname}
                                                value={formvalues[i]}
                                                onChange={(e)=>{setFieldValue(e.target.value, i)}}
                                                label={field.varname}
                                            >
                                                {folders.map((folder)=>{
                                                    return <MenuItem value={folder._id}>{folder.path}</MenuItem>
                                                })

                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                    {field.type=="template"&&
                                        <FormControl>
                                            <InputLabel id={field.name}>
                                                {field.varname}
                                            </InputLabel>
                                            <Select
                                                labelId={field.varname}
                                                value={formvalues[i]}
                                                onChange={(e)=>{setFieldValue(e.target.value, i)}}
                                                label={field.varname}
                                            >
                                                {templates.map((template)=>{
                                                    return <MenuItem value={template._id}>{template.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    {field.type=="envvar"&&
                                        <EnvironVariablesContainer
                                            field={field}
                                            formvalue={formvalues[i]}
                                            setFieldValue={setFieldValue}
                                            index={i}
                                        >
                                        </EnvironVariablesContainer>
                                    }
                                </>
                            )
                        })}
                    </div>
                }
            </div>
            <div style={{display: "flex",alignItems: "center",padding: "10px"}}>
                {installtype=="third-party"?
                    <>
                        {(buttontemplate!=""&&buttontemplate!=undefined&&buttontemplate!=null)?
                            <div>
                                <div onClick={()=>{oninstall()}}>
                                    <AppContainer
                                        templateid={buttontemplate}
                                        indexarr={[]}
                                        elmkey={""}
                                        type={"html"}
                                    >
                                    </AppContainer>
                                </div>
                                
                            </div>:
                            <div>
                                <Button onClick={()=>{oninstall()}}>
                                    Install
                                </Button>
                            </div>
                        }
                    </>:
                    <>
                        <Button onClick={()=>{oninstall()}}>
                            Install
                        </Button>
                    </>
                }
                <div>
                    <Button onClick={()=>{oncancel()}}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    )

}


export default InstallManifest;