import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {getSchemasApi, createSchemaApi, getSchemaByIdApi, updateSchemaApi, getSchemaByAppApi, deleteSchemaApi, setSchemaDescApi, makeSchemaUserActiveApi, getSchemaActiveUsersApi, getSchemaActiveUsersCountApi, makeSchemaUserInactiveApi} from "./shema.service";
import {showError} from "../error.slice";
import * as _ from "lodash";
import { loading, loadingdone } from "../loader/loader.slice";

export const getSchemas = createAsyncThunk("schemas/getschemas",async (payload, {dispatch})=>{
    try{
        dispatch(loading());
        const res = await getSchemasApi();
        dispatch(loadingdone());
        return res;
    }catch(err){
        dispatch(loadingdone());
        console.log(err);
        throw err;
    }
})

export const createSchemas =  createAsyncThunk("schemas/createschemas", async(payload,{dispatch})=>{
    try{
        dispatch(loading());
        dispatch(resetcreateschema());
        const res = await createSchemaApi(payload);
        dispatch(loadingdone());
        return res;
    }catch(err){
        dispatch(loadingdone());
        dispatch(showError(err.response.data.error.message));
        throw err;
    }
})

export const getSchemaById = createAsyncThunk("schemas/getschemabyid",async(id,{dispatch})=>{
    try{
        dispatch(setSchemaLoader());
        const res = await getSchemaByIdApi(id);
        dispatch(resetSchemaLoader());
        return res;
    }catch(err){
        dispatch(resetSchemaLoader());
        dispatch(showError(err.response.data.error.message));
        throw err;
    }
})

export const updateSchema = createAsyncThunk("schemas/updateschema",async(payload,{dispatch})=>{
    try{
        dispatch(loading());
        dispatch(resetcreateschema());
        // console.log("in the api block");
        const res = await updateSchemaApi(payload.id,payload.payload);
        dispatch(loadingdone());
        return res;
    }catch(err){
        dispatch(loadingdone());
        dispatch(showError(err.response.data.error.message));
        throw err;
    }
})

export const getAppSchemas = createAsyncThunk("schemas/getschemabyapp",async(payload, {dispatch})=>{
    try{
        dispatch(setSchemaLoader());
        const res = await getSchemaByAppApi(payload);
        dispatch(resetSchemaLoader());
        return res;
    }catch(err){
        dispatch(resetSchemaLoader());
        dispatch(showError(err.response.data.error.message));
        throw err;
    }
})

export const deleteSchema = createAsyncThunk(
    "schemas/deleteschema",
    async(payload, {dispatch})=>{
        try{
            dispatch(loading());
            const res = await deleteSchemaApi(payload.schemaid);
            dispatch(loadingdone());
            dispatch(getSchemas(payload.appid));
            return res;
        }catch(err){
            dispatch(loadingdone());
            dispatch(showError(err.response.data.error.message));
            throw err;
        }
    }
)

export const setSchemaDesc = createAsyncThunk(
    "schemas/setschemadesc",
    async(payload, {dispatch})=>{
        try{
            dispatch(loading());
            let res = await setSchemaDescApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(err){
            dispatch(loadingdone());
            dispatch(showError("Schema desc offline, please edit after sometime"));
            throw err;
        }
    }
)



// export const makeSchemaUserActive = createAsyncThunk(
//     "schema/makeschemauseractive",
//     async(payload, {dispatch, rejectWithValue})=>{
//         try{
//             let res = await makeSchemaUserActiveApi(payload);
//             return {
//                 "payload": payload,
//                 "res": res
//             };
//         }catch(error){
//             dispatch(showError("Error making user inactive"));
//             throw error;
//         }
//     }
// )

// export const makeSchemaUserInactive = createAsyncThunk(
//     "schema/makeschemauserinactive",
//     async(payload, {dispatch, rejectWithValue, getState})=>{
//         try{    
//             let state = getState();
//             if(state.schemas.activeschemaid!=""){
//                 let res = await makeSchemaUserInactiveApi(state.schemas.activeschemaid);
//                 return res;
//             }else{
//                 dispatch(setschemadeactive());
//             }
            
//         }catch(error){
//             dispatch(showError("Error making user inactive"));
//             throw error;
//         }
//     }
// )



// export const getSchemaActiveUsers = createAsyncThunk(
//     "schema/getschemaactiveusers",
//     async(payload, {dispatch, rejectWithValue})=>{
//         try{
//             let res = await getSchemaActiveUsersApi(payload);
//             return res;
//         }catch(error){
//             dispatch(showError("Error getting active users"));
//             throw error;
//         }
//     }   
// )

// export const getSchemaActiveUsersCount = createAsyncThunk(
//     "schema/getschemaactiveuserscount",
//     async(payload, {dispatch, rejectWithValue, getState})=>{
//         try{
//             let state = getState();
//             if(state.schemas.schemaactive){
//                 let res = await getSchemaActiveUsersCountApi(payload);
//                 if(res.count==state.schemas.activeusercount){
//                     dispatch(counterSleep(payload));
//                     return
//                 }else{
//                     dispatch(counterSleep(payload));
//                     dispatch(getSchemaActiveUsers(payload));
//                     return res;
//                 }
//             }else{
//                 return
//             }
//         }catch(error){
//             dispatch(showError("Error getting active user count"));
//             throw error;
//         }
//     }
// )


export const schemaSlice = createSlice({
    name: "schemas",
    initialState:{
        schemas:[],
        createschema: false,
        schemaapppermission: "",
        schema:{},
        updatedSchema:undefined,
        appschemas:[],
        schemadeleted: false,
        createdId: null,
        "activeusercount": 0,
        "activeusers":[],
        "schemaactive": false,
        "activeschemaid": "",
        "schemaloader": 0
    },
    reducers:{
        setSchemaLoader: (state)=>{
            state.schemaloader = state.schemaloader+1;
        },
        resetSchemaLoader: (state)=>{
            state.schemaloader = state.schemaloader-1;
        },
        resetcreateschema: (state)=>{
            state.createschema = false
        },
        resetschemadeleted: (state)=>{
            state.schemadeleted = false;
        },
        resetCreatedId: (state)=>{
            state.createdId = null;
        },
        setschemaactive: (state)=>{
            state.schemaactive =  true;
        },
        setschemadeactive: (state)=>{
            state.schemaactive = false;
        },
        setactiveschemaid: (state, action)=>{
            state.activeschemaid = action.payload;
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getSchemas.fulfilled,(state,action)=>{
                state.schemas = action.payload;
            })
            .addCase(createSchemas.fulfilled,(state,action)=>{
                state.createschema = true
                state.createdId = action.payload.insertedId;
            })
            .addCase(getSchemaById.fulfilled,(state,action)=>{
                state.schema = action.payload.schema;
                state.schemaapppermission = action.payload.access
            })
            .addCase(updateSchema.fulfilled,(state,action)=>{
                state.createschema = true;
                // console.log(action.payload);
                state.schema = action.payload.schema;
            })
            .addCase(getAppSchemas.fulfilled, (state, action)=>{
                state.schemas = action.payload.schemalist;
                let appschemasCopy = [...state.appschemas];
                let appindex = _.findIndex(appschemasCopy,(app)=>{ return app.appid==action.payload.appid});
                if(appindex>-1){
                    appschemasCopy[appindex].schemalist = action.payload.schemalist;
                }else{
                    appschemasCopy.push({
                        "appname": action.payload.appname,
                        "appid": action.payload.appid,
                        "schemalist": action.payload.schemalist
                    })
                }
                state.appschemas = appschemasCopy;
            })
            .addCase(deleteSchema.fulfilled, (state, action)=>{
                state.schemadeleted = true;
            })
            .addCase(setSchemaDesc.fulfilled, (state, action)=>{
                console.log("desc synced");
            })
    }

})

export const selectSchemas = (state) => state.schemas.schemas;

export const selectCreateschema = (state) => state.schemas.createschema;

export const selectSchemaById = (state) => state.schemas.schema;

export const selectAppSchemas = (state) => state.schemas.appschemas;

export const selectAppSchema = createSelector(
                                        [
                                            state=>state.schemas.appschemas, 
                                            (state, appname)=> appname
                                        ],
                                        (appschemas, appname)=>{
                                            if(appname!=undefined&&appname!=null){
                                                let schemas = appschemas.filter(appschema => appschema.appid==appname);
                                                if(schemas.length>0){
                                                    return schemas[0].schemalist;
                                                }else{
                                                    return [];
                                                }
                                            }else{
                                                return [];
                                            }
                                            
                                            
                                        }
                                    )

export const selectSchemadeleted = (state) => state.schemas.schemadeleted;

export const selectCreatedId = (state) => state.schemas.createdId;

export const selectUpdatedSchema = (state) => state.schemas.updatedSchema;

export const selectSchemaapppermission = (state) => state.schemas.schemaapppermission;

export const selectschemaactiveusers = (state) => state.schemas.activeusers;

export const selectschemaactive = (state) => state.schemas.schemaactive;

export const selectSchemaLoader = (state) => state.schemas.schemaloader; 

export const {
                resetcreateschema, 
                resetschemadeleted, 
                resetCreatedId,
                setSchemaLoader,
                resetSchemaLoader
            } = schemaSlice.actions;

export default schemaSlice.reducer;