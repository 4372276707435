import { get, putjson } from "../../signin/axios";
import { baseURL } from "../../../env";
import {
    setTemplateVariable, 
    getAppObjectVal, 
    getLocalStorageSchema, 
    getSessionStorage, 
    generateLoopVaraibleP
} from "../../apptemplates/apptemplates.slice";
import { generateSchema } from "./utils";
const utils = require("./utils");
const { StageError } = require("./server-apps/errors");
const pipeline = require("./pipeline");
const _ = require("lodash");

const lengthlimits = 10;

const wordslimit = 256;

const getobjectval = (schema, val)=>{
    for(let i=0; i< schema.length; i++){
        if(schema[i].type=="object"){
            let objval = {}
            getobjectval(schema[i].subschema, objval);
            val[schema[i].key] = objval;
        }else{
            val[schema[i].key] = schema[i].value;
        }
    }
}

const addkeytolocation = (location, key)=>{
    if(location==""){
        location = location+key
    }else{
        location = location+"."+key
    }
    return location;
}

const createArraySnapshots = (originalValue)=>{
    let truncatedValue = [];
    for(let j=0; j< originalValue.length; j++){
        let valschema = [];
        utils.generateSchema(originalValue[j], valschema);
        if(valschema[0]?.key!=undefined){
            let snapshotschema = [...valschema];
            createSchemaSnapshot(valschema, snapshotschema);
            truncatedValue.push({
                "type": "object",
                "subschema": snapshotschema
            })
        }else if(valschema[0]?.type=="array"){
            let subval = originalValue[j];
            let substart = 0;
            let totalcount = subval.length;
            let subend = subval.length;
            if(subval.length>lengthlimits){
                subval = subval.slice(0, lengthlimits);
                subend = lengthlimits;
            }
            let subtruncatedval = createArraySnapshots(subval);
            truncatedValue.push({
                "type": "array",
                "value": subtruncatedval,
                start: 0,
                end: subend,
                total: totalcount
            })
        }else if(valschema[0]?.type=="string"||valschema[0]?.type=="text"){
            if(originalValue[j].length>wordslimit){
                let value = ""+originalValue[j];
                let totalcount = value.length;
                let tval = value.slice(0, wordslimit);
                truncatedValue.push({
                    "type": valschema[0].type,
                    "value": tval,
                    start: 0,
                    end: wordslimit,
                    total: totalcount
                })
            }else{
                let value = ""+originalValue[j];
                let totalcount = value.length;
                truncatedValue.push({
                    "type": valschema[0].type,
                    "value": originalValue[j],
                    start: 0,
                    end: totalcount,
                    total: totalcount
                })
            }
        }else{
            if(valschema[0]!=undefined){
                truncatedValue.push({
                    "type": valschema[0].type,
                    "value": originalValue[j]
                })
            }
        }
    }
    return truncatedValue;
}

const createSchemaSnapshot = (outputschema, snapshotschema)=>{
    for(let i=0; i< outputschema.length; i++){
            if(outputschema[i].type=="array"){
            let start = 0;
            let totalcount = outputschema[i].value.length;
            let end = outputschema[i].value.length;
            let originalValue = [...outputschema[i].value];
            if(outputschema[i].value.length>lengthlimits){
                originalValue = originalValue.slice(0,lengthlimits);
                start = 0;
                end = lengthlimits;
                // truncated = true;
            }
            let truncatedValue = createArraySnapshots(originalValue);
            // delete snapshotschema[i].subschema;
            snapshotschema[i] = {...snapshotschema[i],
                                 value: truncatedValue,
                                 start: start,
                                 end: end,
                                 total: totalcount
                                }
        }else if(outputschema[i].type=="object"){
            delete snapshotschema[i].value;
            createSchemaSnapshot(outputschema[i].subschema, snapshotschema[i].subschema);
        }else if(outputschema[i].type=="string"||outputschema[i].type=="text"){
            if(outputschema[i].value.length>wordslimit){
                let value = ""+outputschema[i].value;
                let totalcount = value.length;
                let truncatedValue = value.slice(0, wordslimit);
                snapshotschema[i] = {...snapshotschema[i],
                                     value: truncatedValue,
                                     start: 0,
                                     end: wordslimit,
                                     total: totalcount
                                    }
            }else{
                snapshotschema[i] = {...snapshotschema[i],
                                     start: 0,
                                     end: outputschema[i].value.length,
                                     total: outputschema[i].value.length
                                    }
            }   
        }
    }
}

const createNextArraySnapshots = (originalValue,snapshotvalue, location, nextlocation, direction)=>{
    let truncatedValue = [];
    for(let j=0; j< originalValue.length; j++){
        let valschema = [];
        location = addkeytolocation(location, j.toString());
        utils.generateSchema(originalValue[j], valschema);
        if(valschema[0].key!=undefined){
            if(snapshotvalue==undefined){
                let snapshotschema = [...valschema];
                createNextSchemaSnapshot(valschema, snapshotschema, location, nextlocation, direction);
                truncatedValue.push({
                    "type": "object",
                    "subschema": snapshotschema
                })
            }else{
                let snapshotschema = snapshotvalue[j].subschema;
                createNextSchemaSnapshot(valschema, snapshotschema, location, nextlocation, direction);
                truncatedValue.push({
                    "type": "object",
                    "subschema": snapshotschema
                })
            }
            
        }else if(valschema[0].type=="array"){
            let subval = originalValue[j];
            if(snapshotvalue==undefined){
                let substart = 0;
                let totalcount = subval.length;
                let subend = subval.length;
                if(subval.length>lengthlimits){
                    subval = subval.slice(0, lengthlimits);
                    subend = lengthlimits;
                }
                let subtruncatedval = createNextArraySnapshots(subval, undefined, location, nextlocation, direction);
                truncatedValue.push({
                    "type": "array",
                    "value": subtruncatedval,
                    start: 0,
                    end: subend,
                    total: totalcount
                })
            }else{
                let substart = snapshotvalue[j].start;
                let subend = snapshotvalue[j].end;
                let totalcount = snapshotvalue[j].total;
                if(location==nextlocation){
                    if(direction){
                        if((subend+lengthlimits)>totalcount){
                            subend = totalcount;
                            substart = subend-lengthlimits;
                        }else{
                            subend = subend+lengthlimits;
                            substart = subend-lengthlimits;
                        }
                    }else{
                        if((substart-lengthlimits)<0){
                            substart = 0;
                            subend = substart+lengthlimits;
                        }else{
                            substart = substart-lengthlimits;
                            subend = substart+lengthlimits;
                        }
                    }
                    subval = subval.slice(substart, subend);
                    let subtruncatedval = createNextArraySnapshots(subval, undefined, location, nextlocation, direction);
                    truncatedValue.push({
                        "type": "array",
                        "value": subtruncatedval,
                        start: substart,
                        end: subend,
                        total: totalcount
                    })   
                }else{
                    subval = subval.slice(substart, subend);
                    let subtruncatedval = createNextArraySnapshots(subval, snapshotvalue[j].value, location, nextlocation, direction);
                    truncatedValue.push({
                        "type": "array",
                        "value": subtruncatedval,
                        start: 0,
                        end: subend,
                        total: totalcount
                    })
                }
            }
        }else if(valschema[0].type=="string"||valschema[0].type=="text"){
            let value = ""+originalValue[j];
            if(snapshotvalue==undefined){
                if(originalValue[j].length>wordslimit){
                    let value = ""+originalValue[j];
                    let totalcount = value.length;
                    let tval = value.slice(0, wordslimit);
                    truncatedValue.push({
                        "type": valschema[0].type,
                        "value": tval,
                        start: 0,
                        end: wordslimit,
                        total: totalcount
                    })
                }else{
                    let value = ""+originalValue[j];
                    let totalcount = value.length;
                    truncatedValue.push({
                        "type": valschema[0].type,
                        "value": originalValue[j],
                        start: 0,
                        end: totalcount,
                        total: totalcount
                    })
                }
            }else{
                let start = snapshotvalue[j].start;
                let end = snapshotvalue[j].end;
                let total = snapshotvalue[j].total;
                if(location==nextlocation){
                    if(direction){
                        if((end+wordslimit)>total){
                            end = total;
                            start = end-wordslimit;
                        }else{
                            end = end+wordslimit;
                            start = end-wordslimit;
                        }
                    }else{
                        if((start-wordslimit)<0){
                            start = 0;
                            start = start+wordslimit
                        }else{
                            start = start-wordslimit;
                            end = start+wordslimit;
                        }
                    }
                }
                let tval = value.slice(start, end);
                truncatedValue.push({
                    "type": valschema[0].type,
                    "value": tval,
                        start: start,
                        end: end,
                        total: total
                })
            }
        }else{
            truncatedValue.push({
                "type": valschema[0].type,
                "value": originalValue[j]
            })   
        }
    }
    return truncatedValue;
}

const createNextSchemaSnapshot = (outputschema, snapshotschema, location, nextlocation, direction)=>{
    for(let i=0; i < outputschema.length; i++){
        location = addkeytolocation(location, outputschema[i].key);
        if(outputschema[i].type=="array"){
            let start = snapshotschema[i].start;
            let end = snapshotschema[i].end;
            let totalcount = snapshotschema[i].total;
            let originalValue = [...outputschema[i].value];
            if(location==nextlocation){
                if(direction){
                    if((end+lengthlimits)>totalcount){
                        end = totalcount;
                        start = end - lengthlimits;
                    }else{
                        end = end+lengthlimits;
                        start = end -lengthlimits;
                    }
                }else{
                    if((start-lengthlimits)<0){
                        start = 0;
                        end = start+lengthlimits;
                    }else{
                        start = start-lengthlimits;
                        end = start+lengthlimits;
                    }
                }
                originalValue = originalValue.slice(start, end);
                let truncatedValue = createNextArraySnapshots(originalValue, undefined, location, nextlocation, direction);
                // delete snapshotschema[i].subschema;
                snapshotschema[i] = {...snapshotschema[i],
                                     value: truncatedValue,
                                     start: start,
                                     end: end,
                                     total: totalcount
                                    }
            }else{
                originalValue = originalValue.slice(start,end);
                let truncatedValue = createNextArraySnapshots(originalValue,snapshotschema[i].value, location, nextlocation, direction);
                // delete snapshotschema[i].subschema;
                snapshotschema[i] = {...snapshotschema[i],
                    value: truncatedValue,
                    start: start,
                    end: end,
                    total: totalcount
                }
            }
                        
        }else if(outputschema[i].type=="object"){
            delete snapshotschema[i].value;
            createNextSchemaSnapshot(outputschema[i].subschema, snapshotschema[i].subschema, location, nextlocation, direction);
        }else if(outputschema[i].type=="string"||outputschema[i].type=="text"){
            let start = snapshotschema[i].start;
            let end = snapshotschema[i].end;
            let totalcount = snapshotschema[i].total;
            let value = ""+outputschema[i].value;
            if(location==nextlocation){
                if(direction){
                    if((end+wordslimit)>totalcount){
                        end = totalcount;
                        start = end - wordslimit;
                    }else{
                        end = end+wordslimit;
                        start = end - wordslimit;
                    }
                }else{
                    if((start-wordslimit)<0){
                        start = 0;
                        end = start+wordslimit;
                    }else{
                        start = start-wordslimit;
                        end = start+wordslimit;
                    }
                }
            }
            let truncatedValue = value.slice(start, end);
            snapshotschema[i] = {...snapshotschema[i],
                value: truncatedValue,
                start: start,
                end: end,
                total: totalcount
               }            
        }
    }
}

const referenceSideEffects = (delineatedpipeline, stageindex)=>{
    let keys = Object.keys(delineatedpipeline);
    let referencestages = [];
    for(let i=0; i<keys.length; i++){
        if(delineatedpipeline[keys[i]].type=="reference"){
            if(delineatedpipeline[keys[i]].refStage==stageindex.toString()){
                referencestages.push(keys[i])
            }else{
                let refindex = _.findIndex(referencestages, (rfs)=>{return rfs==delineatedpipeline[keys[i]].refStage})
                if(refindex>-1){
                    referencestages.push(keys[i])
                }
            }            
        }
    }
    return referencestages;
}

const updatesnapshotreferences = (
                                    referencestages, 
                                    delineatedpipeline, 
                                    snapshotpipeline,
                                    effects    
                                )=>{
    for(let i=0; i<referencestages.length; i++){
        let stageschema = utils.getReferenceSchema(referencestages[i], delineatedpipeline);
        let stagesnapshotschema = [...stageschema];
        createSchemaSnapshot(stageschema, stagesnapshotschema);
        effects.push({
            "stageindex": referencestages[i],
            "type": "reference",
            "outputschema": stagesnapshotschema,
            "error": false
        })
        snapshotpipeline[referencestages[i]] = {...snapshotpipeline[referencestages[i]],
                                                "outputschema": stagesnapshotschema
                                                }
    }
}

const generateEffects = async (
                            stageoutput, 
                            snapshotpipeline,
                            delineatedpipeline,
                            error, 
                            effects, 
                        )=>{
    if(error){
        if(stageoutput.stageindex!=undefined){
            effects.push({
                "stageindex": stageoutput.stageindex,
                "message": stageoutput.error?.message,
                "error": true
            })
        }
        
    }else{
        if(stageoutput.type=="reference"){
            // update the refstage
            let refStage = stageoutput.refStage;
            if(stageoutput.updateObj!=undefined){
                let refoutputschema = JSON.parse(JSON.stringify(stageoutput.updateObj.outputschema));
                let refsnapshotschema = JSON.parse(JSON.stringify(stageoutput.updateObj.outputschema));
                createSchemaSnapshot(refoutputschema, refsnapshotschema);
                // propogateTruncatedValues(refsnapshotschema);
                effects.push({
                                "stageindex": stageoutput.updateObj.stageindex,
                                "type": "value",
                                "outputschema": refsnapshotschema,
                                "error": false
                            })
                snapshotpipeline[stageoutput.updateObj.stageindex] = {
                                                "stageindex": stageoutput.updateObj.stageindex,
                                                "type": "value",
                                                "outputschema": refsnapshotschema,
                                                "error": false
                                            }
                let referencestages = referenceSideEffects(delineatedpipeline, stageoutput.updateObj.stageindex);
                updatesnapshotreferences(referencestages,delineatedpipeline, snapshotpipeline, effects)
                // await refreshDependants(

                // )
            }
            // update the current stage
            let stageoutputschema = utils.getReferenceSchema(stageoutput.stageindex, delineatedpipeline);
            let stagesnapshotoutputschema = JSON.parse(JSON.stringify(stageoutputschema));
            createSchemaSnapshot(stageoutputschema, stagesnapshotoutputschema);
            // propogateTruncatedValues(stagesnapshotoutputschema);
            effects.push({
                "stageindex": stageoutput.stageindex,
                "type": "reference",
                "outputschema": stagesnapshotoutputschema,
                "error": false
            })
            snapshotpipeline[stageoutput.stageindex] = {
                                              "stageindex": stageoutput.stageindex,
                                              "type": "reference",
                                              "outputschema": stagesnapshotoutputschema,
                                              "error": false
                                           }
    
    
        }else{
            let outputschema = JSON.parse(JSON.stringify(stageoutput.outputschema));
            let snapshotschema = JSON.parse(JSON.stringify(stageoutput.outputschema));
            createSchemaSnapshot(outputschema, snapshotschema);
            effects.push({
                "stageindex": stageoutput.stageindex,
                "type": "value",
                "outputschema": snapshotschema,
                "loopschema": [...stageoutput.loopschema],
                "current_index": stageoutput.current_index,
                "error": false
            })
            snapshotpipeline[stageoutput.stageindex] = {
                                            "stageindex": stageoutput.stageindex,
                                            "type": "value",
                                            "outputschema": snapshotschema,
                                            "loopschema": [...stageoutput.loopschema],
                                            "current_index": stageoutput.current_index,
                                            "error": false
                                            }                            
        }
    }
}

const findStageByStageIndex = (stageindex, epipeline)=>{
    let returnableStage = null;
    for(let i=0; i< epipeline.length; i++){
        let stage = epipeline[i];
        if(stage.stageindex==stageindex){
            returnableStage = stage;
            break;
        }else if(stage.type=="loop"){
            returnableStage = findStageByStageIndex(stageindex,stage.loop);
            if(returnableStage!=null){
                break;
            }
        }else if(stage.type=="conditional"){
            let conditionalfound = false;
            for(let j = 0;j < stage.conditions.length; j++){
                returnableStage = findStageByStageIndex(stageindex, stage.conditions[j].pipeline);
                if(returnableStage!=null){
                    conditionalfound = true;
                    break;
                }   
            }
            if(conditionalfound){
                break;
            }
        }
    }
    return returnableStage;
}



const refreshDependants = async (
    actionresult, 
    delineatedpipeline,
    snapshotpipeline,
    mappingMap, 
    pipelinetoexecute,
    clientid,
    pipelineid,
    executionId,
    key,
    effects,
    dispatch,
    getState
)=>{
    try{
        let mappingMapCopy = {...mappingMap};
        let schemakeys = Object.keys(mappingMapCopy);
        let stage = findStageByStageIndex(actionresult.stageindex,[...pipelinetoexecute]);
        if(stage.type=="loop"){
            for(let i=0; i < stage.loop.length; i++){
                await executeAction(
                    delineatedpipeline,
                    snapshotpipeline,
                    clientid,
                    pipelineid,
                    pipelinetoexecute,
                    stage.loop[i].stageindex,
                    mappingMap,
                    executionId,
                    key,
                    effects,
                    dispatch,
                    getState
                );
            }
        }
        for(let i=0; i< schemakeys.length; i++){
            let key = schemakeys[i];
            if(key!==""){
                let vals = utils.parseSchemaKey(key);
                let stageindex = actionresult.stageindex;
                if(vals[1]=="current_index"){
                    if(vals[0]==stageindex){
                    }    
                }else{
                    if(vals[0]==stageindex){
                        let keysettings = mappingMapCopy[key];
                        let stages = [...keysettings.stages];
                        let executableStages = [];
                        for(let j=0; j< stages.length; j++){
                            let stageindex = _.findIndex(executableStages, (s)=>{return stages[j].stageindex==s})
                            if(stageindex==-1){
                                executableStages.push(stages[j].stageindex);
                            }
                        }
                        for(let j =0; j< executableStages.length; j++){
                            await executeAction(
                                delineatedpipeline,
                                snapshotpipeline,
                                clientid,
                                pipelineid,
                                pipelinetoexecute,
                                executableStages[j],
                                mappingMap,
                                executionId,
                                key,
                                effects,
                                dispatch,
                                getState
                            );
                        }
                    }
                }
            }  
        }
    }catch(error){
        throw new StageError(actionresult.stageindex, error.message);
    }
    
}

const executeAction = async(
                            delineatedpipeline, 
                            snapshotpipeline, 
                            clientid, 
                            pipelineid, 
                            pipelinetoexecute, 
                            stageindex,
                            mappingMap, 
                            executionId,
                            key,
                            effects,
                            dispatch,
                            getState
                            )=>{
        try{
            try{
                await pipeline.executeStage(
                                                pipelinetoexecute, 
                                                delineatedpipeline, 
                                                stageindex, 
                                                key, 
                                                dispatch,
                                                pipelineid,
                                                getState
                                            );
                let stageoutput = {...delineatedpipeline[stageindex],
                                    stageindex: stageindex
                                  };
                generateEffects(stageoutput, snapshotpipeline,delineatedpipeline,false,effects);
                //update the delineatedpipeline and snapshotpipeline
                await updatesnapshots(pipelineid, delineatedpipeline, snapshotpipeline);
                // refersh dependant states
                await refreshDependants(
                                            stageoutput,
                                            delineatedpipeline,
                                            snapshotpipeline,
                                            mappingMap, 
                                            pipelinetoexecute,
                                            clientid,
                                            pipelineid,
                                            executionId,
                                            key,
                                            effects,
                                            dispatch,
                                            getState    
                                        );
            }catch(error){
                generateEffects(error, snapshotpipeline, delineatedpipeline, true, effects);
            }
        }catch(error){
            throw error;
        }
}


export const createTempVarSnapshot = async(
                                            delineatedpipeline,
                                          )=>{
        let outputschema = JSON.parse(JSON.stringify(delineatedpipeline["template"].outputschema));
        let snapshotschema = JSON.parse(JSON.stringify(delineatedpipeline["template"].outputschema));
        createSchemaSnapshot(outputschema, snapshotschema);
        delineatedpipeline["template"] = {
                                            "stageindex": "template",
                                            "type": "value",
                                            "outputschema": snapshotschema,
                                            "error": false
                                        }
}

export const executeStageAction = async(
                                  clientid, 
                                  pipelineid, 
                                  pipelinetoexecute,
                                  mappingMap,
                                  stageindexes,
                                  templateid,
                                  dispatch,
                                  getState
                                )=>{
    let snapshots = await getsnapshots(pipelineid);
    let delineatedpipeline = {...snapshots.delineatedpipeline};
    loadTemplateVariables(
        "main",
        templateid,
        getState,
        delineatedpipeline,
        "test"
    )

    let snapshotpipeline = {...snapshots.snapshotpipeline};
    let effects = [];
    try{
        for(let i=0; i < stageindexes.length; i++){
            await executeAction(
                                delineatedpipeline,
                                snapshotpipeline,
                                clientid,
                                pipelineid,
                                pipelinetoexecute,
                                stageindexes[i],
                                mappingMap,
                                "",
                                templateid,
                                effects,
                                dispatch,
                                getState
                                );
        }
    }catch(error){
        throw error;
    }
    //update the loginstance
    await updatesnapshots(pipelineid, delineatedpipeline, snapshotpipeline);
    return effects;
}

export const loadTemplateVariables = (
    type,
    templateid,
    getState,
    delineatedPipeline,
    source,
    position,
    event
)=>{
    if(type=="main"){
        let stateCopy = getState();
        let templatevariablesCopy = JSON.parse(JSON.stringify(stateCopy.apptemplates.appvariables));
        let schema = [];
        let output = {};
        if(templatevariablesCopy[templateid]!=undefined){
            let varaibleschema = templatevariablesCopy[templateid].schema;
            output["variable"] = varaibleschema[0].value
            schema.push(varaibleschema[0]);
        }
        //load inputs
        let appinputs = JSON.parse(JSON.stringify(stateCopy.apptemplates.appinputs));
        let templateinputs = appinputs[templateid];
        if(templateinputs!=undefined){
            let inputschema = {
                "key": "inputs",
                "label": "Inputs",
                "type": "object",
                "subschema": [...templateinputs],
                "value": {}
            }
            schema.push(inputschema);
            output["inputs"] = {};
        }
        //load session storage
        let localStorageSchema = getLocalStorageSchema();
        schema.push(localStorageSchema[0]);
        output["localstorage"] = {};
        //load session storage
        let sessionStorageSchema = getSessionStorage();
        schema.push(sessionStorageSchema[0]);
        output["sessionstorage"] = {};
        if(source=="pipelines"){
            // load loop variable schema
            let elements = stateCopy.apptemplates.appelements[templateid];
            let loopschema = generateLoopVaraibleP(elements,templatevariablesCopy[templateid].schema, position);
            schema.push(loopschema[0]);
            output["loopvariable"] = {};
            //load event schema;
            let eventschema = [];
            generateSchema(event, eventschema);
            schema.push(eventschema)
            output["event"] = {};   
        }
        delineatedPipeline["template"] = {
                                          outputschema: schema,
                                          output: output
                                         };
        return delineatedPipeline;
    }else{

    }

}

const getsnapshots = async (pipelineid)=>{
    let res = await get(baseURL+"actions/snapshots/"+pipelineid);
    return res;
}

const updatesnapshots = async (pipelineid, delineatedpipeline, snapshotpipeline)=>{
    let payload = {
        delineatedpipeline: delineatedpipeline,
        snapshotpipeline: snapshotpipeline
    }
    let res = await putjson(baseURL+"actions/snapshots/"+pipelineid, payload);

    return res;
}