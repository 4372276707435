import * as moment from "moment";
import * as _ from "lodash";

export const getsubschemamapping = (subschema, mapping, oldmapping, oldsubschema)=>{
    for(let i=0; i< subschema.length; i++){
        if(subschema[i].type=="array"){
            let keyindex = _.findIndex(oldmapping, (m)=>{return m.key==subschema[i].key});
            let subschemakey = _.findIndex(oldsubschema, (m)=>{return m.key==subschema[i].key})
            if(keyindex>-1&&oldsubschema[subschemakey].type==subschema[i].type){
                if(subschema[i].default!=undefined&&subschema[i].default!=""){
                    mapping.push(JSON.parse(JSON.stringify(oldmapping[keyindex])));
                }else{
                    mapping.push(JSON.parse(JSON.stringify(oldmapping[keyindex])));
                }
            }else{
                if(subschema[i].default!=undefined&&subschema[i].default!=""){
                    mapping.push({
                                    "key": subschema[i].key,
                                    "mapping":""
                                })
                }else{
                    mapping.push({
                                    "key": subschema[i].key,
                                    "mapping":""
                                })
                }
            }
            
        }else{
            if(subschema[i].type=="object"){
                let subschemamapping = [];
                let keyindex = _.findIndex(oldmapping, (m)=>{return m.key==subschema[i].key});
                let subschemakey = _.findIndex(oldsubschema, (m)=>{return m.key==subschema[i].key})
                if(keyindex>-1&&oldsubschema[subschemakey].type==subschema[i].type){
                    getsubschemamapping(subschema[i].subschema,subschemamapping, oldmapping[keyindex].mapping, oldsubschema[i].subschema)
                    mapping.push({
                        "key": subschema[i].key,
                        "mapping": subschemamapping
                    })
                }else{
                    getsubschemamapping(subschema[i].subschema,subschemamapping, [])
                    mapping.push({
                        "key": subschema[i].key,
                        "mapping": subschemamapping
                    })
                }
                
            }else{
                let keyindex = _.findIndex(oldmapping, (m)=>{return m.key==subschema[i].key});
                let subschemakey = _.findIndex(oldsubschema, (m)=>{return m.key==subschema[i].key});
                if(keyindex>-1&&oldsubschema[subschemakey].type==subschema[i].type){
                    mapping.push(JSON.parse(JSON.stringify(oldmapping[keyindex])));
                }else{
                    mapping.push({
                        "key": subschema[i].key,
                        "mapping": ""
                    })
                }
                
            }
        }
    }

}


export const getDefaultVal = (type)=>{
    if(type=="array"){
        return []
    }else{
        return "";
    }
}

// export const validatePrimaryTypes = (value, type, required)=>{
//     if(type=="string"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             };
//         }
//         return {
//             valid: true,
//             value: value
//         };

//     }else if(type=="text"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             }
//         }
//         return {
//             valid: true,
//             value: value
//         };
//     }else if(type=="number"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             };
//         }
//         let val = parseFloat(value);
//         if(val==null||isNaN(val)){
//             return {
//                 valid: false,
//                 error: "TypeError"
//             }
//         }
//         return {
//             valid: true,
//             value: val
//         };
//     }else if(type=="integer"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             }
//         }
//         let val = parseInt(value);
//         if(val==null||isNaN(val)){
//             return {
//                 valid: false,
//                 error: "TypeError"
//             }
//         }
//         return {
//             valid: true,
//             value: val
//         };

//     }else if(type=="boolean"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             };
//         }
//         if(value!="false"||value!="true"){
//             return {
//                 valid: false,
//                 error: "TypeError"                
//             }
//         }
//         return {
//             valid: true,
//             value: value
//         };
//     }else if(type=="datetime"){
//         if(required&&value==""){
//             return {
//                 valid: false,
//                 error: "Value cannot be empty"
//             };
//         }
//         let valid = moment(value).isValid();
//         if(valid){
//             let val = moment(value).format();
//             return {
//                 valid: true,
//                 value: val
//             };
//         }else{
//             return {
//                 valid: false,
//                 error: "TypeError"
//             };
//         }
//     }
// }


export const validatePrimaryTypes = (value, type, validationregex ,required)=>{
    if(type=="string"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        if(validationregex!=""){
            let regex = new RegExp(validationregex);
            // Return true if the str
            // matched the ReGex
            if (regex.test(value) == true) {
                return {
                    valid: true,
                    value: value
                };
            }else{
                return {
                    valid: false,
                    error: "Value doesnt match input pattern"
                };
            }
            
        }else{
            return {
                valid: true,
                value: value
            };
        }
        

    }else if(type=="text"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            }
        }
        return {
            valid: true,
            value: value
        };
    }else if(type=="number"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        let val = parseFloat(value);
        if(val==null||isNaN(val)){
            return {
                valid: false,
                error: "TypeError"
            }
        }
        return {
            valid: true,
            value: val
        };
    }else if(type=="integer"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            }
        }
        let val = parseInt(value);
        if(val==null||isNaN(val)){
            return {
                valid: false,
                error: "TypeError"
            }
        }
        return {
            valid: true,
            value: val
        };

    }else if(type=="boolean"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        if(value!="false"||value!="true"){
            return {
                valid: false,
                error: "TypeError"                
            }
        }
        return {
            valid: true,
            value: value
        };
    }else if(type=="datetime"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        let valid = moment(value).isValid();
        if(valid){
            let val = moment(value).format();
            return {
                valid: true,
                value: val
            };
        }else{
            return {
                valid: false,
                error: "TypeError"
            };
        }
    }else if(type="any"){
        return {
            valid: true,
            value: value
        }
    }
}