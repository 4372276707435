import {useEffect, useState} from "react";
import "./Stagecontainer.css";
import * as _ from "lodash";
import SingleBlock from './blocks/SingleBlock';
import ConditionalBlock from './blocks/Conditionalblock';
import ConditionalStageBlock from './blocks/ConditionalStageBlock';
import LoopBlock from './blocks/LoopBlock';
import {LoopVariableSchema, LoopVariableSchemaMapping} from "../Settings";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getOutput, getOutputSchema, Delineatedpipeline } from '../utils';
import DisplayObject from "../apps/DisplayOutputs/DisplayObject";
import {
        setStage, 
        selectStage,
        getDependantStages, 
        performChange,
        changeblocktype
       } from "../../Services/pipelines/pipeline.slice";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, Button, Typography } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const StageChangeModal = ({
                            open, 
                            handleClose,
                            actiontype, 
                            stageindex, 
                            modalAction
                        })=>{
    const depandantStages = useSelector(getDependantStages(stageindex));

    const getWarningMessage = ()=>{
        if(depandantStages.length>0){
            return "Following blocks may error "+depandantStages.join(",")+" on performing the action. Are you sure you want to perform this action?";
        }else{

            return "Are you sure you want to perform this action?"
        }
    }
    


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        {actiontype=="change"&&
                            <>
                                Change block index {stageindex}
                            </>
                        }
                        {actiontype=="delete"&&
                            <>
                                Delete block index {stageindex}
                            </>
                        }
                    </h3>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {getWarningMessage()}
                    </Typography>
                </div>
                <div style={{display: "flex", "padding": 10}}>
                    <div>
                        <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>Cancel</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{modalAction()}} style={{color: "#3A07CD"}}>Yes</Button>
                    </div>
                </div>
            </Box>
      </Modal>
    )
}

const StageContainer = ({
                         pipelinetype,
                         width,
                         pipelinestage,
                         index,
                         stage,
                         isLog,
                         source,
                         templateid,
                         pipelineid                     
                        })=>{
    
 
    const [openStageChange, setOpenStageChange] = useState(false);
    
    const [actionType, setActionType] = useState(false);

    const [nextValue, setNextValue] = useState(null);

    const dispatch = useDispatch();

    const changeBlockType = (val)=>{
        if(isLog==undefined){
            setActionType("change_type");
            setNextValue(val);
            setOpenStageChange(true);
        }
    }

    const getStageStatus = ()=>{
        let status = Delineatedpipeline[stage.stageindex]?.sync;
        return status;
    }
    
    const deleteStageInt = ()=>{
        setActionType("delete");
        setOpenStageChange(true);

    }


    const performActionInt = ()=>{

        if(actionType=="delete"){
            dispatch(performChange({
                changetype: "delete",
                position: pipelinestage.position,
                source: source

            }))
        }
        if(actionType=="change_type"){
            let val = nextValue;
            if(val=="single"){
                let stageCopy = {
                                "type": val,
                                "app": "",
                                "appid": "",
                                "loop":[],
                                "action": "choose_action",
                                "outputschema":[],
                                "outputschemamapping": [],
                                "inputschema": [],
                                "inputschemamapping":[],
                                "subactions":[],
                                "conditions":[],
                                "blockschema":[],
                                "stageindex": stage.stageindex,
                                "last": stage.last,
                                "sync":0,
                                "expanded": true
                            }
                dispatch(changeblocktype({oldstage: stage, stage: stageCopy, source: source, position: pipelinestage.position}))
            }else if(val=="conditional"){
                let stageCopy = {
                                       "type": val,
                                       "app": "condition",
                                       "appid": "condition",
                                       "blockschema":[],
                                       "loop":[],
                                       "conditions":[
                                            {
                                                "name": "A",
                                                "pipeline": [
                                                    {
                                                        "conditions":[[
                                                            {
                                                                "valuetocompare":"",
                                                                "tocompareopen":false,
                                                                "comparetotype": "any",
                                                                "options":[],
                                                                "condition": "",
                                                                "valuecompareto":"",
                                                                "comparetoopen":false
                                                            }
                                                        ]],
                                                        "last": false,
                                                        "app": "conditions",
                                                        "appid": "conditions",
                                                        "type": "internal",
                                                        "stageindex": stage.stageindex+1
                                                    }
                                        ]}
                                       ],
                                        "action": "choose_action",
                                        "outputschema":[],
                                        "outputschemamapping": [],
                                        "inputschema": [],
                                        "inputschemamapping":[],
                                        "subactions":[],
                                        "stageindex": stage.stageindex,
                                        "last": stage.last,
                                        "sync": 0,
                                        "expanded": true
                                      }
                dispatch(changeblocktype({oldstage: stage, stage: stageCopy, source: source, position: pipelinestage.position}))
            }else if(val=="loop"){
                let stageCopy = {
                    "type": val,
                    "app": "loop",
                    "appid": "loop",
                    "blockschema":[],
                    "active": false,
                    "loopschema":[{
                        "key": "current_index",
                        "label": "Current Index",
                        "type": "any",
                        "subschema":[]
                    }],
                    "loop":[
                        {
                            "type": "single",
                            "app": "",
                            "appid": "",
                            "loop":[],
                            "action": "choose_action",
                            "outputschema":[],
                            "outputschemamapping": [],
                            "inputschema": [],
                            "inputschemamapping":[],
                            "subactions":[],
                            "conditions":[],
                            "blockschema":[],
                            "stageindex": stage.stageindex+1,
                            "last": false
                        }
                    ],
                    "conditions":[
                    ],
                     "action": "choose_action",
                     "outputschema":[],
                     "outputschemamapping": [],
                     "inputschema": [...LoopVariableSchema],
                     "inputschemamapping":[...LoopVariableSchemaMapping],
                     "subactions":[],
                     "stageindex": stage.stageindex,
                     "last": stage.last,
                     "sync": 0,
                     "currentindex":0,
                     "expanded": true
                   }
                   dispatch(changeblocktype({oldstage: stage, stage: stageCopy, source: source, position: pipelinestage.position}))
            }
        }
        setOpenStageChange(false);
    }

    const setExpanded = (val)=>{
        let stageCopy = {...stage,
                         expanded: val
                        }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }


    const closeChangeStage = ()=>{
        setOpenStageChange(false);
    }

    const getStageHeader = ()=>{
        if(stage!=null){
            if(stage.type=="conditional"){
                return "condition"
            }else if(stage.type=="loop"){
                return "loop"
            }else{
                return stage.app+" : "+ stage.action;            
                }
        }else{
            return ""
        }
    }

    return (
        <>
            {stage!=null&&
                <div className={stage.active?"stage-container":"stage-container disabled"}>
                    {stage.expanded?
                        <>
                            <div className="stage-inner-container">
                                <div className="stage-type-container-header">
                                    <div className='stage-info'>
                                        <div style={{padding:5}}>{stage.stageindex}</div>
                                            <div style={{padding:5}}>
                                                {getStageStatus()==0&&
                                                    <div className="sync">
                                                        <span className="material-symbols-outlined">
                                                            sync
                                                        </span>
                                                    </div>
                                                }
                                                {getStageStatus()==1&&
                                                    <div className="loading">
                                                        <span className="material-symbols-outlined">
                                                            sync
                                                        </span>
                                                    </div>
                                                }
                                                {getStageStatus()==2&&
                                                    <div className="error">
                                                        <span className="material-symbols-outlined">
                                                            sync
                                                        </span>
                                                    </div>
                                                }
                                                
                                            </div>
                                    </div>
                                    <div className="stage-return-container">
                                    <div className="stage-type-container">
                                                <Tooltip title="Block">
                                                    <IconButton onClick={()=>{changeBlockType("single")}}>
                                                        <span className={stage.type=="single"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                            stack
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                                {(index>0||pipelinetype=="loop")&&
                                                    <Tooltip title="Conditional Block">
                                                        <IconButton onClick={()=>{changeBlockType("conditional")}}>
                                                            <span className={stage.type=="conditional"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                                device_hub
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {(index>0||pipelinetype=="loop")&&
                                                    <Tooltip title="Loop Block">
                                                        <IconButton onClick={()=>{changeBlockType("loop")}}>
                                                            <span className={stage.type=="loop"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                                all_inclusive
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>    
                                                }
                                                <Tooltip title="Expand Block">
                                                    <IconButton onClick={()=>{setExpanded(false)}}>
                                                        <span className='material-symbols-outlined'>
                                                            expand_less
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        {index>0&&
                                            <>
                                                {isLog==undefined&&
                                                    <Tooltip title="Delete Block">
                                                        <IconButton onClick={()=>{deleteStageInt()}}>
                                                            <span className="material-symbols-outlined">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {pipelinetype=="main"&&
                                    <>
                                        {index==0?
                                            <div style={{width:width}} className="stage-body">
                                                {stage.type=="single"&&
                                                    <SingleBlock
                                                        pipelinetype={pipelinetype}
                                                        pipelinestage={pipelinestage}
                                                        index={index}
                                                        stage={stage}
                                                        isLog={isLog}
                                                        source={source}
                                                        templateid={templateid}
                                                        pipelineid={pipelineid}
                                                    ></SingleBlock>          
                                                }
                                            </div>:
                                            <div style={{width:width}} className="stage-body">
                                                {stage.type=="single"&&
                                                    <SingleBlock
                                                        pipelinetype={pipelinetype}
                                                        pipelinestage={pipelinestage}
                                                        index={index}
                                                        stage={stage}
                                                        isLog={isLog}
                                                        source={source}
                                                        templateid={templateid}
                                                        pipelineid={pipelineid}
                                                    ></SingleBlock>
                                                }
                                                {stage.type=="loop"&&
                                                    <div>
                                                        <LoopBlock
                                                            width={width}
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        ></LoopBlock>
                                                    </div>
                                                }
                                                {stage.type=="conditional"&&
                                                    <div>
                                                        <ConditionalBlock
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        >
                                                        </ConditionalBlock>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                                {pipelinetype=="conditional"&&
                                    <div style={{width:width}} className="stage-body">
                                        {index==0?
                                            <>
                                                <ConditionalStageBlock
                                                    pipelinetype={pipelinetype}
                                                    pipelinestage={pipelinestage}
                                                    index={index}
                                                    stage={stage}
                                                    isLog={isLog}
                                                    source={source}
                                                    templateid={templateid}
                                                    pipelineid={pipelineid}
                                                ></ConditionalStageBlock>
                                            </>:
                                            <>
                                                {stage.type=="single"&&
                                                    <SingleBlock
                                                        pipelinetype={pipelinetype}
                                                        pipelinestage={pipelinestage}
                                                        index={index}
                                                        stage={stage}
                                                        isLog={isLog}
                                                        source={source}
                                                        templateid={templateid}
                                                        pipelineid={pipelineid}
                                                    ></SingleBlock>
                                                }
                                                {stage.type=="loop"&&
                                                    <div>
                                                        <LoopBlock
                                                            width={width}
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        ></LoopBlock>
                                                    </div>
                                                }
                                                {stage.type=="conditional"&&
                                                    <div>
                                                        <ConditionalBlock
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        >
                                                        </ConditionalBlock>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                                {pipelinetype=="loop"&&
                                    <>
                                        <div style={{width:width}} className="stage-body">
                                                {stage.type=="single"&&
                                                    <SingleBlock
                                                        pipelinetype={pipelinetype}
                                                        pipelinestage={pipelinestage}
                                                        index={index}
                                                        stage={stage}
                                                        isLog={isLog}
                                                        source={source}
                                                        templateid={templateid}
                                                        pipelineid={pipelineid}
                                                    ></SingleBlock>
                                                }
                                                {stage.type=="loop"&&
                                                    <div>
                                                        <LoopBlock
                                                            width={width}
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        ></LoopBlock>
                                                    </div>
                                                }
                                                {stage.type=="conditional"&&
                                                    <div>
                                                        <ConditionalBlock
                                                            pipelinetype={pipelinetype}
                                                            pipelinestage={pipelinestage}
                                                            index={index}
                                                            stage={stage}
                                                            isLog={isLog}
                                                            source={source}
                                                            templateid={templateid}
                                                            pipelineid={pipelineid}
                                                        >
                                                        </ConditionalBlock>
                                                    </div>
                                                }
                                        </div>
                                    </>
                                }
                            </div>
                        </>:
                        <>
                            <div style={{width:width}}>
                                <div className="stage-type-container-header">
                                    <div className='stage-info'>
                                        <div style={{padding:5}}>{stage?.stageindex}</div>
                                        <div style={{padding:5}}>{getStageHeader()}</div>
                                        <div style={{padding:5}}>
                                            {getStageStatus()==0&&
                                                <div className="sync">
                                                    <span className="material-symbols-outlined">
                                                        sync
                                                    </span>
                                                </div>
                                            }
                                            {getStageStatus()==1&&
                                                <div className="loading">
                                                    <span className="material-symbols-outlined">
                                                        sync
                                                    </span>
                                                </div>
                                            }
                                            {getStageStatus()==2&&
                                                <div className="error">
                                                    <span className="material-symbols-outlined">
                                                        sync
                                                    </span>
                                                </div>
                                            }                                            
                                        </div>
                                    </div>
                                    <div className="stage-return-container">
                                            <div className="stage-type-container">
                                                <Tooltip title="Block">
                                                    <IconButton onClick={()=>{changeBlockType("single")}}>
                                                        <span className={stage.type=="single"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                            stack
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                                {(index>0||pipelinetype=="loop")&&
                                                    <Tooltip title="Conditional Block">
                                                        <IconButton onClick={()=>{changeBlockType("conditional")}}>
                                                            <span className={stage.type=="conditional"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                                device_hub
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {(index>0||pipelinetype=="loop")&&
                                                    <Tooltip title="Loop Block">
                                                        <IconButton onClick={()=>{changeBlockType("loop")}}>
                                                            <span className={stage.type=="loop"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                                                all_inclusive
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>    
                                                }
                                                <Tooltip title="Expand Block">
                                                    <IconButton onClick={()=>{setExpanded(true)}}>
                                                        <span className='material-symbols-outlined'>
                                                            expand_more
                                                        </span>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            {index>0&&
                                                <>
                                                    {isLog==undefined&&
                                                        <Tooltip title="Delete Block">
                                                            <IconButton onClick={()=>{deleteStageInt()}}>
                                                                <span className="material-symbols-outlined">
                                                                    delete
                                                                </span>
                                                            </IconButton>
                                                        </Tooltip> 
                                                    }
                                                </>
                                            }
                                    </div>
                                </div>
                                <div className="stage-params">
                                    <div className="stage-params-header">
                                        <div>
                                            Output
                                        </div>
                                        <div>
                                            {JSON.stringify(stage.updatetime)}
                                        </div>
                                    </div>
                                    <div>
                                    <pre>
                                        <DisplayObject
                                            schema={getOutputSchema(stage.stageindex)}
                                            spacer={""}
                                            location={""}
                                            stageindex={stage.stageindex}
                                        >
                                        </DisplayObject>
                                    </pre>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            <StageChangeModal 
                open={openStageChange} 
                handleClose={closeChangeStage}
                modalAction={performActionInt}
                actiontype={actionType}
                stageindex={pipelinestage.stageindex}
            ></StageChangeModal>
        </>
    )
}


export default StageContainer;