import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
            getAppElements, 
            getAppVariables, 
            jsoncompare, 
            selectAppElements, 
            selectTemplateLoadStatus,
            getAppPipelines,
            getAppclasses,
            selectAppVariables,
            selectAppPipelines,
            selectActivePosition,
            selectActiveKey,
            selectFocusPosition,
            selectFocusKey,
            selectActivetemplateid,
            setFocusPosition,
            setActivePosition
        } from "../../Services/apptemplates/apptemplates.slice";
import { runPipelines } from "../../Services/pipelines/pipeline.slice";
import {getKey} from "./utils";
import ElementContainer from "./ElementContainer";
import { View } from "react-native-web";

const AppContainer = ({
                        templateid, 
                        indexarr, 
                        elmkey,
                        type,
                        childs,
                        position,
                        templateinteractions,
                        path,
                        isroute,
                        preview,
                        indesign                    
                    })=>{

    const [containerinit, setContainerinit] = useState("");

    const [variablesloaded, setVariablesloaded] = useState(false);

    const [onloadpipes, setOnloadpipes] = useState(false);

    const dispatch = useDispatch();

    const parentloadstatus = useSelector((state)=>{ return selectTemplateLoadStatus(state, elmkey)});
    
    const {__source} = useParams();

    
    useEffect(()=>{
        if(templateid!=undefined&&indexarr!==undefined&&containerinit!=templateid&&parentloadstatus==true){
            dispatch(getAppElements({
                                        "templateid": templateid,
                                        "indexarr": indexarr,
                                        "elmkey": elmkey,
                                        "source": __source
                                    }));
            dispatch(getAppVariables({
                "templateid": templateid,
                "key": getKey(templateid, indexarr),
                "elmkey": elmkey,
                "indexarr": indexarr,
                "source": __source
            }))
            dispatch(getAppPipelines({
                "templateid": templateid,
                "key": getKey(templateid, indexarr),
                "elmkey": elmkey,
                "indexarr": indexarr,
                "source": __source
            }))
            dispatch(getAppclasses(
                {
                    "templateid": templateid,
                    "key": getKey(templateid, indexarr),
                    "elmkey": elmkey,
                    "indexarr": indexarr,
                    "source": __source
                }
            ))
            setContainerinit(""+templateid);
        }
    },[templateid, indexarr, parentloadstatus])
    
    // const {type} = useParams();

    const elements = useSelector((state)=>{return selectAppElements(state, getKey(templateid, indexarr))}, jsoncompare);

    const variables = useSelector((state)=>{return selectAppVariables(state, getKey(templateid, indexarr))}, jsoncompare);

    const pipelines = useSelector((state)=>{return selectAppPipelines(
                                                                        state, 
                                                                        getKey(templateid, indexarr),
                                                                        {}
                                                                    )}, jsoncompare);

    useEffect(()=>{
        if(containerinit&&variables!=undefined){
            setVariablesloaded(true);
        }
    },[variables])

    useEffect(()=>{
        if(containerinit&&variablesloaded&&pipelines!=undefined&&onloadpipes==false){
            for(let i=0; i< pipelines.length; i++){
                if(pipelines[i].onload){
                    console.log("running onload pipelines "+pipelines[i]._id);
                    dispatch(runPipelines({
                        key: getKey(templateid, indexarr),
                        pipelineid: pipelines[i]._id,
                        schemamapping: [],
                        position: [],
                        source: "appcontainer"
                    }))
                }
            }
            setOnloadpipes(true);
        }
    },[pipelines,variablesloaded])

    const [elmActive, setElmActive] = useState(false);

    const [elmFocus, setElmFocus] = useState(false);

    const elementActive = useSelector(selectActivePosition);

    const activekey = useSelector(selectActiveKey);

    useEffect(()=>{
        if(isroute!=true){
            if(
                JSON.stringify(indexarr)==JSON.stringify(elementActive)&&
                activekey==elmkey
              ){
                setElmActive(true);
            }else{
                setElmActive(false);
            }
        }
        
    },[elementActive])

    const elementFocus = useSelector(selectFocusPosition);

    const focuskey = useSelector(selectFocusKey);

    // const focuspath = useSelector(selectFocusPath);
    const activetemplateid = useSelector(selectActivetemplateid);

    useEffect(()=>{
        if(isroute!=true){
            if(
                JSON.stringify(indexarr)==JSON.stringify(elementFocus)&&
                focuskey==elmkey
            ){
                setElmFocus(true);
            }else{
                setElmFocus(false);
            }
        }
    },[elementFocus])

    const setElementInFocusAction = (position, key)=>{
        dispatch(setFocusPosition({
                                    position: position,
                                    key: key,
                                    focussource: "canvas"
                                  }))
    }

    const setElementActiveAction = (position, key)=>{
        dispatch(setActivePosition({
                                    position: position,
                                    key: key,
                                    focussource: "canvas"
                                   }));
    }

    const [ref, setRef] = useState(null);

    const elmref = useCallback((node)=>{
        setRef(node)
    },[])

    const fclick = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementActiveAction(position, elmkey)
            }
        }
    }

    const fmouseleave = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementInFocusAction([],elmkey)
            }
        }
    }

    const fmouseenter = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementInFocusAction(position,elmkey)
            }
        }
    }

    useEffect(()=>{
        if(ref!=null&&elmkey!=undefined){
            ref.addEventListener("click", fclick);
            ref.addEventListener("mouseenter", fmouseenter);
            ref.addEventListener("mouseleave", fmouseleave);
            return ()=>{
                    ref.removeEventListener("click", fclick);
                    ref.removeEventListener("mouseenter", fmouseenter);
                    ref.removeEventListener("mouseleave", fmouseleave)        
            }
        }
        
    },[ref, activetemplateid, elmkey])


    return (
        <>
            {type=="html"&&
                <>
                    {elements.length>0&&indexarr!=undefined&&
                        <div
                            className={preview==undefined&&(elmFocus||elmActive)?"element-active":""}
                            ref={elmref}
                            id="app-container"
                        >
                            {elements[1].childs.map((element,i)=>{
                                return (
                                    <ElementContainer
                                        element = {element}
                                        elmkey = {getKey(templateid, indexarr)}
                                        indexarr = {[1, i]}
                                        position = {[1, i]}
                                        type = {type}
                                        childs = {childs}
                                        parentkey = {elmkey}
                                        parentposition = {position}
                                        parentindexarr = {indexarr}
                                        templateinteractions = {templateinteractions}
                                        path = {path}
                                        preview={preview}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}   
                        </div>
                    }    
                </>
            }
            {type=="android"&&
                <>
                    {elements.length>0&&indexarr!=undefined&&
                        <View>
                            {elements.map((element, i)=>{
                                return (
                                    <ElementContainer
                                        element={element}
                                        elmkey={getKey(templateid, indexarr)}
                                        indexarr={[i]}
                                        position={[i]}
                                        type={type}
                                        childs={childs}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </View>
                    }
                </>
            }
        </>
    )
}


export default AppContainer;