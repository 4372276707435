import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCollaboration, getCollaborators, selectCollaborator, selectCollaborators } from "../../Services/collaborators/collaborators.slice";
import { IconButton, Table, TableCell, TableHead, TableRow , Button} from "@mui/material";

import "./Collaborators.css";
import { useNavigate } from "react-router-dom";
import { appUrl } from "../../env";
import { showError } from "../../Services/error.slice";


const Collaborators = ()=>{

    
    const collaborators = useSelector(selectCollaborators);

    const navigate = useNavigate();

    const navigateToAdd = ()=>{
        navigate("/settings/collaborators/details");
    }

    const navigateToEdit = (id)=>{
        navigate("/settings/collaborators/details/"+id);
    }

    const copyInviteLink = (id)=>{
        let copyInviteLink = appUrl+"make/collaboration/"+id;
        navigator.clipboard.writeText(copyInviteLink);
        dispatch(showError("Invitation link copied to clipboard"))
    }

    const deleteCollaborationAction = (id)=>{
        dispatch(deleteCollaboration(id));
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getCollaborators());
    },[])

    return (
        <div>
            <div className="collaborator-header">
                <div>
                    <h3>Collaborators</h3>
                </div>
                <div>
                    <Button style={{color: "#3A07CD"}} onClick={()=>{navigateToAdd()}}>
                        Add Collaborator
                    </Button>
                    <IconButton onClick={()=>{navigate("/settings")}}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </IconButton>
                </div>
            </div>            
            <div>
                <Table>
                    <TableHead>
                        <TableCell>
                            EmailId
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            Created On
                        </TableCell>
                        <TableCell>
                            Updated On
                        </TableCell>
                        <TableCell>
                            Actions           
                        </TableCell>
                    </TableHead>
                    {collaborators.map((collab)=>{
                        return (
                            <TableRow>
                                <TableCell>
                                    {collab.inivitationmail}
                                </TableCell>
                                <TableCell>
                                    {collab.status.toString()}
                                </TableCell>
                                <TableCell>
                                    {collab.createdAt}
                                </TableCell>
                                <TableCell>
                                    {collab.updatedAt}
                                </TableCell>
                                <TableCell>
                                    <div style={{display: "flex"}}>
                                        {collab.internalstatus=="invited"&&
                                            <div>
                                                <IconButton onClick={()=>{copyInviteLink(collab._id)}}>
                                                    <span className="material-symbols-outlined">
                                                        content_copy
                                                    </span>
                                                </IconButton>
                                                <div>
                                                    Invite Link
                                                </div>
                                            </div>
                                            
                                        }
                                        <IconButton onClick={()=>{navigateToEdit(collab._id)}}>
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </IconButton>
                                        <IconButton onClick={()=>{deleteCollaborationAction(collab._id)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>
                                    
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </Table>

            </div>
        </div>
    )
}


export default Collaborators;