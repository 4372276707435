import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { selectgoogleauth, setgoogleauth } from "./Services/signin/signin.slice";
import CircularLoader from "./apps/utils/CircularLoader";

const SigninGoogle = ()=>{
    const {
        status,
        token,
        email,
        workspaceId,
        clientid,
        error
    } = useParams();

    const dispatch = useDispatch();

    useEffect(()=>{
        if(status==1){
            if(
                token!=undefined&&
                token!=""&&
                email!=undefined&&
                email!=""&&
                workspaceId!=undefined&&
                workspaceId!=""&&
                clientid!=""&&
                clientid!=undefined
            ){
                dispatch(setgoogleauth(
                    {
                        "token": token,
                        email: email,
                        workspaceId: workspaceId,
                        clientid: clientid
                    }
                ))
            }
        }
    },[status])

    const authset = useSelector(selectgoogleauth);

    return (
        <>
            {error!=undefined&&error!=""&&error!="undefined"?
                <div>
                    {error}
                </div>:
                <div style={{display: "flex", justifyContent: "center", marginTop: "200px"}}>
                    <CircularLoader>

                    </CircularLoader>
                </div>
            }
            {authset&&
                <>
                    <Navigate
                        to={`/${workspaceId}/`}
                    ></Navigate>
                </>
            }
        </>
    )

}

export default SigninGoogle;