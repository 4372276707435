import { useEffect, useState } from "react"
import CustomInput from "../../apps/CustomInput/CustomInput";
import {InsertSchemaContainer} from "../../apps/Output/Output";
import CreateMotors from "../CreateMotors";
import "./Conditionalblock.css";
import { useDispatch, useSelector } from "react-redux";
import { addConditionAction, deleteConditionAction, selectStage, setStage , setStageAsync} from "../../../Services/pipelines/pipeline.slice";
import { Button } from "@mui/material";
import { Delineatedpipeline } from '../../utils';

const ConditionalBlock = ({
                            pipelinestage,
                            pipelinetype,
                            index,
                            stage,
                            isLog,
                            source,
                            templateid
                         })=>{

    const dispatch = useDispatch();
    
    // const [blockinit, setblockinit] = useState(true);

    const [conditionopen, setConditionOpen] = useState(null);
    
    // const updateConditions = (conditions)=>{
    //     // let pipelineCopy = [...pipeline];
    //     let stageCopy = {...stage,
    //                     conditions: [...conditions]
    //                     }
    //     dispatch(setStageAsync({position: pipelinestage.position, stage: stageCopy, source: source}));
    // }

    const addtoschema = (schema)=>{
        let stageCopy = {...stage,
                         blockschema: schema
                        }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const closeMotors = ()=>{
        setConditionOpen(null);
    }
   
    // check last output action 
    // if more then one block after the conditional , throw error if block variable empty
    // const closeBlockVariable = ()=>{
    //     setblockinit(false);
    // }

    const addCondition = ()=>{
        dispatch(addConditionAction({stage: stage, position: pipelinestage.position, source: source}))
    }

    const deleteCondition = (index)=>{
        dispatch(deleteConditionAction({stage: stage, position: pipelinestage.position, source: source, index: index}));
    }

    const getStageStatus = (stageindex)=>{
        let status = Delineatedpipeline[stageindex]?.sync;
        return status;
    }


    return (
        <>
            <div className="stage-params">
                <div style={{display: "flex"}}>
                        {stage.conditions.map((c, i)=>{
                            return (
                                <div className="condition-tile">
                                    <div className="condition-tile-header">
                                        <div>
                                            {c.name}
                                        </div>
                                        <div>
                                            <div className="conditionstatus">

                                            </div>
                                            <div onClick={()=>{deleteCondition(i)}}>
                                                <span class="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div style={{width:"100%", height: "100%"}} onClick={()=>{setConditionOpen(i)}}>
                                        {stage.conditions[i].pipeline.map((cc, j)=>{
                                            return(
                                                <>
                                                    {j==0?
                                                    <>
                                                        <div className="condition-tile-stages">
                                                            <div>
                                                                {cc.stageindex}. {cc.app}
                                                            </div> 
                                                            <div>
                                                                {cc.output?
                                                                    <>
                                                                        <span className="material-symbols-outlined active">
                                                                            done
                                                                        </span>
                                                                    </>:
                                                                    <>
                                                                        <span className="material-symbols-outlined error">
                                                                            close
                                                                        </span>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>:
                                                    <>
                                                        <div className="condition-tile-stages">
                                                            <div>
                                                                {cc.stageindex}. {cc.app}: {cc.action}
                                                            </div>
                                                            <div>
                                                                {getStageStatus(cc.stageindex)==0&&
                                                                    <div className="sync">
                                                                        <span className="material-symbols-outlined">
                                                                            sync
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {getStageStatus()==1&&
                                                                    <div className="loading">
                                                                        <span className="material-symbols-outlined">
                                                                            sync
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {getStageStatus()==2&&
                                                                    <div className="error">
                                                                        <span className="material-symbols-outlined">
                                                                            sync
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        {isLog==undefined&&
                            <div style={{
                                display: "flex",
                                width: 200,
                                height: 200, 
                                border: "1px dotted", 
                                margin: 5, 
                                "alignItems": "center",
                                "justifyContent": "center"
                            }}>
                                <Button onClick={()=>{addCondition()}} style={{color: "#3A07CD"}}>Add Condition Block</Button>
                            </div>
                        }
                </div>
            </div>
            {conditionopen!=null&&
                <CreateMotors 
                    close={closeMotors} 
                    pipelinetype="conditional"
                    width={1000}
                    position={[...pipelinestage.position, conditionopen]}
                    setfullscreenmode={closeMotors}
                    isLog={isLog}
                    source={source}
                    templateid={templateid}
                ></CreateMotors>
            }
        </>
    )
}

export default ConditionalBlock;