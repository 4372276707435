import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDomainApi, getDomainsApi, registerDomainApi, updateDomainApi } from "./client.service";
import { loading, loadingdone } from "../loader/loader.slice";
import { showError } from "../error.slice";

export const registerDomain = createAsyncThunk(
    "clientslice/registerdomain",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await registerDomainApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(error){
            dispatch(showError("Error saving domain!!!"));
            dispatch(loadingdone());
            throw error;
        }
    }
)

export const getDomains = createAsyncThunk(
    "clientslice/getdomain",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await getDomainsApi();
            dispatch(loadingdone());
            return res
        }catch(err){
            dispatch(loadingdone());
            throw err;
        }
    }
)

export const updateDomain = createAsyncThunk(
    "createslice/updatedomain",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading());
            let res = await updateDomainApi(payload);
            dispatch(loadingdone());
            return res;
        }catch(err){
            dispatch(loadingdone());
            throw err;
        }
    }
)

export const deleteDomain = createAsyncThunk(
    "createrslice/deletedomain",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            dispatch(loading())
            let res = await deleteDomainApi(payload);
            dispatch(loadingdone());
            dispatch(getDomains());
            return res;
        }catch(err){
            dispatch(loadingdone());
            throw err;
        }
    }
)

const Clientslice = createSlice({
    name: "clientslice",
    initialState:{
        "domains":[],
        "domaincreated": false,
        "domainupdated": false
    },
    reducers:{
        resetdomaincreated: (state, action)=>{
            state.domaincreated = false;
        },
        resetdomainupdated: (state, action)=>{
            state.domainupdated = false;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(registerDomain.fulfilled, (state, action)=>{
            state.domaincreated = true;
        })
        .addCase(getDomains.fulfilled, (state, action)=>{
            state.domains = action.payload;
        })
        .addCase(updateDomain.fulfilled,(state, action)=>{
            state.domainupdated = true;
        })
        .addCase(deleteDomain.fulfilled, (state, action)=>{

        })
    }
})

export const selectDomains = (state) => state.clientslice.domains;
export const selectDomainCreated = (state) => state.clientslice.domaincreated;
export const selectDomainUpdated = (state) => state.clientslice.domainupdated;

export const {resetdomaincreated, resetdomainupdated} = Clientslice.actions;

export default Clientslice.reducer;