import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {getLog, selectLog} from "../../Services/pipelinelogs/pipelinelogs.slice";
import { setpipeline, resetpipeline } from "../../Services/pipelines/pipeline.slice";
import {Delineatedpipeline, resetPipeline} from "../utils";
import * as _ from "lodash";
import CreateMotors from "./CreateMotors";


const Pipelinelogview = ()=>{

    const {appid, schemaid, pipelineid, status,logid, workspace} = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [
            activePipelineName, 
            setActivePipelineName
        ] = useState("Untitled");

    useEffect(()=>{
        if(logid!=undefined&&logid!=null&&logid!=""){
            dispatch(getLog(logid));
        }
    },[logid])

    const logdetails = useSelector(selectLog);

    const updateStageOutputs = (pipeline, delineatedPipeline)=>{
        for(let i=0; i< pipeline.length; i++){
            if(pipeline[i].type=="single"||pipeline[i].type=="internal"){
                pipeline[i] = {...pipeline[i],
                                output: delineatedPipeline[pipeline[i].stageindex].output,
                                outputschema: delineatedPipeline[pipeline[i].stageindex].outputschema,
                                executionTime: delineatedPipeline[pipeline[i].stageindex].executionTime
                              }
            }else if(pipeline[i].type=="conditional"){
                for(let j=0; j< pipeline[i].conditions.length; j++){
                    let conditionalpipline = pipeline[i].conditions[j].pipeline;
                    updateStageOutputs(conditionalpipline, delineatedPipeline);
                }

            }else if(pipeline[i].type=="loop"){
                let currentindexschema = [
                    {
                      ...delineatedPipeline[pipeline[i].stageindex].outputschema[0].subschema[0],
                      key: "current_index",
                      "label": "Current Index",
                      value: delineatedPipeline[pipeline[i].stageindex].output.array_to_loop[delineatedPipeline[pipeline[i].stageindex].current_index]
                     }
                   ]
                pipeline[i] = {...pipeline[i],
                               output: delineatedPipeline[pipeline[i].stageindex].output,
                               outputschema: delineatedPipeline[pipeline[i].stageindex].outputschema,
                               executionTime: delineatedPipeline[pipeline[i].stageindex].executionTime,
                               currentindex: delineatedPipeline[pipeline[i].stageindex].current_index,
                               loopschema: currentindexschema,
                               loopvalue: delineatedPipeline[pipeline[i].stageindex].output.array_to_loop[delineatedPipeline[pipeline[i].stageindex].current_index]
                              }
                let loop = updateStageOutputs(pipeline[i].loop, delineatedPipeline);
                pipeline[i] = {...pipeline[i],
                               loop: loop
                              }
            }
        }
    }

    const updateMappingMap = ()=>{

    }

    
    useEffect(()=>{
        if(logdetails==""||logdetails==null){
            return
        }
        if(logdetails?.pipeline.length==0){
            return
        }
        _.mapKeys(logdetails.delineatedPipeline,(value, key)=>{
            Delineatedpipeline[key] = value;
        })
        // dispatch(setmappingmap(logdetails.mappingMap))
        let pipelineCopy = [...logdetails.pipeline];
        updateStageOutputs(pipelineCopy,logdetails.delineatedPipeline);
        dispatch(setpipeline(pipelineCopy));
        setActivePipelineName(logdetails.pipelineid);
    },[logdetails])

    const closePipes = ()=>{
        resetPipeline();
        dispatch(resetpipeline());
        navigate("/"+workspace+"/pipelinelogs/"+appid+"/"+schemaid+"/"+pipelineid+"/"+status);
    }

    return (
        <div className="input-container">
            <CreateMotors 
                close={closePipes} 
                pipelinetype="main"
                width={1000}
                activePipelineName = {activePipelineName}
                setActivePipelineName = {()=>{}}
                testStageAction = {()=>{}}
                position={[]}
                savePipelinename={()=>{}}
                isLog={true}
            ></CreateMotors>
        </div>
    )
}


export default Pipelinelogview;