import axios from "axios";
import { signinURL , baseURL} from "../../env";

export const signInApi = async (payload)=>{
    let res = await fetch(signinURL+"client/signin/"+payload.source+"?code="+payload.code+"&"+"state="+payload.state);
    return res.json();
}


export const signupApi = async (payload)=>{
    let res = await fetch(signinURL+"client/signup",{
        "method": "POST",
        body: JSON.stringify(payload),
        "headers":{
            "Content-type": "application/json",
        },
    })
    return res.json();
}

export const createGoogleSigninRequestApi = async (payload)=>{
    let res = await fetch(
        signinURL+"client/googlesignuprequest/",
        {
            "method": "GET"
        }
    )
    return res.json()
}

export const loginApi = async (payload)=>{
    let res = await fetch(signinURL+"client/login",{
        "method": "POST",
        body: JSON.stringify(payload),
        "headers":{
            "Content-type": "application/json"
        }
    })
    return res.json()
}

export const forgotpassrequestApi = async(payload)=>{
    let res = await fetch(signinURL+"client/forgotpass",{
        "method": "POST",
        body: JSON.stringify(payload),
        "headers":{
            "Content-type": "application/json"
        }
    })
    return res.json()
}

export const changePasswordApi = async(payload)=>{
    let res = await fetch(signinURL+"client/changepassword",{
        "method": "POST",
        body: JSON.stringify(payload),
        "headers": {
            "Content-type": "application/json"
        }
    })
    return res.json();
}