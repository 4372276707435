import {deleteM, get, postform, postjson, putjson} from "../signin/axios";
import { baseURL } from "../../env";


export const registerEventApi  = async (event)=>{
    let res = await postjson(baseURL+"events/", event);
    return res;
}

export const getEventsApi = async (schemaid)=>{
    let res = await get(baseURL+"events/"+schemaid);
    return res;
}

export const deleteEventApi = async (eventid)=>{
    let res = await deleteM(baseURL+"events/"+eventid);
    return res;
}


export const getSchemaEventsApi = async(payload)=>{
    let res = await get(baseURL+"events/schema/"+payload);
    return res;
}


export const getLoopsApi = async(payload)=>{
    let res = await get(baseURL+"events/loops/");
    return res;
}


export const getEventsByIdApi = async (payload)=>{
    let res = await get(baseURL+"events/getbyid/"+payload);
    return res;
}

export const updateEventApi = async (payload)=>{
    let res = await putjson(baseURL+"events/"+payload.id, payload.schema);
    return res;
}

export const getWorkspaceLoopsApi = async (payload)=>{
    let res = await get(baseURL+"events/loops/"+payload);
    return res;
}

export const getWorkspaceLoopAppsApi = async (payload)=>{
    let res = await get(baseURL+"events/loopapps/"+payload);
    return res;
}