import "./Schemalist.css";
import { useDispatch, useSelector } from "react-redux";
import {
            getSchemas, 
            selectSchemas, 
            getAppSchemas, 
            deleteSchema, 
            resetschemadeleted, 
            selectSchemadeleted,
            createSchemas,
            selectCreateschema,
            selectCreatedId,
            resetCreatedId,
            selectSchemaLoader
        } from "../../Services/schemas/schema.slice";
import { useNavigate , Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, CircularProgress, LinearProgress, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {showError} from "../../Services/error.slice";
import BreadCrumbs from "../BreadCrumbs";
import EnvironVariables from "../environvariables/Environvariables";
import ManifestStarter from "../manifest/ManifestStarter";
import { getManifestVersion, selectManifestLoader, selectManifestVersion } from "../../Services/manifest/manifest.slice";
import Readme from "../readme/Readme";
import { getDocument, 
         getDocumentationPermission, 
         resetactivedocumentpermission, 
         resetdocument, 
         selectActiveDocument, 
         selectActiveDocumentPermission, 
         selectDocumentLoader, 
         updateDocument 
        } from "../../Services/documentations/documentations.slice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const SchemaDeleteModal = ({
                            open, 
                            handleClose,
                            schema,
                            deleteSchema,
                            deleteclicked
                        })=>{
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Delete Schema {schema?.name}
                    </h3>
                    {deleteclicked?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress></CircularProgress>
                        </div>:
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Deleting schema will result in deleting all versions of schema along with associated pipelines and events. Are you sure you want to delete the schema? 
                            </Typography>
                            <div style={{display: "flex", "padding": 10}}>
                                <div>
                                    <Button onClick={()=>{handleClose()}}>Cancel</Button>
                                </div>
                                <div>
                                    <Button onClick={()=>{deleteSchema(schema._id)}}>Yes</Button>
                                </div>
                            </div>
                        </>
                    }
                </div>
                
            </Box>
      </Modal>
    )
}


const SchemaCreateModal = ({open, handleClose, appid})=>{

    const [schemaName, setSchemaname] = useState("");

    const [schemaSlug, setSchemaslug] = useState("");

    const [saveClicked, setSaveclicked] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const {workspace} = useParams();

    const save = ()=>{
        if(schemaName==""){
            dispatch(showError("Schema name cannot be empty"));
            return;
        }

        if(schemaSlug==""){
            dispatch(showError("Schema slug cannot be empty"));
            return;
        }
        setSaveclicked(true);
        dispatch(createSchemas({
            "app": appid,
            "schemaname": schemaName,
            "schemaslug": schemaSlug,
            "schema":[
                {
                    "inputtype": "auto",
                    "inputkey": "id",
                    "inputlabel": "Id",
                    "inputhelp": "Id of the schema object",
                    "required": true,
                    "unique": true,
                    "optionlist": [],
                    "defaultValue": "",
                    "type": "system"
                },
                {
                    "inputtype": "datetime",
                    "inputkey": "createdAt",
                    "inputlabel": "Created At",
                    "inputhelp": "Datetime of creation of object",
                    "required": true,
                    "unique": false,
                    "optionlist":[],
                    "defaultValue": ()=>{return new Date()},
                    "type": "system"
                },
                {
                    "inputtype": "datetime",
                    "inputkey": "updatedAt",
                    "inputlabel": "Updated At",
                    "inputhelp": "Datetime of updation of object",
                    "required": true,
                    "unique": false,
                    "optionlist":[],
                    "defaultValue": ()=>{return new Date()},
                    "type": "system"
                }
            ]
        }));
    }

    const schemaId = useSelector(selectCreatedId);

    useEffect(()=>{
        if(schemaId==null||saveClicked==false){
            return
        }
        dispatch(resetCreatedId());
        navigate("/"+workspace+"/schemas/"+appid+"/details/"+schemaId)
    },[schemaId])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
            >
                <h3>
                    Create Schema
                </h3>
                {saveClicked?
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <CircularProgress></CircularProgress>
                    </div>:
                    <>
                        <div className="schema-create-input">
                            <TextField label="Schema Name" value={schemaName} onChange={(e)=>{setSchemaname(e.target.value)}}></TextField>
                        </div>
                        <div className="schema-create-input">
                            <TextField label="Schema slug (in smallcase) " value={schemaSlug} onChange={(e)=>{setSchemaslug(e.target.value.toLowerCase())}}></TextField>
                        </div>
                        <div className="schema-create-buttons">
                            <div>
                                <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>Cancel</Button>
                            </div>
                            <div>
                                <Button onClick={()=>{save()}} style={{color: "#3A07CD"}}>Save</Button>
                            </div>
                        </div>    
                    </>
                }
            </Box>
        </Modal>
    )

}


const Schemalist = ()=>{

    const {appid, workspace} = useParams();


    const [reports, setReports] = useState({
        "total": 10,
        "dailycount": 1
    });

    const [opendelete, setOpendelete] = useState(false);
    const [schematodelete, setSchematodelete] = useState(null);
    const [deleteclicked, setDeleteClicked] = useState(false);

    const [manifest, setManifest] = useState(false);

    const [opencreate, setOpencreate] = useState(false);

    const schemas = useSelector(selectSchemas);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const delSchema = (schemaid)=>{
        // console.log("in the delete function");
        setDeleteClicked(true);
        dispatch(resetschemadeleted());
        dispatch(deleteSchema({schemaid: schemaid, appid: appid}))

    }

    const openDeleteModal = (schema)=>{
        setSchematodelete(schema);
        setOpendelete(true)
    }

    const closeDeleteModal = ()=>{
        setSchematodelete(null);
        setOpendelete(null);
    }

    const closeCreatedModal = ()=>{
        setOpencreate(false);
    }

    const schemaDeleted = useSelector(selectSchemadeleted);

    const [editorState, setEditorState] = useState(
        ""
      );

    useEffect(()=>{
        
        if(schemaDeleted&&deleteclicked){
            setDeleteClicked(false);
            closeDeleteModal()
        }

    },[schemaDeleted])

    useEffect(()=>{
        dispatch(getAppSchemas(appid))
        dispatch(resetdocument());
        dispatch(resetactivedocumentpermission());
        dispatch((getDocument({
            "source": "app",
            "sourceid": appid
        })))
        dispatch(getDocumentationPermission({
            "source": "app",
            "sourceid": appid
        }))
    },[])


    const resourcepermission = useSelector(selectActiveDocumentPermission);

     // description controls

     const remotedoc = useSelector(selectActiveDocument);
    
     // called on load
     useEffect(()=>{
         if(remotedoc!=""||remotedoc!=undefined){
            setEditorState(remotedoc);
         }
 
     },[remotedoc]);
 
 
     const setAppDoc = (doc)=>{
         let desc = doc;
         dispatch(updateDocument({
                                    source: "app",
                                    sourceid: appid,
                                    document: desc
                                }));
         setEditorState(doc);
     }


    useEffect(()=>{
        dispatch(getManifestVersion({
            source: "app",
            sourceid: appid
       }))
    },[])

    const publishedversion = useSelector(selectManifestVersion);

    const openPublish = ()=>{
        navigate("/"+workspace+"/manifest/app/"+appid)
    }

    const schemaloader = useSelector(selectSchemaLoader);

    const documentloader = useSelector(selectDocumentLoader);

    const manifestloader = useSelector(selectManifestLoader);

    return (
        <>
            <div className="schema-list">
                {schemaloader>0&&
                    <>
                        <LinearProgress></LinearProgress>
                    </>
                }
                <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "apps",
                                "url": "/"+workspace+"/apps"
                            }
                        ]:
                        [
                        {
                            "name": "dashboard",
                            "url": "/"
                        },
                        {
                            "name": "workspace",
                            "url": "/workspaces/"+workspace+"/"
                        },
                        {
                            "name": "apps",
                            "url": "/"+workspace+"/apps"
                        }
                        ]
                    }
                >
                </BreadCrumbs>
                <div className="schema-main-container">
                    <div className="schema-list-container">
                        <div className="schema-headers">
                            <h3>Schemas</h3>
                            <Button  onClick={()=>{setOpencreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
                        </div>
                        {schemas?.map((schema)=>{
                            return(
                                <div className="schema-item">
                                    <Link to={"/"+workspace+"/schemas/"+appid+"/details/"+schema._id} style={{textDecoration:"none"}}>
                                        <div>{schema.schemaname}</div>
                                    </Link>
                                    <div>
                                        {schema.access=="write"&&
                                            <IconButton onClick={()=>{openDeleteModal(schema)}}>
                                                <span class="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="app-main-settings">
                        <div>
                            <h3>
                                Environment Variables
                            </h3>
                            <EnvironVariables
                                resource={"app"}
                                resourceId={appid}
                            >
                            </EnvironVariables>
                        </div>
                        <div>
                            <h3>
                                App Settings
                            </h3>
                            <div className="publish-block">
                                {manifestloader>0?
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <CircularProgress></CircularProgress>
                                    </div>:
                                    <>
                                        <div>
                                            {publishedversion.version>1?
                                                <div>
                                                    Status: published , version: <Link to={"/"+workspace+"/savedmanifest/app/"+appid+"/"+publishedversion.id}>{publishedversion.version-1}</Link>
                                                </div>:
                                                <div>
                                                    Status: not published
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <Button onClick={()=>{openPublish()}}>Publish</Button>
                                        </div>    
                                    </>
                                }
                            </div>
                        </div>
                        <div>
                            {documentloader>0?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <CircularProgress></CircularProgress>
                                </div>:
                                <>
                                    <Readme
                                        readme={editorState}
                                        setreadme={setAppDoc}
                                        write={resourcepermission}
                                        source={"app"}
                                        sourceid={appid}
                                    >
                                    </Readme>   
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <SchemaDeleteModal 
                open={opendelete}
                handleClose={closeDeleteModal}
                schema={schematodelete}
                deleteSchema={delSchema}
                deleteclicked={deleteclicked}
            ></SchemaDeleteModal>
            <SchemaCreateModal
                open={opencreate}
                handleClose={closeCreatedModal}
                appid={appid}
            >
            </SchemaCreateModal>
        </>
    )
}

export default Schemalist;