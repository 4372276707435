import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApplistApi, deleteAppApi, getApplistApi, getWorkspaceAppsApi, getWorkspaceAppsInitApi } from "./app.service";
import {showError} from "../error.slice";
import * as _ from "lodash";
import { loading, loadingdone } from "../loader/loader.slice";


export const getApps = createAsyncThunk(
    "apps/getapps",
    async(payload,{dispatch})=>{
        try{
            dispatch(setApploader());
            let res = await getApplistApi(payload);
            return res;
        }catch(err){
            dispatch(resetApploader())
            throw err;
        }
    }
)


export const createApp = createAsyncThunk(
    "apps/postapps",
    async(payload, {dispatch})=>{
        try{
            dispatch(loading());
            dispatch(resetappcreated())
            let res = await createApplistApi(payload);
            dispatch(loadingdone())
            return res;
        }catch(err){
            dispatch(loadingdone());
            dispatch(showError(err.response.data.error.message));
            // console.log(err.response.data.error);
            throw err;
        }
    }
)


export const deleteApp = createAsyncThunk(
    "apps/deleteapp",
    async(payload, {dispatch})=>{
        try{
            dispatch(loading());
            let res = await deleteAppApi(payload);
            dispatch(loadingdone())
            return res;
        }catch(err){
            dispatch(loadingdone());
            throw err;
        }
    }
)


export const getWorkspaceApps = createAsyncThunk(
    "apps/getworkspaceapps",
    async(payload, {dispatch})=>{
        try{
            let res = await getWorkspaceAppsApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error fetching applications"));
            throw err;
        }
    }
)

export const getWorkspaceAppsInit = createAsyncThunk(
    "apps/getworkspaceappsinit",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWorkspaceAppsInitApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error fetching applications for workspace"));
            throw err;
        }
    }
)

const appSlice = createSlice({
    name: "apps",
    initialState:{
        apps:[],
        appworspacepermission: "",
        appworkspaceshared: true,
        app:{},
        appcreated: false,
        appdeleted: false,
        workspaceapps: [],
        workspaceappsinit: [],
        apploader: 0
    },
    reducers:{
        resetappcreated:(state)=>{
            state.appcreated = false;
        },
        resetappdeleted: (state)=>{
            state.appdeleted = false;
        },
        resetworkspaceappsinit: (state)=>{
            state.workspaceappsinit = []
        },
        setApploader: (state)=>{
            state.apploader = state.apploader+1;
        },
        resetApploader: (state)=>{
            state.apploader = state.apploader-1;
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getApps.fulfilled,(state,action)=>{
            state.apps = action.payload.applist;
            state.appworspacepermission = action.payload.access;
            state.appworkspaceshared = action.payload.appworkspaceshared;
            state.apploader = state.apploader-1;
        })
        .addCase(createApp.fulfilled,(state,action)=>{
            state.appcreated = true;
        })
        .addCase(deleteApp.fulfilled, (state)=>{
            state.appdeleted = true;
        })
        .addCase(getWorkspaceApps.fulfilled, (state, action)=>{
            state.workspaceapps = action.payload;
        })
        .addCase(getWorkspaceAppsInit.fulfilled, (state, action)=>{
            state.workspaceappsinit = action.payload;
        })
    }
})

export const selectApplist = state => state.apps.apps;
export const selectAppcreated = state => state.apps.appcreated;
export const selectAppdeleted = state => state.apps.appdeleted;
export const selectAppWorkspaceAcess = state => state.apps.appworspacepermission;
export const selectAppWorkspaceshared = state => state.apps.appworkspaceshared;
export const selectWorkspaceApps  = state => state.apps.workspaceapps;
export const selectWorkspaceAppsInit = state => state.apps.workspaceappsinit;
export const selectAppLoader = state => state.apps.apploader;

export const {
                resetappcreated, 
                resetappdeleted,
                resetworkspaceappsinit,
                setApploader,
                resetApploader
            } = appSlice.actions;

export default appSlice.reducer;