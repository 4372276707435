import {get, postform, deleteM} from "../signin/axios";
import { baseURL } from "../../env";

export const getApplistApi = async(payload)=>{
    let appList = await get(baseURL+"apps/getlist/"+payload.workspace);
    // console.log(appList);
    return appList;
}

export const createApplistApi = async(payload)=>{
    let formData = new FormData();
    formData.append("logo",payload.logo);
    formData.append("name",payload.name);
    formData.append("workspace", payload.workspace);

    let res = await postform(baseURL+"apps/", formData);
    return res;
}


export const deleteAppApi = async(payload)=>{
    let resp = await deleteM(baseURL+"apps/"+payload);
    return resp;
}


export const getWorkspaceAppsApi = async(payload)=>{
    let resp = await get(baseURL+"apps/workspaceapps/"+payload);
    return resp;
}

export const getWorkspaceAppsInitApi = async(payload)=>{
    let resp = await get(baseURL+"apps/workspaceappsinit/"+payload);
    return resp;
}