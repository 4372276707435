import { useDispatch, useSelector } from "react-redux";
import StageContainer from "./StageContainer";
import { selectStage, addStage } from "../../Services/pipelines/pipeline.slice";
import * as _ from "lodash";

const testEqual = (val1, val2)=>{
    if(JSON.stringify(val1)==JSON.stringify(val2)){
        return true
    }else{
        return false;
    }
}

const MotorBodyTileLast = ({
                            last,
                            pipelinetype,
                            width,
                            updatePipeline,
                            pipelinestage,
                            index,
                            isLog,
                            source,
                            templateid,
                            pipelineid
                        })=>{
    
    const stage = useSelector((state)=>selectStage(state,pipelinestage),testEqual);

    const dispatch = useDispatch();

    const addStageInt = ()=>{
        dispatch(addStage({
            position: pipelinestage.position
        }))
    }



    return (
        <>
            {stage!=null&&
                <>
                    <div className="motor-body-tile-last">
                        <div className="motor-tile-body">
                            {/* <div className="motor-type-header">
                                <div className="motor-type-name">
                                    Input
                                </div>
                                <div>
                                    <span class="material-symbols-outlined" style={{fontSize:40}}>
                                        fullscreen
                                    </span>
                                </div>
                            </div> */}
                            {stage!=null&&
                                <StageContainer 
                                    pipelinetype={pipelinetype}
                                    width={width}
                                    pipelinestage={pipelinestage}
                                    index={index}
                                    stage={stage}
                                    isLog={isLog}
                                    source={source}
                                    templateid={templateid}
                                    pipelineid={pipelineid}
                                ></StageContainer>
                            }
                
                        </div>
                        {(stage.last==false)?
                            <>
                                <div className="motor-next-divider-last">
                                    <div className="motor-divider-line-last-container">
                                        <div className="motor-divider-line-last">
                                        </div>
                                    </div>
                                    {isLog==undefined&&
                                        <div className="add-icon" onClick={()=>{addStageInt()}}>
                                            <span className="material-symbols-outlined">
                                                add_circle
                                            </span>
                                        </div>
                                    }
                                    {last==false&&
                                        <div className="motor-divider-line-last-container">
                                            <div className="motor-divider-line-last">
                                            </div>
                                        </div>
                                    }    
                                </div>
                            </>:
                            <>
                            </>
                        }
                    </div>
            
                </>
            }
        </>
    )
}


export default MotorBodyTileLast;