import { Button, Checkbox } from "@mui/material";
import { useState , useEffect} from "react"
import "./SingleField.css";
import TextField from '@mui/material/TextField';
import SingleFieldPreview from "./SingleFieldPreview";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {getApps, selectApplist} from "./../Services/apps/app.slice";
import {selectSchemas, getAppSchemas}  from "./../Services/schemas/schema.slice";
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import * as _ from "lodash";
import { useParams } from "react-router-dom";

const SingleField = ({ addtoschema,sc, close})=>{

    const dispatch = useDispatch();


    const {workspace} = useParams();

    // const [selectedapp, setSelectedApp] = useState();
    
    // add datetime defaults for now
    const inputtypes = [
        {"name": "id", "value": "auto"},
        {"name": "String", "value": "string"},
        {"name": "Text", "value": "text"},
        {"name": "Integer", "value": "integer"},
        {"name": "Number", "value": "number"},
        {"name": "Boolean", "value": "boolean"},
        {"name": "Enum", "value": "enum"},
        {"name": "Datetime", "value": "datetime"},
    ]

    const [inputtype, setinputtype] = useState("string");
    const [inputkey, setinputkey] = useState("");
    const [inputlabel, setinputlabel] = useState("");
    const [inputhelp, setinputhelp] = useState("");
    const [required, setRequired] = useState(false);
    const [unique, setUnique] = useState(false);
    const [optionlist, setOptionlist] = useState([]);
    const [defaultValue, setDefaultValue] = useState("");
    const [displayApplist, setDisplayApplist] = useState([]);
    const [displaySchemaList, setDisplaySchemalist] = useState([]);
    const [displayFieldList, setDisplayFieldList] = useState([]);
    const [selectedAppid, setSelectedAppid] = useState("");
    const [selectedSchemaid, setSelectedSchemaid] = useState("");
    const [selectedFieldName, setSelectedFieldName] = useState("");
    
    const applist = useSelector(selectApplist);
    
    const schemalist = useSelector(selectSchemas);

    const [] = useState(false);

    useEffect(()=>{
        if(applist.length==0){
            dispatch(getApps({
                workspace: workspace
            }));
        }else{
            setDisplayApplist([...applist]);
        }
    },[applist])

    useEffect(()=>{
       let schemalistCopy = [...schemalist];
       setDisplaySchemalist([...schemalistCopy]);
    },[schemalist])


    const addoption = ()=>{
        let optionlistcopy = [...optionlist];
        optionlistcopy.push({
            "name": "",
            "value": ""
        });
        setOptionlist(optionlistcopy);
    }

    const deleteOption = (index)=>{  
        let optionlistcopy = [...optionlist];
        optionlistcopy.splice(index,1);
        setOptionlist(optionlistcopy);
    }

    const setoptionname = (val, index)=>{
        let optionlistcopy = [...optionlist];
        optionlistcopy[index] = {...optionlistcopy[index],
                                 name: val
                                };
        setOptionlist(optionlistcopy);
    }

    const setoptionvalue = (val, index)=>{
        let optionlistcopy = [...optionlist];
        optionlistcopy[index] = {...optionlistcopy[index],
                                 value: val
                                }
        setOptionlist(optionlistcopy)
    }

    // const setoption = (val,index)=>{
    //     let optionlistcopy = [...optionlist];
    //     optionlistcopy[index] = val;
    //     setOptionlist(optionlistcopy);
    // }

    useEffect(()=>{
        if(sc){
            setinputkey(sc.inputkey);
            setinputlabel(sc.inputlabel);
            setinputhelp(sc.inputhelp);
            setinputtype(sc.inputtype);
            setRequired(sc.required);
            setUnique(sc.unique);
            setOptionlist(sc.optionlist);
            setDefaultValue(sc.defaultValue);
        }
    },[sc])

    const save = ()=>{
        addtoschema({
            "inputtype": inputtype,
            "inputkey": inputkey,
            "inputlabel": inputlabel,
            "inputhelp": inputhelp,
            "required": required,
            "unique": unique,
            "optionlist": [...optionlist],
            "defaultValue": defaultValue,
            "type": "user"
        })
    }

    const selectApp = (val)=>{
        // console.log(val);
        setDisplayFieldList([]);
        dispatch(getAppSchemas(val));
        setSelectedAppid(val);
    }

    const selectSchema = (val)=>{
        let displaySchemaListCopy = [...displaySchemaList];
        let selectSchemaIndex = _.findIndex(displaySchemaListCopy,(sc)=>{return sc._id==val})
        setDisplayFieldList(displaySchemaListCopy[selectSchemaIndex].schema);
        setSelectedSchemaid(val);
    }

    return(
        <div className="form-editor">
            <div className="form-editor-header">    
                <IconButton onClick={()=>{close()}}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </IconButton>
            </div>
            <div className="form-container">
                <div className="input-specs">
                <div className="input-options"> 
                    <div className="input-options-label">
                        Field Type
                    </div>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="input-type-select-label">Field Type</InputLabel>
                                <Select
                                    labelId="input-type-select-label"
                                    id="input-type-select-label"
                                    value={inputtype}
                                    label="Field Type"
                                    onChange={(e)=>{setinputtype(e.target.value)}}
                                >
                                    {inputtypes.map((it)=>{
                                        return (
                                            <MenuItem value={it.value}>{it.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="input-options">
                    <div className="input-options-label">
                        Key (required)
                    </div>
                    <div>
                        <TextField fullWidth value={inputkey} onChange={(e)=>{setinputkey(e.target.value)}}></TextField>
                    </div>
                </div>
                <div className="input-options">
                    <div className="input-options-label">
                        Label (required)
                    </div>
                    <div>
                        <TextField fullWidth value={inputlabel} onChange={(e)=>{setinputlabel(e.target.value)}}></TextField>
                    </div>
                </div>
                <div className="input-options">
                    <div className="input-options-label">
                        Help
                    </div>
                    <div>
                        <TextField fullWidth value={inputhelp} onChange={(e)=>{setinputhelp(e.target.value)}}></TextField>
                    </div>
                </div>
                <div className="input-options">
                    <div className="input-options-label">
                        Required 
                        <Checkbox checked={required} onChange={()=>{setRequired(!required)}}></Checkbox>
                    </div>
                    {required&&
                        <div className="input-options">
                            <div className="input-options-label">
                                Default Value
                            </div>
                            <div>
                                <TextField fullWidth value={defaultValue} onChange={(e)=>{setDefaultValue(e.target.value)}}></TextField>
                            </div>
                        </div>
                    }
                </div>
                <div className="input-options">
                    <div className="input-options-label">
                        Unique
                        <Checkbox checked={unique} onChange={()=>{setUnique(!unique)}}></Checkbox>
                    </div>
                </div>

                {inputtype=="enum"&&
                    <div className="input-options">
                        {optionlist.map((op,index)=>{
                            return(
                                <div className="enum-options">
                                    <div className="enum-options-name">
                                        <div>
                                            Name
                                        </div>
                                        <TextField fullWidth value={op.name} onChange={(e)=>{setoptionname(e.target.value,index)}}></TextField>
                                    </div>
                                    <div className="enum-options-name">
                                        <div>
                                            Value
                                        </div>
                                        <TextField fullWidth value={op.value} onChange={(e)=>{setoptionvalue(e.target.value, index)}}></TextField>
                                    </div>
                                    <div className="enum-options-name" onClick={()=>{deleteOption(index)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                        <Button onClick={()=>{addoption()}}>Add Options</Button>
                    </div>
                }
                {inputtype=="schema"&&
                    <div>
                        <div className="relation-selects">
                            <FormControl fullWidth>
                                <InputLabel id="input-app-select-label">Select App</InputLabel>
                                    <Select
                                        labelId="input-app-select-label"
                                        id="input-app-select-label"
                                        value={selectedAppid}
                                        label="Select app"
                                        onChange={(e)=>{
                                            selectApp(e.target.value)
                                        }}
                                    >
                                        {displayApplist.map((it)=>{
                                            return (
                                                <MenuItem value={it._id}>{it.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                            </FormControl>
                        </div>
                        {selectedAppid!==""&&
                            <div className="relation-selects">
                                <FormControl fullWidth>
                                    <InputLabel id="input-schema-select-label">Select Schema</InputLabel>
                                        <Select
                                            labelId="input-schema-select-label"
                                            id="input-schema-select-label"
                                            value={selectedSchemaid}
                                            label = "Select Schema"
                                            onChange={(e)=>{selectSchema(e.target.value)}}
                                        >
                                            {displaySchemaList.map((sc)=>{
                                                return (
                                                    <MenuItem value={sc._id}>{sc.schemaname}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                </FormControl>
                            </div>
                        }
                        {selectedSchemaid!=""&&
                            <div className="relation-selects">
                                <FormControl fullWidth>
                                    <InputLabel id="input-schema-field-label">Select Field</InputLabel>
                                    <Select
                                        labelId="input-schema-field-label"
                                        id="input-schema-field-label"
                                        value={selectedFieldName}
                                        label="Select Field Name"
                                        onChange={(e)=>{setSelectedFieldName(e.target.value)}}
                                    >  
                                        {displayFieldList.map((sc)=>{
                                            return (
                                                <MenuItem value={sc.inputkey}>{sc.inputlabel}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </div>
                }
                {inputtype=="one_to_many_relation"&&
                    <div>

                    </div>
                }
                {inputtype=="many_to_many_relation"&&
                    <div>

                    </div>
                }
                </div>
                <div className="input-preview">
                    <div>
                        <h3>
                            Preview
                        </h3>
                    </div>
                    <SingleFieldPreview inputtype={inputtype} inputlabel={inputlabel} optionlist={optionlist}></SingleFieldPreview>
                </div>
            </div>
            <div className="input-options">
                <Button onClick={()=>{save()}} variant="outlined">Save</Button>
            </div>
        </div>
    )

}


export default SingleField;