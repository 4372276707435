import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changePasswordApi, createGoogleSigninRequestApi, forgotpassrequestApi, loginApi, signInApi, signOutApi, signupApi } from "./signin.service";
import { showError } from "../error.slice";

export const signin = createAsyncThunk(
    "auth/signin",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await signInApi(payload);
            if(res.status==0){
                return res;
            }else if(res.status==1){
                return
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message);    
            }
        }catch(err){
            dispatch(showError(err.response.data.error.message));
            return rejectWithValue(err.response.data.error.message);
        }   
    }
)

export const signUp = createAsyncThunk(
    "auth/signup",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await signupApi(payload);
            if(res.status){
                return true;
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message);
            }
        }catch(err){
            dispatch(showError("Error signing up"));
            throw err;
        }   
    }
)

export const login = createAsyncThunk(
    "auth/login",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await loginApi(payload);
            if(res.status){
                return res;
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message)
            }
        }catch(err){
            dispatch(showError("Error logging in"));
            return rejectWithValue(err.messages);
        }
    }
)


export const forgotpassrequest = createAsyncThunk(
    "auth/forgorpassrequest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await forgotpassrequestApi(payload);
            if(res.status){
                return true;
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message);
            }
        }catch(err){
            dispatch(showError("Error resetting password"));
            return rejectWithValue(err.message);
        }
    }
)

export const changePassword = createAsyncThunk(
    "auth/changepassword",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await changePasswordApi(payload);
            if(res.status){
                return true;
            }else{
                dispatch(showError(res.message));
                return rejectWithValue(res.message);
            }
        }catch(err){
            dispatch(showError("Error changing password"));
            return rejectWithValue(err.message);
        }
    }
)

export const createGoogleSigninRequest = createAsyncThunk(
    "auth/creategooglesiginrequest",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createGoogleSigninRequestApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error creating signing request"));
            return rejectWithValue(err.message);
        }
    }
)

// export const signout = createAsyncThunk(
//     "auth/signout",
//     async(payload, {dispatch, rejectWithValue, getState})=>{
//         let state = getState();
//         // let res = await signOutApi(state.auth.userid);
//         return res.data;
//     }
// )


const auth = createSlice({
    name: "auth",
    initialState: {
        "auth": false,
        "username": "",
        "userid": "",
        "token": "",
        "signupcreated": false,
        "forgotpasscreated": false,
        "passwordchanged": false,
        "defaultworkspace": "",
        "authfailed": null,
        "googlesigninrequest": "",
        "googleauth": false 
    },
    reducers:{
        resetauth: (state, action)=>{
            state.auth = false;
        },
        signout: (state, action)=>{
            state.auth = false;
            localStorage.removeItem("auth");
            localStorage.removeItem("token");
            localStorage.removeItem("email")
        },
        setauth: (state, action)=>{
            state.auth = true;
            state.username = action.payload.email;
            state.defaultworkspace = action.payload.workspaceId;
        },
        setgoogleauth: (state, action)=>{
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("auth", true);
            localStorage.setItem("email", action.payload.email);
            localStorage.setItem("defaultworkspace", action.payload.workspaceId);
            localStorage.setItem("clientid", action.payload.clientid);
            state.auth = true;
            state.username = action.payload.email;
            state.defaultworkspace = action.payload.workspaceId;
            state.googleauth = true;
        },
        resetforgotpass: (state, action)=>{
            state.forgotpasscreated = false;
        },
        resetchangepassword: (state, action)=>{
            state.passwordchanged = false;
        },
        resetauthfailed: (state, action)=>{
            state.authfailed = action.payload;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(signin.fulfilled, (state, action)=>{
            if(action.payload!=undefined){
                localStorage.setItem("token", action.payload.token);
                localStorage.setItem("auth", true);
                localStorage.setItem("email", action.payload.email);
                localStorage.setItem("defaultworkspace", action.payload.workspaceId);
                localStorage.setItem("clientid", action.payload.clientid);
                state.auth = true;
                state.username = action.payload.email;
                state.defaultworkspace = action.payload.workspaceId;
            }
        })
        builder.addCase(signin.rejected, (state, action)=>{
            state.authfailed = action.payload;
        })
        builder.addCase(signUp.fulfilled, (state, action)=>{
            state.signupcreated = true;
        })
        builder.addCase(login.fulfilled, (state, action)=>{
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("auth",true );
            localStorage.setItem("email", action.payload.email);
            localStorage.setItem("defaultworkspace", action.payload.workspaceId);
            localStorage.setItem("clientid", action.payload.clientid);
            state.auth = true;
            state.username = action.payload.email;
            state.defaultworkspace = action.payload.workspaceId;
        })
        builder.addCase(createGoogleSigninRequest.fulfilled, (state, action)=>{
            state.googlesigninrequest = action.payload._id;
        })
        builder.addCase(forgotpassrequest.fulfilled, (state, action)=>{
            state.forgotpasscreated = true;
        })
        builder.addCase(changePassword.fulfilled, (state, action)=>{ 
            state.passwordchanged = true;
        })
    }
})

export const selectForgotpasscreated = (state) => state.auth.forgotpasscreated;

export const selectSignupcreated = (state) => state.auth.signupcreated;

export const selectpasswordchanged = (state) => state.auth.passwordchanged;

export const selectAuth = (state) => state.auth.auth;

export const selectDefaultWorkspace = (state)=> state.auth.defaultworkspace;

export const selectAuthFailed = (state) => state.auth.authfailed;

export const selectGoogleSinginRequest = (state) => state.auth.googlesigninrequest;

export const selectUsername = (state) => state.auth.username;

export const selectgoogleauth = (state) => state.auth.googleauth;

export const {
                signout, 
                setauth, 
                resetforgotpass, 
                resetchangepassword,
                resetauthfailed,
                setgoogleauth
            } = auth.actions;

export default auth.reducer;