import {get} from "../signin/axios";
import { baseURL } from "../../env";

export const getLoglistApi = async(payload)=>{
    let res = await get(baseURL+"pipelinelogs/"+payload.id+"/"+payload.status+"?start="+payload.start+"&"+"limit="+payload.limit);
    return res;
}

export const getLogApi = async(payload)=>{
    let res = await get(baseURL+"pipelinelogs/"+payload);
    return res;
}