import { baseURL, signinURL } from "../../env"
import { get } from "../signin/axios"



export const getManifestDetailsApi = async(payload)=>{
    let res = await get(signinURL+"marketplace/manifest/"+payload.manifestid+"/"+payload.preview+"/"+payload.manifestscope);
    return res;
}

export const getManifestDocItemsApi = async(payload)=>{
    let res = await get(signinURL+"marketplace/manifest/documentation/"+payload.manifestid+"/"+payload.preview+"/"+payload.manifestscope);
    return res;
}