import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {selectErrors, removeerror} from "./Services/error.slice";
import { useState } from "react";

const ErrorBar = ({index,message})=>{

    const [open, setOpen] = useState(true);

    const dispatch = useDispatch();

    const handleClose = ()=>{
        dispatch(removeerror(index));
        setOpen(false);
    }

    return (
        <Snackbar
            anchorOrigin={{ "vertical": "top", "horizontal": "center" }}
            autoHideDuration={3000}
            open={open}
            onClose={handleClose}
            message={message}
        />
    )
}

const ErrorComponent = ()=>{

    const errors = useSelector(selectErrors);

    return (
        <>
            {errors.map((error,i)=>{
                return (
                    <ErrorBar
                        index={i}
                        message={error}
                    >
                    </ErrorBar>
                )
            })}
        </>
    )

}


export default ErrorComponent;