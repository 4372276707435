import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { filesInFolder , selectFolderfiles, deleteFile, resetfiledeleted, selectFileDeleted, selectFileFolderpermission} from "../../Services/filesystem/filesystem.slice";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, LinearProgress } from "@mui/material";
import { getValfromPipe } from "../utils";
import { createFile, resetfilecreated, selectFileCreatedRes } from "../../Services/filesystem/filesystem.slice";
import { useParams } from "react-router-dom";

const GridView = ()=>{
    
}


const FolderView = ({
                        path, 
                        folderId,
                        handleClose,
                        pipelinemode,
                        setSchemaMappingData,
                        schemaMapping,
                        pipelinestage
                    })=>{

    const dispatch = useDispatch();

    const {workspace} = useParams();

    const [files, setFiles] = useState([]);

    useEffect(()=>{
        if(path==undefined||path==""||path==null){
            return;
        }
        /*
         todo: write api for fetching files that priotize pagination for the selected file
        */
        if(pipelinestage){
            dispatch(filesInFolder({
                path: path,
                workspace: workspace
            }))
        }else{
            dispatch(filesInFolder({
                path: path,
                workspace: workspace
            }));
        }
        
    },[path])

    const [fileval, setFileVal] = useState("");

    const [fileloading, setfileloading] = useState(false);

    const remotefiles = useSelector(selectFolderfiles);

    const filedeleted = useSelector(selectFileDeleted);

    const [deleteClicked, setDeleteClicked] = useState(false);

    const deleteFileAction = (id)=>{
        dispatch(resetfiledeleted());
        setfileloading(true);
        setDeleteClicked(true);
        dispatch(deleteFile(id));
    }

    useEffect(()=>{
        if(filedeleted){
            dispatch(filesInFolder({
                path: path,
                workspace: workspace
            }));
        }
    },[deleteClicked, filedeleted])

    

    const getChecked = (url)=>{
        if(schemaMapping.mapping.inputtype==2){
            let val = schemaMapping.mapping.val;
            if(val.url==url){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const selectFile = (file, checked)=>{
        if(checked){
            setSchemaMappingData(file)
        }else{
            setSchemaMappingData("");
        }
    }

    const handleChange = (e)=>{
        let file = e.target.files[0];
        dispatch(resetfilecreated());
        setfileloading(true);
        dispatch(createFile({
                "file": file,
                "path": path,
                "workspace": workspace
            }))
    }

    const fileuploadres = useSelector(selectFileCreatedRes);

    const filefolderpermission = useSelector(selectFileFolderpermission);

    useEffect(()=>{
        dispatch(filesInFolder({
            path: path,
            workspace: workspace
        }))
        setFileVal("")
    },[fileuploadres])


    useEffect(()=>{
        if(remotefiles.length>0){
            let remotefilesCopy = [...remotefiles];
            if(fileloading==true){
                setfileloading(false);
            }
            setFiles(remotefilesCopy);
        }
    },[remotefiles])

    return (
        <div>
            {fileloading&&
                <LinearProgress></LinearProgress>
            }
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {filefolderpermission=="write"&&
                    <input type="file" value={fileval} onChange={(e)=>{handleChange(e)}}></input>
                }
                <IconButton onClick={()=>{handleClose()}}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </IconButton>
            </div>
            <div>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>LastModified</TableCell>
                            {pipelinemode?
                             <TableCell>
                                Select
                             </TableCell>:
                             <TableCell>
                                Actions
                             </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files!=undefined&&
                            <>
                                {files.map((file)=>{
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {file.name}
                                            </TableCell>
                                            <TableCell>
                                                {file.url}
                                            </TableCell>
                                            <TableCell>
                                                {file.size}
                                            </TableCell>
                                            <TableCell>
                                                {file.lastModified}
                                            </TableCell>
                                            <TableCell>
                                                {pipelinemode?
                                                    <>
                                                        <input type="checkbox" checked={getChecked(file.url)} onChange={(e)=>{selectFile(file, e.target.value)}}></input>
                                                    </>:
                                                    <>
                                                        {filefolderpermission=="write"&&
                                                            <IconButton onClick={()=>{deleteFileAction(file._id)}}>
                                                                <span className="material-symbols-outlined">
                                                                    delete
                                                                </span>
                                                            </IconButton>
                                                        }
                                                    </>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}    
                            </>
                        }
                        
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}


export default FolderView;