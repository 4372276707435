
import { useState } from "react";
import DeclareVariable from "../apps/Fields/DeclareVariable";
import { Tooltip, IconButton, TextField } from "@mui/material";
import * as _ from "lodash";
import "./PropsToolbar.css";
import { useDispatch, useSelector } from "react-redux";
import { setElement, updateElementProps, selectActiveElement, selectActivePosition, setChildEnabled, selectChildEnabled } from "../../Services/apptemplates/apptemplates.slice";
import ElementConditions from "./ElementConditions";
import InputPropstoolbar from "./InputPropstoolbar";
import RouterProps from "./RouterProps";
import { useParams } from "react-router-dom";

const Proptoolbar = ({ 
                        templateid
                    })=>{

    const {type} = useParams();
    
    const innerhtmlavl = [
                            "div", 
                            "p", 
                            "a", 
                            "span", 
                            "pre", 
                            "h1", 
                            "h2", 
                            "h3", 
                            "h4", 
                            "h5", 
                            "h6", 
                            "button", 
                            "td",
                            "option", 
                            "li", 
                            "Text", 
                            "markdown",
                            "strong"
                        ];

    const inputelements = ["input", "select"];

    const dispatch = useDispatch();

    const position = useSelector(selectActivePosition)

    const element = useSelector(selectActiveElement(position));

    const isInnerHtmlAvl = ()=>{
        if(element!==undefined){
            let tagIndex = _.findIndex(innerhtmlavl,(tag)=>{return tag==element.tag});
            if(tagIndex>-1){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const isInputElements = ()=>{
        if(element!==undefined){
            let tagIndex = _.findIndex(inputelements, (tag)=>{return tag==element.tag});
            if(tagIndex>-1){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const modifyInnerHtml = (sc, scm)=>{
        let elementCopy = {...element};
        elementCopy = {...elementCopy,
                       innerhtml: sc,
                       innerhtmlmapping: scm
                       }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
        // setElement(position, elementCopy);
    }

    const modifyProps = (sc, scm)=>{
        let elementCopy = {...element};
        elementCopy = {...elementCopy,
            props: sc,
            propsmapping: scm
           };
        dispatch(updateElementProps({
            position: position,
            element: elementCopy,
            props: {
                props: sc,
                propsmapping: scm
            }
        }))
        // setElement(position, elementCopy);
    }


    const modifyLoopVariable = (sc, scm)=>{
        let elementCopy = {...element};
        elementCopy = {...elementCopy,
                       loopvariable: sc,
                       loopvariablemapping: scm
                      }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    // todo: check for change types of inputs
    const changeElementBlockType = (type)=>{
        let elementCopy = {...element};
        if(type=="single"){
            elementCopy = {...elementCopy,
                           type: type,
                           loopvariable:[],
                           loopvariablemapping:[],
                           conditions:[]
                          }
        }else if(type=="conditional"){
            elementCopy = {...elementCopy,
                           type: type,
                           conditions:[[
                            {
                                "valuetocompare":"",
                                "tocompareopen":false,
                                "comparetotype": "any",
                                "options":[],
                                "condition": "",
                                "valuecompareto":"",
                                "comparetoopen":false
                            }
                           ]],
                           loopvariable:[],
                           loopvariablemapping:[]
                          }
        }else if(type=="loop"){
            elementCopy = {...elementCopy,
                           type: type,
                           conditions:[[]],
                           loopvariable:[
                                {
                                    "key": "loopvariable",
                                    "label": "Loopvariable",
                                    "type": "array",
                                    "required": true,
                                    "guided": false,
                                    "guidefinshed": true,
                                    "fillallowed": true,
                                    "subschema":[
                                        {
                                            "type": "any"
                                        }
                                    ]
                                }
                            ],
                            loopvariablemapping:[
                                {
                                    "key": "loopvariable",
                                    "mapping":""
                                }
                            ]
                          }
        }
        dispatch(setElement({
            position:position,
            element: elementCopy
        }))
    }

    const toggleMobile = ()=>{
        let elementCopy = {...element};
        let mobile = elementCopy.mobile;
        elementCopy = {...elementCopy,
                        mobile: !mobile
                       }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const toggleDesktop = ()=>{
        let elementCopy = {...element};
        let desktop = elementCopy.desktop;
        elementCopy = {...elementCopy,
                       desktop: !desktop
                      }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }


    const addextaprop = ()=>{
        let extraprops = [...element.extraprops]
        extraprops.push({
            "key": "",
            "value": ""
        });
        let elementCopy = {...element,
                            extraprops: extraprops
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }));
    }

    const deleteExtraProp = (index)=>{
        let extraprops = [...element.extraprops];
        extraprops.splice(index,1);
        let elementCopy = {...element,
                            extraprops: extraprops
                           }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const setExtraPropKey = (index, val)=>{
        let extraprops = [...element.extraprops];
        extraprops[index] = {...extraprops[index],
                              key: val
                            }
        let elementCopy = {...element,
                            extraprops: extraprops
                           }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }));
    }

    const setExtraPropValue = (index, val)=>{
        let extraprops = [...element.extraprops];
        extraprops[index] = {...extraprops[index],
                             value: val
                            }
        let elementCopy = {...element,
                            extraprops: extraprops
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const childenabled = useSelector(selectChildEnabled);

    const allowchild = (val)=>{
        dispatch(setChildEnabled({
            position: position,
            state: val
        }))
    }



    return(
        <div>
            <div className="props-container">
                <div className="element-type-header">
                    <div className="element-block-controls">
                        <div style={{display: "flex", alignItems: "center"}}>
                            {element?.tag}
                        </div>
                        {type=="html"&&
                            <div>
                                <Tooltip title="Element Desktop Visibility">
                                    <IconButton onClick={()=>{toggleDesktop()}}>
                                        <span className={element?.desktop?"material-symbols-outlined active":"material-symbols-outlined"}>
                                            desktop_windows
                                        </span>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Element Mobile Visibility">
                                    <IconButton onClick={()=>{toggleMobile()}}>
                                        <span className={element?.mobile?"material-symbols-outlined active":"material-symbols-outlined"}>
                                            smartphone
                                        </span>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </div>
                    <div className="element-block-controls"> 
                        <Tooltip title="Block">
                            <IconButton onClick={()=>{changeElementBlockType("single")}}>
                                <span className={element?.type=="single"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                    stack
                                </span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Conditional Block">
                            <IconButton onClick={()=>{changeElementBlockType("conditional")}}>
                                <span className={element?.type=="conditional"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                    device_hub
                                </span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Loop Block">
                            <IconButton onClick={()=>{changeElementBlockType("loop")}}>
                                <span className={element?.type=="loop"?'material-symbols-outlined active':"material-symbols-outlined"}>
                                    all_inclusive
                                </span>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            {element!=undefined&&
                <div className="props-container">
                    Allow childs: <input 
                                type="checkbox" 
                                value={element?.childenabled} 
                                checked={element?.childenabled}
                                onChange={(e)=>{allowchild(e.target.checked)}}
                                disabled={childenabled&&!element.childenabled} 
                               ></input>
                </div>
            }
            {element?.type=="conditional"&&
                <>
                    <ElementConditions
                        templateid={templateid}
                        element={element}
                        position={position}
                    ></ElementConditions>
                </>
            }
            {element?.type=="loop"&&
                <>
                    <h3>Loop Variable</h3>
                    <DeclareVariable
                        schema={element.loopvariable}
                        modifySchema={modifyLoopVariable}
                        schemamapping={element.loopvariablemapping}
                        pipelinetype={""}
                        stageindex={0}
                        stagetype={"single"}
                        layout={"vertical"}
                        pipelinestage={undefined}
                        source={"appprops"}
                        templateid={templateid}
                        indexarr={position}
                        sourceid={templateid}
                    >

                    </DeclareVariable>
                </>
            }
            {isInnerHtmlAvl()&&
                <div className="props-container">
                    <h3>
                        InnerHtml
                    </h3>
                    <div>
                        <DeclareVariable
                            schema={element.innerhtml} 
                            modifySchema={modifyInnerHtml}
                            schemamapping={element.innerhtmlmapping} 
                            pipelinetype={""}
                            stageindex={0}
                            stagetype={"single"}
                            layout={"vertical"}
                            pipelinestage={undefined}
                            source={"appprops"}
                            templateid={templateid}
                            indexarr={position}
                            sourceid={templateid}
                        >
                        </DeclareVariable>
                    </div>
                </div>
            }
            {element?.props!=undefined&&isInputElements()==false&&element?.tag!="router"&&
                <>
                    <div className="props-container">
                        <h3>
                            Props
                        </h3>
                        <div>
                            <DeclareVariable
                                schema={element.props}
                                modifySchema={modifyProps}
                                schemamapping={element.propsmapping}
                                pipelinetype={""}
                                stageindex={0}
                                stagetype={"single"}
                                layout={"vertical"}
                                pipelinestage={undefined}
                                source={"appprops"}
                                templateid={templateid}
                                indexarr={position}
                                sourceid={templateid}
                            >
                            </DeclareVariable>
                        </div>
                    </div>
                </>
            }

            {isInputElements()&&
                <>
                    {element.tag=="input"&&
                        <InputPropstoolbar
                            element={element}
                            position={position}
                            templateid={templateid}
                        >
                        </InputPropstoolbar>
                    
                    }
                
                </>
            }
            {element?.tag=="router"&&
                <RouterProps
                    position={position}
                    element={element} 
                    templateid={templateid}
                >
                </RouterProps>
            }
            {element!=undefined&&element!=null&&
                <div className="props-container">
                    <h3>
                        Extra Props
                    </h3>
                    <div>
                        {/* <DeclareVariable
                            schema={element.extraprops}
                            modifySchema={modifyExtraProps}
                            schemamapping={element.extrapropsmapping}
                            pipelinetype={""}
                            stageindex={0}
                            stagetype={"single"}
                            layout={"vertical"}
                            pipelinestage={undefined}
                            source={"appprops"}
                            templateid={templateid}
                            indexarr={position}   
                        >
                        </DeclareVariable> */}
                        {element.extraprops.map((prop, index)=>{
                            return (
                                <div className="extra-prop-item">
                                    <div className="extra-prop-val">
                                        <TextField 
                                            sx={{width:100}}
                                            value={prop.key} 
                                            label={"key"} 
                                            onChange={(e)=>{setExtraPropKey(index,e.target.value)}}></TextField>
                                    </div>
                                    <div className="extra-prop-val">
                                        <TextField 
                                            value={prop.value}
                                            sx={{width:100}} 
                                            label={"value"} 
                                            onChange={(e)=>{setExtraPropValue(index,e.target.value)}}></TextField>
                                    </div>
                                    
                                    <IconButton>
                                        <span className="material-symbols-outlined" onClick={()=>{deleteExtraProp(index)}}>
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            )
                        })}
                        <div className="extra-add-button">
                            <IconButton onClick={()=>{addextaprop()}}>
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </IconButton>
                        </div>
                    </div>
                </div>
            }
        </div>
    )


}


export default Proptoolbar;