
import { useState } from "react";
import "./DisplayObject.css";
import { useDispatch } from "react-redux";
import { getNextSchema } from "../../../Services/pipelines/pipeline.slice";

const getLocation = (location, key)=>{
    if(location==""){
        return location+key;
    }else{
        return location+"."+key;
    }
}

const MoreHorizon = ({direction, location, stageindex})=>{
    const [showmore, setShowMore] = useState(false);

    const dispatch = useDispatch();

    const getNextSchemaAction = ()=>{
        dispatch(getNextSchema({
            direction: direction,
            location: location,
            stageindex: stageindex
        }))
    }

    return (
        <>
            <span 
                className="more-horizon"
                onMouseEnter={()=>{setShowMore(true)}}
                onMouseLeave={()=>{setShowMore(false)}}
                onClick={()=>{getNextSchemaAction()}}
            >
                ...
            </span>
            {showmore&&
                <>
                    {direction?
                        <span style={{fontSize: "8px"}}>
                            show next
                        </span>:
                        <span style={{fontSize: "8px"}}>
                            show prev
                        </span>
                    } 
                </>                
            }
        </>
    )
}

const DisplayString = ({
    value, 
    start, 
    end, 
    total,
    location,
    spacer,
    stageindex
})=>{


    return (
            <>
                <span>
                    {start==0?
                        <></>:
                        <MoreHorizon direction={false} location={location} stageindex={stageindex}></MoreHorizon>
                    }
                    <span
                        style={{
                                    "whiteSpaceCollapse": "collapse",
                                    "textWrap": "wrap"
                                }}  
                    >{spacer}{value}</span>
                    {end==total?
                        <></>:
                        <MoreHorizon direction={true} location={location} stageindex={stageindex}></MoreHorizon>
                    }
                </span>
            </>
        )
}

const DisplayTable = ({
                        value, 
                        start, 
                        end, 
                        total, 
                        location, 
                        spacer,
                        stageindex
                    })=>{


    return (
        <>
            <br></br>
            <span>
                {spacer}{'\u00A0'}[
            </span>
            {start==0?
                <>
                    <br></br>
                </>:
                <>
                    <br></br>
                    <span>
                        {spacer}{'\u00A0\u00A0'}
                        <MoreHorizon direction={false} location={location} stageindex={stageindex}></MoreHorizon>
                    </span>
                    <br></br>
                </>
            }
            {value.map((v, index)=>{
                return(
                    <>
                        {v.type=="string"||v.type=="text"?
                            <>
                                <DisplayString
                                    value={v.value}
                                    start={v.start}
                                    end={v.end}
                                    total={v.total}
                                    location={getLocation(location, index.toString())}
                                    spacer={spacer+"\u00A0\u00A0"}
                                    stageindex={stageindex}
                                >
                                </DisplayString>
                            </>
                            :
                            <>
                                {v.type=="object"?
                                    <>
                                        {spacer}{"\u00A0\u00A0"}
                                        <DisplayObject
                                            schema={v.subschema}
                                            start={v.start}
                                            end={v.end}
                                            location={getLocation(location, index.toString())}
                                            spacer={spacer+"\u00A0\u00A0"}
                                            stageindex={stageindex}                            >
                                        </DisplayObject>
                                    </>
                                    :
                                    <>
                                     {v.type=="array"?
                                        <>
                                        {spacer}{"\u00A0\u00A0"}
                                        <DisplayTable
                                            value={v.value}
                                            start={v.start}
                                            end={v.end}
                                            total={v.total}
                                            location={getLocation(location, index.toString())}
                                            spacer={spacer+"\u00A0\u00A0"}
                                            stageindex={stageindex}
                                        >
                                        </DisplayTable>
                                        </>:
                                        <>
                                            {v.type=="file"?
                                                <span>
                                                    {spacer}{'\u00A0\u00A0'}{JSON.stringify(v.value, null, 2)}
                                                </span>:
                                                <span>
                                                    {spacer}{'\u00A0\u00A0'}{v.value}
                                                </span>
                                            }
                                        </>
                                        
                                     }
                                    </>
                                    
                                }
                            </>                    
                        }
                        {index==(value.length-1)?
                            <span>
                            </span>:
                            <span>
                                ,
                                <br></br>
                            </span>
                        }
                           
                    </>
                )
            })}
            {end==total?
                <>
                    <br></br>
                </>:
                <>
                    {','}
                    <br></br>
                    <span>
                        {spacer}{'\u00A0\u00A0'}
                        <MoreHorizon direction={true} location={location} stageindex={stageindex}></MoreHorizon>
                        <br></br>
                    </span>
                </>
                
            }
            <span>
                {spacer}{'\u00A0'}]
            </span>
        </>
    )
}

const DisplayObject = ({
                        schema, 
                        location, 
                        spacer, 
                        stageindex
                       })=>{

    return (
        <>
            {schema!=undefined&&
                <>
                    <span>
                        {"{"}
                    </span>
                        {schema?.map((sc)=>{
                            return (
                                <>
                                    <br></br>
                                    <>
                                        <span>
                                            {spacer}{'\u00A0'}{sc.key}:
                                        </span>
                                        <>
                                            {sc.type=="array"?
                                                <>
                                                    <DisplayTable
                                                        value={sc.value}
                                                        start={sc.start}
                                                        total={sc.total}
                                                        end = {sc.end}
                                                        location = {getLocation(location, sc.key)}
                                                        spacer={spacer+"\u00A0\u00A0"}
                                                        stageindex={stageindex}
                                                    >
                                                    </DisplayTable>
                                                </>:
                                                <>
                                                    {sc.type=="string"?
                                                        <DisplayString
                                                            value={sc.value}
                                                            start={sc.start}
                                                            total={sc.total}
                                                            end={sc.end}
                                                            location={getLocation(location, sc.key)}
                                                            stageindex={stageindex}
                                                        >
                                                        </DisplayString>:
                                                        <>
                                                            {sc.type=="object"?
                                                                <>
                                                                    <DisplayObject
                                                                        schema={sc.subschema}
                                                                        location={getLocation(location, sc.key)}
                                                                        spacer={spacer+"\u00A0\u00A0"}
                                                                        stageindex={stageindex}
                                                                    >
                                                                    </DisplayObject>
                                                                </>:
                                                                <>
                                                                {sc.type=="file"?
                                                                    <span>
                                                                        {JSON.stringify(sc.value, null, 2)}
                                                                    </span>:
                                                                    <span>
                                                                        {sc.value?.toString()}
                                                                    </span>
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                        
                                                    }
                                                </>
                                            }
                                        </>
                                    </>
                                </>
                            )
                        })}
                    <br></br>
                    <span>
                        {spacer}{"}"}
                    </span>
                </>
            }
        </>
    )
}

export default DisplayObject;