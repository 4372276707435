import StageContainer from "./StageContainer";

const MotorBodyTileRight = ({
                                
                            })=>{

    return (
        <>
        </>
        // <div className="motor-body-tile">
        //     <div className="motor-tile-body">
        //         {/* <div className="motor-type-header">
        //             <div className="motor-type-name">
        //                 Input
        //             </div>
        //             <div>
        //                 <span class="material-symbols-outlined" style={{fontSize:40}}>
        //                     fullscreen
        //                 </span>
        //             </div>
        //         </div> */}
        //         {pipeline[index]!=undefined&&
        //             <StageContainer 
        //             ></StageContainer>
        //         }
        //     </div>
        //     {(pipeline[index].last==false)&&
        //         <div className="motor-next-divider">
        //             <div className="motor-divider-line">
        //             </div>
        //             <div className="add-icon" onClick={()=>{addStage(index)}}>
        //                 <span class="material-symbols-outlined">
        //                     add_circle
        //                 </span>
        //             </div>
        //             {last==false&&
        //                 <div className="motor-divider-line">
        //                 </div>
        //             }
                
        //         </div>
        //     }
        // </div>
    )
}

export default MotorBodyTileRight;